import BuyBoxTab from './Components/BuyBoxTab';
import BuyerInfoTab from './Components/BuyerInfoTab';
import BuyerVerificationTab from './Components/BuyerVerificationTab';

export const getTabs = ({
  props,
  selectedBuyer,
  changedParams,
  setChangedParams,
  params,
  setParams,
  revertChangesHandler,
}) => [
  {
    key: 'info',
    label: 'Info',
    children: (
      <BuyerInfoTab
        selectedBuyer={selectedBuyer}
        changedParams={changedParams}
        setChangedParams={setChangedParams}
        params={params}
        setParams={setParams}
        revertChangesHandler={revertChangesHandler}
      />
    ),
  },
  {
    key: 'buybox',
    label: 'Buy Box',
    children: <BuyBoxTab {...props} />,
  },
  {
    key: 'verification',
    label: 'Verification',
    children: <BuyerVerificationTab selectedBuyer={selectedBuyer} />,
  },
];

export const AGENT_OPTIONS = [
  { label: 'Masha Cikota', value: '664e07b856061215560a3f1c' },
  { label: 'Elijah Perez', value: '671ff9fae41858ebc01282dc' },
  { label: 'Sally Marvin', value: '671ff9dae4185895a51282a6' },
  { label: 'Trevor Yard', value: '671ff9bae41858a51912825c' },
  { label: 'Anj Murphy', value: '670014a4e771d5b645782aac' },
  { label: 'Joseph Benezra', value: '66f2ed366fba809c4f423395' },
  { label: 'Aaron Godwin', value: '6761ba99a8f1df0b81541a4d' },
  { label: 'Brett Woodruff', value: '6761f9d7a8f1df6807544045' },
  { label: 'David Ordonez', value: '6785388c09f5fe21aa79065b' },
];
