import { Spin } from 'antd';
import ZorbaConfirmButton from '../../../../components/forms/ZorbaConfirmButton';

export default (props) => (
  <Spin spinning={props.isLoading}>
    <div className="buyer-status-box rejected">
      <div className="buyer-status-box--info">This buyer was rejected.</div>
      <ZorbaConfirmButton
        confirmTitle="Do you want to verify this buyer?"
        onConfirm={() => props?.onConfirm(true)}
      >
        Verify
      </ZorbaConfirmButton>
    </div>
  </Spin>
);
