import './index.scss';

import { v4 as uuid } from 'uuid';
import { BiArrowBack } from '@react-icons/all-files/bi/BiArrowBack';
import { ZorbaButton } from '../../../../../../components/forms';
import { BUTTON_VARIANT_DARK } from '../../../../../../components/forms/ZorbaButton/constants';

const FilterPropertyList = ({
  filterConfig,
  onSelectFilter,
  goBack,
  isBackButtonEnabled,
}) => (
  <div className="filter-property">
    {isBackButtonEnabled && (
      <ZorbaButton
        fullWidth={false}
        onClick={() => goBack && goBack()}
        icon={<BiArrowBack />}
        variant={BUTTON_VARIANT_DARK}
        className="btn-filter-property-back"
      >
        back
      </ZorbaButton>
    )}

    {filterConfig?.map((property) => (
      <div
        className="filter-property-item"
        key={uuid()}
        onClick={() => onSelectFilter(property)}
      >
        {property.title}
      </div>
    ))}
  </div>
);

export default FilterPropertyList;
