let textArea;
const isOS = () => navigator.userAgent.match(/ipad|iphone/i);

const createTextArea = (text) => {
  textArea = document.createElement('textArea');
  textArea.value = text;
  textArea.setAttribute('readonly', '');
  textArea.style.position = 'absolute';
  textArea.style.left = '-9999px';

  document.body.appendChild(textArea);
};
export const selectTextInElement = (node) => {
  // If it's a string we know it's a selector that needs to be fetched by ID, otherwise we simply use it
  node = typeof node === 'string' ? document.getElementById(node) : node;
  copy(node.innerText);
  if (document.body.createTextRange) {
    const range = document.body.createTextRange();
    range.moveToElementText(node);
    range.select();
  } else if (window.getSelection) {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(node);
    selection.removeAllRanges();
    selection.addRange(range);
  } else {
    console.warn('Could not select text in node: Unsupported browser.');
  }
};
const selectText = () => {
  let range;
  let selection;

  if (isOS()) {
    range = document.createRange();
    range.selectNodeContents(textArea);
    selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    textArea.setSelectionRange(0, 999999);
  } else {
    textArea.select();
  }
};

const copyToClipboard = () => {
  document.execCommand('copy');
  document.body.removeChild(textArea);
};

export const copy = (text) => {
  createTextArea(text);
  selectText();
  copyToClipboard();
};
