/**
 * Replace moment.js with day.js
 * Solution taken from https://ant.design/docs/react/replace-moment
 */
import { Form, DatePicker } from 'antd';

const RangePicker = ({ label, formClassName, tooltip, name, ...props }) =>
  label ? (
    <Form.Item
      name={name}
      label={label}
      className={formClassName}
      tooltip={tooltip}
    >
      <DatePicker.RangePicker {...props} />
    </Form.Item>
  ) : (
    <DatePicker.RangePicker {...props} />
  );

export default RangePicker;
