import {
  capitalizeFirstLetter,
  getDaysSince,
} from '@zorba-shared/core/Services/textService';
import { Tag, Popconfirm } from 'antd';
import { ImCheckmark } from '@react-icons/all-files/im/ImCheckmark';
import { ImCross } from '@react-icons/all-files/im/ImCross';

export const getTableColumns = ({
  handleEntityMappingSuccess,
  handleEntityMappingFailure,
  handleContactMappingFailure,
  handleContactMappingSuccess,
}) => [
  {
    title: 'Kind',
    key: 'kind',
    render: ({ kind }) => capitalizeFirstLetter(kind),
  },
  {
    title: 'Status',
    key: 'status',
    render: ({ status }) => capitalizeFirstLetter(status),
  },
  {
    title: 'Name',
    key: 'entityName',
    render: ({ entityName, mappedOrganizationId }) => (
      <a
        href={
          mappedOrganizationId
            ? `https://data.getzorba.com/top-buyers/${mappedOrganizationId}/${entityName}`
            : `https://data.getzorba.com/top-buyers/${entityName}`
        }
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'underline', color: 'black' }}
      >
        {entityName}
      </a>
    ),
  },
  {
    title: 'Requesting User',
    key: 'requestingUser',
    render: ({ requestingUser }) => requestingUser?.name,
  },
  {
    title: 'Requesting Organization',
    key: 'requestingOrganization',
    render: ({ requestingOrganization }) => `${requestingOrganization?.name}`,
  },
  {
    title: 'Requested At',
    key: 'createdAt',
    render: ({ createdAt }) => (
      <Tag>{`${getDaysSince(new Date(createdAt))} days ago`}</Tag>
    ),
  },
  {
    title: '',
    key: 'actions',
    render: ({ _id: requestId, status, entityName, kind }) => {
      if (status === 'pending') {
        return (
          <div style={{ display: 'flex', columnGap: '10px' }}>
            <Popconfirm
              title="Mark request as a success"
              okText="Submit"
              cancelText="Cancel"
              onConfirm={() => {
                if (kind === 'entity') {
                  handleEntityMappingSuccess(entityName, requestId);
                }
                if (kind === 'contact') {
                  handleContactMappingSuccess(requestId);
                }
              }}
            >
              <ImCheckmark style={{ cursor: 'pointer' }} />
            </Popconfirm>
            <Popconfirm
              title="Mark request as a failure"
              okText="Submit"
              cancelText="Cancel"
              onConfirm={() => {
                if (kind === 'entity') {
                  handleEntityMappingFailure(requestId);
                }
                if (kind === 'contact') {
                  handleContactMappingFailure(requestId);
                }
              }}
            >
              <ImCross style={{ cursor: 'pointer' }} />
            </Popconfirm>
          </div>
        );
      }
      return '';
    },
  },
];
