import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import ContentHeader from '../../components/ContentHeader';
import { PreDealsList } from './Components/PreDealsList';

const PreDealsPage = () => {
  useDocumentTitle('Pre Deals Search');

  return (
    <>
      <ContentHeader title="Pre-Deals search" />
      <PreDealsList />
    </>
  );
};

export default PreDealsPage;
