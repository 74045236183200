import { push } from 'connected-react-router';
import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Route } from 'react-router-dom';
import { buildAuthPageUrl } from '../services/urlBuilderService';
import { getUserData } from '../reducer/Auth/actions';
import ProtectedLayout from '../containers/Layout/index';

import {
  getAuthToken,
  postRefreshTasks,
  QUERY_PARAM_USER_ID,
  QUERY_PARAM_MAGIC_TOKEN,
} from '../services/authService';
import { getQueryParam } from '../services/clientService';
import { Spinner } from '../components/Spinner';

const PrivateRoute = (props) => {
  const {
    isAppLoading,
    userDetails,
    component: Component,
    path,
    sidebar,
    ...rest
  } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    postRefreshTasks();
  }, []);
  useEffect(() => {
    const magicToken = getQueryParam(QUERY_PARAM_MAGIC_TOKEN);
    const queryUserId = getQueryParam(QUERY_PARAM_USER_ID);
    const authToken = getAuthToken();

    if (!magicToken && !authToken) {
      dispatch(push(buildAuthPageUrl()));
    } else if (!userDetails && !isAppLoading) {
      let placeFriendlyUrl;

      props.getUserData(queryUserId, placeFriendlyUrl);
    }
  }, [isAppLoading, userDetails]);

  const render = (props) =>
    userDetails ? (
      <ProtectedLayout {...props}>
        <Component {...props} sidebar={sidebar} userDetails={userDetails} isAppLoading={isAppLoading} />
      </ProtectedLayout>
    ) : (
      <Spinner />
    );

  return <Route path={path} render={render} {...rest} />;
};
const mapStateToProps = ({ AuthReducer }) => {
  const { userDetails, isAppLoading } = AuthReducer;
  return {
    userDetails,
    isAppLoading,
  };
};
const mapDispatchToProps = {
  getUserData,
  push,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
