import {
  getFormattedTime,
  getPriceText,
} from '@zorba-shared/core/Services/textService';

export const getTableColumns = () => [
  {
    title: 'Address',
    key: 'address',
    render: ({ address }) => address,
  },
  {
    title: 'State',
    key: 'state',
    render: ({ state }) => state,
  },
  {
    title: 'Entity name',
    key: 'entityName',
    render: ({ entityName }) => entityName,
  },
  {
    title: 'Purchase price',
    key: 'purchasePrice',
    render: ({ purchasePrice }) => getPriceText(purchasePrice),
  },
  {
    title: 'AVM',
    key: 'avm',
    render: ({ avm }) => getPriceText(avm),
  },
  {
    title: 'Transaction date',
    key: 'transactionDate',
    render: ({ transactionDate }) => getFormattedTime(transactionDate),
  },
];
