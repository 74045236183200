import _ from 'lodash';

export const getChangedFields = (selectedBuyBox, updatedParams) => {
  const res = {};

  const firstAreas = selectedBuyBox.areas.map((x) => x.formattedAddress);
  const secondAreas = updatedParams.areas.map((x) => x.formattedAddress);

  if (!_.isEqual(firstAreas, secondAreas)) {
    res.areas = updatedParams.areas;
  }

  if (selectedBuyBox.title !== updatedParams.title) {
    res.title = updatedParams.title;
  }

  if (!_.isEqual(selectedBuyBox.bedroomRange, updatedParams.bedroomRange)) {
    res.bedroomRange = updatedParams.bedroomRange;
  }
  if (!_.isEqual(selectedBuyBox.bathroomRange, updatedParams.bathroomRange)) {
    res.bathroomRange = updatedParams.bathroomRange;
  }
  if (!_.isEqual(selectedBuyBox.bathroomRange, updatedParams.bathroomRange)) {
    res.bathroomRange = updatedParams.bathroomRange;
  }

  if (!_.isEqual(selectedBuyBox.yearBuiltRange, updatedParams.yearBuiltRange)) {
    res.yearBuiltRange = updatedParams.yearBuiltRange;
  }

  if (!_.isEqual(selectedBuyBox.priceRange, updatedParams.priceRange)) {
    res.priceRange = updatedParams.priceRange;
  }

  if (!_.isEqual(selectedBuyBox.radiusRange, updatedParams.radiusRange)) {
    res.radiusRange = updatedParams.radiusRange;
  }
  if (!_.isEqual(selectedBuyBox.parkingTypes, updatedParams.parkingTypes)) {
    res.parkingTypes = updatedParams.parkingTypes;
  }
  if (
    !_.isEqual(
      selectedBuyBox.allowedFloodZones,
      updatedParams.allowedFloodZones,
    )
  ) {
    res.allowedFloodZones = updatedParams.allowedFloodZones;
  }
  if (
    !_.isEqual(
      selectedBuyBox.notificationsRecipients,
      updatedParams.notificationsRecipients,
    )
  ) {
    res.notificationsRecipients = updatedParams.notificationsRecipients;
  }
  if (!_.isEqual(selectedBuyBox.agentName, updatedParams.agentName)) {
    res.agentName = updatedParams.agentName;
  }
  if (
    !_.isEqual(
      selectedBuyBox.notificationsPhones,
      updatedParams.notificationsPhones,
    )
  ) {
    res.notificationsPhones = updatedParams.notificationsPhones;
  }
  if (selectedBuyBox.isBuyBoxActive !== updatedParams.isBuyBoxActive) {
    res.isBuyBoxActive = updatedParams.isBuyBoxActive;
  }
  return res;
};

export const findAddedAndRemovedAreas = (obj1, obj2) => {
  const areas1 = obj1.areas || [];
  const areas2 = obj2.areas || [];

  const addedAreas = _.difference(areas2, areas1);
  const removedAreas = _.difference(areas1, areas2);

  return {
    addedAreas,
    removedAreas,
  };
};
