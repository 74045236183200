import { Controller } from 'react-hook-form';
import { getLatestAskingPriceBySeller } from '@zorba-shared/core/Constants/offer';
import { getPriceText } from '@zorba-shared/core/Services/textService';
import { Divider } from 'antd';

import './index.scss';

export default (props) => {
  const { control, permissions, offerParams, selectedDealSubmission, watch } =
    props;

  if (!offerParams) return null;

  const latestAskingPrice = getLatestAskingPriceBySeller(
    selectedDealSubmission,
  );
  const feeAmount = watch('feeAmount');

  return (
    <div className="offer-status-strip">
      {permissions.canSubmitOfferToTheSeller && (
        <>
          <div className="form-label">Offer Calculator</div>
          <Controller
            name="offerParams"
            control={control}
            render={() => (
              <div className="offer-params-wrapper">
                <div className="stat-item">
                  <div className="title offer-header">
                    Asking price we show the buyer
                  </div>
                  <div className="value offer-header">
                    {getPriceText(latestAskingPrice + feeAmount)}
                  </div>
                </div>
                <Divider />
                <div className="stat-item">
                  <div className="title">Our Fee</div>
                  <div className="value">{getPriceText(feeAmount)}</div>
                </div>
                <div className="stat-item">
                  <div className="title">{`Seller's asking price`}</div>
                  <div className="value">{getPriceText(latestAskingPrice)}</div>
                </div>
              </div>
            )}
          />
        </>
      )}
    </div>
  );
};
