import { useEffect, useState } from 'react';
import { Drawer, Steps, Tag } from 'antd';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineUserDelete } from '@react-icons/all-files/ai/AiOutlineUserDelete';
import { AiOutlineUserAdd } from '@react-icons/all-files/ai/AiOutlineUserAdd';
import { MdAutorenew } from '@react-icons/all-files/md/MdAutorenew';
import { GiTakeMyMoney } from '@react-icons/all-files/gi/GiTakeMyMoney';
import { FaHandshake } from '@react-icons/all-files/fa/FaHandshake';
import { MdFindReplace } from '@react-icons/all-files/md/MdFindReplace';
import { GiPayMoney } from '@react-icons/all-files/gi/GiPayMoney';
import { FaFlagCheckered } from '@react-icons/all-files/fa/FaFlagCheckered';
import { getLatestAskingPriceBySeller } from '@zorba-shared/core/Constants/offer';

import { getFriendlyTime } from '@zorba-shared/core/Services/textService';
import { setDrawerState } from '../../../../reducer/Drawer/actions';
import { ACTIVITY_DEAL_SUBMISSION_DRAWER } from '../../../../reducer/Drawer/types';
import { ZorbaButton } from '../../../../components/forms';
import { BUTTON_VARIANT_DARK } from '../../../../components/forms/ZorbaButton/constants';
import { RejectBuyerModal } from './Components/RejectBuyerModal';
import { SubmitOfferModal } from './Components/SubmitOfferModal';
import { CounterOfferModal } from './Components/CounterOfferModal';
import { AcceptOfferModal } from './Components/AcceptOfferModal';
import { UpdateAskingPriceModal } from './Components/UpdateAskingPriceModal';
import { setModalState } from '../../../../reducer/Modal/actions';
import {
  MODAL_REJECT_BUYER,
  MODAL_REMOVE_BUYER_EVENT,
  MODAL_SUBMIT_OFFER,
  MODAL_COUNTER_OFFER,
  MODAL_ACCEPT_OFFER,
  MODAL_REPLACE_DEAL_SUBMISSION,
  MODAL_UPDATE_ASKING_PRICE,
  MODAL_FINAL_OFFER,
} from '../../../../reducer/Modal/types';
import { renewOffer } from '../../../../reducer/DealSubmission/actions';

import { EventDescription } from './Components/EventDescription';
import { DealSubmissionMatchTitle } from './Components/DealSubmissionMatchTitle';
import { DealSubmissionMatchSubTitle } from './Components/DealSubmissionMatchSubTitle';
import { RemoveBuyerEventModal } from './Components/RemoveBuyerEventModal';
import { ReplaceDealSubmissionModal } from './Components/ReplaceDealSubmissionModal';
import {
  getDealSubmissionMatchTitle,
  getDealSubmissionTitle,
  mapDealSubmissionMatchEventTypeToTitle,
  mapDealSubmissionStepToTitle,
  LOW_BALL_OFFER_MARGIN,
  getOneBeforeLastEventForOrganization,
} from './constants';

import './index.scss';
import { FinalOfferModal } from './Components/FinalOfferModal';

export const ActivityDrawer = ({
  selectedDealSubmission,
  otherDealSubmissions,
}) => {
  const { events } = selectedDealSubmission;
  const { isActivityDealSubmissionDrawerOpen } = useSelector(
    ({ DrawerReducer }) => DrawerReducer,
  );
  const { isRenewingOffer } = useSelector(
    ({ DealSubmissionReducer }) => DealSubmissionReducer,
  );
  const [selectedBuyerEvent, setSelectedBuyerEvent] = useState();
  useState();
  const [oneBeforeSelectedBuyerEvent, setOneBeforeSelectedBuyerEvent] =
    useState();
  const dispatch = useDispatch();
  const handleActivityDrawerClose = () => {
    dispatch(setDrawerState(ACTIVITY_DEAL_SUBMISSION_DRAWER, false));
  };
  const handleOpenModal = (modalName) => {
    dispatch(setModalState(modalName, true));
  };

  const handleRenewOffer = () => {
    dispatch(
      renewOffer({
        dealSubmissionId: selectedDealSubmission._id,
        dealSubmissionMatchId: selectedDealSubmission.associatedMatch,
      }),
    );
  };

  const handleOpenRemoveBuyerEventModal = (event) => {
    setSelectedBuyerEvent(event);
    dispatch(setModalState(MODAL_REMOVE_BUYER_EVENT, true));
  };

  const lastAskingPriceBySeller = getLatestAskingPriceBySeller(
    selectedDealSubmission,
  );

  const dealSubmissionMatchesEvents = selectedDealSubmission.matches.flatMap(
    (match) => {
      if (match.events.length) {
        const offerRequestEvents = match.events.filter((event) =>
          [
            'offer_request',
            'offer',
            'firm_on_offer',
            'rejected_by_seller',
            'rejected_by_buyer',
            'seller_update_asking_price',
          ].some((kind) => kind === event.kind),
        );
        const lastSubmittedOffer = _.last(
          match.events.filter((e) => e.kind === 'offer'),
        );
        return offerRequestEvents.map((event) => ({
          ...event,
          source: 'buyers',
          organizationName: match.organization?.name,
          dealSubmissionMatchId: match._id,
          amount:
            event.kind === 'firm_on_offer'
              ? lastSubmittedOffer.amount
              : event.amount,
          feeAmount: event.feeAmount || match.feeAmount,
        }));
      }

      return [];
    },
  );

  useEffect(() => {
    if (selectedBuyerEvent) {
      setOneBeforeSelectedBuyerEvent(
        getOneBeforeLastEventForOrganization(
          dealSubmissionMatchesEvents,
          selectedBuyerEvent.organizationName,
        ),
      );
    }
  }, [selectedBuyerEvent]);

  const mappedDealSubmissionMatchesEvents = dealSubmissionMatchesEvents.map(
    (event) => {
      const organizationEvents = dealSubmissionMatchesEvents.filter(
        (dealSubmissionMatchEvent) =>
          dealSubmissionMatchEvent.organizationName === event.organizationName,
      );
      const lastOrganizationEvent = _.last(organizationEvents);
      const isLastOrganizationEvent =
        lastOrganizationEvent &&
        lastOrganizationEvent._id === event._id &&
        lastOrganizationEvent.kind !== 'offer_request';

      return {
        title: (
          <DealSubmissionMatchTitle
            description={getDealSubmissionMatchTitle(
              event?.kind,
              event?.organizationName,
            )}
            isLowBallOffer={
              event.kind === 'offer'
                ? lastAskingPriceBySeller - event.amount > LOW_BALL_OFFER_MARGIN
                : false
            }
          />
        ),
        subTitle: (
          <DealSubmissionMatchSubTitle
            event={event}
            subTitle={getFriendlyTime(event.createdAt)}
            isLastEvent={isLastOrganizationEvent}
            handleOpenModal={handleOpenRemoveBuyerEventModal}
          />
        ),
        description: <EventDescription event={event} />,
        createdAt: event.createdAt,
        status:
          event?.kind === 'rejected_by_seller' ||
          event?.kind === 'rejected_by_buyer'
            ? 'error'
            : '',
      };
    },
  );

  const mappedDealSubmissionEvents = events.map((event) => ({
    title:
      event.kind === 'buyer_reject' && !event.user
        ? 'Automation rejected the deal'
        : getDealSubmissionTitle(
            event?.kind,
            selectedDealSubmission?.associatedMatch?.organization?.name,
          ),
    subTitle: getFriendlyTime(event.createdAt),
    description: <EventDescription event={{ ...event, source: 'partners' }} />,
    status:
      event?.kind === 'buyer_reject' || event?.kind === 'seller_reject'
        ? 'error'
        : '',
    createdAt: event.createdAt,
  }));

  const combinedSortedItems = [
    ...mappedDealSubmissionEvents,
    ...mappedDealSubmissionMatchesEvents,
  ].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

  const items = [
    {
      title: 'Seller requested an offer',
      subTitle: getFriendlyTime(selectedDealSubmission.createdAt),
      description: (
        <EventDescription
          event={{
            createdAt: selectedDealSubmission.createdAt,
            user: { name: selectedDealSubmission?.submittedBy?.name },
            amount: selectedDealSubmission.userData.askingPrice,
            source: 'partners',
            kind: 'deal_submission',
          }}
        />
      ),
    },
    ...combinedSortedItems,
    { title: 'Closed won', status: 'wait' },
  ];

  const isAnyOfferInTheEvents = selectedDealSubmission.matches
    .flatMap((match) => match.events)
    .find((event) => event.kind === 'offer');

  const isOfferButtonDisabled =
    !selectedDealSubmission.matches.filter(
      (match) =>
        match.status === 'offer' ||
        match.status === 'firm_on_offer' ||
        match.status === 'seller_update_asking_price',
    ).length ||
    selectedDealSubmission.step === 'pending_agreement' ||
    selectedDealSubmission.step === 'pending_inspection';

  return (
    <Drawer
      title="Activity logs"
      placement="right"
      onClose={handleActivityDrawerClose}
      open={isActivityDealSubmissionDrawerOpen}
      width={500}
    >
      <div className="activity-log-container">
        <div className="statuses-container">
          <div className="buyers-dashboard-status-container">
            <h4>{`Partner's app status: `}</h4>
            <Tag color="geekblue">
              {mapDealSubmissionStepToTitle[selectedDealSubmission.step]}
            </Tag>
          </div>
          {selectedDealSubmission.matches.map((match) => (
            <div className="buyers-dashboard-status-container" key={uuid()}>
              <h4>{`${match?.organization?.name}: `}</h4>
              <Tag color="geekblue">
                {mapDealSubmissionMatchEventTypeToTitle[match.status]}
              </Tag>
            </div>
          ))}
        </div>
        <div className="actions-container">
          <div className="action-container">
            <ZorbaButton
              variant={BUTTON_VARIANT_DARK}
              fullWidth
              onClick={() => handleOpenModal(MODAL_SUBMIT_OFFER)}
              disabled={isOfferButtonDisabled}
            >
              Submit an offer
              <AiOutlineUserAdd size={25} />
            </ZorbaButton>
          </div>
          <div className="action-container">
            <ZorbaButton
              variant={BUTTON_VARIANT_DARK}
              fullWidth
              onClick={() => handleOpenModal(MODAL_FINAL_OFFER)}
              disabled={isOfferButtonDisabled}
            >
              Submit final offer
              <FaFlagCheckered size={25} />
            </ZorbaButton>
          </div>
          <div className="action-container">
            <ZorbaButton
              fullWidth
              variant={BUTTON_VARIANT_DARK}
              onClick={() => handleOpenModal(MODAL_REJECT_BUYER)}
              disabled={
                !selectedDealSubmission.matches.filter(
                  (match) => match.status !== 'rejected_by_seller',
                ).length
              }
            >
              Reject by seller
              <AiOutlineUserDelete size={25} />
            </ZorbaButton>
          </div>
          <div className="action-container">
            <ZorbaButton
              variant={BUTTON_VARIANT_DARK}
              fullWidth
              onClick={() => handleOpenModal(MODAL_COUNTER_OFFER)}
              disabled={isOfferButtonDisabled}
            >
              Counter by seller
              <GiTakeMyMoney size={25} />
            </ZorbaButton>
          </div>
          <div className="action-container">
            <ZorbaButton
              variant={BUTTON_VARIANT_DARK}
              fullWidth
              onClick={() => handleOpenModal(MODAL_ACCEPT_OFFER)}
              disabled={isOfferButtonDisabled}
            >
              Accept by seller
              <FaHandshake size={25} />
            </ZorbaButton>
          </div>
          <div className="action-container">
            <ZorbaButton
              fullWidth
              variant={BUTTON_VARIANT_DARK}
              onClick={handleRenewOffer}
              disabled={!isAnyOfferInTheEvents || isRenewingOffer}
              loading={isRenewingOffer}
            >
              Renew an offer
              <MdAutorenew size={25} />
            </ZorbaButton>
          </div>
          <div className="action-container">
            <ZorbaButton
              fullWidth
              variant={BUTTON_VARIANT_DARK}
              onClick={() => handleOpenModal(MODAL_REPLACE_DEAL_SUBMISSION)}
            >
              Replace deal
              <MdFindReplace size={25} />
            </ZorbaButton>
          </div>
          <div className="action-container">
            <ZorbaButton
              fullWidth
              variant={BUTTON_VARIANT_DARK}
              onClick={() => handleOpenModal(MODAL_UPDATE_ASKING_PRICE)}
            >
              Update price
              <GiPayMoney size={25} />
            </ZorbaButton>
          </div>
        </div>
        <Steps
          direction="vertical"
          items={items}
          labelPlacement="vertical"
          className="activity-log-steps"
          current={
            mappedDealSubmissionEvents.length +
            mappedDealSubmissionMatchesEvents.length
          }
        />
      </div>
      <RejectBuyerModal selectedDealSubmission={selectedDealSubmission} />
      <SubmitOfferModal selectedDealSubmission={selectedDealSubmission} />
      <CounterOfferModal selectedDealSubmission={selectedDealSubmission} />
      <AcceptOfferModal selectedDealSubmission={selectedDealSubmission} />
      <ReplaceDealSubmissionModal
        selectedDealSubmission={selectedDealSubmission}
        otherDealSubmissions={otherDealSubmissions}
      />
      <RemoveBuyerEventModal
        selectedBuyerEvent={selectedBuyerEvent}
        oneBeforeSelectedBuyerEvent={oneBeforeSelectedBuyerEvent}
      />
      <UpdateAskingPriceModal selectedDealSubmission={selectedDealSubmission} />
      <FinalOfferModal selectedDealSubmission={selectedDealSubmission} />
    </Drawer>
  );
};
