import { v4 as uuid } from 'uuid';
import { Tag } from 'antd';
import { getFormattedTime } from '@zorba-shared/core/Services/textService';
import {
  DEFAULT_FILTERS,
  DEAL_SUBMISSION_STATUS_OPTIONS,
} from '../../constants';

const camelCaseToTitleCase = (str) => {
  // Use regular expression to split the string on uppercase letters
  const words = str.split(/(?=[A-Z])/);

  // Capitalize the first letter of each word and join them with spaces
  return words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
const DealSubmissionAppliedFilter = ({
  form,
  searchCriteria,
  onRemovedFilter,
  powerBuyers,
}) => {
  const handleRemoveTag = (filterName) => {
    if (onRemovedFilter) {
      onRemovedFilter(filterName);
    }
    form.setFieldValue(filterName, DEFAULT_FILTERS[filterName]);
  };
  return (
    <div
      className="applied-filter"
      style={{ paddingTop: searchCriteria.length > 0 ? '16px' : '' }}
    >
      <div className="applied-filter__item">
        {Object.entries(searchCriteria).map(([filterName, value]) => {
          if (Array.isArray(value) && value.length > 0) {
            const getLabels = () => {
              if (filterName === 'status') {
                return value.map(
                  (element) =>
                    DEAL_SUBMISSION_STATUS_OPTIONS.find(
                      (option) => option.value === element,
                    )?.label,
                );
              }
              if (filterName === 'powerBuyers') {
                return value.map(
                  (element) =>
                    powerBuyers.find((option) => option.value === element)
                      ?.label,
                );
              }
              if (filterName === 'areas') {
                return value.map((element) => element.formattedAddress);
              }
              if (filterName === 'createdAtRange') {
                return value.map((element) =>
                  getFormattedTime(element, 'YYYY-MM-DD'),
                );
              }
              return [];
            };

            const labels = getLabels();
            return (
              <Tag
                closable
                onClose={() => handleRemoveTag(filterName)}
                key={uuid()}
                className="applied-filter__item--tag"
              >
                {`${camelCaseToTitleCase(filterName)}: ${labels.join(', ')}`}
              </Tag>
            );
          }
          if (!Array.isArray(value) && value) {
            return (
              <Tag
                closable
                onClose={() => handleRemoveTag(filterName)}
                key={uuid()}
                className="applied-filter__item--tag"
              >
                {`${camelCaseToTitleCase(filterName)}: ${value}`}
              </Tag>
            );
          }

          return null;
        })}
      </div>
    </div>
  );
};
export default DealSubmissionAppliedFilter;
