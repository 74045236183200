export const SET_LOADING = 'SET_LOADING';

export const SET_PRE_DEALS = 'SET_PRE_DEALS';

export const SET_CURRENT_PAGE = 'SET_PAGE';
export const SET_PAGE_SIZE = 'SET_PAGE_SIZE';

export const SET_SELECTED_PRE_DEAL = 'SET_SELECTED_PRE_DEAL';

export const SET_IS_FETCHING_PRE_DEAL = 'SET_IS_FETCHING_PRE_DEAL';
export const SET_SELECTED_PRE_DEAL_MATCH = 'SET_SELECTED_PRE_DEAL_MATCH';
