import { Link } from 'react-router-dom';
import { Tag } from 'antd';
import {
  getFriendlyTime,
  getPriceText,
} from '@zorba-shared/core/Services/textService';
import _ from 'lodash';
import { DEAL_SUBMISSION_STATUS_OPTIONS } from '../DealSubmissionFilter/constants';
import {
  buildBuyerPageUrl,
  buildDealSubmissionPageUrl,
} from '../../../../services/urlBuilderService';

export const getTableColumns = () => [
  {
    title: 'last updated',
    key: 'updatedAt',
    dataIndex: 'updatedAt',
    render: (updatedAt) => getFriendlyTime(updatedAt),
    sorter: (a, b) => (a.updatedAt > b.createdAt ? 1 : -1),
  },
  {
    title: 'Address',
    key: 'address',
    render: ({ address, _id }) => (
      <Link
        className="buyer-name"
        to={buildDealSubmissionPageUrl({ dealSubmission: { _id } })}
      >
        {address.formattedAddress}
      </Link>
    ),
  },
  {
    title: 'Status',
    key: 'status',
    render: ({ step }) => (
      <Tag className={`zorba-tag ${step}`}>
        {
          DEAL_SUBMISSION_STATUS_OPTIONS.find((option) => option.value === step)
            ?.label
        }
      </Tag>
    ),
  },
  {
    title: 'Seller',
    key: 'seller',
    render: ({ organization, submittedBy }) => (
      <Link
        className="buyer-name"
        to={buildBuyerPageUrl({ userDetails: { _id: submittedBy?._id } })}
      >
        {submittedBy?.name || organization?.name}
      </Link>
    ),
  },
  {
    title: 'Matches',
    key: 'matches',
    render: ({ powerBuyerMatches }) => {
      const matches = powerBuyerMatches?.filter((item) => item.status) || [];

      return matches.length;
    },
  },
  {
    title: 'Offer requests',
    key: 'offers',
    render: ({ matches }) => matches?.length,
  },
  {
    title: 'ARV',
    key: 'arv',
    render: ({ underwriting }) => getPriceText(underwriting?.arv),
  },
  {
    title: 'Inspection mode',
    key: 'inspectionMode',
    render: (dealSubmission) => {
      const finalOffer = _.last(
        dealSubmission.events.filter((e) => e.kind === 'buyer_final_offer'),
      );

      const lastOfferEvent =
        finalOffer ||
        _.last(dealSubmission.events.filter((event) => event.kind === 'offer'));

      return lastOfferEvent?.inspectionMode || '';
    },
  },
  {
    title: 'Did finish underwriting?',
    key: 'didFinishUnderwriting',
    render: ({ underwriting }) => (underwriting?.arv ? 'Yes' : ''),
  },
  {
    title: 'Linked Buyer',
    key: 'linkedBuyer',
    render: ({ matches }) => {
      const sortedMatches = matches?.length
        ? matches.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
        : [];
      const userLink =
        sortedMatches?.length > 0 &&
        sortedMatches[0].organization?.members?.length > 0
          ? sortedMatches[0]?.organization.members[0]
          : null;
      return (
        <Tag>
          <Link
            className="buyer-name"
            to={buildBuyerPageUrl({
              userDetails: { _id: userLink },
            })}
          >
            {sortedMatches[0]?.organization?.name}
          </Link>
        </Tag>
      );
    },
  },
];
