import * as types from './types';
import { postRequest } from '../../services/requestService';
import { showErrorToast } from '../../services/notificationsService';

export const getPropertyDataJobs =
  (params = {}) =>
  async (dispatch) => {
    dispatch({
      type: types.SET_IS_FETCHING_JOBS,
      payload: { isFetchingData: true },
    });
    const payload = { isFetchingData: false };

    try {
      const res = await postRequest(
        '/backoffice/property-data/job/search',
        params,
      );
      const { total, data } = res.data || {};

      payload.total = total;
      payload.data = data;
    } catch (error) {
      showErrorToast('Fetching property data jobs failed');
    }

    dispatch({ type: types.SET_PROPERTY_DATA_JOBS_RESULTS, payload });
  };
