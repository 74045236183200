import { Space } from 'antd';
import { ZorbaButton } from '../forms';
import './index.scss';

/**
 * The H1 title that is being shown in each page
 * @returns
 */
export default ({ title, actionButtons = [], variant }) => (
  <div className="content-header">
    {title && <h1>{title}</h1>}
    {actionButtons && actionButtons.length > 0 ? (
      <Space>
        {actionButtons.map((button) => {
          const { label, ...rest } = button;
          return (
            <ZorbaButton
              key={`action_btn_${label}`}
              {...rest}
              variant={variant}
            >
              {label || 'Button Label'}
            </ZorbaButton>
          );
        })}
      </Space>
    ) : (
      ''
    )}
  </div>
);
