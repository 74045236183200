import { useState, useEffect } from 'react';
import { Table } from 'antd';
import { useDispatch } from 'react-redux';
import { getRequest } from '../../../../services/requestService';
import { showErrorToast } from '../../../../services/notificationsService';
import { isMobile } from '../../../../services/clientService';
import { ReassignReferralModal } from './Components/ReassignReferralModal';
import { setModalState } from '../../../../reducer/Modal/actions';
import { MODAL_REASSIGN_REFERRAL } from '../../../../reducer/Modal/types';
import { getTableColumns } from './constants';
import { ZorbaInput } from '../../../../components/forms';

export const UserReferrals = () => {
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [userReferrals, setUserReferrals] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedReferral, setSelectedReferral] = useState();
  const dispatch = useDispatch();
  const loadUserReferrals = async () => {
    setIsLoading(true);
    try {
      const res = await getRequest(
        '/backoffice/user/referrals/get-user-referrals',
      );
      setUserReferrals(res?.data?.data);
    } catch (error) {
      showErrorToast('Fetching user referrals failed');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadUserReferrals();
  }, []);

  const tableScroll = {
    scrollToFirstRowOnChange: true,
    x: isMobile() ? true : 'max-content',
  };

  const pagination = {
    position: ['bottomRight'],
    defaultPageSize: 50,
  };

  const handleOpenModal = (referral) => {
    setSelectedReferral(referral);
    dispatch(setModalState(MODAL_REASSIGN_REFERRAL, true));
  };

  const handleSearch = (value) => {
    const filtered = userReferrals.filter(
      (item) =>
        item.referredUser.name.toLowerCase().includes(value.toLowerCase()) ||
        item.referredUser.email.toLowerCase().includes(value.toLowerCase()) ||
        item.referringUser.name.toLowerCase().includes(value.toLowerCase()) ||
        item.referringUser.email.toLowerCase().includes(value.toLowerCase()),
    );
    setFilteredData(filtered);
    setSearchText(value);
  };

  return (
    <div className="user-referrals-page">
      <div style={{ marginBottom: '25px' }}>
        <ZorbaInput
          placeholder="Search by user name or email"
          value={searchText}
          onChange={(e) => handleSearch(e.target.value)}
          disabled={isLoading}
        />
      </div>
      <Table
        rowKey="_id"
        dataSource={searchText ? filteredData : userReferrals}
        loading={isLoading}
        columns={getTableColumns({ handleOpenModal })}
        scroll={tableScroll}
        pagination={pagination}
      />
      <ReassignReferralModal
        selectedReferral={selectedReferral}
        loadReferrals={loadUserReferrals}
      />
    </div>
  );
};
