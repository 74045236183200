import { Link } from 'react-router-dom';
import { FaStar } from '@react-icons/all-files/fa/FaStar';
import { TagItem } from '../../components/Tag';

const calculatePercentage = (value1, value2) => {
  if (value2 === 0 || value1 === 0) {
    return '';
  }

  const percentage = (value1 / value2) * 100;
  return `(${parseFloat(percentage.toFixed(2))}%)`;
};

export const getTableColumns = () => [
  {
    title: '',
    key: '',
    render: ({ didSendFirstOffer }) => (didSendFirstOffer ? <FaStar /> : ''),
  },
  {
    title: 'Customer',
    key: 'customer',
    render: ({ postgresId, name, entitiesCounter, isSoftPaused }) => (
      <Link className="buyer-name" to={`/buyers-report/${postgresId}/${name}`}>
        {name}
        <div className="tags-container">
          <TagItem>{`${entitiesCounter} ${
            entitiesCounter === '1' ? 'entity' : 'entities'
          }`}</TagItem>
          {isSoftPaused ? <TagItem>Soft Paused</TagItem> : ''}
        </div>
      </Link>
    ),
  },
  {
    title: 'Total Acquired Properties',
    key: 'totalAcquiredProperties',
    sorter: (a, b) => a.totalAcquiredProperties - b.totalAcquiredProperties,
    render: ({ totalAcquiredProperties }) => totalAcquiredProperties,
  },
  {
    title: '# of deals we sent them',
    key: 'numberOfDealsWeRequestedOffer',
    render: ({ requestedOffers }) => requestedOffers,
    sorter: (a, b) => a.requestedOffers - b.requestedOffers,
  },
  {
    title: 'Did not respond',
    key: 'didNotRespond',
    sorter: (a, b) => a.didNotRespond - b.didNotRespond,
    render: ({ didNotRespond, requestedOffers }) =>
      `${didNotRespond} ${calculatePercentage(didNotRespond, requestedOffers)}`,
  },
  {
    title: '# of offers',
    key: 'numberOfOffers',
    sorter: (a, b) => a.offers - b.offers,
    render: ({ offers, requestedOffers }) =>
      `${offers} ${calculatePercentage(offers, requestedOffers)}`,
  },
  {
    title: '# of rejected deals',
    key: 'rejectedByBuyer',
    render: ({ rejectedByBuyer, requestedOffers }) =>
      `${rejectedByBuyer} ${calculatePercentage(
        rejectedByBuyer,
        requestedOffers,
      )}`,
  },
  {
    title: 'Offers submitted to seller',
    key: 'offersSubmittedToSeller',
    sorter: (a, b) =>
      a.offersSubmittedToTheSeller - b.offersSubmittedToTheSeller,
    render: ({ offersSubmittedToTheSeller, offers }) =>
      `${offersSubmittedToTheSeller} ${calculatePercentage(
        offersSubmittedToTheSeller,
        offers,
      )}`,
  },
  {
    title: 'Accepted offers',
    key: 'acceptedOffers',
    sorter: (a, b) => a.acceptedOffers - b.acceptedOffers,
    render: ({ acceptedOffers, offersSubmittedToTheSeller }) =>
      `${acceptedOffers} ${calculatePercentage(
        acceptedOffers,
        offersSubmittedToTheSeller,
      )}`,
  },
  {
    title: 'Buybox',
    key: 'buyBoxCounter',
    render: ({ buyBoxCounter }) => buyBoxCounter,
  },
  {
    title: 'Last login',
    key: 'lastLogin',
    render: ({ lastLogin }) => lastLogin,
  },
  {
    title: 'Is Active?',
    key: 'isActive',
    render: ({ isActive }) =>
      isActive ? (
        <TagItem type="success">Yes</TagItem>
      ) : (
        <TagItem type="error">No</TagItem>
      ),
  },
];

export const daysOptions = [
  { label: '1 day', value: 1 },
  { label: '7 days', value: 7 },
  { label: '14 days', value: 14 },
  { label: '30 days', value: 30 },
  { label: '60 days', value: 60 },
  { label: '90 days', value: 90 },
];
