import { Table, Space } from 'antd';
import { v4 as uuid } from 'uuid';
import {
  addCommas,
  getPriceText,
} from '@zorba-shared/core/Services/textService';
import { useDispatch, useSelector } from 'react-redux';
import { FaFileCsv } from '@react-icons/all-files/fa/FaFileCsv';
import { FaTags } from '@react-icons/all-files/fa/FaTags';
import { useState } from 'react';
import { getColumns } from './constants';
import {
  ZorbaButton,
  ZorbaInput,
  ZorbaSelect,
} from '../../../../components/forms';
import { BUTTON_VARIANT_DARK } from '../../../../components/forms/ZorbaButton/constants';
import {
  searchPropertyData,
  triggerPropertyDataJob,
} from '../../../../reducer/PropertyData/actions';
import { postRequest } from '../../../../services/requestService';
import { showErrorToast } from '../../../../services/notificationsService';
import { combineObjectsByTitle } from '../../../BuyerCheckerPage/constants';

import './index.scss';
import { DownloadCsVModal } from './Components/DownloadCSVModal';
import { AddTagsModal } from './Components/AddTagsModal';
import { isMobile } from '../../../../services/clientService';
import { LimitRecordModal } from './Components/LimitRecordModal';
import Modal from 'antd/es/modal/Modal';

const DEFAULT_JOB_PARAMS = {
  jobKind: null,
  selectedRowKeys: [],
  didSelectAll: false,
  from: undefined,
  to: undefined,
};

const tableScroll = {
  scrollToFirstRowOnChange: true,
  x: isMobile() ? true : 'max-content',
};

export default () => {
  const { data, total, isFetchingData, searchQuery, appliedFilters } =
    useSelector(({ PropertyDataReducer }) => PropertyDataReducer);
  const dispatch = useDispatch();
  const [jobParams, setJobParams] = useState({
    ...DEFAULT_JOB_PARAMS,
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [isDownloadingCSV, setIsDownloadingCSV] = useState(false);
  const [isExportValid, setIsExportValid] = useState(false);
  const [isLimitRecordModalOpen, setIsLimitRecordModalOpen] = useState(false);
  const [exportParams, setExportParams] = useState({
    fileDirection: 'horizontal',
    communicationChannel: 'text',
    fileName: '',
  });
  const [isDownloadCSVModalOpened, setIsDownloadCSVModalOpened] =
    useState(false);
  const [isAddTagsModalOpened, setIsAddTagsModalOpened] = useState(false);

  const onJobParamsChange = (updatedParams) => {
    setJobParams({ ...jobParams, ...updatedParams });
  };

  const rowSelection = {
    type: 'checkbox',
    selectedRowKeys: jobParams.selectedRowKeys,
    onChange: (selectedKeys) => {
      onJobParamsChange({ selectedRowKeys: selectedKeys });
    },
    getCheckboxProps: (record) => ({
      disabled: jobParams.didSelectAll,
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  const onShowSizeChange = (current, pageSize) => {
    setCurrentPage(current - 1);
    dispatch(
      searchPropertyData({
        page: current - 1,
        pageSize,
        searchQuery,
        filters: appliedFilters,
      }),
    );
  };

  const onChange = (key, value) => {
    const updatedParams = { ...exportParams, [key]: value };
    setExportParams(updatedParams);
    setIsExportValid(
      updatedParams.fileName &&
        updatedParams.fileDirection &&
        updatedParams.communicationChannel,
    );
  };

  const pageSize = 100;

  const pagination = {
    position: ['topRight'],
    onChange: onShowSizeChange,
    total,
    pageSize,
    showTotal: (totalItems, range) =>
      `${range[0]}-${range[1]} of ${totalItems} items`,
    showSizeChanger: false,
  };

  const generateCSVFileName = () => `${exportParams.fileName}.csv`;
  const handleDownloadCSV = async () => {
    if (!isExportValid) return;
    try {
      const requestUrl = '/backoffice/property-data/download-csv';
      setIsDownloadingCSV(true);
      const response = await postRequest(requestUrl, {
        selectedRowKeys: jobParams.selectedRowKeys,
        searchQuery,
        exportParams,
        filters: appliedFilters,
      });
      setIsDownloadingCSV(false);
      setJobParams({ ...DEFAULT_JOB_PARAMS });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', generateCSVFileName());
      document.body.appendChild(link);
      link.click();
      setIsDownloadCSVModalOpened(false);
    } catch (err) {
      setIsDownloadingCSV(false);
      showErrorToast('Downloading CSV file failed!');
    }
  };

  const onJobRunClick = () => {
    if (!jobParams?.jobKind) {
      showErrorToast('Please select job');
      return;
    }
    setIsLimitRecordModalOpen(true);
  };

  const runJob = (params = {}) => {
    dispatch(
      triggerPropertyDataJob({
        ...jobParams,
        searchQuery,
        filters: appliedFilters,
        ...params,
      }),
    );
  };

  const onChangeLimitHandler = (params) => {
    setIsLimitRecordModalOpen(false);
    runJob(params);
  };

  return (
    <div className="results-table">
      <Space style={{ marginBottom: 16 }} wrap>
        <ZorbaButton
          onClick={() => setIsDownloadCSVModalOpened(true)}
          variant={BUTTON_VARIANT_DARK}
          disabled={
            !jobParams.selectedRowKeys.length ||
            isDownloadingCSV ||
            isFetchingData
          }
          loading={isDownloadingCSV}
        >
          <FaFileCsv />
        </ZorbaButton>
        <ZorbaButton
          onClick={() => setIsDownloadCSVModalOpened(true)}
          variant={BUTTON_VARIANT_DARK}
          disabled={
            !data?.length ||
            isDownloadingCSV ||
            jobParams.selectedRowKeys.length ||
            isFetchingData
          }
          loading={isDownloadingCSV}
        >
          Download all rows as CSV
        </ZorbaButton>
        <ZorbaButton
          onClick={() => setIsAddTagsModalOpened(true)}
          variant={BUTTON_VARIANT_DARK}
          disabled={
            !data?.length ||
            (!jobParams.selectedRowKeys.length && !jobParams.didSelectAll) ||
            isDownloadingCSV ||
            isFetchingData
          }
        >
          <FaTags />
        </ZorbaButton>
      </Space>
      {/* SELECT ITEMS + ACTIONS */}
      {total ? (
        <div className="select-and-job-actions">
          {(jobParams.selectedRowKeys?.length > 0 ||
            jobParams.didSelectAll) && (
            <div className="selected-items-status">
              You have selected{' '}
              <b>
                {jobParams.didSelectAll
                  ? total
                  : jobParams.selectedRowKeys.length}
              </b>{' '}
              records
            </div>
          )}
          {!jobParams.didSelectAll && jobParams.selectedRowKeys?.length > 0 && (
            <span
              className="select-all-btn"
              onClick={() => {
                onJobParamsChange({ selectedRowKeys: [], didSelectAll: true });
              }}
            >{`Select all ${addCommas(total)} records`}</span>
          )}
          {jobParams.didSelectAll && (
            <span
              className="select-all-btn"
              onClick={() => {
                onJobParamsChange({
                  selectedRowKeys: [],
                  didSelectAll: false,
                  to: total,
                });
              }}
            >{`Unselect all ${addCommas(total)} records`}</span>
          )}

          <ZorbaSelect
            placeholder="Select job"
            className="select-job-kind"
            options={[
              { text: 'Skiptrace', value: 'skiptrace' },
              { text: 'BB Checker', value: 'bbchecker' },
              { text: 'Scrub numbers', value: 'number_lookup' },
              { text: 'Generate offer', value: 'generate_offer' },
              { text: 'Vacancy check', value: 'vacancy_check' },
            ]}
            onChange={(e) => onJobParamsChange({ jobKind: e })}
            value={jobParams.jobKind}
          />
          <ZorbaButton
            variant={BUTTON_VARIANT_DARK}
            fullWidth={false}
            onClick={onJobRunClick}
          >
            Run job
          </ZorbaButton>
        </div>
      ) : null}
      {/* TABLE */}
      <div className="results-table-container">
        <Table
          rowKey="_id"
          rowSelection={rowSelection}
          columns={getColumns()}
          dataSource={data}
          loading={isFetchingData}
          pagination={pagination}
          scroll={tableScroll}
          expandable={{
            expandedRowRender: (record) =>
              Object.values(record?.powerBuyerMatches).map(
                (powerBuyerMatch) => (
                  <div className="buybox-results-container">
                    <span className="buybox-title">{powerBuyerMatch.name}</span>
                    {powerBuyerMatch.reasons.length > 0 && (
                      <div className="reason-container">
                        <span className="reason-title">Reason:</span>
                        <span>{`${powerBuyerMatch.reasons.join(',')}`}</span>
                      </div>
                    )}
                    {combineObjectsByTitle(powerBuyerMatch.detailedReasons).map(
                      (detailedReason) => (
                        <div className="detailed-reason-container" key={uuid()}>
                          <span className="reason-title">{`${detailedReason.title}:`}</span>
                          <span>{detailedReason.reason}</span>
                        </div>
                      ),
                    )}
                  </div>
                ),
              ),
            rowExpandable: (record) =>
              record?.powerBuyerMatches && !record.isPowerBuyerMatch,
          }}
        />
      </div>
      <DownloadCsVModal
        isDownloadCSVModalOpened={isDownloadCSVModalOpened}
        setIsDownloadCSVModalOpened={setIsDownloadCSVModalOpened}
        onSubmit={handleDownloadCSV}
        onChange={onChange}
        exportParams={exportParams}
        isExportValid={isExportValid}
      />
      <AddTagsModal
        isAddTagsModalOpened={isAddTagsModalOpened}
        setIsAddTagsModalOpened={setIsAddTagsModalOpened}
        jobParams={jobParams}
        currentPage={currentPage}
        pageSize={pageSize}
        total={total}
      />

      <Modal
        title={`Run ${jobParams.jobKind} job`}
        centered
        open={isLimitRecordModalOpen}
        footer={null}
        closable
        destroyOnClose
        onCancel={() => setIsLimitRecordModalOpen(false)}
      >
        <LimitRecordModal
          onChangeLimit={onChangeLimitHandler}
          total={total}
          didSelectAll={jobParams.didSelectAll}
        />
      </Modal>
    </div>
  );
};
