// import { COLUMN_NAME_MAILING_ADDRESS, COLUMN_NAME_MAILING_CITY, COLUMN_NAME_MAILING_STATE, COLUMN_NAME_MAILING_ZIP_CODE } from "@skiptracing-shared/core/Constants/jobs";
import {
  FIELD_MAPPING_STATUS_MAPPED,
  REQUIRED_COLUMN_NAMES,
} from './constants';

const COLUMN_NAME_MAILING_ADDRESS = 'mailingAddress';
const COLUMN_NAME_MAILING_CITY = 'mailingCity';
const COLUMN_NAME_MAILING_STATE = 'mailingState';
const COLUMN_NAME_MAILING_ZIP_CODE = 'mailingZip';

/**
 * This function is used to search a query (string) in the file columns
 */
const getMatchingColumn = (query, fileColumns) => {
  const columnIndex = fileColumns.findIndex((x) => x.includes(query));
  if (columnIndex >= 0) return fileColumns.splice(columnIndex, 1)[0];
  return null;
};

/**
 * // TODO: Extend this function to support presets
 * This function is used to make a suggestion of matching columns
 */
export const populateSuggestedFields = (job) => {
  const { columnNames, mappedColumns } = job;
  const unassignedColumnNames = [...columnNames];
  const res = {
    ...mappedColumns,
    firstName: getMatchingColumn('First Name', unassignedColumnNames),
    lastName: getMatchingColumn('Last Name', unassignedColumnNames),
    mailingAddress: getMatchingColumn('Mailing Address', unassignedColumnNames),
    mailingCity: getMatchingColumn('Mailing City', unassignedColumnNames),
    mailingState: getMatchingColumn('Mailing State', unassignedColumnNames),
    mailingZip: getMatchingColumn('Mailing Zip', unassignedColumnNames),
    address: getMatchingColumn('Address', unassignedColumnNames),
    city: getMatchingColumn('City', unassignedColumnNames),
    state: getMatchingColumn('State', unassignedColumnNames),
    zip: getMatchingColumn('Zip', unassignedColumnNames),
  };

  return res;
};

/**
 * This function is used to calculate the percentage of cells with value in a column
 */
export const percentageOfRecordsWithValue = (field, records) => {
  const numOfRecords = records.length;
  const numOfRecordsWithField = records.filter(
    (record) => record.find((cell) => cell.key === field)?.value,
  ).length;
  return (100 * numOfRecordsWithField) / numOfRecords;
};

export const validateFieldMapping = (mappedFieldsTemp, fileFieldsStatus) => {
  let invalidFields = [];
  for (const requiredColumn of REQUIRED_COLUMN_NAMES) {
    const mappedFieldKey = mappedFieldsTemp[requiredColumn];
    if (
      !mappedFieldKey ||
      fileFieldsStatus[mappedFieldKey] !== FIELD_MAPPING_STATUS_MAPPED
    )
      invalidFields.push(requiredColumn);
  }

  // If we have mailing address --> skip property address
  if (
    mappedFieldsTemp[COLUMN_NAME_MAILING_ADDRESS] &&
    mappedFieldsTemp[COLUMN_NAME_MAILING_CITY] &&
    mappedFieldsTemp[COLUMN_NAME_MAILING_STATE] &&
    mappedFieldsTemp[COLUMN_NAME_MAILING_ZIP_CODE]
  ) {
    invalidFields = [];
  }

  return { isValid: invalidFields.length === 0, invalidFields };
};

export const convertNumberToLetters = (num) => {
  const mod = num % 26;
  let pow = (num / 26) | 0;
  const out = mod ? String.fromCharCode(64 + mod) : (--pow, 'Z');
  return pow ? convertNumberToLetters(pow) + out : out;
};
