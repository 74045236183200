/**
 * This function gets an object, it creates a new object and turns the values to keys and vice versa.
 * For example: input ({key: value}) output ({value: key})
 */
export const switchObjectKeyAndValue = (object) => {
  const newObj = {};
  for (const key in object) {
    newObj[object[key]] = key;
  }
  return newObj;
};

/**
 * This function takes an object and a value to search for and returns its key.
 * For example: input (object {key: value} value value) output (key)
 */
export const findObjectKeyByValue = (object, value) => {
  const reversedObj = switchObjectKeyAndValue(object);
  return reversedObj[value];
};
