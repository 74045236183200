import { Modal, Select } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ZorbaButton } from '../../../../../components/forms';
import { BUTTON_VARIANT_DARK } from '../../../../../components/forms/ZorbaButton/constants';

import '../index.scss';
import { createTags } from '../../../../../reducer/PropertyData/actions';

export const AddTagsModal = ({
  isAddTagsModalOpened,
  setIsAddTagsModalOpened,
  jobParams,
  currentPage,
  pageSize,
  total,
}) => {
  const dispatch = useDispatch();
  const {
    tags,
    appliedFilters = [],
    isFetchingData,
  } = useSelector(({ PropertyDataReducer }) => PropertyDataReducer);
  const [selectedTags, setSelectedTags] = useState([]);
  const tagsInSelectFormat = tags.map((tag) => ({
    label: tag.name,
    value: tag.name,
  }));

  const handleCreateTags = () => {
    dispatch(
      createTags(
        {
          tags: selectedTags,
          propertyDataIds: jobParams.selectedRowKeys,
          filters: appliedFilters,
          currentPage,
          pageSize,
        },
        setIsAddTagsModalOpened,
      ),
    );
  };

  return (
    <Modal
      title="Add tags"
      centered
      open={isAddTagsModalOpened}
      footer={null}
      closable
      onCancel={() => setIsAddTagsModalOpened(false)}
      destroyOnClose
    >
      <div className="tags-modal-container">
        <span>{`Assign tags to ${
          jobParams.selectedRowKeys.length || total
        } records`}</span>
        <Select
          mode="tags"
          className="tags-select"
          placeholder="Add Tags"
          onChange={(newVal) => setSelectedTags(newVal)}
          options={tagsInSelectFormat}
        />
        <div className="add-button-container">
          <ZorbaButton
            variant={BUTTON_VARIANT_DARK}
            onClick={handleCreateTags}
            fullWidth={false}
            loading={isFetchingData}
          >
            Add
          </ZorbaButton>
        </div>
      </div>
    </Modal>
  );
};
