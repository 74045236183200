import { Table } from 'antd';
import { useDispatch } from 'react-redux';
import { getTableColumns } from './columns';
import { isMobile } from '../../../../services/clientService';

import './index.scss';

const tableScroll = {
  scrollToFirstRowOnChange: true,
  x: isMobile() ? true : 'max-content',
};

export const MatchesTab = ({ dealSubmission, isFetchingDealSubmission }) => {
  const dispatch = useDispatch();
  const locale = {
    emptyText: isFetchingDealSubmission ? null : <p>No Record Found.</p>,
  };

  return (
    <Table
      rowKey="_id"
      className="matches-table"
      dataSource={dealSubmission.matches}
      columns={getTableColumns(dispatch, isFetchingDealSubmission)}
      loading={isFetchingDealSubmission}
      scroll={tableScroll}
      locale={locale}
    />
  );
};
