import { AUTH_STATE_INITIAL } from '@zorba-shared/core/Constants/auth';
import InitialAuthState from './Components/InitialAuthState';

export const AUTH_STATE_DATA = {
  [AUTH_STATE_INITIAL]: {
    title: 'Login to Zorba backoffice',
    getComponent: (props) => <InitialAuthState {...props} />,
    showBack: false,
  },
};
