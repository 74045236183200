import { Link } from 'react-router-dom';
import { MdArrowBack } from '@react-icons/all-files/md/MdArrowBack';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { Tag } from 'antd';
import { PROPERTY_TYPE_LOT } from '@zorba-shared/core/Constants/deal';
import Stats from '../../../../components/Stats';
import { ZorbaButton } from '../../../../components/forms';
import { generateStats } from './service';
import { BUTTON_VARIANT_SECONDARY } from '../../../../components/forms/ZorbaButton/constants';
import { postRequest } from '../../../../services/requestService';
import { setSelectedDealSubmission } from '../../../../reducer/DealSubmission/actions';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../services/notificationsService';
import { setDrawerState } from '../../../../reducer/Drawer/actions';
import { ACTIVITY_DEAL_SUBMISSION_DRAWER } from '../../../../reducer/Drawer/types';
import { toQueryString } from '../../../../utils';

import './index.scss';

const TEST_DEAL_SUBMISSION_ID = '64b15c25fb9fece6979d7304';

const DealSubmissionHeader = ({
  selectedDealSubmission,
  lastFetchedCriteria,
}) => {
  const dispatch = useDispatch();
  const title =
    selectedDealSubmission?.lastCalculationParams?.propertyType ===
    PROPERTY_TYPE_LOT ? (
      <>
        ${selectedDealSubmission.address.formattedAddress}{' '}
        <Tag color="orange">VACANT LOT</Tag>
      </>
    ) : (
      selectedDealSubmission.address.formattedAddress
    );
  const matchingBuyers =
    selectedDealSubmission.powerBuyerMatches?.filter((item) => item.status) ||
    [];
  const [loading, setLoading] = useState(false);

  const handleActivityDrawer = () => {
    dispatch(setDrawerState(ACTIVITY_DEAL_SUBMISSION_DRAWER, true));
  };

  const { pageSize, page, ...restOfCriteria } = lastFetchedCriteria;
  const queryParams = toQueryString(restOfCriteria);

  const handleResetDealSubmissionTest = async () => {
    setLoading(true);
    try {
      const res = await postRequest(
        `/backoffice/deal-submission/reset-deal-submission-test`,
      );
      dispatch(setSelectedDealSubmission(res.data.dealSubmission));
      showSuccessToast('The deal was updated');
    } catch (e) {
      showErrorToast('Woops. huddle Kobi');
    }
    setLoading(false);
  };

  return (
    <div className="deal-submission">
      <div className="left">
        <div className="names">
          <div className="full-name">
            <Link
              className="back-btn"
              to={`/deal-submission${
                queryParams.toString().length
                  ? `?${queryParams.toString()}`
                  : ''
              }`}
            >
              <MdArrowBack />
            </Link>
            {title}
          </div>
        </div>
        <div className="stats-container">
          <Stats
            stats={generateStats({ selectedDealSubmission, matchingBuyers })}
            selectedDealSubmission={selectedDealSubmission}
          />
          <div className="activity-log-button-container">
            <ZorbaButton
              fullWidth={false}
              variant={BUTTON_VARIANT_SECONDARY}
              onClick={handleActivityDrawer}
            >
              Activity logs
            </ZorbaButton>
            {selectedDealSubmission._id === TEST_DEAL_SUBMISSION_ID && (
              <ZorbaButton
                fullWidth={false}
                variant={BUTTON_VARIANT_SECONDARY}
                onClick={handleResetDealSubmissionTest}
                loading={loading}
                disabled={loading}
              >
                Reset deal submission test
              </ZorbaButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealSubmissionHeader;
