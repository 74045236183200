import { Tabs } from 'antd';
import { useState } from 'react';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import ContentHeader from '../../components/ContentHeader';
import { getTabs } from './constants';

import './index.scss';

const PropertyDataSearch = () => {
  useDocumentTitle('Property Data');
  const [selectedTab, setSelectedTab] = useState();
  const tabs = getTabs({ selectedTab });
  return (
    <>
      <ContentHeader title="Property Data" />
      <div className="tabs-container propertyData-tabs-container">
        <Tabs
          type="card"
          items={tabs}
          activeKey={selectedTab}
          onChange={(newTab) => setSelectedTab(newTab)}
        />
      </div>
    </>
  );
};

export default PropertyDataSearch;
