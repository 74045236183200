import { useState } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Menu, Tag } from 'antd';
import { useSelector } from 'react-redux';
import { getEnvName } from '../../../../services/clientService';

import { UserPanel } from './Components/UserPanel';
import { getNavItems } from './constants';

import './index.scss';

const SidenavLayout = () => {
  const location = useLocation();
  const evnName = getEnvName();
  const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);
  const [nav] = useState(getNavItems({ userDetails }));

  const activeKey = nav.find(
    (item) =>
      matchPath(location.pathname, {
        path: item.label?.props?.to,
        exact: false,
        strict: false,
      })?.isExact,
  )?.key;

  return (
    <div className="sidebar">
      <div>
        <div className="logo">
          <img
            alt="logo"
            width={100}
            src="https://res.cloudinary.com/dyqquolic/image/upload/v1675256922/zorba-logo-black_xwagzk.svg"
          />
          <Tag
            color="warning"
            className={`environment-badge ${
              evnName !== 'production' ? 'environment-badge-show' : ''
            }`}
          >
            {{ development: 'Dev', staging: 'Staging' }[evnName]}
          </Tag>
        </div>
        <Menu
          theme="light"
          mode="inline"
          selectedKeys={activeKey}
          items={nav}
        />
      </div>
      <UserPanel />
    </div>
  );
};

export default SidenavLayout;
