import classNames from 'classnames';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import ZorbaInput from '../ZorbaInput';
import { PHONE_INPUT_OUTPUT_TYPE_JSON } from './constants';
import './index.scss';

export default (props) => {
  const {
    defaultValue,
    showErrorMessage,
    errorMessage = '',
    outputType = PHONE_INPUT_OUTPUT_TYPE_JSON,
  } = props;
  const [value, setValue] = useState('');

  const inputParams = _.pick(props, ['fullWidth', 'variant']);

  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
  }, []);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const isValidNumber = (input) => input?.toString().length === 10;
  const onChange = (newValue, reactHookFormField) => {
    let formattedValue = newValue;
    if (props.value && newValue.length - props.value.length > 1) {
      // The new value was pasted, let's format it in case it's a full number (+19172678655)
      formattedValue = formattedValue.replace('+1', '');
    }
    formattedValue = formattedValue.toString().replace(/\D/g, '');
    setValue(formattedValue);

    // Prepare the output
    let output;
    if (outputType === PHONE_INPUT_OUTPUT_TYPE_JSON) {
      output = isValidNumber(formattedValue)
        ? {
            countryCode: '1',
            number: formattedValue,
          }
        : { countryCode: 1, number: '' };
    } else {
      output = formattedValue;
    }

    if (reactHookFormField?.onChange) {
      reactHookFormField?.onChange(output);
    } else if (props.onChange) {
      props.onChange(output);
    }
  };
  const parserNumber = (val) => {
    if (!val) return 0;
    return Number.parseFloat(
      val.replace(/\$\s?|(\.*)/g, '').replace(/(,{1})/g, '.'),
    ).toFixed(2);
  };
  return (
    <div className={classNames('phone-input', { invalid: showErrorMessage })}>
      <div className="input-wrapper">
        <ZorbaInput
          {...inputParams}
          parser={parserNumber}
          help={props.help}
          label={props.label}
          prefix="+1"
          maxLength={10}
          autoComplete="off"
          // formatter={(val) => val}
          type="tel"
          placeholder={props.placeholder || 'Phone number'}
          value={value}
          {...(props.field || {})}
          onChange={(e) => onChange(e.target.value, props.field)}
          // onBlur={validateInput}
        />
      </div>
      <div className="error">{showErrorMessage ? errorMessage : ''}</div>
    </div>
  );
};
