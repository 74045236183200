import * as types from './types';

const initialState = {
  total: undefined,
  data: [],
  isFetchingData: false,
};
export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_PROPERTY_DATA_JOBS_RESULTS:
    case types.SET_IS_FETCHING_JOBS:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
