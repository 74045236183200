import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import ContentHeader from '../../components/ContentHeader';
import { setModalState } from '../../reducer/Modal/actions';
import {
  MODAL_CREATE_USER,
  MODAL_UNSUBSCRIBE_FROM_SMS_CAMPAIGNS,
} from '../../reducer/Modal/types';
import { setNewOrganization } from '../../reducer/Organization/actions';
import BuyerCreateForm from './Components/BuyerCreateForm';
import BuyerList from './Components/BuyerList';
import { BUTTON_VARIANT_DARK } from '../../components/forms/ZorbaButton/constants';
import { UnsubscribeFromSMSModal } from './Components/UnsubscribeFromSMSModal';

const BuyerPage = () => {
  const dispatch = useDispatch();
  const { isCreateUserModalOpen } = useSelector(
    ({ ModalReducer }) => ModalReducer,
  );
  useDocumentTitle('Buyers Search Page');

  const onCancelHandler = () => {
    dispatch(setModalState(MODAL_CREATE_USER, false));
  };

  const resetOrganization = () => {
    dispatch(setNewOrganization(null));
  };

  return (
    <>
      <ContentHeader
        title="Buyers"
        variant={BUTTON_VARIANT_DARK}
        actionButtons={[
          {
            label: 'Add new user',
            onClick: () => {
              dispatch(setModalState(MODAL_CREATE_USER, true));
            },
          },
          {
            label: 'Unsubscribe From SMS',
            onClick: () => {
              dispatch(
                setModalState(MODAL_UNSUBSCRIBE_FROM_SMS_CAMPAIGNS, true),
              );
            },
          },
        ]}
      />
      <BuyerList />

      <Modal
        title="Add new user"
        centered
        open={isCreateUserModalOpen}
        footer={null}
        closable={false}
        destroyOnClose
        afterClose={resetOrganization}
      >
        <BuyerCreateForm onCancel={onCancelHandler} />
      </Modal>
      <UnsubscribeFromSMSModal />
    </>
  );
};

export default BuyerPage;
