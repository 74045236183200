import * as types from './types';

const initialState = {
  total: undefined,
  data: [],
  isFetchingData: false,
  searchQuery: {},
  job: undefined,
  tags: [],
  appliedFilters: [],
};
export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_PROPERTY_DATA_RESULTS:
    case types.SET_JOB:
    case types.SET_RECORDS:
    case types.SET_TAGS:
    case types.SET_IS_FETCHING_DATA:
    case types.SET_SEARCH_QUERY:
    case types.SET_PROPERTY_DATA_FILTER:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
