import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  DAYS_TO_CLOSE_OPTIONS,
  INSPECTION_DAYS_OPTIONS,
} from '@zorba-shared/core/Constants/offer';
import _ from 'lodash';
import { setModalState } from '../../../../../../reducer/Modal/actions';
import { MODAL_SUBMIT_OFFER } from '../../../../../../reducer/Modal/types';
import {
  ZorbaButton,
  ZorbaInput,
  ZorbaSelect,
} from '../../../../../../components/forms';

import {
  showErrorToast,
  showSuccessToast,
} from '../../../../../../services/notificationsService';
import { postRequest } from '../../../../../../services/requestService';

import '../RejectBuyerModal/index.scss';

export const SubmitOfferModal = ({ selectedDealSubmission }) => {
  const { isSubmitOfferModalOpen } = useSelector(
    ({ ModalReducer }) => ModalReducer,
  );
  const dispatch = useDispatch();

  const [params, setParams] = useState({});

  const buyersOptions = selectedDealSubmission.matches
    .filter((match) => match.status === 'offer')
    .map((match) => ({
      label: match.organization.name,
      value: match._id,
    }));

  const selectedDealSubmissionMatch = selectedDealSubmission.matches.find(
    (match) => match._id === params.dealSubmissionMatchId,
  );
  const lastOfferEventByBuyer = _.last(
    selectedDealSubmissionMatch?.events.filter(
      (event) => event.kind === 'offer',
    ),
  );

  useEffect(() => {
    if (lastOfferEventByBuyer) {
      setParams((prevState) => ({
        ...prevState,
        rawAmount: lastOfferEventByBuyer.rawAmount,
        amount:
          lastOfferEventByBuyer.rawAmount -
          selectedDealSubmissionMatch.feeAmount,
        inspectionMode: lastOfferEventByBuyer.inspectionMode,
        inspectionDays: lastOfferEventByBuyer.inspectionDays,
        daysToClose: lastOfferEventByBuyer.daysToClose,
        titleCompanyName: lastOfferEventByBuyer.titleCompanyName,
        emd: lastOfferEventByBuyer.emd,
        feeAmount: selectedDealSubmissionMatch.feeAmount,
      }));
    }
  }, [lastOfferEventByBuyer]);

  const handleCloseModal = () => {
    dispatch(setModalState(MODAL_SUBMIT_OFFER, false));
    setParams({});
  };

  const onChange = (key, value) => {
    const updatedParams = { ...params, [key]: value };
    setParams(updatedParams);
  };

  const handleSubmitOffer = async () => {
    try {
      await postRequest('/backoffice/deal-submission/submit-offer', {
        dealSubmissionId: selectedDealSubmission._id,
        dealSubmissionMatchId: params.dealSubmissionMatchId,
        feeAmount: params.feeAmount,
        offerParams: {
          amount: params.amount,
          inspectionMode: params.inspectionMode,
          inspectionDays: params.inspectionDays,
          daysToClose: params.daysToClose,
          titleCompanyName: params.titleCompanyName,
          emd: params.emd,
        },
      });
      handleCloseModal();
      showSuccessToast('Offer submitted successfully');
    } catch (error) {
      showErrorToast('Submitting an offer failed');
    }
  };

  return (
    <Modal
      open={isSubmitOfferModalOpen}
      footer={null}
      onCancel={handleCloseModal}
      title="Submit an offer to the Seller"
      className="reject-buyer-modal"
      destroyOnClose
    >
      <span>
        Are you sure that you want to submit an offer on behalf of the buyer?
      </span>
      <div className="select-container">
        <ZorbaSelect
          label="Select buyer*"
          options={buyersOptions}
          onChange={(e) => onChange('dealSubmissionMatchId', e)}
        />
        {params.dealSubmissionMatchId && (
          <>
            <ZorbaInput
              type="number"
              addonBefore="$"
              symbol=""
              label="Buyer all-in offer"
              value={params.rawAmount}
              disabled
            />
            <ZorbaInput
              type="number"
              addonBefore="$"
              symbol=""
              label="Our fee*"
              value={params.feeAmount}
              onChange={(e) => {
                const newAmount = lastOfferEventByBuyer.rawAmount - e;
                const updatedParams = {
                  ...params,
                  feeAmount: e,
                  amount: newAmount,
                };
                setParams(updatedParams);
              }}
              className="fee-amount-input"
            />
            <div className="offer-input-container">
              <ZorbaInput
                type="number"
                addonBefore="$"
                symbol=""
                label="Offer to be submitted to the seller (all-in offer minus our fee)"
                value={params.amount}
                disabled
              />
            </div>
            <ZorbaSelect
              label="Inspection Mode*"
              options={[
                { text: 'Pre inspection', value: 'pre_inspection' },
                { text: 'Contingency', value: 'contingency' },
              ]}
              value={params.inspectionMode}
              onChange={(e) => onChange('inspectionMode', e)}
            />
            {params.inspectionMode === 'contingency' && (
              <ZorbaSelect
                label="Inspection period"
                options={INSPECTION_DAYS_OPTIONS}
                value={params.inspectionDays}
                onChange={(e) => onChange('inspectionPeriod', e)}
              />
            )}
            <ZorbaSelect
              label="Days to close*"
              options={DAYS_TO_CLOSE_OPTIONS}
              value={params.daysToClose}
              onChange={(e) => onChange('daysToClose', e)}
            />
            <ZorbaInput
              type="number"
              addonBefore="$"
              symbol=""
              label="EMD*"
              value={params.emd}
              onChange={(e) => onChange('emd', e)}
            />
            <ZorbaInput
              label="Title company name*"
              placeholder="Blueprint Title, LLC"
              value={params.titleCompanyName}
              onChange={(e) => onChange('titleCompanyName', e)}
            />
          </>
        )}
      </div>
      <div className="button-container">
        <ZorbaButton
          fullWidth={false}
          onClick={handleSubmitOffer}
          disabled={!params.dealSubmissionMatchId || !params.feeAmount}
        >
          Send offer
        </ZorbaButton>
      </div>
    </Modal>
  );
};
