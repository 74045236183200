import './index.scss';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isBoolean } from 'lodash';

import { Tag } from 'antd';
import { SEARCH_PARAMS_LABELS } from '../../constants';

const BuyerAppliedFilter = (props) => {
  const [appliedFilters, setAppliedFilters] = useState([]);
  const { areas } = useSelector(({ BuyerReducer }) => BuyerReducer);
  const { searchCriteria } = props;

  useEffect(() => setFilters(), [searchCriteria, areas]);

  const remove = (filter) => {
    const index = appliedFilters.findIndex((f) => f.key === filter.key);
    if (index >= 0) {
      appliedFilters.splice(index, 1);
    }

    if (props.onRemovedFilter) {
      props.onRemovedFilter(filter);
    }
  };

  const setFilters = () => {
    const filters = [];

    for (const key in searchCriteria) {
      if (searchCriteria[key]) {
        if ((key === 'areas') & (areas?.length > 0)) {
          searchCriteria[key].forEach((placeId) => {
            const place = areas.find((place) => place._id === placeId);
            if (place) {
              filters.push({
                key: placeId,
                value: place.formattedAddress,
                type: 'areas',
              });
            }
          });
        } else {
          filters.push({
            key,
            label: SEARCH_PARAMS_LABELS[key] || '',
            value: searchCriteria[key],
          });
        }
      }
    }
    setAppliedFilters(filters);
  };

  const getValueText = (value) =>
    isBoolean(value) ? (value ? 'Yes' : 'No') : value;

  return (
    <div
      className="applied-filter"
      style={{ paddingTop: appliedFilters.length > 0 ? '16px' : '' }}
    >
      <div className="applied-filter__item">
        {appliedFilters.map((filter, index) => (
          <Tag
            closable
            onClose={() => remove(filter)}
            key={index}
            className="applied-filter__item--tag"
          >
            {filter.label ? `${filter.label}: ` : ''}
            {getValueText(filter.value)}
          </Tag>
        ))}
      </div>
    </div>
  );
};

export default BuyerAppliedFilter;
