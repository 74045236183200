import XLSX from 'xlsx';

/**
 * This function takes a csv/xls/xlsx file buffer and returns the sheet names.
 */
export const getSpreadsheetsFromBuffer = (data) => {
  const workbook = XLSX.read(data, { type: 'array' });

  const sheetNames = workbook.SheetNames;
  const sheetColumnJson = {};

  for (const sheet of sheetNames) {
    const records = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
    sheetColumnJson[sheet] = records.reduce((keys, record) => {
      const recordKeys = Object.keys(record);
      return [...new Set([...keys, ...recordKeys])];
    }, []);
  }
  return { sheetNames, sheetColumnJson };
};
