import { getPriceText } from '@zorba-shared/core/Services/textService';

import '../../index.scss';

export const EventDescription = ({ event }) => {
  const submittedBy = event.user?.name || event.user?.defaultOrganization?.name;

  const getEventDescription = () => {
    if (event.kind === 'deal_submission') {
      return (
        <div
          style={{ display: 'flex', flexDirection: 'column', rowGap: '5px' }}
        >
          <span>{`Submitted by: ${event.user.name}`}</span>
          <span>{`Asking price: ${getPriceText(event.amount)}`}</span>
        </div>
      );
    }
    if (event.kind === 'offer_request') {
      return (
        <div
          style={{ display: 'flex', flexDirection: 'column', rowGap: '5px' }}
        >
          <span>{`${submittedBy} sent the deal to ${event.organizationName}`}</span>
          <span>{`Seller asking price: ${getPriceText(event.amount)}`}</span>
          <span>{`Our fee: ${getPriceText(event.feeAmount)}`}</span>
          <span>
            {`Asking price the buyer sees: ${getPriceText(event.rawAmount)}`}
          </span>
        </div>
      );
    }

    if (event.kind === 'offer' && event.source === 'buyers') {
      return (
        <div
          style={{ display: 'flex', flexDirection: 'column', rowGap: '5px' }}
        >
          <span>
            {`All-In Offer by ${event.organizationName}: ${getPriceText(
              event.rawAmount,
            )}`}
          </span>
          <span>{`Our fee: ${getPriceText(event.feeAmount)}`}</span>
          <span>{`The offer the seller sees: ${getPriceText(
            event.amount,
          )}`}</span>
          {event.isLowBall && (
            <span>
              Low ball - we did not deliver this offer to the seller because
              it’s lower than the threshold.
            </span>
          )}
        </div>
      );
    }

    if (event.kind === 'offer' && event.source === 'partners') {
      return (
        <div
          style={{ display: 'flex', flexDirection: 'column', rowGap: '5px' }}
        >
          <span>{`The offer the seller sees: ${getPriceText(
            event.amount,
          )}`}</span>
        </div>
      );
    }

    if (
      event.kind === 'seller_update_asking_price' &&
      event.source === 'partners'
    ) {
      return (
        <div
          style={{ display: 'flex', flexDirection: 'column', rowGap: '5px' }}
        >
          <span>{`New asking price: ${getPriceText(event.amount)}`}</span>
        </div>
      );
    }

    if (
      event.kind === 'seller_update_asking_price' &&
      event.source === 'buyers'
    ) {
      return (
        <div
          style={{ display: 'flex', flexDirection: 'column', rowGap: '5px' }}
        >
          <span>{`Seller asking price: ${getPriceText(event.amount)}`}</span>
          <span>{`Our fee: ${getPriceText(event.feeAmount)}`}</span>
          <span>
            {`Asking price the buyer sees: ${getPriceText(
              event.amount + event.feeAmount,
            )}`}
          </span>
        </div>
      );
    }

    if (event.kind === 'rejected_by_buyer') {
      return (
        <div
          style={{ display: 'flex', flexDirection: 'column', rowGap: '5px' }}
        >
          <span>{`Deal rejected by ${event.organizationName} - ${submittedBy}`}</span>
        </div>
      );
    }

    return (
      <div>
        <span className="event-description amount">
          {getPriceText(event.amount)}
        </span>
        <span className="event-description">{submittedBy}</span>
      </div>
    );
  };

  return <>{getEventDescription()}</>;
};
