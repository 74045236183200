import { Tooltip } from 'antd';
import { BsTrash } from '@react-icons/all-files/bs/BsTrash';

import './index.scss';

export const DealSubmissionMatchSubTitle = ({
  event,
  subTitle,
  isLastEvent,
  handleOpenModal,
}) => (
  <>
    {subTitle}
    {isLastEvent && (
      <Tooltip title="Remove event from buyer dashboard">
        <BsTrash
          size={25}
          className="remove-event-icon"
          onClick={() => handleOpenModal(event)}
        />
      </Tooltip>
    )}
  </>
);
