import { useEffect } from 'react';
import LoginCard from './Components/LoginCard';

import { postRefreshTasks } from '../../services/authService';
import './index.scss';

const LoginPage = (props) => {
  useEffect(() => {
    postRefreshTasks();
  }, []);
  return (
    <div className="login-page centered">
      <LoginCard {...props} />
    </div>
  );
};
export default LoginPage;
