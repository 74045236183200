import * as types from './types';
import { postRequest, getRequest } from '../../services/requestService';
import { showErrorToast } from '../../services/notificationsService';

export const setCurrentPage =
  ({ page }) =>
  (dispatch) => {
    dispatch({
      type: types.SET_CURRENT_PAGE,
      payload: { currentPage: page },
    });
  };

export const setPageSize =
  ({ pageSize }) =>
  (dispatch) => {
    dispatch({
      type: types.SET_PAGE_SIZE,
      payload: { pageSize },
    });
  };

export const searchPreDeals =
  (searchCriteria = {}) =>
  async (dispatch) => {
    dispatch({
      type: types.SET_LOADING,
      payload: { isLoading: true },
    });
    try {
      const res = await postRequest(
        '/backoffice/pre-deal/search',
        searchCriteria,
      );

      const { items, hasMoreResults, page, total } = res.data;
      dispatch({
        type: types.SET_PRE_DEALS,
        payload: {
          preDeals: items,
          isLoading: false,
          hasMoreResults,
          currentPage: page,
          total,
          lastFetchedCriteria: searchCriteria,
        },
      });
    } catch (error) {
      showErrorToast('Fetching pre deals failed!');
    }
    dispatch({
      type: types.SET_LOADING,
      payload: { isLoading: false },
    });
  };

export const fetchPreDeal = (preDealId) => async (dispatch) => {
  try {
    dispatch({
      type: types.SET_IS_FETCHING_PRE_DEAL,
      payload: { isLoading: true, isFetchingPreDeal: true },
    });
    const { data } = await getRequest(`/backoffice/pre-deal/${preDealId}`);

    dispatch({
      type: types.SET_SELECTED_PRE_DEAL,
      payload: {
        selectedPreDeal: data.preDeal,
        isLoading: false,
        isFetchingPreDeal: false,
      },
    });
  } catch (error) {
    showErrorToast('Fetching pre deal failed!');
  }
  dispatch({
    type: types.SET_IS_FETCHING_PRE_DEAL,
    payload: { isFetchingPreDeal: false, isLoading: false },
  });
};

export function setSelectedPreDeal(selectedPreDeal) {
  return {
    type: types.SET_SELECTED_PRE_DEAL,
    payload: { selectedPreDeal },
  };
}
