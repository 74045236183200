/* eslint react/jsx-props-no-spreading: 0 */
import { Radio } from 'antd';
import './index.scss';
import Form from 'antd/es/form/Form';
import { pick } from 'lodash';

export default (props) => {
  const { options = [], value, label } = props;

  const itemProps = pick(props, ['help', 'prefix', 'tooltip', 'className']);
  const inputProps = pick(props, [
    'placeholder',
    'value',
    'onChange',
    'prefix',
    'onBlur',
    'autoComplete',
    'disabled',
  ]);

  return (
    <Form.Item {...itemProps} label={label}>
      <Radio.Group
        className="zorba-radio-group"
        direction="horizontal"
        value={value}
        {...(props.field || {})}
        {...inputProps}
      >
        {options.map((singleOption) => (
          <Radio
            value={singleOption.value}
            key={`radio-btn-${singleOption.value}`}
          >
            {singleOption.label || singleOption.text}
          </Radio>
        ))}
      </Radio.Group>
    </Form.Item>
  );
};
