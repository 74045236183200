import './index.scss';

import { Button, Switch, Space, InputNumber, Row, Col, Form } from 'antd';
import React, { useEffect, useState } from 'react';
import PlacesAutoComplete from '../../../../../../components/forms/PlacesAutoComplete';
import { DEFAULT_FILTERS } from '../../constants';

export default (props) => {
  const [form] = Form.useForm();
  const [areaList, setAreaList] = useState([]);
  const { removedFilter, appliedFilters } = props;

  const applySearch = (searchCriteria = {}) => {
    if (props.onAdvanceFilterChanged) {
      props.onAdvanceFilterChanged(searchCriteria);
    }
  };

  const clearSearch = () => {
    form.resetFields();
    if (props.onAdvanceSearchClear) {
      props.onAdvanceSearchClear(DEFAULT_FILTERS);
    }
  };

  useEffect(() => {
    removedFilter && form.resetFields([removedFilter.key]);
  }, [removedFilter]);

  useEffect(() => {
    appliedFilters && form.setFieldsValue(appliedFilters);
  }, ['']);

  const onPlacesChange = (address) => {
    if (!address) return;
    const updatedAreaList = [...areaList, address];
    setAreaList(updatedAreaList);

    const values = {
      ...form.getFieldsValue(),
      areas: updatedAreaList.map(({ _id }) => _id),
    };
    applySearch(values);
  };
  return (
    <div className="buyer-advance-filter">
      <Form
        layout="vertical"
        name="advanceFilter"
        initialValues={DEFAULT_FILTERS}
        onFinish={applySearch}
        autoComplete="off"
        form={form}
      >
        <Row gutter={16}>
          <Col xs={24} xl={6} lg={12} md={12} sm={12}>
            <div className="form-field form-field__group">
              <div className="form-field__item">
                <PlacesAutoComplete
                  title="Area of interest"
                  placeholder="City/State/Zipcode"
                  onChange={(address) => onPlacesChange(address)}
                />
              </div>
            </div>
          </Col>
          <Col xs={24} xl={6} lg={12} md={12} sm={12}>
            <div className="form-field form-field__group">
              <div className="form-field__item">
                <Form.Item
                  label="Min Budget"
                  name="minBudget"
                  rules={[{ type: 'number', min: 0 }]}
                >
                  <InputNumber
                    size="large"
                    style={{ width: '100%' }}
                    min="0"
                    formatter={(value) =>
                      value
                        ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        : ''
                    }
                  />
                </Form.Item>
              </div>
              <div className="form-field__separator" />
              <div className="form-field__item">
                <Form.Item
                  label="Max Budget"
                  name="maxBudget"
                  rules={[{ type: 'number' }]}
                >
                  <InputNumber
                    size="large"
                    style={{ width: '100%' }}
                    min="0"
                    formatter={(value) =>
                      value
                        ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        : ''
                    }
                  />
                </Form.Item>
              </div>
            </div>
          </Col>

          <Col xs={24} xl={6} lg={12} md={12} sm={12}>
            <div className="form-field form-field__group">
              <div className="form-field__item">
                <Form.Item
                  label="Min year built"
                  name="minYearBuilt"
                  rules={[{ type: 'number' }]}
                >
                  <InputNumber size="large" style={{ width: '100%' }} min="0" />
                </Form.Item>
              </div>
              <div className="form-field__separator" />
              <div className="form-field__item">
                <Form.Item
                  label="Max year built"
                  name="maxYearBuilt"
                  rules={[{ type: 'number' }]}
                >
                  <InputNumber size="large" style={{ width: '100%' }} min="0" />
                </Form.Item>
              </div>
            </div>
          </Col>

          <Col xs={24} xl={6} lg={12} md={12} sm={12}>
            <div className="form-field form-field__group">
              <div className="form-field__item">
                <Form.Item
                  label="Min beds"
                  name="minBeds"
                  rules={[{ type: 'number' }]}
                >
                  <InputNumber size="large" style={{ width: '100%' }} min="0" />
                </Form.Item>
              </div>
              <div className="form-field__separator" />
              <div className="form-field__item">
                <Form.Item
                  label="Min baths"
                  name="minBaths"
                  rules={[{ type: 'number' }]}
                >
                  <InputNumber size="large" style={{ width: '100%' }} min="0" />
                </Form.Item>
              </div>
            </div>
          </Col>
          <Col xs={12} xl={6} lg={8} md={12} sm={12}>
            <div className="form-field form-field__group mb-0">
              <div className="form-field__item">
                <Form.Item
                  label="Min SQFT"
                  name="minSqft"
                  rules={[{ type: 'number' }]}
                >
                  <InputNumber
                    size="large"
                    style={{ width: '100%' }}
                    min="1"
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                  />
                </Form.Item>
              </div>
              <div className="form-field__separator" />
              <div className="form-field__item">
                <Form.Item
                  label="Max SQFT"
                  name="maxSqft"
                  rules={[{ type: 'number' }]}
                >
                  <InputNumber
                    size="large"
                    style={{ width: '100%' }}
                    min="1"
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                  />
                </Form.Item>
              </div>
            </div>
          </Col>
          <Col xs={12} xl={6} lg={6} md={6} sm={6}>
            <div className="form-field">
              <div className="form-field__item">
                <Form.Item label="Is banned?" name="isBanned">
                  <Switch />
                </Form.Item>
              </div>
            </div>
          </Col>
          <Col xs={12} xl={6} lg={6} md={6} sm={6}>
            <div className="form-field">
              <div className="form-field__item">
                <Form.Item label="Has buy box?" name="hasBuyBox">
                  <Switch />
                </Form.Item>
              </div>
            </div>
          </Col>
          <Col xs={12} xl={6} lg={6} md={6} sm={6}>
            <div className="form-field">
              <div className="form-field__item">
                <Form.Item label="Is verified?" name="isVerified">
                  <Switch />
                </Form.Item>
              </div>
            </div>
          </Col>
          <Col xs={24} xl={24} lg={8} md={24} sm={24}>
            <Space
              align="end"
              style={{ height: '100%', marginTop: '12px' }}
              className="btn-group-filter"
            >
              <Button type="primary" size="large" htmlType="submit">
                Search
              </Button>
              <Button type="default" size="large" onClick={clearSearch}>
                Clear all filters
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
