import { getFriendlyTime } from '@zorba-shared/core/Services/textService';
import { MdVerifiedUser } from '@react-icons/all-files/md/MdVerifiedUser';
import { Badge, Space } from 'antd';

import { USER_KIND_TITLES } from '../../../../constants/permission';

import './index.scss';

const DEFAULT_USER_IMAGE =
  'https://app.getzorba.com/img/auth/default-user-img.jpg';

export default (props) => {
  const { selectedBuyer } = props;

  const isVerified =
    !!selectedBuyer.defaultOrganization?.verification?.verified;

  return (
    <div className="top-section-info">
      <div className="left">
        <div className="user-img">
          <img
            src={selectedBuyer.picture || DEFAULT_USER_IMAGE}
            alt={selectedBuyer?.name}
          />
          {isVerified && (
            <div className="verified-icon">
              <MdVerifiedUser />
            </div>
          )}
        </div>
        <div className="names">
          <div className="full-name">
            {selectedBuyer.name}{' '}
            <Space
              size={[8, 8]}
              wrap
              className="user-permissions"
              align="center"
            >
              {isVerified && <Badge count="Verified" color="#00cb6f" />}

              {selectedBuyer.kind?.length > 0 &&
                selectedBuyer.kind.map((kind) => (
                  <Badge
                    key={kind}
                    count={USER_KIND_TITLES[kind]}
                    color="#ec8337"
                  />
                ))}
            </Space>
          </div>
          <div className="company">
            Company: {selectedBuyer.defaultOrganization?.name}
          </div>
          <div className="member-since">
            Member since:{' '}
            {getFriendlyTime(
              selectedBuyer.createdAt || selectedBuyer.dateCreated,
            )}
          </div>
          <div className="stats__item">
            <div className="stats__item--label">Last login:</div>
            <div className="stats__item--value">
              {getFriendlyTime(selectedBuyer.lastLogin)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
