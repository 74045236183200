import Card from 'antd/es/card/Card';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { updateBuyerStatus } from '../../../../reducer/Buyer/actions';
import DealList from './Components/DealList';
import './index.scss';
import RejectedBuyer from './RejectedBuyer';
import RequiredAction from './RequiredAction';
import VerifiedBuyer from './VerifiedBuyer';
import BuyerVerification from '../BuyerInfoTab/Components/BuyerVerification';

export default (props) => {
  const dispatch = useDispatch();
  const { selectedBuyer } = props;

  const { isLoading } = useSelector(({ BuyerReducer }) => BuyerReducer);

  const { evaluated, verified } =
    get(selectedBuyer, 'defaultOrganization.verification') || {};
  const deals =
    get(selectedBuyer, 'defaultOrganization.verification.previousDeals') || [];

  const onConfirm = (status) => {
    dispatch(updateBuyerStatus({ status, userId: selectedBuyer._id }));
  };
  /**
   * We have 4 states we'd like to cover:
     1. Verification was never submitted
     2. Verification was submitted - action requried
     3. Verified
     4. Rejected
   */
  const getVerificationStep = () => {
    // Buyer submitted but we never approved/rejected this request
    if (!evaluated)
      return (
        <RequiredAction
          selectedBuyer={selectedBuyer}
          isLoading={isLoading}
          onConfirm={onConfirm}
        />
      );

    // Return the state we chose (verified/rejected)
    return verified ? (
      <VerifiedBuyer onConfirm={onConfirm} isLoading={isLoading} />
    ) : (
      <RejectedBuyer onConfirm={onConfirm} isLoading={isLoading} />
    );
  };

  if (!selectedBuyer) return null;
  return (
    <div className="buyer-verification-tab">
      {deals.length > 0 && (
        <Card
          title={`Last ${deals.length} deals`}
          style={{ marginTop: '15px' }}
        >
          <DealList deals={deals} />
        </Card>
      )}

      {getVerificationStep()}

      <div className="marketing-channels">
        <h4 className="marketing-channels__title">Marketing Channels</h4>
        <BuyerVerification selectedBuyer={selectedBuyer} />
      </div>
    </div>
  );
};
