import { sortedUniqBy } from 'lodash';
import { postRequest } from '../../services/requestService';
import * as types from './types';

import { setModalState } from '../Modal/actions';
import { MODAL_CREATE_ORGANIZATION } from '../Modal/types';
import {
  showErrorToast,
  showSuccessToast,
} from '../../services/notificationsService';

export const getOrganizations =
  (searchCriteria = {}) =>
  async (dispatch) => {
    dispatch({
      type: types.SET_ORGANIZATION_LOADING,
      payload: { isLoading: true },
    });
    const res = await postRequest(
      '/backoffice/organization/search',
      searchCriteria,
    );
    const { items, hasMoreResults, page, total } = res.data;
    dispatch({
      type: types.SET_ORGANIZATIONS,
      payload: {
        organizations: items,
        isLoading: false,
        hasMoreResults,
        page,
        total,
      },
    });
  };

export const setNewOrganization =
  (newOrganization = null) =>
  async (dispatch) => {
    dispatch({
      type: 'SET_NEW_ORGANIZATION',
      payload: {
        newOrganization,
      },
    });
  };

export const createOrganization =
  (data = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: types.SET_ORGANIZATION_SAVING,
      payload: { isOrganizationSaving: true },
    });
    try {
      const res = await postRequest('/backoffice/organization/create', data);
      const { organization, reason } = res.data;
      let { organizations } = getState().OrganizationReducer;
      if (reason) {
        showErrorToast(reason);
        dispatch({
          type: types.SET_ORGANIZATION_SAVING,
          payload: { isOrganizationSaving: false },
        });
      } else {
        showSuccessToast('Organization created successfully.');
        organizations = sortedUniqBy([...organizations, organization], 'name');
        dispatch({
          type: types.ADD_ORGANIZATION,
          payload: {
            isOrganizationSaving: false,
            newOrganization: organization,
            organizations,
          },
        });
        dispatch(setModalState(MODAL_CREATE_ORGANIZATION, false));
      }
    } catch (err) {
      showErrorToast('Oops, Please try again later');
      dispatch({
        type: types.SET_ORGANIZATION_SAVING,
        payload: { isOrganizationSaving: false },
      });
    }
  };
