import * as types from './types';
import { getRequest, postRequest } from '../../services/requestService';
import {
  showErrorToast,
  showSuccessToast,
} from '../../services/notificationsService';
import { setModalState } from '../Modal/actions';
import { MODAL_DUPLICATED_DEAL } from '../Modal/types';

export const setCurrentPage =
  ({ page }) =>
  (dispatch) => {
    dispatch({
      type: types.SET_CURRENT_PAGE,
      payload: { currentPage: page },
    });
  };

export const setPageSize =
  ({ pageSize }) =>
  (dispatch) => {
    dispatch({
      type: types.SET_PAGE_SIZE,
      payload: { pageSize },
    });
  };
export const searchDealSubmissions =
  (searchCriteria = {}) =>
  async (dispatch) => {
    dispatch({
      type: types.SET_LOADING,
      payload: { isLoading: true },
    });
    try {
      const res = await postRequest(
        '/backoffice/deal-submission/search',
        searchCriteria,
      );

      const { items, hasMoreResults, page, total } = res.data;
      dispatch({
        type: types.SET_DEAL_SUBMISSIONS,
        payload: {
          dealSubmissions: items,
          isLoading: false,
          hasMoreResults,
          currentPage: page,
          total,
          lastFetchedCriteria: searchCriteria,
        },
      });
    } catch (error) {
      showErrorToast('Fetching deal submissions failed!');
    }
    dispatch({
      type: types.SET_LOADING,
      payload: { isLoading: false },
    });
  };

export const fetchDealSubmission = (dealSubmissionId) => async (dispatch) => {
  try {
    dispatch({
      type: types.SET_IS_FETCHING_DEAL_SUBMISSION,
      payload: { isLoading: true, isFetchingDealSubmission: true },
    });
    const { data } = await getRequest(
      `/backoffice/deal-submission/${dealSubmissionId}`,
    );

    dispatch({
      type: types.SET_SELECTED_DEAL_SUBMISSION,
      payload: {
        selectedDealSubmission: data.dealSubmission,
        otherDealSubmissions: data.otherDealSubmissions,
        isLoading: false,
        isFetchingDealSubmission: false,
      },
    });
  } catch (error) {
    showErrorToast('Fetching deal submission failed!');
  }
  dispatch({
    type: types.SET_IS_FETCHING_DEAL_SUBMISSION,
    payload: { isFetchingDealSubmission: false, isLoading: false },
  });
};

export const refreshDealSubmissionMatches = (params) => async (dispatch) => {
  try {
    dispatch({
      type: types.SET_IS_FETCHING_DEAL_SUBMISSION,
      payload: { isFetchingDealSubmission: true },
    });
    const { data } = await postRequest(
      `/backoffice/deal-submission/refresh-matches`,
      params,
    );

    dispatch({
      type: types.SET_SELECTED_DEAL_SUBMISSION,
      payload: { selectedDealSubmission: data.dealSubmission },
    });
  } catch (error) {
    showErrorToast('Refreshing deal submission matches failed!');
  }
  dispatch({
    type: types.SET_IS_FETCHING_DEAL_SUBMISSION,
    payload: { isFetchingDealSubmission: false },
  });
};

export const createDealSubmissionMatch = (params) => async (dispatch) => {
  try {
    dispatch({
      type: types.SET_IS_FETCHING_DEAL_SUBMISSION,
      payload: { isFetchingDealSubmission: true },
    });
    const { data } = await postRequest(
      `/backoffice/deal-submission-match/create-or-update`,
      params,
    );

    dispatch({
      type: types.SET_SELECTED_DEAL_SUBMISSION,
      payload: { selectedDealSubmission: data.dealSubmission },
    });
  } catch (error) {
    if (error?.response?.data?.redirectUrl) {
      dispatch(setModalState(MODAL_DUPLICATED_DEAL, true));
      dispatch({
        type: types.SET_REDIRECT_URL,
        payload: { redirectUrl: error?.response?.data?.redirectUrl },
      });
    } else {
      showErrorToast(
        error?.response?.data?.reason ||
          'Creating deal submission match failed!',
      );
    }
  }
  dispatch({
    type: types.SET_IS_FETCHING_DEAL_SUBMISSION,
    payload: { isFetchingDealSubmission: false },
  });
};

export function setSelectedDealSubmission(selectedDealSubmission) {
  return {
    type: types.SET_SELECTED_DEAL_SUBMISSION,
    payload: { selectedDealSubmission },
  };
}

export const excludeDealSubmissionMatchFromCommunication =
  (params) => async (dispatch) => {
    try {
      dispatch({
        type: types.SET_IS_FETCHING_DEAL_SUBMISSION,
        payload: { isFetchingDealSubmission: true },
      });
      await postRequest(
        `/backoffice/deal-submission-match/exclude-from-communication`,
        params,
      );
      dispatch(fetchDealSubmission(params.dealSubmissionId));
    } catch (error) {
      showErrorToast('Excluding from communication failed!');
      dispatch({
        type: types.SET_IS_FETCHING_DEAL_SUBMISSION,
        payload: { isFetchingDealSubmission: false },
      });
    }
  };

export const removeDealSubmissionMatchEvent =
  (params, callback) => async (dispatch) => {
    try {
      const { data } = await postRequest(
        '/backoffice/deal-submission-match/remove-event',
        params,
      );
      dispatch({
        type: types.SET_SELECTED_DEAL_SUBMISSION,
        payload: { selectedDealSubmission: data.dealSubmission },
      });
      if (callback) {
        callback();
      }
      showSuccessToast('Event removed successfully');
    } catch (error) {
      showErrorToast('Removing an event failed');
    }
  };

export const renewOffer = (params) => async (dispatch) => {
  dispatch({
    type: types.SET_IS_RENEWING_OFFER,
    payload: { isRenewingOffer: true },
  });
  try {
    await postRequest(
      '/backoffice/deal-submission-match/renew-offer-expiration-time',
      params,
    );
    showSuccessToast('Offer expiration time renewed successfully');
  } catch (error) {
    showErrorToast('Renewing an offer expiration time failed');
  }
  dispatch({
    type: types.SET_IS_RENEWING_OFFER,
    payload: { isRenewingOffer: true },
  });
};
