/* eslint-disable default-param-last */
import * as types from './types';

const initialState = {
  title: 'Buyers',
  isLoading: false,
  hasMoreResults: false,
  bannedCount: 0,
  selectedBuyer: undefined,
  selectedBuyerData: undefined,
  properties: [],
  propertyTotal: 0,
  areas: [],
  offers: [],
  isBuyerSaving: false,
  isCheckingBuyers: false,
  isGeneratingOffer: false,
  maxOffer: null,
  buyers: [],
  buyBoxMatchedProperties: [],
  isFetchingBuyboxMatchedProperties: false,
  buyBoxPropertyMatch: null,
  buyBoxMatchedPropertyTotal: 0,
  isMatchingCountShown: false,
};
const BuyerReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_BUYERS:
    case types.SET_BUYER:
    case types.SET_LOADING:
    case types.SET_BUYER_PROPERTIES:
    case types.SET_BUYER_OFFER:
    case types.SET_MATCHING_BUYERS:
    case types.SET_BUYER_SAVING:
    case types.SET_BUYBOX_MATCHED_PROPERTIES:
      return {
        ...state,
        ...payload,
        isCheckingBuyers: false,
        isGeneratingOffer: false,
      };
    case types.SET_ADD_USER: {
      return {
        ...state,
        ...payload,
        buyers: [state.user, ...state.buyers],
        isCheckingBuyers: false,
        isGeneratingOffer: false,
      };
    }
    case types.SET_MATCHING_COUNT_SHOWN:
    case types.SET_CHECKING_BUYERS:
    case types.SET_GENERATING_OFFERS:
    case types.SET_TAX_ASSESSOR_DATA:
    case types.SET_INPUT_OBJECT:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default BuyerReducer;
