import { Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { setModalState } from '../../../../../../reducer/Modal/actions';
import { MODAL_REMOVE_BUYER_EVENT } from '../../../../../../reducer/Modal/types';
import { mapDealSubmissionMatchEventTypeToTitle } from '../../constants';

import './index.scss';
import { ZorbaButton } from '../../../../../../components/forms';
import { removeDealSubmissionMatchEvent } from '../../../../../../reducer/DealSubmission/actions';

export const RemoveBuyerEventModal = ({
  selectedBuyerEvent,
  oneBeforeSelectedBuyerEvent,
}) => {
  const { isRemoveBuyerEventModalOpen } = useSelector(
    ({ ModalReducer }) => ModalReducer,
  );
  const dispatch = useDispatch();
  const handleCloseModal = () => {
    dispatch(setModalState(MODAL_REMOVE_BUYER_EVENT, false));
  };

  const handleRemoveEvent = () => {
    dispatch(
      removeDealSubmissionMatchEvent(
        {
          dealSubmissionMatchId: selectedBuyerEvent.dealSubmissionMatchId,
          eventId: selectedBuyerEvent._id,
        },
        handleCloseModal,
      ),
    );
  };

  return (
    <Modal
      open={isRemoveBuyerEventModalOpen}
      footer={null}
      onCancel={handleCloseModal}
      title={`Remove event from ${selectedBuyerEvent?.organizationName} dashboard`}
      className="remove-buyer-event-modal"
      destroyOnClose
    >
      <div className="instructions-container">
        <span>
          Are you sure that you want to remove{' '}
          <span className="instruction-bold">
            {mapDealSubmissionMatchEventTypeToTitle[selectedBuyerEvent?.kind]}
          </span>{' '}
          event from {selectedBuyerEvent?.organizationName} dashboard?
        </span>

        {oneBeforeSelectedBuyerEvent ? (
          <span>
            Performing this action will move the deal in{' '}
            {selectedBuyerEvent?.organizationName} dashboard to{' '}
            <span className="instruction-bold">
              {
                mapDealSubmissionMatchEventTypeToTitle[
                  oneBeforeSelectedBuyerEvent?.kind
                ]
              }
            </span>{' '}
            step.
          </span>
        ) : (
          <span>
            {`Performing this action will completely remove the deal from ${selectedBuyerEvent?.organizationName} dashboard.`}
          </span>
        )}
        <span>The buyer will not receive any notifications.</span>
      </div>
      <div className="button-container">
        <ZorbaButton
          fullWidth={false}
          onClick={handleRemoveEvent}
          disabled={!selectedBuyerEvent}
        >
          Remove event
        </ZorbaButton>
      </div>
    </Modal>
  );
};
