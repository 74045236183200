export const stateNameToCode = {
  Alabama: 'AL',
  Alaska: 'AK',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
};

export const stateCodeToName = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};
export const getDynamicColor = (count, minCount, maxCount) => {
  if (!count || count <= minCount) {
    return '#DDE4F7'; // Very light blue
  }

  const percentage = ((count - minCount) / (maxCount - minCount)) * 100;

  if (percentage <= 20) return '#B4C2F0'; // Light blue
  if (percentage > 20 && percentage <= 40) return '#8B9FE8'; // Medium light blue
  if (percentage > 40 && percentage <= 60) return '#7388D9'; // Primary blue
  if (percentage > 60 && percentage <= 80) return '#5F74CB'; // Darker blue
  if (percentage > 80 && percentage <= 100) return '#4C62BE'; // Deep blue

  return '#384DAE'; // Very dark blue
};

export const STYLES = {
  STATES_FILL: {
    'fill-color': [
      'case',
      ['boolean', ['feature-state', 'selected'], false],
      '#FFFFFF',
      ['boolean', ['feature-state', 'blurred'], false],
      '#000000',
      ['get', 'background_color'],
    ],
    'fill-opacity': [
      'case',
      ['boolean', ['feature-state', 'selected'], false],
      0.2,
      ['boolean', ['feature-state', 'blurred'], false],
      0.3,
      0.9,
    ],
  },
  STATES_OUTLINE: {
    'line-width': [
      'case',
      ['boolean', ['feature-state', 'selected'], false],
      3,
      ['boolean', ['feature-state', 'blurred'], false],
      1,
      2,
    ],
    'line-color': [
      'case',
      ['boolean', ['feature-state', 'selected'], false],
      '#000000',
      ['boolean', ['feature-state', 'blurred'], false],
      '#000000',
      '#FFFFFF',
    ],
  },
  STATES_SYMBOL: {
    'text-color': '#000000',
    'text-halo-color': '#fff',
    'text-halo-width': 1.5,
  },
  MSA_TRANSACTIONS_FILL: {
    'fill-color': [
      'case',
      ['boolean', ['feature-state', 'msaSelected'], false],
      '#FFFFFF',
      ['boolean', ['feature-state', 'msaBlurred'], false],
      '#000000',
      ['get', 'background_color'],
    ],
    'fill-opacity': [
      'case',
      ['boolean', ['feature-state', 'msaSelected'], false],
      0.2,
      ['boolean', ['feature-state', 'msaBlurred'], false],
      0.3,
      0.9,
    ],
  },
  MSA_TRANSACTIONS_OUTLINE: {
    'line-width': 1,
    'line-color': '#000000',
  },
  MSA_TRANSACTIONS_SYMBOL: {
    'text-color': '#000000',
    'text-halo-color': '#FFFFFF',
    'text-halo-width': 1.5,
  },
  MSA_ZIP_TRANSACTIONS_FILL: {
    'fill-color': ['get', 'background_color'],
    'fill-opacity': 0.8,
  },
  MSA_ZIP_TRANSACTIONS_OUTLINE: {
    'line-width': 1,
    'line-color': '#000000',
  },
  MSA_ZIP_TRANSACTIONS_SYMBOL: {
    'text-color': '#000000',
    'text-halo-color': '#FFFFFF',
    'text-halo-width': 1.5,
  },
};

export const LAYOUT = {
  STATES_SYMBOL: {
    'text-field': ['format', ['get', 'name']],
    'text-font': ['Roboto Regular', 'Arial Unicode MS Regular'],
    'text-size': 15,
    'text-anchor': 'center',
  },
  MSA_TRANSACTIONS_SYMBOL: {
    'icon-image': 'marker-15',
    'icon-size': 1.5,
    'icon-anchor': 'center',
  },
  MSA_ZIP_TRANSACTIONS_SYMBOL: {
    'icon-image': 'marker-15',
    'icon-size': 1.5,
    'icon-anchor': 'center',
  },
};

export const ZOOM_LEVELS = {
  STATE: 4,
  MSA: 6,
  ZIPCODE: 8,
};

export const EVENT_SOURCES = {
  STATES: 'states',
  MSA: 'msa-transactions',
};
