import { Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getTableColumns } from './constants';
import { isMobile } from '../../../../services/clientService';
import {
  setCurrentPage,
  setPageSize,
} from '../../../../reducer/PreDeals/actions';

import './index.scss';

const tableScroll = {
  scrollToFirstRowOnChange: true,
  x: isMobile() ? true : 'max-content',
};
export const PreDealsTable = () => {
  const { isLoading, total, preDeals, currentPage, pageSize } = useSelector(
    ({ PreDealsReducer }) => PreDealsReducer,
  );
  const dispatch = useDispatch();
  const locale = {
    emptyText: isLoading ? null : <p>No Record Found.</p>,
  };

  const onShowSizeChange = (changedPage, changedCurrentPageSize) => {
    if (changedPage - 1 !== currentPage) {
      dispatch(setCurrentPage({ page: changedPage - 1 }));
    }
    if (pageSize !== changedCurrentPageSize) {
      dispatch(setPageSize({ pageSize: changedCurrentPageSize }));
    }
  };

  const pagination = {
    position: ['bottomRight'],
    onChange: onShowSizeChange,
    total,
    pageSize,
    current: currentPage + 1,
    showTotal: (totalItems, range) =>
      `${range[0]}-${range[1]} of ${totalItems} items`,
  };

  return (
    <Table
      rowKey="_id"
      className="pre-deals-table"
      loading={isLoading}
      columns={getTableColumns()}
      dataSource={preDeals}
      scroll={tableScroll}
      pagination={pagination}
      locale={locale}
    />
  );
};
