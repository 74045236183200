import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { setModalState } from '../../../../reducer/Modal/actions';
import { MODAL_UNSAVED_CHANGES } from '../../../../reducer/Modal/types';

import { ZorbaButton } from '../../../../components/forms';
import { BUTTON_VARIANT_DARK } from '../../../../components/forms/ZorbaButton/constants';

import './index.scss';

export const UnsavedChangesModal = ({ handleDiscardChanges }) => {
  const dispatch = useDispatch();
  const { isUnsavedChangesModalOpen } = useSelector(
    ({ ModalReducer }) => ModalReducer,
  );
  const handleClose = () => {
    dispatch(setModalState(MODAL_UNSAVED_CHANGES, false));
  };

  return (
    <Modal
      open={isUnsavedChangesModalOpen}
      footer={null}
      onCancel={handleClose}
      title="Unsaved Changes"
      destroyOnClose
      className="unsaved-buybox-changes-modal"
    >
      <p>
        You have unsaved changes in this form. Are you sure you want to switch
        tab and discard those changes?
      </p>
      <div className="buttons-container">
        <div className="button-container">
          <ZorbaButton fullWidth onClick={handleDiscardChanges}>
            Discard changes
          </ZorbaButton>
        </div>
        <div className="button-container">
          <ZorbaButton
            variant={BUTTON_VARIANT_DARK}
            fullWidth
            onClick={handleClose}
          >
            Cancel
          </ZorbaButton>
        </div>
      </div>
    </Modal>
  );
};
