import { ZorbaButton } from '../../../../../../../../components/forms';
import {
  FIELD_MAPPING_STATUS_IGNORE,
  FIELD_MAPPING_STATUS_MAPPED,
} from '../../constants';

const MapFieldConfirmAction = (props) => {
  const { field, disableConfirm, onFieldStatusUpdate, onIgnore } = props;

  return (
    <>
      <div className="field-status-info mapping-status-false">
        {field ? (
          <i className="fas fa-check" />
        ) : (
          <i className="fas fa-exclamation" />
        )}
        <span>
          {field
            ? `Match to the ${field} field`
            : `Unable to automatically match`}
        </span>
      </div>
      <div className="controls-wrapper">
        <ZorbaButton
          disabled={disableConfirm}
          fullWidth={false}
          onClick={() => onFieldStatusUpdate(FIELD_MAPPING_STATUS_MAPPED)}
        >
          Confirm mapping
        </ZorbaButton>
        <ZorbaButton
          fullWidth={false}
          onClick={() => {
            onIgnore();
            onFieldStatusUpdate(FIELD_MAPPING_STATUS_IGNORE);
          }}
        >
          Ignore this column
        </ZorbaButton>
      </div>
    </>
  );
};

export default MapFieldConfirmAction;
