import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';

import { buildHomePageUrl } from '@zorba-shared/core/Services/urlBuilderService';
import {
  getAuthToken,
  QUERY_PARAM_REDIRECT_URL,
} from '../../../../../../services/authService';

import SocialLoginButtons from '../../../SocialLoginButtons';
import { getQueryParam } from '../../../../../../services/clientService';

export default (props) => {
  const { authStateData, params } = props;
  if (!authStateData) return null;
  const { subtitle } = authStateData;
  const dispatch = useDispatch();
  const { isAuthLoading } = useSelector(({ AuthReducer }) => AuthReducer);

  const redirectUrl = getQueryParam(QUERY_PARAM_REDIRECT_URL);
  if (redirectUrl)
    Cookies.set(QUERY_PARAM_REDIRECT_URL, redirectUrl, { expires: 5 / 24 }); // Expires in 5 hours

  return (
    <div className="auth-state initial">
      <SocialLoginButtons />
    </div>
  );
};
