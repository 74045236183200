export const SET_BUYERS = 'SET_BUYERS';
export const SET_BUYER = 'SET_BUYER';
export const SET_LOADING = 'SET_LOADING';
export const SET_BUYER_PROPERTIES = 'SET_BUYER_PROPERTIES';
export const SET_BUYER_OFFER = 'SET_BUYER_OFFER';
export const SET_BUYER_SAVING = 'SET_BUYER_SAVING';
export const SET_ADD_USER = 'SET_ADD_USER';
export const SET_MATCHING_BUYERS = 'SET_MATCHING_BUYERS';
export const SET_CHECKING_BUYERS = 'SET_CHECKING_BUYERS';
export const SET_GENERATING_OFFERS = 'SET_GENERATING_OFFERS';
export const SET_BUYBOX_MATCHED_PROPERTIES = 'SET_BUYBOX_MATCHED_PROPERTIES';
export const SET_MATCHING_COUNT_SHOWN = 'SET_MATCHING_COUNT_SHOWN';

export const SET_TAX_ASSESSOR_DATA = 'SET_TAX_ASSESSOR_DATA';
export const SET_INPUT_OBJECT = 'SET_INPUT_OBJECT';
