import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PlacesAutoComplete } from '../../../../components/forms';
import { getMatchedTransactions } from '../../../../reducer/InstitutionalResearch/actions';
import { useSearchParams } from '../../../../utils';

import { TransactionsList } from './Components/TransactionsList';
import { MapComponent } from './Components/Map';

import './index.scss';

export const InstitutionalResearch = () => {
  const [mapRef, setMapRef] = useState(null);
  const [popupInfo, setPopupInfo] = useState(null);
  const { isLoading, transactions } = useSelector(
    ({ InstitutionalResearchReducer }) => InstitutionalResearchReducer,
  );
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const queryAddress = searchParams.get('query');
  const [selectedLocation, setSelectedLocation] = useState([]);

  const onPlacesChange = (newAddress) => {
    if (!newAddress) return;
    setSelectedLocation(newAddress.location);

    dispatch(getMatchedTransactions(newAddress));
  };

  return (
    <div className="institutional-research-page">
      <div className="input-container">
        <PlacesAutoComplete
          title={null}
          disabled={isLoading}
          allowFullAddress
          placeholder="Start by typing the property address"
          onChange={(value) => onPlacesChange(value)}
          initialInputText={queryAddress}
          autoSelectMatchingItem
        />
      </div>
      <div className="map-list-container">
        <div className="map-container">
          <MapComponent
            transactions={transactions}
            location={selectedLocation}
            mapRef={mapRef}
            setMapRef={setMapRef}
            popupInfo={popupInfo}
            setPopupInfo={setPopupInfo}
          />
        </div>
        <div className="list-container">
          <TransactionsList
            isLoading={isLoading}
            transactions={transactions}
            mapRef={mapRef}
            setPopupInfo={setPopupInfo}
          />
        </div>
      </div>
    </div>
  );
};
