/* eslint-disable consistent-return */
/* eslint no-use-before-define: 0 */

import { push } from 'connected-react-router';
import { get } from 'lodash';
import {
  deleteRequest,
  getRequest,
  postRequest,
} from '../../services/requestService';
import * as types from './types';
import {
  buildBuyerPageUrl,
  buildHomePageUrl,
} from '../../services/urlBuilderService';
import {
  showErrorToast,
  showSuccessToast,
} from '../../services/notificationsService';
import { setModalState } from '../Modal/actions';
import { MODAL_CREATE_USER } from '../Modal/types';

export const fetchBuyers = async (searchCriteria) => {
  const res = await postRequest('/backoffice/user/search', searchCriteria);

  return res?.data;
};

export const searchBuyers =
  (searchCriteria = {}) =>
  async (dispatch) => {
    dispatch({
      type: types.SET_BUYERS,
      payload: { isLoading: true },
    });
    const res = await fetchBuyers(searchCriteria);
    const { items, hasMoreResults, page, total, areas } = res;
    dispatch({
      type: types.SET_BUYERS,
      payload: {
        buyers: items,
        isLoading: false,
        hasMoreResults,
        page,
        total,
        bannedCount: 200,
        areas: areas || [],
      },
    });
  };

export const createBuyerAccount = (data) => async (dispatch) => {
  dispatch({
    type: types.SET_BUYER_SAVING,
    payload: { isBuyerSaving: true },
  });

  try {
    const res = await postRequest(`/backoffice/user/create`, data);
    const { reason, user } = res.data;
    if (reason) {
      showErrorToast(reason);
    } else {
      dispatch(setModalState(MODAL_CREATE_USER, false));
      showSuccessToast(
        'A buyer has been created successfully. Redirecting you to Buyer page.',
      );
      // refresh buyer listing to show newly added buyer in list
      dispatch(searchBuyers());
      // open buyer page in new tab
      setTimeout(() => {
        window.open(
          buildBuyerPageUrl({ userDetails: user, queryString: 'tab=tab2' }),
          '_blank',
        );
      }, 2000);
    }

    dispatch({
      type: types.SET_BUYER_SAVING,
      payload: { isBuyerSaving: false },
    });
  } catch (err) {
    showErrorToast('Oops, Please try again later');
    dispatch({
      type: types.SET_BUYER_SAVING,
      payload: { isBuyerSaving: false },
    });
  }
};

export const getBuyerDetails = (userId) => async (dispatch) => {
  if (!userId) return dispatch(push(buildHomePageUrl()));
  dispatch({
    type: types.SET_LOADING,
    payload: { isLoading: true },
  });

  const res = await getRequest(`/backoffice/user/${userId}`);
  dispatch({
    type: types.SET_BUYER,
    payload: {
      ...res.data,
      isLoading: false,
    },
  });
};

export const updateBuyerDetails = (params) => async (dispatch) => {
  const hasChangedparams = Object.keys(params).length > 0;
  if (!hasChangedparams) return;

  dispatch({
    type: types.SET_LOADING,
    payload: { isLoading: true },
  });
  const { data: response } = await postRequest(
    '/backoffice/user/update-details',
    params,
  );
  dispatch({
    type: types.SET_BUYER,
    payload: { ...response.data, isLoading: false },
  });
};

export const updateBuyerStatus = (params, callback) => async (dispatch) => {
  dispatch({
    type: types.SET_LOADING,
    payload: { isLoading: true },
  });

  await postRequest(
    `/backoffice/user/set-verification-status/${params.userId}`,
    params,
  );
  if (callback) {
    callback();
  } else {
    dispatch(getBuyerDetails(params.userId));
  }
};

export const setPhoneVerified = (params) => async (dispatch) => {
  dispatch({
    type: types.SET_LOADING,
    payload: { isLoading: true },
  });

  await postRequest(`/backoffice/user/set-phone-verify/${params.userId}`, {
    status: params.status,
  });
  dispatch(getBuyerDetails(params.userId));
};

export const setEmailVerified = (params) => async (dispatch) => {
  dispatch({
    type: types.SET_LOADING,
    payload: { isLoading: true },
  });

  await postRequest(`/backoffice/user/set-email-verify/${params.userId}`, {
    status: params.status,
  });
  dispatch(getBuyerDetails(params.userId));
};

export const getProperties =
  (searchCriteria = {}) =>
  async (dispatch) => {
    dispatch({
      type: types.SET_LOADING,
      payload: { isLoading: true },
    });
    try {
      const res = await postRequest(
        `/backoffice/user/${searchCriteria.userId}/properties`,
        searchCriteria,
      );
      const { items, hasMoreResults, page, total } = res.data;
      const properties = transformData(items);
      dispatch({
        type: types.SET_BUYER_PROPERTIES,
        payload: {
          properties,
          isLoading: false,
          hasMoreResults,
          page,
          propertyTotal: total,
        },
      });
    } catch (err) {
      showErrorToast('Oops, Please try again later');
      dispatch({
        type: types.SET_LOADING,
        payload: { isLoading: false },
      });
    }
  };

export const getOffers =
  (searchCriteria = {}) =>
  async (dispatch) => {
    dispatch({
      type: types.SET_LOADING,
      payload: { isLoading: true },
    });
    try {
      const res = await postRequest(
        `/backoffice/user/${searchCriteria.userId}/offers`,
        searchCriteria,
      );
      const { items, hasMoreResults, page, total } = res.data;
      dispatch({
        type: types.SET_BUYER_OFFER,
        payload: {
          offers: items,
          isLoading: false,
          hasMoreResults,
          page,
          offerTotal: total,
        },
      });
    } catch (err) {
      showErrorToast('Oops, Please try again later');
      dispatch({
        type: types.SET_LOADING,
        payload: { isLoading: false },
      });
    }
  };

export const deleteBuyer = (userId) => async (dispatch) => {
  if (!userId) return dispatch(push(buildHomePageUrl()));

  try {
    dispatch({
      type: types.SET_LOADING,
      payload: { isLoading: true },
    });

    const res = await deleteRequest(`/backoffice/user/${userId}`);
    const { reason } = res.data;
    if (reason) {
      showErrorToast(reason);
    } else {
      showSuccessToast(
        'A buyer has been deleted successfully. Please wait while refreshing a list.',
      );
      // refresh buyer listing to show newly added buyer in list
      dispatch(searchBuyers());
    }
    dispatch({
      type: types.SET_LOADING,
      payload: { isLoading: false },
    });
  } catch (err) {
    showErrorToast('Oops, Please try again later');
    dispatch({
      type: types.SET_LOADING,
      payload: { isLoading: false },
    });
  }
};

export const unsubscribeUserFromSMSCampaigns =
  (userId, searchCriteria) => async (dispatch) => {
    try {
      dispatch({
        type: types.SET_LOADING,
        payload: { isLoading: true },
      });

      await postRequest(`/backoffice/user/unsubscribe-from-sms-campaign`, {
        userId,
      });

      showSuccessToast(
        'A buyer has been unsubscribed from SMS campaigns in CIO',
      );
      // refresh buyer listing to show newly added buyer in list
      dispatch(searchBuyers(searchCriteria));
      dispatch({
        type: types.SET_LOADING,
        payload: { isLoading: false },
      });
    } catch (err) {
      showErrorToast('Oops, Please try again later');
      dispatch({
        type: types.SET_LOADING,
        payload: { isLoading: false },
      });
    }
  };

export const bulkUnsubscribeUsersFromSMSCampaigns =
  (phoneNumbers, closeModal) => async (dispatch) => {
    try {
      dispatch({
        type: types.SET_LOADING,
        payload: { isLoading: true },
      });

      await postRequest(`/backoffice/user/unsubscribe-from-sms-campaign`, {
        phoneNumbers,
      });

      showSuccessToast('Users has been unsubscribed from SMS campaigns in CIO');
      dispatch({
        type: types.SET_LOADING,
        payload: { isLoading: false },
      });
      closeModal();
    } catch (err) {
      showErrorToast('Oops, Please try again later');
      dispatch({
        type: types.SET_LOADING,
        payload: { isLoading: false },
      });
    }
  };
export const sendLeadToBuyer =
  (userId, sellerLeadId) => async (dispatch, getState) => {
    if (!userId) return dispatch(push(buildHomePageUrl()));
    const { properties } = getState().BuyerReducer;

    try {
      dispatch({
        type: types.SET_LOADING,
        payload: { isLoading: true },
      });

      const res = await postRequest(
        `/backoffice/user/${userId}/send-pre-listing`,
        { sellerLeadId },
      );
      const { reason, sellerLead } = res.data;
      if (reason) {
        showErrorToast(reason);
      } else {
        if (sellerLead) {
          const index = properties.findIndex((p) => p._id === sellerLeadId);
          if (index >= 0) {
            properties[index] = {
              ...properties[index],
              messages: sellerLead.messages,
            };
            dispatch({
              type: types.SET_BUYER_PROPERTIES,
              payload: { properties },
            });
          }
        }
        showSuccessToast('A property has been sent to buyer.');
      }
      dispatch({
        type: types.SET_LOADING,
        payload: { isLoading: false },
      });
    } catch (error) {
      const msg = get(error, 'response.data.reason');
      showErrorToast(msg || 'Oops, Please try again later');
      dispatch({
        type: types.SET_LOADING,
        payload: { isLoading: false },
      });
    }
  };

/**
 * get buyers whose buybox(address) matches with property's address
 * @param {*} address
 */
export const getMatchedBuyers = (address) => async (dispatch) => {
  if (!address) return;

  dispatch({
    type: types.SET_CHECKING_BUYERS,
    payload: {
      isCheckingBuyers: true,
      zillowResponse: null,
      buyers: [],
      taxAssessorData: null,
    },
  });
  let payload = { isCheckingBuyers: false };

  try {
    const res = await postRequest(`/backoffice/buyer-checker/search`, {
      address,
    });
    const { reason } = res.data;
    if (reason) {
      showErrorToast(reason);
    }
    payload = {
      ...res.data,
    };
    // payload.matchingBuyers = buyers;
    // payload.zillowResponse = zillowResponse;
  } catch (error) {
    const msg = get(error, 'response.data.reason');
    showErrorToast(msg || 'Oops, Please try again later');
  }

  dispatch({
    type: types.SET_MATCHING_BUYERS,
    payload,
  });
};

/**
 * generate offer
 * @param {*} params {
 * grossRent,
 * rehabCost,
 * arv
 * }
 */
export const generateMaxOffer = (params) => async (dispatch) => {
  if (!params) return;

  dispatch({
    type: types.SET_GENERATING_OFFERS,
    payload: { isGeneratingOffer: true, maxOffer: null },
  });
  const payload = { isGeneratingOffer: false };

  try {
    const res = await postRequest(
      `/backoffice/buyer-checker/calc-offers`,
      params,
    );
    // payload.maxOffer = res.data || {};
    payload.buyers = res.data.buyers;
  } catch (error) {
    const msg = get(error, 'response.data.reason');
    showErrorToast(msg || 'Oops, Please try again later');
  }

  dispatch({
    type: types.SET_GENERATING_OFFERS,
    payload,
  });
};

/**
 * Get buyer's buybox matched properties
 *
 * @param {*} userId A buyer Id
 */
export const getBuyBoxMatchedProeprties =
  ({ userId, buyBoxList }) =>
  async (dispatch, getState) => {
    if (!userId) return;

    dispatch({
      type: types.SET_BUYBOX_MATCHED_PROPERTIES,
      payload: {
        isFetchingBuyboxMatchedProperties: true,
        buyBoxMatchedProperties: [],
        buyBoxMatchedPropertyTotal: 0,
        isMatchingCountShown: true,
      },
    });
    const payload = { isFetchingBuyboxMatchedProperties: false };

    try {
      const res = await postRequest(
        `/backoffice/user/${userId}/buybox-matches`,
        {
          userId,
          buyBoxList,
        },
      );
      const { reason, items, total, buyBoxPropertyMatch } = res.data;
      if (reason) {
        throw new Error(reason);
      }

      let matches = getState().BuyerReducer.buyBoxPropertyMatch || {};
      matches = { ...matches, ...(buyBoxPropertyMatch || {}) };

      payload.buyBoxMatchedPropertyTotal = total;
      payload.buyBoxMatchedProperties = items;
      payload.buyBoxPropertyMatch = matches;
    } catch (error) {
      const msg = get(error, 'response.data.reason');
      showErrorToast(msg || 'Oops, Please try again later');
    }

    dispatch({
      type: types.SET_BUYBOX_MATCHED_PROPERTIES,
      payload,
    });
  };

/**
 * Updates buyBoxPropertyMatch in Buyer State
 *
 * @param {*} matches
 */
export const updateBuyBoxPropertyMatches = (matches) => async (dispatch) => {
  dispatch({
    type: types.SET_BUYBOX_MATCHED_PROPERTIES,
    payload: {
      buyBoxPropertyMatch: matches,
    },
  });
};

/**
 * A Helper function to transform a sellerLead. It updates the bedroom, bathroom and squareFootage
 * it check if sellerLead has post(A published listing on marketplace)
 * gets bedroom, bathroom and squareFootage from post and override existing values if has value
 *
 * @param {*} items A sellerLead object
 * @returns sellerLead[]
 */
const transformData = (items) =>
  items.map((item) => {
    const { units } = item.post || {};
    // override the bedroom, bathroom, squareFootage from Post if exists
    if (units) {
      const features = units && units.length > 0 ? units[0] : null;
      if (features) {
        Object.assign(item, {
          bedroom: features.bedroom || item.bedroom,
          bathroom: features.bathroom || item.bathroom,
          squareFootage: features.squareFootage || item.squareFootage,
        });
      }
    }

    return item;
  });

/**
 * Toggle isMatchingCountShown to hide/show matching counts in bybox list
 *
 * @param {*} matches
 */
export const setMatchingCountShown = (flag) => async (dispatch) => {
  dispatch({
    type: types.SET_MATCHING_COUNT_SHOWN,
    payload: {
      isMatchingCountShown: flag,
    },
  });
};
