import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './index.scss';
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle';
import { setModalState } from '../../../../reducer/Modal/actions';
import { MODAL_IMPORT_DATA } from '../../../../reducer/Modal/types';
import {
  getPropertyDataTags,
  searchPropertyData,
  clearPropertyDataFilter,
} from '../../../../reducer/PropertyData/actions';
import PropertyDataActions from '../PropertyDataActions';
import ResultsTable from '../ResultsTable';
import ImportWizardModal from '../ImportListModal';
import FilterDrawer from '../FilterDrawer';

const PropertyDataTab = () => {
  useDocumentTitle('Property Data');
  const dispatch = useDispatch();
  const { isImportDataModalOpen } = useSelector(
    ({ ModalReducer }) => ModalReducer,
  );
  const setIsImportModalOpen = (state) => {
    dispatch(setModalState(MODAL_IMPORT_DATA, state));
  };
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);

  useEffect(() => {
    dispatch(getPropertyDataTags());
    dispatch(
      searchPropertyData({
        filters: [],
        page: 0,
      }),
    );
  }, []);

  const clearFilterHandler = () => {
    dispatch(clearPropertyDataFilter());
    dispatch(
      searchPropertyData({
        filters: [],
        page: 0,
      }),
    );
  };

  return (
    <>
      <PropertyDataActions
        setIsImportModalOpen={setIsImportModalOpen}
        setIsFilterDrawerOpen={setIsFilterDrawerOpen}
        onClearFilter={clearFilterHandler}
      />
      {/* <SearchForm /> */}
      <ResultsTable />
      <ImportWizardModal
        isImportDataModalOpen={isImportDataModalOpen}
        setIsImportModalOpen={setIsImportModalOpen}
      />
      <FilterDrawer
        isFilterDrawerOpen={isFilterDrawerOpen}
        setIsFilterDrawerOpen={setIsFilterDrawerOpen}
      />
    </>
  );
};

export default PropertyDataTab;
