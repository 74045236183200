import { AiFillCheckCircle } from '@react-icons/all-files/ai/AiFillCheckCircle';
import { AiFillCloseCircle } from '@react-icons/all-files/ai/AiFillCloseCircle';
import { AiFillClockCircle } from '@react-icons/all-files/ai/AiFillClockCircle';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { capitalizeFirstLetter } from '@zorba-shared/core/Services/textService';

const JobStatus = ({ status }) => {
  let statusText = '';
  const antIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;
  switch (status) {
    case 'running':
      statusText = (
        <>
          <Spin indicator={antIcon} /> Running
        </>
      );
      break;
    case 'pending':
    case 'saving':
      statusText = (
        <>
          <AiFillClockCircle
            style={{ color: '#f0ad4e', marginRight: 5, fontSize: 20 }}
          />
          {capitalizeFirstLetter(status)}
        </>
      );
      break;

    case 'success':
      statusText = (
        <>
          <AiFillCheckCircle
            style={{ color: '#49e387', marginRight: 5, fontSize: 20 }}
          />
          Success
        </>
      );
      break;

    case 'failure':
      statusText = (
        <>
          <AiFillCloseCircle
            style={{ color: '#fc6d6d', marginRight: 5, fontSize: 20 }}
          />
          failured
        </>
      );
      break;
    default:
      break;
  }

  return <div className="propertyDataJob-status">{statusText}</div>;
};

const JobStats = ({ stats = {} }) => (
  <>
    <div>Success: {stats.success}</div>
    <div>Failed: {stats.failure}</div>
  </>
);

export const getColumns = () => [
  {
    title: 'Job Title',
    key: 'title',
    dataIndex: 'title',
    render: (_, { title }) => title,
    align: 'left',
  },
  {
    title: 'Job Type',
    key: 'jobType',
    dataIndex: 'kind',
    render: (_, { kind }) => kind,
    align: 'left',
  },
  {
    title: 'Records processed',
    key: 'records',
    dataIndex: 'records',
    render: (_, { stats }) => stats?.total,
    align: 'center',
  },
  {
    title: 'Stats',
    key: 'stats',
    dataIndex: 'stats',
    render: (_, { stats }) => <JobStats stats={stats} />,
    align: 'left',
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    render: (_, { status }) => <JobStatus status={status} />,
    align: 'left',
  },
];
