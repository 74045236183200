import { Modal } from 'antd';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getLatestAskingPriceBySeller } from '@zorba-shared/core/Constants/offer';
import { getPriceText } from '@zorba-shared/core/Services/textService';
import { setModalState } from '../../../../../../reducer/Modal/actions';
import { fetchDealSubmission } from '../../../../../../reducer/DealSubmission/actions';
import { MODAL_REPLACE_DEAL_SUBMISSION } from '../../../../../../reducer/Modal/types';
import { ZorbaButton, ZorbaSelect } from '../../../../../../components/forms';

import {
  showErrorToast,
  showSuccessToast,
} from '../../../../../../services/notificationsService';
import { postRequest } from '../../../../../../services/requestService';

import './index.scss';

export const ReplaceDealSubmissionModal = ({
  selectedDealSubmission,
  otherDealSubmissions,
}) => {
  const { isReplaceDealSubmissionModalOpen } = useSelector(
    ({ ModalReducer }) => ModalReducer,
  );
  const dispatch = useDispatch();
  const [previousDealSubmissionId, setPreviousDealSubmissionId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const handleCloseModal = () => {
    dispatch(setModalState(MODAL_REPLACE_DEAL_SUBMISSION, false));
  };

  const selectDealSubmissionOptions = otherDealSubmissions.map(
    (otherDealSubmission) => ({
      text: `${otherDealSubmission?.submittedBy?.name} | ${getPriceText(
        getLatestAskingPriceBySeller(otherDealSubmission) || 0,
      )} | Requested offers: ${otherDealSubmission.matches.length}`,
      value: otherDealSubmission._id,
    }),
  );

  const handleReplaceDealSubmission = async () => {
    try {
      setIsLoading(true);
      await postRequest('/backoffice/deal-submission/replace-deal-submission', {
        dealSubmissionId: selectedDealSubmission._id,
        previousDealSubmissionId,
      });
      dispatch(fetchDealSubmission(selectedDealSubmission._id));
      setIsLoading(false);
      handleCloseModal();
      showSuccessToast('Deal submission replaced successfully');
    } catch (error) {
      setIsLoading(false);
      showErrorToast(
        error?.response?.data?.reason || 'Replacing deal submission failed',
      );
    }
  };

  return (
    <Modal
      open={isReplaceDealSubmissionModalOpen}
      footer={null}
      onCancel={handleCloseModal}
      title="Replace deal submission"
      className="replace-deal-modal"
      destroyOnClose
    >
      <div className="info-container">
        <div>
          <span>Are you sure that you want replace deal submission for</span>
          <h3>{`${selectedDealSubmission.address.formattedAddress}?`}</h3>
        </div>
        <h4>{`New asking price: ${getPriceText(
          getLatestAskingPriceBySeller(selectedDealSubmission),
        )}`}</h4>
      </div>
      <div className="select-container">
        <ZorbaSelect
          label="Select previous deal submission*"
          options={selectDealSubmissionOptions}
          onChange={(e) => setPreviousDealSubmissionId(e)}
          value={previousDealSubmissionId}
        />
      </div>
      <div className="button-container">
        <ZorbaButton
          fullWidth={false}
          onClick={handleReplaceDealSubmission}
          isLoading={isLoading}
          disabled={!previousDealSubmissionId || isLoading}
        >
          Submit
        </ZorbaButton>
      </div>
    </Modal>
  );
};
