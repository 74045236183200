import { Switch, Tag } from 'antd';
import {
  getFriendlyTime,
  getPriceText,
} from '@zorba-shared/core/Services/textService';
import {
  getLatestAskingPriceBySeller,
  DEAL_SUBMISSION_MATCH_STATUS_TITLES,
} from '@zorba-shared/core/Constants/offer';
import _ from 'lodash';
import { excludeDealSubmissionMatchFromCommunication } from '../../../../reducer/DealSubmission/actions';

export const getTableColumns = (dispatch, isFetchingDealSubmission) => [
  {
    title: 'Buyer',
    key: 'buyer',
    render: (match) => <div>{match?.organization?.name}</div>,
  },
  {
    title: 'Status',
    key: 'status',
    render: ({ status }) => (
      <Tag>{DEAL_SUBMISSION_MATCH_STATUS_TITLES[status]}</Tag>
    ),
  },
  {
    title: 'Rejection reason',
    key: 'rejectionReason',
    render: ({ events }) => {
      const buyerRejectionEvent = _.last(
        events.filter((event) => event.kind === 'rejected_by_buyer'),
      );

      return buyerRejectionEvent?.rejectionReason;
    },
  },
  {
    title: 'Manual followups mode',
    key: 'excludedFromCommunication',
    render: ({ excludedFromCommunication, dealSubmission, _id }) => {
      const handleExcludeFromCommunication = (checked) => {
        dispatch(
          excludeDealSubmissionMatchFromCommunication({
            dealSubmissionId: dealSubmission._id,
            dealSubmissionMatchId: _id,
            excludedFromCommunication: checked,
          }),
        );
      };
      return (
        <Switch
          loading={isFetchingDealSubmission}
          checked={excludedFromCommunication}
          onChange={(checked) => handleExcludeFromCommunication(checked)}
        />
      );
    },
  },
  {
    title: 'Visible to buyer',
    key: 'visible',
    render: ({ visible }) => (
      <Tag className={`zorba-tag ${visible ? 'success' : 'error'}`}>
        {visible ? 'Yes' : 'No'}
      </Tag>
    ),
  },
  {
    title: 'Created',
    key: 'createdAt',
    render: ({ createdAt }) => getFriendlyTime(createdAt),
  },
  {
    title: `Current asking price without our fee`,
    key: 'ourOffer',
    render: (match) => {
      const lastAskingPriceBySeller = getLatestAskingPriceBySeller(
        match.dealSubmission,
      );

      return getPriceText(lastAskingPriceBySeller);
    },
  },
  {
    title: `Buyer's offer without our fee`,
    key: 'offer',
    render: (match) => {
      const offers =
        match.events?.filter((event) => event.kind === 'offer') || [];

      return offers.length > 0
        ? getPriceText(offers[offers.length - 1].amount)
        : null;
    },
  },
  {
    title: `Our fee`,
    key: 'offer',
    render: ({ feeAmount }) => getPriceText(feeAmount),
  },
];
