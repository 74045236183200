export const buildQueryString = (url, queryParams) => {
  if (!url || !queryParams) return url || '';

  let baseUrl = url;
  const queryString = Object.keys(queryParams)
    .filter(
      (x) =>
        (!!queryParams[x] && typeof queryParams[x] === 'number') ||
        !typeof queryParams[x] === 'array' ||
        queryParams[x]?.length > 0,
    )
    .map((key) => `${key}=${queryParams[key]}`)
    .join('&');
  if (queryString.length > 0) {
    baseUrl += `?${queryString}`;
  }
  console.log(baseUrl);
  return baseUrl;
};

export const queryStringToObject = (queryString) => {
  const obj = {};

  // Remove the leading "?" if present
  queryString = queryString.startsWith('?')
    ? queryString.slice(1)
    : queryString;

  const pairs = queryString.split('&');
  pairs.forEach((pair) => {
    const [key, value] = pair.split('=');
    const decodedKey = decodeURIComponent(key);
    const decodedValue = decodeURIComponent(value);
    if (decodedKey && decodedValue) {
      obj[decodedKey] = decodedValue;
    }
  });

  return obj;
};
