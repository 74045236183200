import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import {
  updateBuyerDetails,
  getBuyBoxMatchedProeprties,
  updateBuyBoxPropertyMatches,
} from '../../../../reducer/Buyer/actions';
import AddBuyboxForm from './Components/AddBuyboxForm';
import BuyboxList from './Components/BuyboxList';
import {
  BUYBOX_VARIANT_ONBOARDING,
  BUYBOX_VARIANT_PAGE,
  DEFAULT_BUYBOX_PARAMS,
} from './constants';
import { findAddedAndRemovedAreas } from './service';

import './index.scss';

export default (props) => {
  const { showSidebar = true, variant = BUYBOX_VARIANT_PAGE } = props;
  const {
    selectedBuyer,
    isLoading,
    isFetchingBuyboxMatchedProperties,
    buyBoxPropertyMatch,
    isMatchingCountShown,
  } = useSelector(({ BuyerReducer }) => BuyerReducer);

  const [selectedBuyBox, setSelectedBuyBox] = useState();
  const [buyBoxList, setBuyBoxList] = useState([]);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [changedParams, setChangedParams] = useState({});
  const dispatch = useDispatch();

  const organization = get(selectedBuyer, 'defaultOrganization');
  const userId = get(selectedBuyer, '_id');

  useEffect(() => {
    if (organization?.buyBoxList.length === 0) {
      onCreateCriteria();
    }
  }, []);

  /**
   * Whenever we update the buy box in the db make sure to refresh the updated array here
   */
  useEffect(() => {
    const list = organization?.buyBoxList || [];
    setBuyBoxList(list);
    if (isUpdateMode) {
      const buybox = list.find((item) => item._id === selectedBuyBox._id);
      if (buybox) setSelectedBuyBox(buybox);
    } else {
      setSelectedBuyBox(list[0]);
    }
  }, [organization]);

  const getPropetyMatchByBuyBox = (buBox) => {
    dispatch(getBuyBoxMatchedProeprties({ userId, buyBoxList: [buBox] }));
  };

  /**
   * An Helper function to call API and save buyBox list in database
   * @see reducer/Buyer/actions/updateBuyerDetails
   * @param {*} requestParams  {buyBoxList: [],requestedUserId: string,}
   */
  const saveOrganization = (requestParams) => {
    dispatch(updateBuyerDetails(requestParams));
  };

  const onCreateCriteria = () => {
    const newBuyBox = { ...DEFAULT_BUYBOX_PARAMS };
    const updatedBuyBoxList = [newBuyBox, ...buyBoxList];
    setBuyBoxList(updatedBuyBoxList);
    saveOrganization({
      buyBoxList: updatedBuyBoxList,
      requestedUserId: userId,
    });
    setIsUpdateMode(false);
  };

  const onUpdateCriteria = (updatedBuyBox) => {
    const { addedAreas, removedAreas } = findAddedAndRemovedAreas(
      selectedBuyBox,
      updatedBuyBox,
    );
    const updatedBuyBoxList = buyBoxList.map((buyBox) =>
      buyBox._id === updatedBuyBox._id ? updatedBuyBox : buyBox,
    );
    setBuyBoxList(updatedBuyBoxList);

    if (variant === BUYBOX_VARIANT_ONBOARDING) {
      props.onChange && props.onChange('buyBoxList', updatedBuyBoxList);
    } else {
      saveOrganization({
        requestedUserId: userId,
        buyBoxList: updatedBuyBoxList,
        addedAreas,
        removedAreas,
        changedParams,
      });
    }
    setIsUpdateMode(true);
  };

  const onDeleteCriteria = (criteria) => {
    const updatedBuyBoxList = buyBoxList.filter(
      (item) => criteria._id !== item._id,
    );
    setBuyBoxList(updatedBuyBoxList);
    saveOrganization({
      buyBoxList: updatedBuyBoxList,
      requestedUserId: userId,
    });
    setIsUpdateMode(false);

    const newPropertyMatches = { ...(buyBoxPropertyMatch || {}) };
    delete newPropertyMatches[criteria._id];
    dispatch(updateBuyBoxPropertyMatches(newPropertyMatches));
  };

  const onPreviewBuyBoxMatchProperties = (updatedBuyBox) => {
    getPropetyMatchByBuyBox(updatedBuyBox);
  };

  return (
    <Spin spinning={isLoading}>
      <div className="buybox-container">
        <BuyboxList
          buyBoxList={buyBoxList}
          onCreateCriteria={onCreateCriteria}
          selectedBuyBox={selectedBuyBox}
          setSelectedBuyBox={setSelectedBuyBox}
          isFetchingBuyboxMatchedProperties={isFetchingBuyboxMatchedProperties}
          buyBoxPropertyMatch={buyBoxPropertyMatch}
          isMatchingCountShown={isMatchingCountShown}
        />

        <div className="right">
          <AddBuyboxForm
            selectedBuyBox={selectedBuyBox}
            setSelectedBuyBox={setSelectedBuyBox}
            onUpdateCriteria={onUpdateCriteria}
            onDeleteCriteria={onDeleteCriteria}
            onPreviewBuyBoxMatchProperties={onPreviewBuyBoxMatchProperties}
            variant={variant}
            organizationMembers={organization.members}
            changedParams={changedParams}
            setChangedParams={setChangedParams}
          />
        </div>
      </div>
    </Spin>
  );
};
