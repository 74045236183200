import './index.scss';
import { useUnmountElement } from './useUnmountElement';

export default ({ children, isRender }) => {
  const isShowElement = useUnmountElement(isRender, 450);
  const mountedStyle = { animation: 'inAnimation 450ms ease-in' };
  const unmountedStyle = {
    animation: 'outAnimation 470ms ease-out',
    animationFillMode: 'forwards',
  };
  return (
    isShowElement && (
      <div
        style={isRender ? mountedStyle : unmountedStyle}
        className="with-animation-container"
      >
        {children}
      </div>
    )
  );
};
