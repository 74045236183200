import { useState } from 'react';
import { Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';

import { setModalState } from '../../../../reducer/Modal/actions';
import { MODAL_CREATE_ENTITY } from '../../../../reducer/Modal/types';
import { ZorbaButton, ZorbaInput } from '../../../../components/forms';

import { createEntity } from '../../../../reducer/BuyersReport/actions';

import './index.scss';

export const CreateEntityModal = ({ postgresId }) => {
  const { isCreateEntityModalOpen } = useSelector(
    ({ ModalReducer }) => ModalReducer,
  );

  const dispatch = useDispatch();
  const [isCreatingEntity, setIsCreatingEntity] = useState();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      entityName: '',
    },
  });
  const handleCloseModal = () => {
    dispatch(setModalState(MODAL_CREATE_ENTITY, false));
  };

  const handleCreateEntity = async (formValues) => {
    try {
      setIsCreatingEntity(true);
      dispatch(createEntity({ entityName: formValues.entityName, postgresId }));
      setIsCreatingEntity(false);
      handleCloseModal();
    } catch (error) {
      setIsCreatingEntity(false);
    }
  };

  return (
    <Modal
      open={isCreateEntityModalOpen}
      footer={null}
      onCancel={handleCloseModal}
      title="Create an entity"
      destroyOnClose
      className="create-entity-modal"
    >
      <form onSubmit={handleSubmit(handleCreateEntity)}>
        <div className="form-item">
          <Controller
            name="entityName"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <ZorbaInput
                label="Entity name*"
                onChange={field.onChange}
                value={field.value}
                help={errors.entityName ? 'Required' : ''}
                placeholder="ENTITY NAME"
              />
            )}
          />
        </div>
        <div className="button-container">
          <ZorbaButton
            className="counter-accept-button"
            htmlType="submit"
            fullWidth={false}
            loading={isCreatingEntity}
            disabled={isCreatingEntity}
          >
            Create entity
          </ZorbaButton>
        </div>
      </form>
    </Modal>
  );
};
