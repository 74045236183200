import * as types from './types';
import { postRequest } from '../../services/requestService';
import { showErrorToast } from '../../services/notificationsService';

export const getReportData =
  (searchCriteria = {}) =>
  async (dispatch) => {
    dispatch({
      type: types.SET_LOADING,
      payload: { isLoading: true },
    });
    try {
      const res = await postRequest(
        '/backoffice/buyers-report/get-report',
        searchCriteria,
      );

      const { data } = res.data;
      dispatch({
        type: types.SET_BUYERS_REPORT_DATA,
        payload: {
          data,
          isLoading: false,
        },
      });
    } catch (error) {
      showErrorToast('Fetching report failed!');
    }
    dispatch({
      type: types.SET_LOADING,
      payload: { isLoading: false },
    });
  };

export const getReportDataByOrganizationName =
  (searchCriteria = {}) =>
  async (dispatch) => {
    dispatch({
      type: types.SET_LOADING,
      payload: { isLoading: true },
    });
    try {
      const res = await postRequest(
        '/backoffice/buyers-report/get-by-postgres-id',
        searchCriteria,
      );

      const response = res.data;
      dispatch({
        type: types.SET_SELECTED_ORGANIZATION_DATA,
        payload: {
          selectedOrganizationData: {
            ...response,
          },
          isLoading: false,
        },
      });
    } catch (error) {
      showErrorToast('Fetching report failed!');
    }
    dispatch({
      type: types.SET_LOADING,
      payload: { isLoading: false },
    });
  };

export const createEntity = (payload) => async (dispatch) => {
  dispatch({
    type: types.SET_LOADING,
    payload: { isLoading: true },
  });
  try {
    const res = await postRequest(
      '/backoffice/buyers-report/create-entity',
      payload,
    );

    const { entities } = res.data;
    dispatch({
      type: types.SET_SELECTED_ORGANIZATION_DATA,
      payload: {
        selectedOrganizationData: {
          entities,
        },
        isLoading: false,
      },
    });
  } catch (error) {
    showErrorToast('Fetching report failed!');
  }
  dispatch({
    type: types.SET_LOADING,
    payload: { isLoading: false },
  });
};

export const deleteEntity = (payload) => async (dispatch) => {
  dispatch({
    type: types.SET_LOADING,
    payload: { isLoading: true },
  });
  try {
    const res = await postRequest(
      `/backoffice/buyers-report/delete-entity`,
      payload,
    );

    const { entities } = res.data;
    dispatch({
      type: types.SET_SELECTED_ORGANIZATION_DATA,
      payload: {
        selectedOrganizationData: {
          entities,
        },
        isLoading: false,
      },
    });
  } catch (error) {
    showErrorToast('Deleting entity failed!');
  }
  dispatch({
    type: types.SET_LOADING,
    payload: { isLoading: false },
  });
};
