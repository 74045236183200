import { getFriendlyTime } from '@zorba-shared/core/Services/textService';
import { Link } from 'react-router-dom';
import { buildBuyerPageUrl } from '../../../../services/urlBuilderService';

export const generateStats = ({ selectedDealSubmission, matchingBuyers }) => [
  {
    label: 'Created',
    value: getFriendlyTime(selectedDealSubmission.createdAt),
  },
  {
    label: 'Matching buyers',
    value: matchingBuyers.length,
  },
  {
    label: 'Offers',
    value: selectedDealSubmission.matches.length,
  },
  {
    label: 'Submitted by',
    value: (
      <Link
        target="_blank"
        to={buildBuyerPageUrl({
          userDetails: selectedDealSubmission?.submittedBy,
        })}
      >
        {`${selectedDealSubmission?.submittedBy?.name} (${selectedDealSubmission?.submittedBy?.email})`}
      </Link>
    ),
  },
];
