import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '../../../../components/Spinner';
import {
  providerLogin,
  setAuthLoadingState,
} from '../../../../reducer/Auth/actions';
import { AUTH_STRATEGY_GOOGLE } from '../../../../services/authService';
import { getQueryParam } from '../../../../services/clientService';
import { buildAuthPageUrl } from '../../../../services/urlBuilderService';
import { GOOGLE_SCOPES } from './constants';

import './index.scss';

export default () => {
  const { isAuthLoading } = useSelector(({ AuthReducer }) => AuthReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    const googleCode = getQueryParam('code');
    if (googleCode) {
      dispatch(setAuthLoadingState(true));
      dispatch(
        providerLogin({
          googleCode,
          strategy: AUTH_STRATEGY_GOOGLE,
        }),
      );
    }
  }, []);

  const onGoogleSignin = () => {
    let targetUrl =
      'https://accounts.google.com/o/oauth2/v2/auth?client_id=863393740130-10sntl8lf8pskq2l9bjbuo0m7vbhc2o5.apps.googleusercontent.com';
    targetUrl += `&response_type=code`;
    targetUrl += `&redirect_uri=${buildAuthPageUrl({ isAbsolute: true })}`;
    targetUrl += `&scope=${GOOGLE_SCOPES}`;

    window.location.href = targetUrl;
  };
  return (
    <div className={`social-login-buttons ${isAuthLoading ? 'loading' : ''}`}>
      {isAuthLoading && (
        <div className="bg-spinner">
          <Spinner fontSize={45} />
        </div>
      )}
      <button className="login-btn google" onClick={() => onGoogleSignin()}>
        <img src="/img/google-icon.svg" alt="google-icon" /> Login with Google
      </button>
    </div>
  );
};
