/* eslint-disable default-param-last */
import * as types from './types';

const initialState = {
  isLoading: false,
  dealSubmissions: [],
  hasMoreResults: false,
  currentPage: 0,
  total: 0,
  pageSize: 50,
  selectedDealSubmission: null,
  selectedMatch: null,
  isFetchingDealSubmission: false,
  lastFetchedCriteria: {},
  isRenewingOffer: false,
  otherDealSubmissions: [],
  redirectUrl: '',
};
const DealSubmissionReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_DEAL_SUBMISSIONS:
    case types.SET_LOADING:
    case types.SET_CURRENT_PAGE:
    case types.SET_PAGE_SIZE:
    case types.SET_SELECTED_DEAL_SUBMISSION:
    case types.SET_IS_FETCHING_DEAL_SUBMISSION:
    case types.SET_SELECTED_MATCH:
    case types.SET_IS_RENEWING_OFFER:
    case types.SET_REDIRECT_URL:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default DealSubmissionReducer;
