import React from 'react';

import './index.scss';

const InstructionsComponent = () => (
  <div className="instructions">
    <div className="instructions-section">
      <span className="sub-title">Instructions</span>
      <span className="text">
        Your CSV file must be formatted exactly as indicated in the Skip Trace
        Template. If your results return less than an 80% hit rate, you may have
        formatted your data incorrectly. Check your file to confirm it is
        structured as required by the SkipTracing.ai system, then try again. You
        must upload a minimum of 25 records.
      </span>
    </div>
    <div className="instructions-section">
      <span className="sub-title">Note</span>
      <span className="text">
        You can opt to include only Property Address fields. However, it is
        recommended that you include everything in the template below for best
        results.
      </span>
    </div>
  </div>
);

export default InstructionsComponent;
