import { useEffect, useState } from 'react';
import { Switch } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { FEMA_FLOOD_ZONE_OPTIONS } from '@zorba-shared/core/Constants/fema';
import { PARKING_TYPES_OPTIONS } from '@zorba-shared/core/Constants/buybox';
import { PROPERTY_TYPE_OPTIONS } from '@zorba-shared/core/Constants/deal';
import {
  ZorbaInput,
  ZorbaButton,
  ZorbaSelect,
} from '../../../../components/forms';
import TranslationService from '../../../../services/translationService';
import { BUTTON_VARIANT_DARK } from '../../../../components/forms/ZorbaButton/constants';
import { loadTranslationCategories } from '../../../../reducer/Translation/actions';
import { generateMaxOffer } from '../../../../reducer/Buyer/actions';

import './index.scss';

const GenerateOffer = ({
  params,
  buyers,
  zillowData,
  address,
  onSubmitButtonText,
  handleOnSubmit,
  loading,
  isUnderwritingDisabled = false,
}) => {
  const [overrideZillowData, setOverrideZillowData] = useState(false);
  const dispatch = useDispatch();
  const { translationCategories } = useSelector(
    ({ TranslationReducer }) => TranslationReducer,
  );
  const { isGeneratingOffer } = useSelector(({ BuyerReducer }) => BuyerReducer);
  const translationService = new TranslationService(translationCategories);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    defaultValues: {},
  });
  // Load locale by category['errors']
  useEffect(() => {
    dispatch(loadTranslationCategories(['errors']));
  }, ['']);

  // Watch props and set form fields
  useEffect(() => {
    if (params && !loading) {
      reset(params);
    }
  }, [params]);

  const onSubmit = async (formValues) => {
    if (handleOnSubmit) {
      handleOnSubmit(formValues, overrideZillowData);
    } else {
      dispatch(
        generateMaxOffer({
          ...formValues,
          buyers,
          livingAreaValue: zillowData?.livingAreaValue,
          overrideZillowData,
          address,
        }),
      );
    }
  };

  return (
    <div className="generate-offer">
      <div className="switches-container">
        <div className="switch-container">
          <span>Override Data</span>
          <Switch
            className="switch"
            checked={overrideZillowData}
            onChange={(value) => setOverrideZillowData(value)}
          />
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {overrideZillowData && (
          <>
            <h3 className="section-title">Data</h3>
            <div className="generate-offer-form">
              <Controller
                name="propertyType"
                control={control}
                render={({ field }) => (
                  <ZorbaSelect
                    label="Property Type"
                    field={field}
                    options={PROPERTY_TYPE_OPTIONS}
                  />
                )}
              />
              <Controller
                name="bedroom"
                control={control}
                render={({ field }) => (
                  <ZorbaInput
                    label="Bedrooms"
                    type="number"
                    placeholder="3"
                    field={field}
                    symbol={null}
                    min={0}
                  />
                )}
              />
              <Controller
                name="bathroom"
                control={control}
                render={({ field }) => (
                  <ZorbaInput
                    label="Bathrooms"
                    type="number"
                    placeholder="3"
                    field={field}
                    symbol={null}
                    min={0}
                  />
                )}
              />
              <Controller
                name="yearBuilt"
                control={control}
                render={({ field }) => (
                  <ZorbaInput
                    label="Year Built"
                    type="number"
                    placeholder="1970"
                    field={field}
                    symbol={null}
                    min={0}
                    disableFormatter
                  />
                )}
              />
              <Controller
                name="squareFootage"
                control={control}
                render={({ field }) => (
                  <ZorbaInput
                    label="Living Area"
                    type="number"
                    field={field}
                    symbol={null}
                    min={0}
                    addonAfter="sqft"
                  />
                )}
              />
              <Controller
                name="lotSize"
                control={control}
                render={({ field }) => (
                  <ZorbaInput
                    label="Lot size"
                    type="number"
                    field={field}
                    symbol={null}
                    min={0}
                    addonAfter="sqft"
                  />
                )}
              />
              <Controller
                name="schoolRating"
                control={control}
                render={({ field }) => (
                  <ZorbaInput
                    label="School Rating"
                    type="number"
                    field={field}
                    symbol={null}
                    min={0}
                  />
                )}
              />
              <Controller
                name="hasPool"
                control={control}
                render={({ field }) => (
                  <ZorbaSelect
                    label="Has Pool"
                    field={field}
                    options={[
                      { text: 'Yes', value: true },
                      { text: 'No', value: false },
                    ]}
                  />
                )}
              />
              <Controller
                name="parkingSpaces"
                control={control}
                render={({ field }) => (
                  <ZorbaInput
                    label="Parking Spaces"
                    type="number"
                    field={field}
                    symbol={null}
                    min={0}
                  />
                )}
              />
              <Controller
                name="parkingTypes"
                control={control}
                render={({ field }) => (
                  <ZorbaSelect
                    mode="multiple"
                    label="Parking Types"
                    field={field}
                    options={PARKING_TYPES_OPTIONS}
                  />
                )}
              />
              <Controller
                name="floodZone"
                control={control}
                render={({ field }) => (
                  <ZorbaSelect
                    label="Flood zone"
                    field={field}
                    options={FEMA_FLOOD_ZONE_OPTIONS}
                  />
                )}
              />
              <Controller
                name="wastewaterType"
                control={control}
                render={({ field }) => (
                  <ZorbaSelect
                    label="Wastewater type"
                    options={[
                      {
                        text: 'Septic',
                        value: 'septic',
                      },
                      {
                        text: 'Sewer',
                        value: 'sewer',
                      },
                      { text: `I'm not sure`, value: 'not_sure' },
                    ]}
                    field={field}
                  />
                )}
              />
              <Controller
                name="foundationIssues"
                control={control}
                render={({ field }) => (
                  <ZorbaSelect
                    label="Foundation issues"
                    options={[
                      {
                        text: 'Yes',
                        value: true,
                      },
                      {
                        text: 'No',
                        value: false,
                      },
                    ]}
                    field={field}
                  />
                )}
              />
            </div>
          </>
        )}
        <h3 className="section-title">
          {handleOnSubmit ? 'Underwriting:' : 'Offer Params:'}
        </h3>
        <div className="generate-offer-form">
          <Controller
            name="maxAllowableOffer"
            control={control}
            rules={{
              validate: {
                required: (newVal) => {
                  if (!newVal && !getValues('maxAllowableOffer'))
                    return translationService.translateKey(
                      'errors',
                      'required',
                    );

                  return true;
                },
              },
            }}
            render={({ field }) => (
              <ZorbaInput
                label="Estimated Asking Price"
                type="number"
                placeholder="1000"
                field={field}
                help={errors?.maxAllowableOffer?.message}
                addonBefore="$"
                symbol={null}
              />
            )}
          />
          <Controller
            name="grossRent"
            control={control}
            render={({ field }) => (
              <ZorbaInput
                label="Estimated Rent"
                type="number"
                placeholder="1000"
                field={field}
                help={errors?.grossRent?.message}
                addonBefore="$"
                symbol={null}
                disabled={isUnderwritingDisabled}
              />
            )}
          />
          <Controller
            name="rehabCost"
            control={control}
            render={({ field }) => (
              <ZorbaInput
                label="Estimated rehab"
                type="number"
                placeholder="1000"
                field={field}
                help={errors?.rehabCost?.message}
                addonBefore="$"
                symbol={null}
                disabled={isUnderwritingDisabled}
              />
            )}
          />

          <Controller
            name="monthlyHoaFee"
            control={control}
            render={({ field }) => (
              <ZorbaInput
                label="Monthly Hoa Fee"
                type="number"
                placeholder="820"
                field={field}
                help={errors?.monthlyHoaFee?.message}
                addonBefore="$"
                symbol={null}
                disabled={isUnderwritingDisabled}
              />
            )}
          />
        </div>
        <ZorbaButton
          fullWidth={false}
          htmlType="submit"
          variant={BUTTON_VARIANT_DARK}
          className="generateOfferButton"
          disabled={isGeneratingOffer}
          loading={isGeneratingOffer}
        >
          {onSubmitButtonText}
        </ZorbaButton>
      </form>
    </div>
  );
};

export default GenerateOffer;
