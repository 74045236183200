import {
  USER_KIND_AGENT,
  USER_KIND_BUYER,
  USER_KIND_INVESTOR,
} from '@zorba-shared/core/Constants/auth';

export const PERMISSION_TITLES = {
  seller_agent: 'Sellers Team',
  buyer_agent: 'Buyers Team',
  superuser: 'Employee',
  admin: 'Admin',
};

export const USER_KIND_TITLES = {
  [USER_KIND_AGENT]: 'Agent',
  [USER_KIND_BUYER]: 'Buyer',
  [USER_KIND_INVESTOR]: 'Investor',
};

export const USER_KIND_OPTIONS = [
  USER_KIND_AGENT,
  USER_KIND_BUYER,
  USER_KIND_INVESTOR,
].map((value) => ({ value, text: USER_KIND_TITLES[value] }));
