import '../index.scss';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { ZorbaInput, ZorbaButton } from '../../../../../components/forms';
import { BUTTON_VARIANT_DARK } from '../../../../../components/forms/ZorbaButton/constants';
import TranslationService from '../../../../../services/translationService';
import { loadTranslationCategories } from '../../../../../reducer/Translation/actions';

export const LimitRecordModal = ({ onChangeLimit, total, didSelectAll }) => {
  const [params, setParams] = useState({});
  const dispatch = useDispatch();
  const { translationCategories } = useSelector(
    ({ TranslationReducer }) => TranslationReducer,
  );
  const translationService = new TranslationService(translationCategories);
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      endIndex: total,
      startIndex: 0,
    },
  });

  // Load locale by category['errors']
  useEffect(() => {
    dispatch(loadTranslationCategories(['errors']));
  }, ['']);

  const onChange = (key, value) => {
    const updatedParams = { ...params, [key]: value };
    setParams(updatedParams);
  };

  const onSubmit = async (formValues) => {
    onChangeLimit(formValues);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="run-job-form">
      <div className="limit-record-modal-container">
        <span>Select the items you’d like to run in this job </span>

        <Controller
          name="jobTitle"
          control={control}
          rules={{
            validate: {
              required: (newVal) => {
                if (!newVal && !getValues('jobTitle'))
                  return translationService.translateKey('errors', 'required');

                return true;
              },
            },
          }}
          render={({ field }) => (
            <ZorbaInput
              label="Job Title"
              onChange={(value) => onChange('jobTitle', value)}
              symbol={null}
              field={field}
              help={errors?.jobTitle?.message}
            />
          )}
        />
        {didSelectAll && (
          <div className="limit-record-fields">
            <Controller
              name="startIndex"
              control={control}
              rules={{
                validate: {
                  required: (newVal) => {
                    if (
                      (newVal && getValues('startIndex') >= 0) ||
                      newVal === 0
                    )
                      return true;
                    return translationService.translateKey(
                      'errors',
                      'required',
                    );
                  },
                },
              }}
              render={({ field }) => (
                <ZorbaInput
                  label="Start"
                  onChange={(value) => onChange('startIndex', value)}
                  type="number"
                  symbol={null}
                  field={field}
                  help={errors?.startIndex?.message}
                />
              )}
            />
            <Controller
              name="endIndex"
              control={control}
              rules={{
                validate: {
                  required: (newVal) => {
                    if (!newVal || !getValues('endIndex'))
                      return translationService.translateKey(
                        'errors',
                        'required',
                      );

                    return true;
                  },
                },
              }}
              render={({ field }) => (
                <ZorbaInput
                  label="End"
                  onChange={(value) => onChange('endIndex', value)}
                  type="number"
                  symbol={null}
                  field={field}
                  help={errors?.endIndex?.message}
                />
              )}
            />
          </div>
        )}

        <div className="button-container">
          <ZorbaButton
            fullWidth={false}
            htmlType="submit"
            variant={BUTTON_VARIANT_DARK}
          >
            Start job
          </ZorbaButton>
        </div>
      </div>
    </form>
  );
};
