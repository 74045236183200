import { useState } from 'react';
import { Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import {
  DAYS_TO_CLOSE_OPTIONS,
  INSPECTION_DAYS_OPTIONS,
} from '@zorba-shared/core/Constants/offer';
import { getPriceText } from '@zorba-shared/core/Services/textService';
import {
  ZorbaSelect,
  ZorbaButton,
  ZorbaInput,
} from '../../../../../../components/forms';
import { setModalState } from '../../../../../../reducer/Modal/actions';
import { MODAL_COUNTER_OFFER } from '../../../../../../reducer/Modal/types';
import { diffObject } from '../../../../../../services/validationService';
import { postRequest } from '../../../../../../services/requestService';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../../../services/notificationsService';
import { fetchDealSubmission } from '../../../../../../reducer/DealSubmission/actions';

import './index.scss';

export const CounterOfferModal = ({ selectedDealSubmission }) => {
  const { isCounterOfferModalOpen } = useSelector(
    ({ ModalReducer }) => ModalReducer,
  );
  const dispatch = useDispatch();
  const lastOffer = _.last(
    selectedDealSubmission.events.filter((event) => event.kind === 'offer'),
  );
  const selectedDealSubmissionMatch = selectedDealSubmission.matches.find(
    (match) => match._id === selectedDealSubmission.associatedMatch,
  );
  const initialState = {
    inspectionMode: lastOffer?.inspectionMode,
    inspectionDays: lastOffer?.inspectionDays,
    updatedAskingPrice: lastOffer?.amount,
    daysToClose: lastOffer?.daysToClose,
    emd: lastOffer?.emd,
    feeAmount: selectedDealSubmissionMatch?.feeAmount,
  };
  const [params, setParams] = useState(initialState);
  const [changedParams, setChangedParams] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const handleCloseModal = () => {
    dispatch(setModalState(MODAL_COUNTER_OFFER, false));
  };

  const onChange = (key, value) => {
    const updatedParams = { ...params, [key]: value };
    setParams(updatedParams);

    const changedParamsToUpdate = diffObject(updatedParams, initialState);
    setChangedParams(changedParamsToUpdate);
  };

  const isSubmitDisabled =
    !Object.keys(changedParams).length ||
    (params.inspectionMode === 'contingency' && !params.inspectionDays) ||
    !params.updatedAskingPrice ||
    !params.emd;

  const handleCounterOffer = async () => {
    const payload = {
      ...params,
      dealSubmissionId: selectedDealSubmission._id,
      dealSubmissionMatchId: selectedDealSubmission.associatedMatch,
      feeAmount: params.feeAmount,
      changedParams,
      additionalRequests: '',
      inspectionDays:
        params.inspectionMode === 'pre_inspection'
          ? null
          : params.inspectionDays,
    };

    try {
      setIsLoading(true);
      await postRequest('/backoffice/deal-submission/counter-offer', payload);
      setIsLoading(false);
      handleCloseModal();
      showSuccessToast('Counter an offer sent successfully');
      dispatch(fetchDealSubmission(selectedDealSubmission._id));
    } catch (error) {
      setIsLoading(false);
      showErrorToast('Sending counter an offer failed');
    }
  };

  return (
    <Modal
      open={isCounterOfferModalOpen}
      footer={null}
      onCancel={handleCloseModal}
      title="Counter an offer on behalf of the seller"
      className="counter-offer-modal"
      destroyOnClose
    >
      <div className="form-item-container">
        <ZorbaInput
          placeholder="Enter counter offer"
          label="Counter offer*"
          onChange={(value) => onChange('updatedAskingPrice', value)}
          type="number"
          value={
            !Number.isNaN(params.updatedAskingPrice)
              ? params.updatedAskingPrice
              : undefined
          }
          symbol=""
          addonBefore="$"
          fullWidth
        />
      </div>
      <div className="form-item-container">
        <ZorbaInput
          type="number"
          addonBefore="$"
          symbol=""
          label="Our fee*"
          value={params.feeAmount}
          onChange={(e) => onChange('feeAmount', e)}
          className="fee-amount-input"
        />
      </div>
      <div className="total-asking-price">
        <span>
          Asking price that the buyer would see including our fee:{' '}
          {getPriceText(params.updatedAskingPrice + params.feeAmount)}
        </span>
      </div>
      <div>
        <div className="form-item-container">
          <ZorbaSelect
            label="Inspection Mode*"
            options={[
              { text: 'Pre inspection', value: 'pre_inspection' },
              { text: 'Contingency', value: 'contingency' },
            ]}
            value={params.inspectionMode}
            onChange={(e) => onChange('inspectionMode', e)}
          />
        </div>
        {params?.inspectionMode !== 'pre_inspection' && (
          <div className="form-item-container">
            <ZorbaSelect
              label="Inspection period*"
              onChange={(e) => onChange('inspectionDays', e)}
              options={INSPECTION_DAYS_OPTIONS}
              value={params.inspectionDays}
            />
          </div>
        )}
        <div className="form-item-container">
          <ZorbaSelect
            label="Days to close*"
            onChange={(e) => onChange('daysToClose', e)}
            options={DAYS_TO_CLOSE_OPTIONS}
            value={params.daysToClose}
          />
        </div>
        <div className="form-item-container">
          <ZorbaInput
            type="number"
            addonBefore="$"
            fullWidth
            symbol=""
            label="EMD*"
            onChange={(value) => onChange('emd', value)}
            value={!Number.isNaN(params.emd) ? params.emd : undefined}
          />
        </div>
      </div>
      <div className="button-container">
        <ZorbaButton
          disabled={isSubmitDisabled || isLoading || !params.feeAmount}
          onClick={handleCounterOffer}
          fullWidth={false}
          loading={isLoading}
        >
          Counter offer
        </ZorbaButton>
      </div>
    </Modal>
  );
};
