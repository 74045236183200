import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs } from 'antd';

import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { fetchDealSubmission } from '../../reducer/DealSubmission/actions';

import PageSkeleton from '../../components/PageSkeleton';
import { toQueryString, useSearchParams } from '../../utils';
import { getTabs } from './constants';
import DealSubmissionHeader from './Components/DealSubmissionHeader';
import DealSubmissionMatchDrawer from './Components/DealSubmissionMatchDrawer';
import { ActivityDrawer } from './Components/ActivityDrawer';

import './index.scss';
import { setDrawerState } from '../../reducer/Drawer/actions';
import { ACTIVITY_DEAL_SUBMISSION_DRAWER } from '../../reducer/Drawer/types';

const DealSubmissionPage = ({ match }) => {
  const dispatch = useDispatch();
  const { dealSubmissionId } = match.params;
  const {
    selectedDealSubmission,
    isFetchingDealSubmission,
    lastFetchedCriteria,
    otherDealSubmissions,
  } = useSelector(({ DealSubmissionReducer }) => DealSubmissionReducer);
  useDocumentTitle(
    `Deal Submission -  ${
      selectedDealSubmission
        ? selectedDealSubmission?.address?.formattedAddress
        : ''
    }`,
  );
  const [activeTab, setActiveTab] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (dealSubmissionId) {
      dispatch(fetchDealSubmission(dealSubmissionId));
    }
    const tab = searchParams.get('tab');
    if (tab) {
      setActiveTab(tab);
    }
    const drawer = searchParams.get('drawer');
    if (drawer) {
      dispatch(setDrawerState(ACTIVITY_DEAL_SUBMISSION_DRAWER, true));
    }
  }, []);
  const tabChangeHandler = (tab) => {
    const queryParams = toQueryString({ tab }) || {};
    setSearchParams(queryParams);
    setActiveTab(tab);
  };
  const tabs = getTabs(selectedDealSubmission, isFetchingDealSubmission);

  if (!selectedDealSubmission) return <PageSkeleton />;

  return (
    <div className="deal-submission-page">
      {!isFetchingDealSubmission ? (
        <DealSubmissionHeader
          isFetchingDealSubmission={isFetchingDealSubmission}
          selectedDealSubmission={selectedDealSubmission}
          lastFetchedCriteria={lastFetchedCriteria}
        />
      ) : null}
      <div className="tabs-container">
        <Tabs
          type="card"
          items={tabs}
          activeKey={activeTab}
          onChange={tabChangeHandler}
        />
      </div>
      <DealSubmissionMatchDrawer />
      <ActivityDrawer
        selectedDealSubmission={selectedDealSubmission}
        otherDealSubmissions={otherDealSubmissions}
      />
    </div>
  );
};

export default DealSubmissionPage;
