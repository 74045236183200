export const DEFAULT_FILTERS = {
  query: '',
  areas: [],
  minBudget: '',
  maxBudget: '',
  minYearBuilt: '',
  maxYearBuilt: '',
  minBeds: '',
  minBaths: '',
  minSqft: '',
  maxSqft: '',
  isVerified: '',
  isBanned: '',
  hasBuyBox: '',
};

export const SEARCH_PARAMS_LABELS = {
  query: '',
  areas: 'Area',
  minBudget: 'Min budget',
  maxBudget: 'Max budget',
  minYearBuilt: 'Min year',
  maxYearBuilt: 'Max year',
  minBeds: 'Min bed',
  minBaths: 'Min bath',
  minSqft: 'Min SQFT',
  maxSqft: 'Max SQFT',
  isVerified: 'Is verified',
  isBanned: 'Is banned',
  hasBuyBox: 'Has buy box',
};

export const PLACES_COUNTER = {
  TX: 8333,
  CA: 6054,
  FL: 7499,
  NY: 3859,
  undefined: 132,
  AZ: 4433,
  MO: 4938,
  NC: 2124,
  OH: 1206,
  IL: 1231,
  AR: 2968,
  NJ: 4336,
  IN: 1902,
  GA: 6594,
  CO: 8,
  KY: 432,
  OK: 5357,
  AB: 6574,
  BC: 3444,
  MD: 1476,
  AL: 1222,
  PA: 2397,
  DE: 5918,
  LA: 8890,
  'Tel Aviv District': 543,
  MA: 4234,
  VA: 6753,
  TN: 3332,
  SC: 1670,
  WV: 400,
  CT: 2231,
  MI: 5466,
  NV: 2444,
  NM: 3222,
  DC: 8473,
  WI: 1500,
  NSW: 2344,
  VIC: 7553,
  MN: 4938,
  'Méx.': 1423,
  ID: 5800,
  HI: 2112,
  MT: 9382,
  IA: 9433,
  WY: 1050,
  RI: 1454,
  VT: 3211,
  MS: 1234,
  ON: 3432,
  WA: 4543,
  OR: 8372,
  England: 1324,
  Normandy: 1234,
};
