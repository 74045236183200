import React, { useMemo, useRef, useState } from 'react';
import { Form, Select, Spin } from 'antd';
import debounce from 'lodash/debounce';

import './index.scss';

const ZorbaSearchSelect = ({
  fetchOptions,
  debounceTimeout = 800,
  label,
  ...props
}) => {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  const selectComponent = (
    <Select
      className="zorba-search-select"
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
      options={options}
    />
  );

  return label ? (
    <Form.Item label={label}>{selectComponent}</Form.Item>
  ) : (
    selectComponent
  );
};

export default ZorbaSearchSelect;
