export const mapDealSubmissionEventTypeToTitle = {
  offer: 'Offer submitted to the seller',
  uw_reject: 'Rejected by underwriter',
  seller_reject: 'Seller rejected the offer',
  accept: 'Seller accepted the offer',
  seller_update_asking_price: 'Seller updated asking price',
  buyer_reject: 'Rejection sent to the seller',
  buyer_final_offer: 'Final offer submitted to the seller',
};

export const mapDealSubmissionMatchEventTypeToTitle = {
  offer_request: 'Buyer received an offer request',
  offer: 'Buyer submitted an offer',
  firm_on_offer: 'Buyer firm on offer',
  rejected_by_seller: 'Offer rejected by the seller',
  rejected_by_buyer: 'Buyer rejected the deal',
  pending_agreement: 'Pending agreement',
  seller_update_asking_price: 'New asking price delivered to buyer',
};

export const getDealSubmissionTitle = (eventType, buyerName) => {
  const titles = {
    offer: `Offer submitted to the seller by ${buyerName}`,
    uw_reject: 'Rejected by underwriter',
    seller_reject: 'Seller rejected the offer',
    accept: `Seller accepted ${buyerName}'s offer`,
    seller_update_asking_price: 'Seller updated asking price',
    buyer_reject: `${buyerName} sent rejection to the seller`,
    buyer_final_offer: `${buyerName} submitted final offer to the seller`,
  };

  return titles[eventType] || 'Unknown event';
};

export const getDealSubmissionMatchTitle = (eventType, buyerName) => {
  const titles = {
    offer_request: `${buyerName} received an offer request`,
    offer: `${buyerName} submitted an offer`,
    firm_on_offer: `${buyerName} firm on offer`,
    rejected_by_seller: `Seller rejected ${buyerName}'s offer (it might have happened automatically)`,
    rejected_by_buyer: `${buyerName} rejected the deal`,
    pending_agreement: 'Pending agreement',
    seller_update_asking_price: 'New asking price delivered to buyer',
  };

  return titles[eventType] || '';
};
export const mapDealSubmissionStepToTitle = {
  draft: 'Draft',
  missing_info: 'Missing info from the Seller',
  review: 'Underwriting',
  revised_offer: 'Buyer submitted an offer',
  pending_agreement: 'Pending agreement',
  pending_inspection: 'Pending inspection',
  closing: 'Closing',
  rejected_by_uw: 'Rejected by UW',
  rejected_by_seller: 'Rejected by Seller',
  rejected_by_buyer: 'Rejected by Buyer',
};

export const LOW_BALL_OFFER_MARGIN = 65000;

export const getOneBeforeLastEventForOrganization = (
  events,
  organizationName,
) => {
  const filteredEvents = events.filter(
    (event) => event.organizationName === organizationName,
  );

  if (filteredEvents.length < 2) {
    return undefined; // Not enough events for the given organizationName
  }

  const secondToLastIndex = filteredEvents.length - 2;
  return filteredEvents[secondToLastIndex];
};
