import { getDaysSince } from '@zorba-shared/core/Services/textService';
import { Tag } from 'antd';
import { ZorbaButton } from '../../components/forms';
import { BUTTON_VARIANT_LINK } from '../../components/forms/ZorbaButton/constants';

export const getTableColumns = (handleRefundCredit) => [
  {
    title: 'Contact',
    key: 'contactName',
    render: ({ contactName }) => contactName,
  },
  {
    title: 'Organization',
    key: 'contactOrganizationName',
    render: ({ contactOrganizationName }) => contactOrganizationName,
  },
  {
    title: 'Title',
    key: 'contactTitle',
    render: ({ contactTitle }) => contactTitle,
  },
  {
    title: 'Email',
    key: 'contactEmail',
    render: ({ contactEmail }) => contactEmail,
  },
  {
    title: 'Requested by',
    key: 'requestedBy',
    render: ({ requestingOrganization, requestingUser }) =>
      `${requestingOrganization} - ${requestingUser}`,
  },
  {
    title: 'Requested At',
    key: 'requestedRefundAt',
    render: ({ requestedRefundAt }) => (
      <Tag>{`${getDaysSince(new Date(requestedRefundAt))} days ago`}</Tag>
    ),
  },
  {
    title: '',
    key: '',
    render: ({ contactOrganizationId, contactOrganizationName }) => (
      <a
        href={`https://data.getzorba.com/top-buyers/${contactOrganizationId}/${contactOrganizationName}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Data App
      </a>
    ),
  },
  {
    title: '',
    key: 'actions',
    render: ({ transactionId, contactId, requestingOrganizationId }) => (
      <ZorbaButton
        variant={BUTTON_VARIANT_LINK}
        onClick={() =>
          handleRefundCredit({
            organizationId: requestingOrganizationId,
            contactId,
            transactionId,
          })
        }
      >
        Refund
      </ZorbaButton>
    ),
  },
];
