import { Spin } from 'antd';
import ZorbaConfirmButton from '../../../../components/forms/ZorbaConfirmButton';

export default (props) => (
  <Spin spinning={props.isLoading}>
    <div className="buyer-status-box verified">
      <div className="buyer-status-box--info">This buyer was verified.</div>
      <ZorbaConfirmButton
        confirmTitle="Do you want to reject this buyer?"
        onConfirm={() => props?.onConfirm(false)}
      >
        {' '}
        Reject
      </ZorbaConfirmButton>
    </div>
  </Spin>
);
