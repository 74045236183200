import { useDispatch } from 'react-redux';

import MatchingBuyersTable from '../../../BuyerCheckerPage/Components/MatchingBuyersTable';
import GenerateOffer from '../../../BuyerCheckerPage/Components/GenerateOffer';
import { refreshDealSubmissionMatches } from '../../../../reducer/DealSubmission/actions';
import { getMonthlyHoaFee } from './utils';
import './index.scss';
import { DuplicatedDealModal } from '../DuplicatedDealModal';

export const BuyBoxCheckerTab = ({
  dealSubmission,
  isFetchingDealSubmission,
}) => {
  const dispatch = useDispatch();
  const handleRefreshMatches = (formValues, overrideZillowData) => {
    dispatch(
      refreshDealSubmissionMatches({
        ...formValues,
        overrideZillowData,
        dealSubmissionId: dealSubmission._id,
      }),
    );
  };

  const data = dealSubmission?.powerBuyerMatches?.length
    ? dealSubmission.powerBuyerMatches.map((powerBuyerMatch) => ({
        ...powerBuyerMatch,
        match: dealSubmission?.matches.find(
          (match) => match.organization._id === powerBuyerMatch.organization,
        ),
      }))
    : [];

  const offerParams = {
    bedroom: dealSubmission.lastCalculationParams?.bedroom,
    bathroom: dealSubmission.lastCalculationParams?.bathroom,
    yearBuilt: dealSubmission.lastCalculationParams?.yearBuilt,
    squareFootage: dealSubmission.lastCalculationParams?.squareFootage,
    lotSize: dealSubmission.lastCalculationParams?.lotSize,
    schoolRating:
      dealSubmission.lastCalculationParams?.schoolRating ||
      dealSubmission.zillowData?.schoolRating
        ? dealSubmission.zillowData?.schoolRating.toFixed(2)
        : '',
    hasPool: dealSubmission.lastCalculationParams?.hasPool,
    parkingSpaces: dealSubmission.lastCalculationParams?.parkingSpaces,
    floodZone: dealSubmission.lastCalculationParams?.floodZone,

    maxAllowableOffer:
      dealSubmission.lastCalculationParams.askingPrice ||
      dealSubmission.underwriting?.arv ||
      dealSubmission.lastCalculationParams?.arv,
    grossRent:
      dealSubmission.underwriting?.rent ||
      dealSubmission.lastCalculationParams?.grossRent,
    rehabCost:
      dealSubmission.underwriting?.rehabCost ||
      dealSubmission.lastCalculationParams?.rehabCost,
    monthlyHoaFee: getMonthlyHoaFee(dealSubmission),
    wastewaterType: dealSubmission.lastCalculationParams?.wastewaterType,
    foundationIssues: dealSubmission.lastCalculationParams?.foundationIssues,
    parkingTypes: dealSubmission.lastCalculationParams?.parkingTypes || [],
    propertyType: dealSubmission.lastCalculationParams?.propertyType,
  };

  return (
    <>
      <div className="refresh-matches-form-container">
        <GenerateOffer
          params={offerParams}
          zillowData={dealSubmission.zillowData}
          onSubmitButtonText="Refresh Matches"
          handleOnSubmit={handleRefreshMatches}
          loading={isFetchingDealSubmission}
          isUnderwritingDisabled
        />
      </div>
      <MatchingBuyersTable
        address={dealSubmission?.address}
        data={data}
        buyersWithMatchingBuybox={
          dealSubmission?.powerBuyerMatches?.filter((item) => item.status) || []
        }
        hideTitle
        isLoading={isFetchingDealSubmission}
        isShowMatchingCount={false}
        dispatch={dispatch}
      />
      <DuplicatedDealModal selectedDealSubmission={dealSubmission} />
    </>
  );
};
