import { Link } from 'react-router-dom';
import { Tag } from 'antd';
import { getFriendlyTime } from '@zorba-shared/core/Services/textService';
import {
  buildBuyerPageUrl,
  buildPreDealPageUrl,
} from '../../../../services/urlBuilderService';

export const PRE_DEALS_STATUS_OPTIONS = [
  {
    label: 'Draft',
    value: 'confirm_details',
  },
  {
    label: 'Review',
    value: 'review',
  },
  {
    label: 'Missing Info',
    value: 'missing_info',
  },
  {
    label: 'Revised Offer',
    value: 'revised_offer',
  },
  {
    label: 'Pending Agreement',
    value: 'pending_agreement',
  },
  {
    label: 'Pending Inspection',
    value: 'pending_inspection',
  },
  {
    label: 'Closing',
    value: 'closing',
  },
  {
    label: 'Rejected By UW',
    value: 'rejected_by_uw',
  },
  {
    label: 'Rejected By Seller',
    value: 'rejected_by_seller',
  },
];

export const getTableColumns = () => [
  {
    title: 'last updated',
    key: 'updatedAt',
    dataIndex: 'updatedAt',
    render: (updatedAt) => getFriendlyTime(updatedAt),
    sorter: (a, b) => (a.updatedAt > b.createdAt ? 1 : -1),
  },
  {
    title: 'Address',
    key: 'address',
    render: ({ address, _id }) => (
      <Link
        className="buyer-name"
        to={buildPreDealPageUrl({ preDeal: { _id } })}
      >
        {address.formattedAddress}
      </Link>
    ),
  },
  {
    title: 'Status',
    key: 'status',
    render: ({ step }) => (
      <Tag className={`zorba-tag ${step}`}>
        {
          PRE_DEALS_STATUS_OPTIONS.find((option) => option.value === step)
            ?.label
        }
      </Tag>
    ),
  },
  {
    title: 'Seller',
    key: 'seller',
    render: ({ organization, submittedBy }) => (
      <Link
        className="buyer-name"
        to={buildBuyerPageUrl({ userDetails: { _id: submittedBy?._id } })}
      >
        {submittedBy?.name || organization?.name}
      </Link>
    ),
  },
  {
    title: 'Linked Buyer',
    key: 'linkedBuyer',
    render: ({ associatedMatch }) => {
      const userLink = associatedMatch?.organization?.members?.[0];

      return (
        <Tag>
          <Link
            className="buyer-name"
            to={buildBuyerPageUrl({
              userDetails: { _id: userLink },
            })}
          >
            {associatedMatch?.organization?.name}
          </Link>
        </Tag>
      );
    },
  },
];
