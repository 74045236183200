import { Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getColumns } from './constants';

import './index.scss';
import { getPropertyDataJobs } from '../../../../../../reducer/PropertyDataJobs/actions';

export default () => {
  const { data, total, isFetchingData, searchQuery } = useSelector(
    ({ PropertyDataJobsReducer }) => PropertyDataJobsReducer,
  );
  const dispatch = useDispatch();

  const onShowSizeChange = (current, pageSize) => {
    dispatch(
      getPropertyDataJobs({
        page: current - 1,
        pageSize,
        searchQuery,
      }),
    );
  };

  const pagination = {
    position: ['bottomRight'],
    onChange: onShowSizeChange,
    total,
    pageSize: 20,
    showTotal: (totalItems, range) =>
      `${range[0]}-${range[1]} of ${totalItems} items`,
    showSizeChanger: false,
  };

  return (
    <div className="propertyData-jobs">
      <Table
        rowKey="_id"
        columns={getColumns()}
        dataSource={data}
        loading={isFetchingData}
        pagination={pagination}
      />
    </div>
  );
};
