export default class TranslationService {
  constructor(translationCategories) {
    this.translationCategories = translationCategories;
  }

  translateKey(category, key) {
    if (!this.translationCategories) return '';

    return this.translationCategories[category]
      ? this.translationCategories[category][key]
      : '';
  }
}
