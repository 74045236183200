import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { Modal } from 'antd';

import StepsView from './Components/StepView';
import { setJob, setRecords } from '../../../../reducer/PropertyData/actions';

import './index.scss';

import { JOB_WIZARD_STEPS } from './constants';

let getJobs;
let setModalState;
let MODAL_PARAM_CREATE_JOB;

const ImportWizardModal = ({ isImportDataModalOpen, setIsImportModalOpen }) => {
  const { job } = useSelector(({ PropertyDataReducer }) => PropertyDataReducer);
  const { records } = useSelector(
    ({ PropertyDataReducer }) => PropertyDataReducer,
  );
  const [modalExtraProps, setModalExtraProps] = useState({ footer: null });
  const [stepIndex, setStepIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const currentStepData = JOB_WIZARD_STEPS[stepIndex];
  const dispatch = useDispatch();

  const onContinue = () => {};

  const onSubmitClick = async () => {
    setModalExtraProps({ footer: null });
    setStepIndex(null);
    dispatch(setJob(undefined));
    dispatch(setRecords([]));
    dispatch(getJobs());
    dispatch(setModalState(MODAL_PARAM_CREATE_JOB, false));
  };

  const onCloseModal = () => {
    dispatch(setModalState(MODAL_PARAM_CREATE_JOB, false));
    setStepIndex(0);
  };

  return (
    <Modal
      className={`job-wizard-modal step-${stepIndex}`}
      destroyOnClose
      width="auto"
      closable={false}
      okText="Pay and continue"
      okButtonProps={{
        className: 'submit-btn',
      }}
      open={isImportDataModalOpen}
      onOk={() => onSubmitClick()}
      onCancel={() => setIsImportModalOpen(false)}
      {...modalExtraProps}
    >
      <StepsView
        job={job}
        records={records}
        stepIndex={stepIndex}
        currentStepData={currentStepData}
        onContinue={onContinue}
        setStepIndex={setStepIndex}
        loading={loading}
        setLoading={setLoading}
        onCloseModal={onCloseModal}
        setIsImportModalOpen={setIsImportModalOpen}
      />
    </Modal>
  );
};

export default ImportWizardModal;
