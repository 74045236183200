import { message, Upload } from 'antd';
import PropTypes from 'prop-types';

import { getAuthToken } from '../../../services/authService';
import { BASE_URL } from '../../../services/clientService';
import ZorbaButton from '../ZorbaButton';
import { UPLOAD_PROPS } from './constants';

import './index.scss';

const { Dragger } = Upload;

const DragUpload = (props) => {
  const {
    uploadUrl,
    titleObj,
    subtitle = '.csv, .xls, .xlsx spreadsheets accepted',
    hint = "You can upload any .csv, .xls, .xlsx file with any set of columns as long as it has 1 record per row. The next step will allow you to match your spreadsheet columns to the right data points. You'll be able to clean up or remove any corrupted data before finalizing your report.",
    accept = '.csv,.xls,.xlsx',
    maxCount = 1,
    loading,
    setLoading,

    onSuccess,
    onFailure,
    onDrop,
    beforeUpload,
  } = props;
  const accessToken = getAuthToken();

  return (
    <Dragger
      {...UPLOAD_PROPS}
      beforeUpload={beforeUpload}
      disabled={loading}
      action={uploadUrl}
      loading={loading}
      accept={accept}
      maxCount={maxCount}
      showUploadList
      headers={{ 'x-access-token': accessToken }}
      className="drag-upload"
      onDrop={onDrop}
      onChange={(info) => {
        if (props.onChange) props.onChange(info);
        const { status, error } = info.file;
        if (props.onLoadingChange) {
          props.onLoadingChange(status === 'uploading');
        }
        if (status === 'uploading' && setLoading) {
          setLoading(true);
        } else if (setLoading) {
          setLoading(false);
        }
        if (status === 'done') {
          message.success(`${info.file.name} file uploaded successfully.`);

          if (onSuccess) onSuccess(info.file);
        } else if (status === 'error') {
          if (onFailure) onFailure();
          if (error.status === 406)
            message.error(`${info.file.name} file has no records.`, 5);
          else message.error(`${info.file.name} file upload failed.`, 5);
        }
        if (props.onChange) {
          props.onChange(info.fileList);
        }
      }}
      customRequest={props.customRequest}
    >
      <div className="upload-title-wrapper">
        {titleObj || (
          <p className="ant-upload-text">
            <ZorbaButton>Upload data from file</ZorbaButton>
          </p>
        )}
        <p className="ant-upload-hint upload-subtitle">{subtitle}</p>
      </div>
      <p className="ant-upload-hint upload-hint">{hint}</p>
    </Dragger>
  );
};

DragUpload.propTypes = {
  title: PropTypes.string,
  uploadUrl: PropTypes.string,
};

DragUpload.defaultProps = {
  title: 'Click or drag files to this area to upload',
  uploadUrl: `${BASE_URL}/backoffice/property-data/job/import`,
};

export default DragUpload;
