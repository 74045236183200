import { FiPlus } from '@react-icons/all-files/fi/FiPlus';
import { IoShieldCheckmark } from '@react-icons/all-files/io5/IoShieldCheckmark';
import {
  AutoComplete,
  Button,
  Form,
  Input,
  Modal,
  Space,
  Switch,
  Tooltip,
} from 'antd';
import { debounce } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from '../../../../components/forms/PhoneInput';
import OrganizationCreateForm from '../../../../components/OrganizationCreateForm';
import { createBuyerAccount } from '../../../../reducer/Buyer/actions';
import { setModalState } from '../../../../reducer/Modal/actions';
import { MODAL_CREATE_ORGANIZATION } from '../../../../reducer/Modal/types';
import { getOrganizations } from '../../../../reducer/Organization/actions';
import { ZorbaSelect } from '../../../../components/forms';

import './index.scss';

const SEARCH_THROTLE_DELAY = 500;

export default (props) => {
  const [searchResult, setSearchResult] = useState([]);
  const { isCreateOrganizationModalOpen } = useSelector(
    ({ ModalReducer }) => ModalReducer,
  );
  const { isBuyerSaving } = useSelector(({ BuyerReducer }) => BuyerReducer);
  const { organizations, newOrganization } = useSelector(
    ({ OrganizationReducer }) => OrganizationReducer,
  );

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const firstNameRef = useRef();

  const onCancelHandler = () => {
    dispatch(setModalState(MODAL_CREATE_ORGANIZATION, false));
  };

  /**
   * initial on load
   */
  useEffect(() => {
    searchOrganization('');
    firstNameRef.current.focus();
  }, ['']);

  /**
   * watch organizations and transform data for AutoComplete
   */
  useEffect(() => {
    const results = organizations.map((org) => ({
      value: org.name,
      label: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <span>{org.name}</span>
          {org?.verification?.verified && (
            <Tooltip title="Verified organization">
              <span>
                <IoShieldCheckmark className="text-success" />
              </span>
            </Tooltip>
          )}
        </div>
      ),
    }));
    setSearchResult(results);
  }, [organizations]);

  /**
   * set newly created organization from organizationCreateModal
   */
  useEffect(() => {
    if (newOrganization) {
      form.resetFields(['organizationName']);
      form.setFieldValue('organizationName', newOrganization.name);
    }
  }, [newOrganization]);

  const searchOrganization = (query) => {
    dispatch(getOrganizations({ query }));
  };

  const handleOrgSearch = useMemo(
    () =>
      debounce((q) => {
        q = q.trim();
        searchOrganization(q);
      }, SEARCH_THROTLE_DELAY),
    [],
  );

  const onFinish = (values) => {
    dispatch(createBuyerAccount(values));
  };

  const setOrgCreateModal = () => {
    dispatch(setModalState(MODAL_CREATE_ORGANIZATION, true));
  };

  const onChange = (field, value) => {
    form.setFieldValue(field, value);
  };

  return (
    <div className="buyer-create-form">
      <Form
        name="basic"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        initialValues={{ isVerified: true }}
      >
        <Form.Item
          name="firstName"
          label="First Name"
          rules={[
            {
              required: true,
              message: 'Please enter First Name',
            },
          ]}
        >
          <Input ref={firstNameRef} />
        </Form.Item>

        <Form.Item
          name="lastName"
          label="Last Name"
          rules={[
            {
              required: true,
              message: 'Please enter Last Name',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="phoneNumber"
          label="Phone Number"
          rules={[
            {
              required: true,
              message: 'Please enter Phone Number',
            },
            {
              validator: (_, value) =>
                value?.number?.length === 10
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error('Please enter 10 digits valid phone number'),
                    ),
            },
          ]}
        >
          <PhoneInput
            fullWidth
            label="‎"
            placeholder="Phone number"
            onChange={(value) => onChange('phoneNumber', value)}
          />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: 'Please enter email',
            },
            {
              type: 'email',
              message: 'Please enter valid email',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="kind"
          label="User Type"
          rules={[
            {
              required: true,
              message: 'Please enter user type',
            },
          ]}
        >
          <ZorbaSelect
            options={[
              { text: 'Buyer', value: 'buyer' },
              { text: 'Investor', value: 'investor' },
            ]}
            onChange={(value) => onChange('kind', value)}
          />
        </Form.Item>

        <Space>
          <Form.Item
            name="organizationName"
            label="Organization"
            rules={[
              {
                required: true,
                message: 'Select organization',
              },
            ]}
          >
            <AutoComplete
              dropdownMatchSelectWidth={252}
              style={{
                width: 300,
              }}
              options={searchResult}
              onChange={handleOrgSearch}
              allowClear
              notFoundContent="This organization does not exist, click add new to create it"
            />
          </Form.Item>

          <Button onClick={setOrgCreateModal} className="btn-add-org">
            <FiPlus /> Add new
          </Button>
        </Space>

        <Form.Item
          label="Is Verified?"
          name="isVerified"
          valuePropName="checked"
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          layout="horizontal"
        >
          <Switch />
        </Form.Item>
        <Space align="end" className="btn-group" wrap size="large">
          <Button onClick={() => props.onCancel()}>cancel</Button>
          <Button type="primary" htmlType="submit" loading={isBuyerSaving}>
            Save
          </Button>
        </Space>
      </Form>

      <Modal
        title="Add new organization"
        centered
        open={isCreateOrganizationModalOpen}
        footer={null}
        closable={false}
        destroyOnClose
      >
        <OrganizationCreateForm
          defaultName={form.getFieldValue('organizationName')}
          onCancel={onCancelHandler}
        />
      </Modal>
    </div>
  );
};
