import * as types from './types';

const initialState = {
  isLoading: false,
  preDeals: [],
  hasMoreResults: false,
  currentPage: 0,
  total: 0,
  pageSize: 50,
  selectedPreDeal: null,
  selectedPreDealMatch: null,
  isFetchingPreDeal: false,
};
const PreDealsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_PRE_DEALS:
    case types.SET_LOADING:
    case types.SET_CURRENT_PAGE:
    case types.SET_PAGE_SIZE:
    case types.SET_SELECTED_PRE_DEAL:
    case types.SET_IS_FETCHING_PRE_DEAL:
    case types.SET_SELECTED_PRE_DEAL_MATCH:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default PreDealsReducer;
