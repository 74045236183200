import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import BuyerReducer from './Buyer';
import AuthReducer from './Auth';
import PropertyReducer from './Property';
import OrganizationReducer from './Organization';
import ModalReducer from './Modal';
import DashboardReducer from './Dashboard';
import ListingReducer from './Listing';
import UserReducer from './User';
import TaskReducer from './Task';
import DrawerReducer from './Drawer';
import TranslationReducer from './Translation';
import PropertyDataReducer from './PropertyData';
import PropertyDataJobsReducer from './PropertyDataJobs';
import DealSubmissionReducer from './DealSubmission';
import BuyersReportReducer from './BuyersReport';
import InstitutionalResearchReducer from './InstitutionalResearch';
import PreDealsReducer from './PreDeals';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    AuthReducer,
    BuyerReducer,
    PropertyReducer,
    OrganizationReducer,
    ModalReducer,
    DashboardReducer,
    ListingReducer,
    UserReducer,
    TaskReducer,
    DrawerReducer,
    TranslationReducer,
    PropertyDataReducer,
    PropertyDataJobsReducer,
    DealSubmissionReducer,
    BuyersReportReducer,
    InstitutionalResearchReducer,
    PreDealsReducer,
  });
