/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { message } from 'antd';

import { DragUpload } from '../../../../../../components/forms';
import { getSpreadsheetsFromBuffer } from '../../../../../../services/xlsService';
import {
  setJob,
  setRecords,
} from '../../../../../../reducer/PropertyData/actions';

import './index.scss';

const excelFileTypes = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];
const fileTypes = ['text/csv', ...excelFileTypes];
const checkIsValid = (file) => fileTypes.includes(file.type);

const UploadStep = (props) => {
  const { uploadStatus, onNextClick } = props;
  const [fileName, setFileName] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [sheets, setSheets] = useState([]);
  const [sheetColumnJson, setSheetColumnJson] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const beforeUpload = async (file) => {
    if (file.size / 1024 / 1024 > 50) {
      message.error(
        `${file.name} file upload failed, file size is larger than 50MB.`,
      );
      return false;
    }
    if (excelFileTypes.includes(file.type)) {
      setFileName(file.name);
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        setFileData(data);

        setSheets(() => {
          const { sheetNames, sheetColumnJson: sheetColumnJsonFromBuffer } =
            getSpreadsheetsFromBuffer(data);
          setSheetColumnJson(sheetColumnJsonFromBuffer);
          return sheetNames.map((name) => ({ value: name, text: name }));
        });
      };
      reader.readAsArrayBuffer(file);

      return true;
    }
    return true;
  };

  const onDrop = (e) => {
    const file = e.dataTransfer.files[0];
    const isValidFileType = checkIsValid(file);
    if (!isValidFileType) {
      message.error(
        `${file.name} file upload failed, file extension isn't allowed.`,
      );
    }
  };

  return (
    <div className="job-wizard-modal-step job-wizard-modal-step-upload">
      <DragUpload
        {...props}
        loading={loading}
        setLoading={setLoading}
        onSuccess={(file) => {
          dispatch(setJob(file?.response?.jobObj));
          dispatch(setRecords(file?.response?.records));
          onNextClick();
        }}
        onFailure={() => {}}
        onDrop={onDrop}
        beforeUpload={beforeUpload}
        showUploadList={uploadStatus}
      />
    </div>
  );
};

export default UploadStep;
