import { Link } from 'react-router-dom';

export const getTableColumns = () => [
  {
    title: 'Address',
    key: 'address',
    render: ({ dealSubmissionId, address }) => (
      <Link className="address" to={`/deal-submission/${dealSubmissionId}`}>
        {address}
      </Link>
    ),
  },
  {
    title: 'Status',
    key: 'status',
    render: ({ status, offer }) =>
      status === 'Rejected by seller' && !offer
        ? 'Buyer did not respond'
        : status,
  },
  {
    title: 'Buyer rejection reason',
    key: 'rejectionReason',
    render: ({ rejectionReason }) => rejectionReason,
  },
  {
    title: 'Offer price',
    key: 'offer',
    render: ({ offer }) => offer,
  },
  {
    title: 'Asking price',
    key: 'askingPrice',
    render: ({ askingPrice }) => askingPrice,
  },
];
