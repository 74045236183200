export const getDealSubmissionMatchDefaultParams = (underwriting) => ({
  status: 'offer_request',
  arv: underwriting?.arv || null,
  rehabCost: underwriting?.rehabCost || null,
  rent: underwriting?.rent || null,
  emd: null,
  inspectionDays: null,
  offer: null,
  isVisibleToPowerBuyer: false,
  offerParams: {},
});

export const DEAL_SUBMISSION_MATCH_FORM = [
  '_id',
  // 'offerParams',
  'status',
  'dealSubmission',
  'organization',
  'visible',
  'feeAmount',
];

export const DEAL_SUBMISSION_MATCH_OFFER_PARAMS = [
  'arv',
  'rehabCost',
  'emd',
  'rent',
  'inspectionDays',
  'maxAllowableOffer',
  'inspectionMode',
  'grossRent',
  'daysToClose',
];

export const HOMEWARD_ORGANIZATION_ID = '613bb6dd0ceb620e2c4d3a73';
