import './index.scss';
import { Switch, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { set, cloneDeep, get } from 'lodash';
import { IoShieldCheckmark } from '@react-icons/all-files/io5/IoShieldCheckmark';
import { DEAL_SUBMISSION_MATCH_STATUS_TITLES } from '@zorba-shared/core/Constants/offer';
import SaveChangesDrawer from '../../../../components/forms/SaveChangesDrawer';
import ZorbaInput from '../../../../components/forms/ZorbaInput';
import PhoneInput from '../../../../components/forms/PhoneInput';
import { diffObject } from '../../../../services/validationService';
import { updateBuyerDetails } from '../../../../reducer/Buyer/actions';
import { postRequest } from '../../../../services/requestService';
import { ZorbaSearchSelect, ZorbaSelect } from '../../../../components/forms';
import { USER_KIND_OPTIONS } from '../../../../constants/permission';
import { getAllOrganizationPowerBuyers } from '../../../../reducer/User/actions';
import { AGENT_OPTIONS } from '../../constants';

export default (props) => {
  const {
    selectedBuyer,
    changedParams,
    setChangedParams,
    params,
    setParams,
    revertChangesHandler,
  } = props;
  const [selectedOrganization, setSelectedOrganization] = useState([]);
  const { organizationPowerBuyers } = useSelector(
    ({ UserReducer }) => UserReducer,
  );
  const organization = get(selectedBuyer, 'defaultOrganization');
  const dispatch = useDispatch();
  const changedPropertiesCount = Object.keys(changedParams).length;
  const isSaveChangesVisible = changedPropertiesCount > 0;

  useEffect(() => {
    dispatch(getAllOrganizationPowerBuyers());
  }, []);

  const onChange = (key, value) => {
    const updatedParams = set({ ...params }, key, value);
    const updatedChangedParams = diffObject(updatedParams, selectedBuyer);
    setParams({ ...updatedParams });
    setChangedParams({ ...updatedChangedParams });
  };

  const getRequestPayload = (updatedParams) => {
    const requestPayload = {};

    Object.keys(updatedParams).forEach((paramKey) => {
      if (paramKey === 'defaultOrganization') {
        const {
          name,
          isPowerBuyer,
          buyerConfig,
          globalNotificationsRecipients,
          isSoftPaused,
          dataSubscription,
          shouldAutoSubmitProperties,
          defaultFeePercentage,
          buyersAppFeatureFlags,
        } = updatedParams[paramKey];
        requestPayload.organizationName = name;
        requestPayload.isPowerBuyer = isPowerBuyer;
        requestPayload.isSoftPaused = isSoftPaused;
        requestPayload.shouldAutoSubmitProperties = shouldAutoSubmitProperties;
        requestPayload.dataSubscription = dataSubscription;
        requestPayload.buyerConfig = buyerConfig;
        requestPayload.globalNotificationsRecipients =
          globalNotificationsRecipients;
        requestPayload.defaultFeePercentage = defaultFeePercentage;
        requestPayload.buyersAppFeatureFlags = buyersAppFeatureFlags;
      } else {
        requestPayload[paramKey] = updatedParams[paramKey];
      }
    });

    return requestPayload;
  };

  const onSubmit = () => {
    if (Object.values(changedParams).length === 0) return;
    const requestPayload = getRequestPayload(changedParams);
    dispatch(
      updateBuyerDetails({
        ...requestPayload,
        requestedUserId: params._id,
      }),
    );
  };

  const notificationsRecipientsOptions = organization.members.map((member) => ({
    text: member.email,
    value: member.email,
  }));

  const tranformOrganizationItem = (orgList) =>
    orgList.map((org) => ({
      value: org._id,
      title: org.members[0]?.name ? `- ${org.members[0].name} (${org.members[0].email})` : '',
      label: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <span>{`${org.name} ${
            org.members[0]?.name ? `- ${org.members[0].name} (${org.members[0].email})` : ''
          }`}</span>
          {org?.verification?.verified && (
            <Tooltip title="Verified organization">
              <span>
                <IoShieldCheckmark className="text-success" />
              </span>
            </Tooltip>
          )}
        </div>
      ),
    }));

  const fetchOrganizations = async (searchText) => {
    const query = searchText.trim();
    const res = await postRequest('/backoffice/organization/search', {
      query,
    });
    const { items } = res.data;

    return tranformOrganizationItem(items);
  };

  /**
   * An helper function designed to build a ZorbaSearchSelect value object
   */
  const setDefaultOrganizationValue = (label, value) => {
    let orgSelectOption = [];
    if (label && value) {
      orgSelectOption = [{ label, value, title: label }];
    }
    setSelectedOrganization(orgSelectOption);
  };

  const onChangeOrganization = (options) => {
    const org = options.length > 1 ? options.pop() : options[0];
    const { value, title } = org || {};

    if (value) onChange('defaultOrganizationId', value);
    setDefaultOrganizationValue(title, value);
  };

  const onChangeDefaulFee = (e) => {
    onChange('defaultOrganization.defaultFeePercentage', e.target.value / 100);
  };

  useEffect(() => {
    if (selectedBuyer) {
      const buyerObj = cloneDeep(selectedBuyer);
      setParams(buyerObj);
      setChangedParams({});
    }
  }, [selectedBuyer]);

  /**
   * Watch defaultOrganization and set selectedValue of DefaultOrganization
   */
  useEffect(() => {
    if (params.defaultOrganization) {
      setDefaultOrganizationValue(
        params.defaultOrganization.name,
        params.defaultOrganization._id,
      );
    }
  }, [params.defaultOrganization]);

  if (!selectedBuyer) return null;
  return (
    <>
      <div className="buyer-info-tab">
        <div className="row">
          <div className="form-item">
            <ZorbaInput
              label="First name"
              onChange={(e) => onChange('firstName', e.target.value)}
              value={params.firstName}
            />
          </div>

          <div className="form-item">
            <ZorbaInput
              label="Last name"
              onChange={(e) => onChange('lastName', e.target.value)}
              value={params.lastName}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-item">
            <ZorbaInput
              label="Email"
              onChange={(e) => onChange('email', e.target.value)}
              value={params.email}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-item">
            <PhoneInput
              fullWidth
              label="Phone Number"
              defaultValue={params.phoneNumber?.number}
              value={params.phoneNumber}
              placeholder="Phone number"
              onChange={(value) => onChange('phoneNumber', value)}
            />
          </div>
        </div>
        <ZorbaSearchSelect
          label="Select Company"
          showSearch
          mode="multiple"
          placeholder="Search Organization"
          fetchOptions={fetchOrganizations}
          onChange={onChangeOrganization}
          value={selectedOrganization}
          optionLabelProp="label"
          maxTagCount={1}
        />
        <ZorbaSelect
          label="Select user type(s)"
          placeholder="Agent, Buyer, Investor"
          mode="multiple"
          onChange={(e) => onChange('kind', e)}
          value={params.kind}
          options={USER_KIND_OPTIONS}
        />
        <ZorbaSelect
          label="Select associated agent"
          onChange={(e) => onChange('associatedAgent', e)}
          value={params.associatedAgent}
          options={AGENT_OPTIONS}
        />
        <ZorbaSelect
          label="Excluded funds from matching algorithm"
          mode="multiple"
          onChange={(e) => onChange('excludedOrganizationsFromMatching', e)}
          options={organizationPowerBuyers}
          value={params.excludedOrganizationsFromMatching}
        />
        <ZorbaSelect
          label="Global notifications recipients"
          mode="tags"
          onChange={(e) =>
            onChange('defaultOrganization.globalNotificationsRecipients', e)
          }
          value={params.defaultOrganization?.globalNotificationsRecipients}
          options={notificationsRecipientsOptions}
          className="test"
        />
        <span className="global-notifications-hint">
          The email addresses you enter here will receive every single deal, in
          addition to the members of your company or the recipients specified in
          the buy box recipients feature.
        </span>
        <ZorbaSelect
          label="Default filters for the Buyers App"
          mode="multiple"
          onChange={(e) => onChange('buyersAppDefaultFilters', e)}
          options={Object.keys(DEAL_SUBMISSION_MATCH_STATUS_TITLES).map(
            (key) => ({
              text: DEAL_SUBMISSION_MATCH_STATUS_TITLES[key],
              value: key,
            }),
          )}
          value={params.buyersAppDefaultFilters}
        />
        <ZorbaSelect
          label="Do we buy with foundation issues?"
          onChange={(e) =>
            onChange(
              'defaultOrganization.buyerConfig.isBuyingWithFoundationIssues',
              e,
            )
          }
          options={[
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ]}
          value={
            params.defaultOrganization?.buyerConfig
              ?.isBuyingWithFoundationIssues
          }
        />
        <div className="form-item">
          <ZorbaInput
            className="default-fee-percentage-input"
            fullWidth
            label="Default fee percentage"
            symbol=""
            addonAfter="%"
            onChange={onChangeDefaulFee}
            value={
              params.defaultOrganization?.defaultFeePercentage &&
              !Number.isNaN(params.defaultOrganization?.defaultFeePercentage)
                ? Math.round(
                    params.defaultOrganization.defaultFeePercentage * 100,
                  )
                : 0
            }
          />
        </div>
        <div className="row column">
          <div className="form-item form-item--switch">
            <Switch
              checked={params.isBanned}
              onChange={(checked) => onChange('isBanned', checked)}
            />
            <label>Is banned?</label>
          </div>
          <span className="global-notifications-hint">
            If active, the seller won’t be able to use the partners app
          </span>
        </div>
        <div className="row column">
          <div className="form-item form-item--switch">
            <Switch
              checked={params.defaultOrganization?.isPowerBuyer}
              onChange={(checked) =>
                onChange('defaultOrganization.isPowerBuyer', checked)
              }
            />
            <label>Is organization power buyer?</label>
          </div>
          <span className="global-notifications-hint">
            If active, the buyer’s buy box will be added to the shared buy box
            of our active buyers
          </span>
        </div>
        <div className="row">
          <div className="form-item form-item--switch">
            <Switch
              checked={
                params.defaultOrganization?.buyerConfig?.doesIncludeFeeInOffer
              }
              onChange={(checked) =>
                onChange(
                  'defaultOrganization.buyerConfig.doesIncludeFeeInOffer',
                  checked,
                )
              }
            />
            <label>Include Zorba fee in our offer?</label>
          </div>
        </div>
        <div className="row">
          <div className="form-item form-item--switch">
            <Switch
              checked={
                params.defaultOrganization?.buyerConfig?.unsubscribeFromEodEmail
              }
              onChange={(checked) =>
                onChange(
                  'defaultOrganization.buyerConfig.unsubscribeFromEodEmail',
                  checked,
                )
              }
            />
            <label>Should unsubscribe buyer from the EOD report?</label>
          </div>
        </div>
        <div className="row">
          <div className="form-item form-item--switch">
            <Switch
              checked={
                params.defaultOrganization?.buyerConfig?.requireDoubleClose
              }
              onChange={(checked) =>
                onChange(
                  'defaultOrganization.buyerConfig.requireDoubleClose',
                  checked,
                )
              }
            />
            <label>The buyer requires the wholesaler to double close</label>
          </div>
        </div>
        <div className="row">
          <div className="form-item form-item--switch">
            <Switch
              checked={params.defaultOrganization?.isSoftPaused}
              onChange={(checked) =>
                onChange('defaultOrganization.isSoftPaused', checked)
              }
            />
            <label>Is soft paused?</label>
          </div>
        </div>
        <div className="row">
          <div className="form-item form-item--switch">
            <Switch
              checked={params.defaultOrganization?.shouldAutoSubmitProperties}
              onChange={(checked) =>
                onChange(
                  'defaultOrganization.shouldAutoSubmitProperties',
                  checked,
                )
              }
            />
            <label>
              {`Should we auto-submit properties submitted through the partner's
              app to all buyers?`}
            </label>
          </div>
        </div>
        <h3>Data app feature flags:</h3>
        <div className="row">
          <div className="form-item form-item--switch">
            <Switch
              checked={
                params.defaultOrganization?.dataSubscription?.featureFlags
                  ?.transactionsReport?.isActive
              }
              onChange={(checked) =>
                onChange(
                  'defaultOrganization.dataSubscription.featureFlags.transactionsReport.isActive',
                  checked,
                )
              }
            />
            <label>Transactions report</label>
          </div>
        </div>
        <div className="row">
          <div className="form-item form-item--switch">
            <Switch
              checked={
                params.defaultOrganization?.dataSubscription?.featureFlags
                  ?.marketOverviewReport?.isActive
              }
              onChange={(checked) =>
                onChange(
                  'defaultOrganization.dataSubscription.featureFlags.marketOverviewReport.isActive',
                  checked,
                )
              }
            />
            <label>Market overview report</label>
          </div>
        </div>
        <h3>Buyers app feature flags:</h3>
        <div className="row">
          <div className="form-item form-item--switch">
            <Switch
              checked={
                params.defaultOrganization?.buyersAppFeatureFlags?.preDeals
                  ?.isActive
              }
              onChange={(checked) =>
                onChange(
                  'defaultOrganization.buyersAppFeatureFlags.preDeals.isActive',
                  checked,
                )
              }
            />
            <label>Pre Deals</label>
          </div>
        </div>
      </div>
      <SaveChangesDrawer
        visible={isSaveChangesVisible}
        saveLoading={false}
        text={
          changedPropertiesCount === 1
            ? `You've changed 1 property`
            : `You've changed ${changedPropertiesCount} properties`
        }
        onSave={onSubmit}
        onRevert={revertChangesHandler}
      />
    </>
  );
};
