import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import ContentHeader from '../../components/ContentHeader';
import { InstitutionalResearch } from './Components/InstitutionalResearch';

const InstitutionalActivityResearchPage = () => {
  useDocumentTitle('Institutional Activity Research Tool');

  return (
    <>
      <ContentHeader title="Institutional Activity Research Tool" />
      <InstitutionalResearch />
    </>
  );
};

export default InstitutionalActivityResearchPage;
