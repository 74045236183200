import { getLatestAskingPriceBySeller } from '@zorba-shared/core/Constants/offer';

export const buildCreateRequest = ({
  drawerParams,
  selectedDealSubmission,
  offerParams,
  newParams,
  userDetails,
}) => ({
  _id: drawerParams._id,
  dealSubmissionId: selectedDealSubmission._id,
  address: selectedDealSubmission.address.formattedAddress,
  status: 'offer_request',
  organization: newParams.organization,
  visible: true,
  offerParams: {
    ...offerParams,
    inspectionDays: newParams.inspectionDays,
    arv: newParams.arv,
    rehabCost: newParams.rehabCost,
    rent: newParams.rent,
    emd: newParams.emd,
    maxAllowableOffer: newParams.maxAllowableOffer,
  },
  event: {
    kind: 'offer_request',
    amount: getLatestAskingPriceBySeller(selectedDealSubmission),
    createdAt: new Date(),
    user: userDetails._id,
    emd: newParams.emd,
    inspectionDays: newParams.inspectionDays,
    inspectionMode: newParams.inspectionMode,
    daysToClose: newParams.daysToClose,
    rawAmount:
      getLatestAskingPriceBySeller(selectedDealSubmission) +
      newParams.feeAmount,
    feeAmount: newParams.feeAmount,
  },
  matchedBuyBox: drawerParams.matchedBuyBox,
  feeAmount: newParams.feeAmount,
});
