import * as types from './types';

const initialState = {
  isLoading: false,
  listings: [],
};

const DashboardReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_LOADING:
    case types.SET_LISTINGS:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default DashboardReducer;
