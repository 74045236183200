export const DEFAULT_FILTERS = {
  status: [],
  query: '',
  powerBuyers: [],
  sellerName: '',
  minActions: undefined,
  areas: [],
};

export const DEAL_SUBMISSION_STATUS_OPTIONS = [
  {
    label: 'Draft',
    value: 'draft',
  },
  {
    label: 'Review',
    value: 'review',
  },
  {
    label: 'Missing Info',
    value: 'missing_info',
  },
  {
    label: 'Revised Offer',
    value: 'revised_offer',
  },
  {
    label: 'Pending Agreement',
    value: 'pending_agreement',
  },
  {
    label: 'Pending Inspection',
    value: 'pending_inspection',
  },
  {
    label: 'Closing',
    value: 'closing',
  },
  {
    label: 'Rejected By UW',
    value: 'rejected_by_uw',
  },
  {
    label: 'Rejected By Seller',
    value: 'rejected_by_seller',
  },
];
