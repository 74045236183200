import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';

import InstructionsComponent from '../InstructionsComponent';
import { ZorbaButton } from '../../../../../../components/forms';
import { BUTTON_VARIANT_LINK } from '../../../../../../components/forms/ZorbaButton/constants';

import './index.scss';

const InstructionsStep = () => (
  <div className="modal-view instructions-modal-view">
    <InstructionsComponent />
    <ZorbaButton
      className="template-download-link"
      icon={<DownloadOutlined />}
      fullWidth={false}
      rounded={false}
      variant={BUTTON_VARIANT_LINK}
      href="https://skiptracing.s3.amazonaws.com/public/skip-trace-template.csv"
      download
    >
      Download Skip Trace Template
    </ZorbaButton>
  </div>
);

export default InstructionsStep;
