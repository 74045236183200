/* eslint-disable default-param-last */
import * as types from './types';

const initialState = {
  isAddingTask: false,
  tasks: [],
  filteredTasks: [],
  isLoading: false,
};
export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_IS_ADDING_TASK:
    case types.SET_TASKS:
    case types.SET_IS_LOADING:
    case types.FILTER_TASKS:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
