/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */

import PropertyDataTab from './Components/PropertyDataTab';
import PropertyDataJobsTab from './Components/PropertyDataJobTab';

export const getTabs = (props) => [
  {
    key: 'propertyData',
    label: 'Property Data',
    children: <PropertyDataTab {...props} />,
  },
  {
    key: 'propertyDataJobs',
    label: 'Property Data Jobs',
    children: <PropertyDataJobsTab {...props} />,
  },
];
