import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { StateResearch } from './Components/StateResearch';

const StateActivityResearchPage = () => {
  useDocumentTitle('State Activity Research Tool');

  return <StateResearch />;
};

export default StateActivityResearchPage;
