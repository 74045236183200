import { useState, useEffect, useMemo } from 'react';
import { Button, Form, Input } from 'antd';
import { FiFilter } from '@react-icons/all-files/fi/FiFilter';
import { SearchOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';

import { useSelector } from 'react-redux';
import { toQueryString, useSearchParams } from '../../../../utils';

import DealSubmissionAdvanceFilter from './Components/DealSubmissionAdvanceFilter';
import DealSubmissionAppliedFilter from './Components/DealSubmissionAppliedFilter';
import { DEFAULT_FILTERS } from './constants';

import './index.scss';

const prefix = (
  <SearchOutlined
    style={{
      fontSize: 20,
      color: '#8A9099',
    }}
  />
);

const SEARCH_THROTLE_DELAY = 500;
const DealSubmissionFilter = ({
  searchCriteria,
  setSearchCriteria,
  powerBuyers,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [form] = Form.useForm();
  const [isAdvanceSearch, setIsAdvanceSearch] = useState(true);
  const { isLoading } = useSelector(
    ({ DealSubmissionReducer }) => DealSubmissionReducer,
  );
  const [selectedAreas, setSelectedAreas] = useState([]);

  /**
   * Read filters from query params and set it as default values
   */
  useEffect(() => {
    const queryParamsToSet = {};
    searchParams.forEach((value, key) => {
      if (key in DEFAULT_FILTERS) {
        if (Array.isArray(searchCriteria[key])) {
          queryParamsToSet[key] = value.split(',');
        } else {
          queryParamsToSet[key] = value;
        }
      }
    });
    setSearchCriteria((oldValue) => ({
      ...oldValue,
      ...queryParamsToSet,
    }));
    form.setFieldsValue(queryParamsToSet);
  }, []);

  /**
   * Keep watching for changes on search criteria changes and set applied filters in current URL's query params
   */

  useEffect(() => {
    const { createdAtRange, areas, ...rest } = searchCriteria || {};

    const queryParams = toQueryString(rest) || {};
    const currentParamsString = window.location.search;

    if (currentParamsString !== queryParams.toString()) {
      setSearchParams(queryParams);
    }
  }, [searchCriteria]);

  const searchQueryHandler = useMemo(
    () =>
      debounce((event) => {
        const query = event.target.value.trim();
        setSearchCriteria((oldValue) => ({
          ...oldValue,
          query,
        }));
      }, SEARCH_THROTLE_DELAY),
    [],
  );

  const toggleAdvancedSearch = () => {
    setIsAdvanceSearch(!isAdvanceSearch);
  };

  const updateSearchCriteria = (newSearchCriteria) => {
    setSearchCriteria((oldValue) => ({
      ...oldValue,
      ...newSearchCriteria,
    }));
  };

  const onAdvanceFilterChange = (updatedSearchCriteria) => {
    updateSearchCriteria(updatedSearchCriteria);
  };
  const onAdvanceSearchClear = (clearedSearchCriteria) => {
    updateSearchCriteria(clearedSearchCriteria);
    form.resetFields();
    setSelectedAreas([]);
  };

  const onRemovedFilter = (filterName) => {
    updateSearchCriteria({
      ...searchCriteria,
      [filterName]: DEFAULT_FILTERS[filterName],
    });

    if (filterName === 'areas') {
      setSelectedAreas([]);
    }
  };

  const onUserAutoCompleteHandler = (newSearchCriteria) => {
    setSearchCriteria((oldValue) => ({
      ...oldValue,
      sellerName: `${newSearchCriteria.firstName} ${newSearchCriteria.lastName}`,
    }));
  };

  return (
    <div className="filter-container">
      <Form layout="horizontal" name="querySearchForm" form={form}>
        <div
          className={`form-field form-field__search ${
            !isAdvanceSearch ? 'mb-0' : ''
          }`}
        >
          <div className="form-field__item">
            <div className="form-field__item__input">
              <Input.Group compact className="search-field">
                <Form.Item name="query">
                  <Input
                    size="large"
                    prefix={prefix}
                    style={{ width: 'calc(100% - 40px)' }}
                    className="search-input"
                    placeholder="Search properties by address"
                    onChange={searchQueryHandler}
                  />
                </Form.Item>
                <Button
                  size="large"
                  icon={<FiFilter />}
                  onClick={toggleAdvancedSearch}
                />
              </Input.Group>
            </div>
          </div>
        </div>
      </Form>
      {isAdvanceSearch && (
        <DealSubmissionAdvanceFilter
          onAdvanceSearchClear={onAdvanceSearchClear}
          onAdvanceFilterChanged={onAdvanceFilterChange}
          searchCriteria={searchCriteria}
          form={form}
          powerBuyers={powerBuyers}
          onUserAutoCompleteHandler={onUserAutoCompleteHandler}
          isLoading={isLoading}
          selectedAreas={selectedAreas}
          setSelectedAreas={setSelectedAreas}
        />
      )}
      <DealSubmissionAppliedFilter
        searchCriteria={searchCriteria}
        onRemovedFilter={onRemovedFilter}
        form={form}
        powerBuyers={powerBuyers}
      />
    </div>
  );
};

export default DealSubmissionFilter;
