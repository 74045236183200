import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { AutoComplete } from 'antd';
import { fetchBuyers } from '../../../reducer/Buyer/actions';
import './index.scss';

const SEARCH_THROTLE_DELAY = 500;

export default ({ onAutoCompleteSelect }) => {
  const [options, setOptions] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState();
  const [inputValue, setInputValue] = useState();
  const applySearch = async () => {
    // TODO: Get options by query
    const results = await fetchBuyers({
      query: searchCriteria.query,
      itemsPerPage: 5,
    });

    setOptions([...results.items]);
  };

  /**
   * keep watching for changes on search criteria changes
   */
  useEffect(() => {
    if (searchCriteria) {
      applySearch();
    }
  }, [searchCriteria]);

  const handleOnAutoCompleteSelect = (event, selectedOption) => {
    if (onAutoCompleteSelect) {
      onAutoCompleteSelect(selectedOption.user);
    }
    setInputValue(
      `${selectedOption.user.firstName} ${selectedOption.user.lastName}`,
    );
    setSearchCriteria(
      `${selectedOption.user.firstName} ${selectedOption.user.lastName}`,
    );
  };

  const searchQueryHandler = useMemo(
    () =>
      _.debounce((queryInputValue = '') => {
        const query = queryInputValue.trim();
        setSearchCriteria((oldValue) => ({
          ...oldValue,
          query,
        }));
      }, SEARCH_THROTLE_DELAY),
    [],
  );

  const mappedOptions = options.map((user) => ({
    key: user._id,
    user,
    value: (
      <div className="user-auto-complete-option">
        <div className="user-name">{`${user.firstName} ${user.lastName}`}</div>
        <div className="company-name">{`Company: ${user.defaultOrganization?.name}`}</div>
      </div>
    ),
  }));

  return (
    <div className="user-auto-complete">
      <AutoComplete
        id="user-auto-complete"
        placeholder="Search user by name/email/phone"
        dropdownMatchSelectWidth={252}
        style={{
          width: 300,
        }}
        options={mappedOptions}
        onChange={(newVal) => {
          if (newVal === null) return;
          setInputValue(newVal);
          searchQueryHandler(newVal);
        }}
        value={inputValue}
        onSelect={handleOnAutoCompleteSelect}
        clearInputOnBlur={false}
        clearInputOnSelect={false}
        // allowClear
        notFoundContent="User does not exist"
      />
    </div>
  );
};
