import { Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import { searchDealSubmissions } from '../../../../reducer/DealSubmission/actions';
import DealSubmissionFilter from '../DealSubmissionFilter';
import DealSubmissionTable from '../DealSubmissionTable';
import { DEFAULT_FILTERS } from '../DealSubmissionFilter/constants';

import { getAllOrganizationPowerBuyers } from '../../../../reducer/User/actions';

import './index.scss';

export default () => {
  const [mounted, setMounted] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState(DEFAULT_FILTERS);
  const dispatch = useDispatch();
  const { total, currentPage, pageSize, lastFetchedCriteria } = useSelector(
    ({ DealSubmissionReducer }) => DealSubmissionReducer,
  );
  const { organizationPowerBuyers } = useSelector(
    ({ UserReducer }) => UserReducer,
  );

  /**
   * call search API on every change in search criteria.
   */
  useEffect(() => {
    if (
      searchCriteria &&
      mounted &&
      !isEqual(
        { ...searchCriteria, page: currentPage, pageSize },
        lastFetchedCriteria,
      )
    ) {
      dispatch(
        searchDealSubmissions({
          ...searchCriteria,
          page: currentPage,
          pageSize,
        }),
      );
    } else {
      setMounted(true);
    }
  }, [searchCriteria, currentPage, mounted, pageSize]);

  useEffect(() => {
    dispatch(getAllOrganizationPowerBuyers());
  }, []);

  const getLabel = (title, totalItems) => (
    <div className="label-container">
      {title}
      <div className="indicator-container">{totalItems}</div>
    </div>
  );

  const tabs = [
    {
      key: 'tab1',
      label: getLabel('All', total),
      children: (
        <>
          <DealSubmissionFilter
            searchCriteria={searchCriteria}
            setSearchCriteria={setSearchCriteria}
            powerBuyers={organizationPowerBuyers}
          />
          <DealSubmissionTable />
        </>
      ),
    },
  ];

  return (
    <div className="tabs-container deal-submission-list">
      <Tabs type="card" items={tabs} />
    </div>
  );
};
