import React, { useState } from 'react';
import { Table, ConfigProvider } from 'antd';

import { ZorbaSelect } from '../../../../../../../../components/forms';
import { FIELD_MAPPING_SELECT_OPTIONS } from '../../constants';

const FieldCard = (props) => {
  const {
    field,
    mappedFieldsTemp,
    records,
    fieldChar,
    setMappedFieldsTemp,
    disableSelect,
    fileFieldStatus,
    children,
  } = props;
  const mappedFieldIndex = Object.values(mappedFieldsTemp).indexOf(field);
  const mappedFieldValue =
    mappedFieldIndex === -1
      ? null
      : Object.keys(mappedFieldsTemp)[mappedFieldIndex];

  const selectedFields = Object.keys(mappedFieldsTemp).filter(
    (selectedField) =>
      !!mappedFieldsTemp[selectedField] &&
      mappedFieldsTemp[selectedField] !== field,
  );

  const availableSelectOptions = FIELD_MAPPING_SELECT_OPTIONS.map((option) => ({
    ...option,
    disabled: selectedFields.includes(option.value),
  }));

  const dataSource = disableSelect
    ? []
    : records
        ?.filter((value, index) => index < 3)
        .map((record, index) => ({
          key: `${index + 1}`,
          [field]: record[field],
        }));

  const columns = [
    {
      title: fieldChar,
      dataIndex: 'key',
      key: 'key',
      width: '0',
      className: 'index-column',
    },
    {
      title: field,
      dataIndex: field,
      key: field,
      render: (text, row, index) => ({
        children: <>{text}</>,
        props: {
          colSpan: 2,
        },
      }),
    },
    {
      title: () => (
        <ZorbaSelect
          className="field-select"
          disabled={disableSelect}
          dropdownClassName="mapping-fields-dropdown"
          bordered={false}
          allowClear
          placeholder="Select matching field"
          options={availableSelectOptions}
          value={mappedFieldValue}
          onClear={() =>
            setMappedFieldsTemp((fields) => ({
              ...fields,
              [mappedFieldValue]: null,
            }))
          }
          onSelect={(value) => {
            props.onSelect && props.onSelect();
            setMappedFieldsTemp((fields) => {
              const newFields = { ...fields, [value]: field };
              for (const key in newFields) {
                if (newFields[key] === field && key !== value)
                  newFields[key] = null;
              }
              return newFields;
            });
          }}
        />
      ),
      render: (text, row, index) => ({
        children: <td>{text}</td>,
        props: {
          colSpan: 0,
        },
      }),
      width: '47%',
    },
  ];

  return (
    <div className={`field-mapping-card mapping-status-${fileFieldStatus}`}>
      <ConfigProvider renderEmpty={() => null}>
        <Table
          className="mapping-fields-table"
          bordered
          columns={columns}
          dataSource={dataSource}
          pagination={false}
        />
      </ConfigProvider>

      <div className="mapping-fields-controls">{children}</div>
    </div>
  );
};

export default FieldCard;
