import { Modal, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { setModalState } from '../../../../reducer/Modal/actions';
import { MODAL_UNSUBSCRIBE_FROM_SMS_CAMPAIGNS } from '../../../../reducer/Modal/types';

import { ZorbaButton } from '../../../../components/forms';
import { BUTTON_VARIANT_DARK } from '../../../../components/forms/ZorbaButton/constants';

import { bulkUnsubscribeUsersFromSMSCampaigns } from '../../../../reducer/Buyer/actions';

import './index.scss';

export const UnsubscribeFromSMSModal = () => {
  const dispatch = useDispatch();
  const { isUnsubscribeFromSMSCampaignsModalOpen } = useSelector(
    ({ ModalReducer }) => ModalReducer,
  );
  const { confirm } = Modal;

  const [textAreaValue, setTextAreaValue] = useState('');

  const handleClose = () => {
    dispatch(setModalState(MODAL_UNSUBSCRIBE_FROM_SMS_CAMPAIGNS, false));
  };

  const handleUnsubscribe = () => {
    const formattedNumbers = textAreaValue
      .split('\n')
      .map((number) => number.replace(/[\s()-]/g, '').trim())
      .filter(Boolean);

    confirm({
      content: `Do you want to unsubscribe ${formattedNumbers?.length} user${
        formattedNumbers.length > 1 ? `'s` : ''
      } from SMS campaigns in CIO?`,
      okText: 'Unsubscribe',
      confirmLoading: false,
      onOk() {
        dispatch(
          bulkUnsubscribeUsersFromSMSCampaigns(formattedNumbers, handleClose),
        );
      },
    });
  };

  return (
    <Modal
      open={isUnsubscribeFromSMSCampaignsModalOpen}
      footer={null}
      onCancel={handleClose}
      title="Unsubscribe from SMS campaigns"
      destroyOnClose
      className="unsubscribe-modal"
    >
      <Input.TextArea
        placeholder={`(954) 608-1716
(904) 894-4954 
(602) 833-0702
(877) 504-6637
(214) 902-3833
`}
        onChange={(e) => setTextAreaValue(e.target.value)}
        className="text-area-container"
      />
      <div className="button-container">
        <ZorbaButton
          variant={BUTTON_VARIANT_DARK}
          fullWidth
          onClick={handleUnsubscribe}
          disabled={!textAreaValue}
        >
          Unsubscribe
        </ZorbaButton>
      </div>
    </Modal>
  );
};
