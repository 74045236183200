import { getFriendlyTime } from '@zorba-shared/core/Services/textService';
import { Link } from 'react-router-dom';
import { buildBuyerPageUrl } from '../../../../services/urlBuilderService';

export const generateStats = ({ selectedPreDeal }) => [
  {
    label: 'Created',
    value: getFriendlyTime(selectedPreDeal.createdAt),
  },
  {
    label: 'Submitted by',
    value: (
      <Link
        target="_blank"
        to={buildBuyerPageUrl({
          userDetails: selectedPreDeal?.submittedBy,
        })}
      >
        {`${selectedPreDeal?.submittedBy?.name} (${selectedPreDeal?.submittedBy?.email})`}
      </Link>
    ),
  },
];
