import './index.scss';
import { BiArrowBack } from '@react-icons/all-files/bi/BiArrowBack';
import { v4 as uuid } from 'uuid';
import { Radio, Space } from 'antd';
import { useState, useCallback } from 'react';
import {
  OPERATOR_TITLE_MAPPING,
  VALUE_OPERATOR_MAPPING,
} from '@zorba-shared/core/Constants/propertyData';
import { ZorbaButton } from '../../../../../../components/forms';
import FilterOperatorValue from '../FilterOperatorValue';
import { BUTTON_VARIANT_DARK } from '../../../../../../components/forms/ZorbaButton/constants';

const AddFilter = (props) => {
  const { selectedFilter, goBack, onFilterUpdated } = props;
  const [filter, setFilter] = useState(selectedFilter);
  const [selectedOperator, setSelectedOperator] = useState(null);

  const handleOperatorValue = useCallback(
    (value) => {
      setFilter({ ...filter, value, operator: selectedOperator });
    },
    [selectedOperator],
  );

  const handleOperatorChange = useCallback(
    (value) => {
      setSelectedOperator(value);
      setFilter({ ...filter, operator: value });
    },
    [selectedOperator],
  );

  if (!selectedFilter) return '';
  return (
    <div className="add-filter">
      <ZorbaButton
        fullWidth={false}
        onClick={() => goBack()}
        icon={<BiArrowBack />}
        variant={BUTTON_VARIANT_DARK}
      >
        back
      </ZorbaButton>

      <div className="operators">
        <div className="operator-title">{selectedFilter?.title}</div>
        <Radio.Group
          onChange={(event) => handleOperatorChange(event.target.value)}
          value={selectedOperator}
        >
          <Space direction="vertical">
            {VALUE_OPERATOR_MAPPING[selectedFilter.valueType].map(
              (operator) => (
                <Radio value={operator} key={uuid()}>
                  {OPERATOR_TITLE_MAPPING[operator]}
                  <FilterOperatorValue
                    initialValue={filter.value}
                    operator={operator}
                    selectedOperator={selectedOperator}
                    valueType={filter.valueType}
                    onChange={handleOperatorValue}
                  />
                </Radio>
              ),
            )}
          </Space>
        </Radio.Group>
      </div>

      <div className="footer-buttons">
        <ZorbaButton
          fullWidth
          variant={BUTTON_VARIANT_DARK}
          onClick={() => onFilterUpdated(filter)}
        >
          Save
        </ZorbaButton>
      </div>
    </div>
  );
};

export default AddFilter;
