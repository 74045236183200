export const getMonthlyHoaFee = (dealSubmission) => {
  if (!dealSubmission.userData.hoaPeriod || !dealSubmission.userData.hoaFee) {
    return '';
  }
  if (dealSubmission.userData.hoaPeriod === 'month') {
    return dealSubmission.userData.hoaFee;
  }
  if (dealSubmission.userData.hoaPeriod === 'quarter') {
    return parseInt(dealSubmission.userData.hoaFee / 3);
  }
  if (dealSubmission.userData.hoaPeriod === 'annual') {
    return parseInt(dealSubmission.userData.hoaFee / 12);
  }

  return '';
};