import './index.scss';

import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Form } from 'antd';
import React, { useEffect, useState, useMemo } from 'react';
import { debounce } from 'lodash';
import { FiFilter } from '@react-icons/all-files/fi/FiFilter';
import { toQueryString, useSearchParams } from '../../../../utils';
import { BuyerAppliedFilter, BuyerAdvanceFilter } from './Components';

const SEARCH_THROTLE_DELAY = 500;

const BuyerFilter = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isAdvanceSearch, setIsAdvanceSearch] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState();
  const [removedFilter, setRemovedFilter] = useState(null);
  const [appliedFilters, setAppliedFilters] = useState(null);
  const [form] = Form.useForm();

  const prefix = (
    <SearchOutlined
      style={{
        fontSize: 20,
        color: '#8A9099',
      }}
    />
  );

  /**
   * keep watching for changes on search criteria changes
   */
  useEffect(() => {
    if (searchCriteria) {
      applySearch();
    }
  }, [searchCriteria]);

  /**
   * set applied filters if available in url
   */
  useEffect(() => {
    const queryParams = {};
    for (const entry of searchParams.entries()) {
      const [param, value] = entry;
      queryParams[param] = value;
    }
    if (Object.keys(queryParams).length > 0) {
      if (queryParams.query) {
        form.setFieldsValue({ query: queryParams.query });
      }

      if (queryParams.areas) {
        queryParams.areas = queryParams.areas.split(',');
      }

      setAppliedFilters(queryParams);
    }
    setSearchCriteria((oldValue) => ({
      ...oldValue,
      ...queryParams,
    }));
  }, ['']);

  /**
   * set applied filters in current URL's query params
   * trigger onSearchChange to make a API request
   * @see BuyerList
   */
  const applySearch = () => {
    const queryParams = toQueryString(searchCriteria) || {};
    setSearchParams(queryParams);
    if (props.onSearchChange) {
      props.onSearchChange(searchCriteria);
    }
  };

  const searchQueryHandler = useMemo(
    () =>
      debounce((event) => {
        const query = event.target.value.trim();
        setSearchCriteria((oldValue) => ({
          ...oldValue,
          query,
        }));
      }, SEARCH_THROTLE_DELAY),
    [],
  );

  const toggleAdvancedSearch = () => {
    setIsAdvanceSearch(!isAdvanceSearch);
  };

  const onAdvanceFilterChange = (searchCriteria) => {
    updateSearchCriteria(searchCriteria);
  };
  const onAdvanceSearchClear = (searchCriteria) => {
    updateSearchCriteria(searchCriteria);
    form.resetFields();
  };
  /**
   * Remove filter from search criteria
   * Reset from Input field
   * Set removedFilter to remove it from BuyerAdvanceFilter
   * @param {*} filter Filter oBject to be removed
   *
   * @see BuyerAdvanceFilter
   * @see BuyerAppliedFilter
   */
  const onRemovedFilter = (filter) => {
    let criteria;
    if (filter.type === 'areas') {
      const placeIndex = searchCriteria.areas.indexOf(filter.key);
      if (placeIndex >= 0) {
        searchCriteria.areas.splice(placeIndex, 1);
      }
      criteria = { ...searchCriteria };
    } else {
      criteria = { ...searchCriteria, [filter.key]: '' };
    }

    updateSearchCriteria(criteria);
    if (filter.key === 'query') {
      form.resetFields(['query']);
    }
    setRemovedFilter(filter);
  };

  const updateSearchCriteria = (newSearchCriteria) => {
    setSearchCriteria((oldValue) => ({
      ...oldValue,
      ...newSearchCriteria,
    }));
  };

  const onFinish = (values) => {};

  return (
    <div className="filter-container">
      <Form
        layout="horizontal"
        name="querySearchForm"
        form={form}
        onFinish={onFinish}
      >
        <div
          className={`form-field form-field__search ${
            !isAdvanceSearch ? 'mb-0' : ''
          }`}
        >
          <div className="form-field__item">
            <div className="form-field__item__input">
              <Input.Group compact className="search-field">
                <Form.Item name="query">
                  <Input
                    size="large"
                    prefix={prefix}
                    style={{ width: 'calc(100% - 40px)' }}
                    className="search-input"
                    placeholder="Search buyers by name, email, phone number, or company name"
                    onChange={searchQueryHandler}
                  />
                </Form.Item>
                <Button
                  size="large"
                  icon={<FiFilter />}
                  onClick={toggleAdvancedSearch}
                />
              </Input.Group>
            </div>
          </div>
        </div>
      </Form>

      {isAdvanceSearch && (
        <BuyerAdvanceFilter
          onAdvanceSearchClear={onAdvanceSearchClear}
          onAdvanceFilterChanged={onAdvanceFilterChange}
          removedFilter={removedFilter}
          appliedFilters={appliedFilters}
        />
      )}
      <BuyerAppliedFilter
        searchCriteria={searchCriteria}
        onRemovedFilter={onRemovedFilter}
      />
    </div>
  );
};

export default BuyerFilter;
