import React from 'react';

import './index.scss';

import { BASE_URL } from '../../../../../../services/clientService';
import { Spinner } from '../../../../../../components/Spinner';
import { ZorbaButton } from '../../../../../../components/forms';

const JobWizardFooter = (props) => {
  const {
    currentStepData,
    job,
    onNextClick,
    onBackClick,
    disabled = false,
    secondaryDisabled = false,
    placement,
    loading,
    setLoading,
    checkoutParams,
  } = props;

  if (!currentStepData) return null;

  const getCheckoutWrapper = () =>
    !checkoutParams ? null : (
      <form
        method="POST"
        disabled={loading}
        onClick={() => {
          setTimeout(() => {
            setLoading(true);
          }, 500);
          return true;
        }}
        action={`${BASE_URL}/billing/create-checkout-session?jobId=${job._id}`}
      >
        <button
          disabled={loading}
          className={`ant-btn ant-btn-submit skiptracing-button custom-pay-btn full-width ${
            loading && 'loading'
          }`}
          type="submit"
        >
          <span>
            {loading && <Spinner />}
            Pay
          </span>
        </button>
      </form>
    );

  const nextButton = (
    <ZorbaButton
      fullWidth={false}
      onClick={onNextClick}
      className="continue-btn"
      disabled={disabled}
      loading={loading}
    >
      {currentStepData.nextButtonText}
    </ZorbaButton>
  );
  if (props.setLoading && props.loading && currentStepData.key !== 'checkout')
    return null;
  return (
    <div
      className={`job-wizard-modal-buttons${
        placement ? ` placement-${placement}` : ''
      }`}
    >
      {!loading && currentStepData.backButtonText && (
        <ZorbaButton
          onClick={onBackClick}
          fullWidth={false}
          // className={`secondary-btn`}
          disabled={secondaryDisabled}
        >
          {currentStepData.backButtonText}
        </ZorbaButton>
      )}
      {currentStepData.nextButtonText && currentStepData.key === 'checkout'
        ? getCheckoutWrapper(nextButton)
        : nextButton}
      {/* )} */}
    </div>
  );
};

export default JobWizardFooter;
