import { BiImport } from '@react-icons/all-files/bi/BiImport';
import { BiFilterAlt } from '@react-icons/all-files/bi/BiFilterAlt';
import './index.scss';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import { ZorbaButton } from '../../../../components/forms';
import { BUTTON_VARIANT_DARK } from '../../../../components/forms/ZorbaButton/constants';

export default ({
  setIsImportModalOpen,
  setIsFilterDrawerOpen,
  onClearFilter,
}) => {
  const { appliedFilters } = useSelector(
    ({ PropertyDataReducer }) => PropertyDataReducer,
  );

  return (
    <div className="property-data-actions">
      <button
        className="action-btn"
        onClick={() => setIsImportModalOpen(true)}
        type="button"
      >
        <BiImport />
        Import list
      </button>

      <div className="btns-property-advance-filter">
        <ZorbaButton
          variant={BUTTON_VARIANT_DARK}
          onClick={() => setIsFilterDrawerOpen(true)}
          icon={<BiFilterAlt />}
        >
          Filters{' '}
          {appliedFilters.length > 0 && <span>({appliedFilters.length})</span>}
        </ZorbaButton>
        {appliedFilters.length > 0 && (
          <Button type="link" onClick={onClearFilter} className="btn-clear">
            Clear All
          </Button>
        )}
      </div>
    </div>
  );
};
