import './index.scss';

import { useEffect, useState } from 'react';
import { ZorbaButton, ZorbaSelect } from '../../../../components/forms';
import { BUTTON_VARIANT_DARK } from '../../../../components/forms/ZorbaButton/constants';

const MatchingBuyerFilter = ({ buyers, setIsFilterDrawerOpen, onFilter }) => {
  const [buyerOptions, setBuyerOptions] = useState([]);
  const [selectedBuyers, setSelectedBuyers] = useState([]);

  const handleApplyFilter = () => {
    setIsFilterDrawerOpen(false);
    onFilter(selectedBuyers);
  };
  const handleCancel = () => {
    setIsFilterDrawerOpen(false);
  };

  useEffect(() => {
    if (buyers) {
      const options = buyers.map((buyer) => ({
        text: buyer.name,
        value: buyer.buyer,
      }));
      setBuyerOptions(options);
    }
  }, [buyers]);

  return (
    <div className="matching-buyer-filter">
      <ZorbaSelect
        label="Select Matching Buyers"
        mode="multiple"
        placeholder="Kobi, Or ..."
        options={buyerOptions}
        onChange={(values) => setSelectedBuyers(values)}
        value={selectedBuyers}
        allowClear
      />
      <div className="drawer-footer-buttons">
        <ZorbaButton
          fullWidth={false}
          variant={BUTTON_VARIANT_DARK}
          onClick={handleApplyFilter}
        >
          Apply
        </ZorbaButton>
        <ZorbaButton
          fullWidth={false}
          variant={BUTTON_VARIANT_DARK}
          onClick={handleCancel}
        >
          Cancel
        </ZorbaButton>
      </div>
    </div>
  );
};

export default MatchingBuyerFilter;
