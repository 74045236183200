import { BuyBoxCheckerTab } from './Components/BuyBoxCheckerTab';
import { MatchesTab } from './Components/MatchesTab';

export const getTabs = (dealSubmission, isFetchingDealSubmission) => [
  {
    key: 'matches',
    label: 'Matches',
    children: (
      <MatchesTab
        dealSubmission={dealSubmission}
        isFetchingDealSubmission={isFetchingDealSubmission}
      />
    ),
  },
  {
    key: 'buybox-checker',
    label: 'BuyBox Checker',
    children: (
      <BuyBoxCheckerTab
        dealSubmission={dealSubmission}
        isFetchingDealSubmission={isFetchingDealSubmission}
      />
    ),
  },
];
