import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';
import { MdArrowBack } from '@react-icons/all-files/md/MdArrowBack';
import { Card, Input } from 'antd';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { getReportDataByOrganizationName } from '../../reducer/BuyersReport/actions';
import { BUTTON_VARIANT_LIGHT } from '../../components/forms/ZorbaButton/constants';
import { ZorbaButton, ZorbaSelect } from '../../components/forms';
import { setModalState } from '../../reducer/Modal/actions';
import { MODAL_CREATE_ENTITY } from '../../reducer/Modal/types';
import { CreateEntityModal } from './Components/CreateEntityModal';
import { EntitiesTable } from './Components/EntitiesTable';
import { AcquiredPropertiesTable } from './Components/AcquiredPropertiesTable';
import { MatchingPropertiesTable } from './Components/MatchingPropertiesTable';
import { daysOptions } from '../BuyersReportPage/constants';
import { postRequest } from '../../services/requestService';
import { showSuccessToast } from '../../services/notificationsService';

import './index.scss';

const BuyerReportPage = ({ match }) => {
  useDocumentTitle(`Buyer report`);
  const dispatch = useDispatch();
  const { postgresId, buyerName } = match.params;
  const [selectedDays, setSelectedDays] = useState(30);
  const [organizationParams, setOrganizationParams] = useState({});

  const { selectedOrganizationData, isLoading } = useSelector(
    ({ BuyersReportReducer }) => BuyersReportReducer,
  );

  useEffect(() => {
    setOrganizationParams(selectedOrganizationData?.organization);
  }, [selectedOrganizationData?.organization]);

  function findDifferences(initialObj, updatedObj) {
    if (!initialObj || !updatedObj) return {};
    // Create an empty object to store the differences
    const differences = {};

    // Check each key in the updated object
    Object.keys(updatedObj).forEach((key) => {
      // Use lodash's isEqual to compare values for the same key in both objects
      if (!_.isEqual(initialObj[key], updatedObj[key])) {
        // If they are not equal, add to differences object
        differences[key] = updatedObj[key];
      }
    });

    return differences;
  }

  const diffOrganizationParams = findDifferences(
    selectedOrganizationData?.organization,
    organizationParams,
  );

  useEffect(() => {
    if (postgresId) {
      dispatch(
        getReportDataByOrganizationName({
          daysSince: selectedDays,
          postgresId,
        }),
      );
    }
  }, [selectedDays, postgresId]);

  const handleOpenCreateEntityModal = () => {
    dispatch(setModalState(MODAL_CREATE_ENTITY, true));
  };
  const onOrganizationParamsChange = (key, value) => {
    setOrganizationParams({ ...organizationParams, [key]: value });
  };

  const onSaveOrganizationParams = async () => {
    if (!diffOrganizationParams || !Object.keys(diffOrganizationParams).length)
      return;

    await postRequest('/backoffice/top-buyers-report/update-organization', {
      ...diffOrganizationParams,
      organizationId: postgresId,
    });
    showSuccessToast(`${buyerName} was updated succesfully 🎉`);
  };

  return (
    <div className="buyer-report-page">
      <div className="buyers-report-filter">
        <div className="back-container">
          <Link
            className="back-btn"
            to={
              selectedOrganizationData?.mongoId
                ? '/buyers-report'
                : '/buyer-activity-report'
            }
          >
            <MdArrowBack size={35} />
          </Link>
          <h1>{buyerName}</h1>
        </div>
        <ZorbaSelect
          options={daysOptions}
          value={selectedDays}
          onChange={(e) => setSelectedDays(e)}
          className="buyers-report-select"
          disabled={isLoading}
        />
      </div>
      <Card>
        <div className="company-info">
          <div className="form-params">
            <div className="form-item">
              <Input
                placeholder="Linkedin page URL"
                value={organizationParams?.linkedin_page}
                onChange={(e) =>
                  onOrganizationParamsChange('linkedin_page', e.target.value)
                }
              />
            </div>
            <div className="form-item">
              <Input
                placeholder="Website URL"
                value={organizationParams?.website_url}
                onChange={(e) =>
                  onOrganizationParamsChange('website_url', e.target.value)
                }
              />
            </div>

            <ZorbaSelect
              width={300}
              placeholder="Company type"
              onChange={(e) => onOrganizationParamsChange('company_type', e)}
              options={[
                { label: 'Buyer', value: 'buyer' },
                { label: 'Lender', value: 'lender' },
                { label: 'Builder', value: 'builder' },
                { label: 'Bank', value: 'bank' },
                { label: 'Title company', value: 'title_company' },
                { label: 'Blacklisted', value: 'blacklisted' },
                { label: 'Government entity', value: 'government_entity' },
              ]}
              value={organizationParams?.company_type}
            />
          </div>

          <ZorbaButton
            disabled={!Object.keys(diffOrganizationParams || {}).length}
            fullWidth={false}
            onClick={() => onSaveOrganizationParams()}
          >
            Save
          </ZorbaButton>
        </div>
      </Card>

      {!isLoading && selectedOrganizationData?.mongoId && (
        <div className="stats">
          <Card>
            <h3>Matching deals</h3>
            <h1>{selectedOrganizationData?.requestedOffers}</h1>
          </Card>
          <Card>
            <h3>Offers they placed</h3>
            <h1>{selectedOrganizationData?.placedOffers}</h1>
          </Card>
          <Card>
            <h3>Offers submitted</h3>
            <h1>{selectedOrganizationData?.offersSubmittedToTheSeller}</h1>
          </Card>
          <Card>
            <h3>Rejected deals</h3>
            <h1>{selectedOrganizationData?.rejectedDeals}</h1>
          </Card>
          <Card>
            <h3>Did not respond</h3>
            <h1>{selectedOrganizationData?.didNotRespond}</h1>
          </Card>
        </div>
      )}
      {selectedOrganizationData?.mongoId && (
        <Card
          title={`MATCHING DEALS (${
            selectedOrganizationData?.matchingProperties?.length || 0
          })`}
        >
          <MatchingPropertiesTable />
        </Card>
      )}

      <div />
      <Card
        title={`ACQUIRED PROPERTIES BASED ON CHERRE (${
          selectedOrganizationData?.acquiredProperties?.length || 0
        })`}
      >
        <AcquiredPropertiesTable />
      </Card>
      <Card
        title={`ENTITIES (${selectedOrganizationData?.entities?.length || 0})`}
      >
        <div className="add-entity-container">
          <ZorbaButton
            fullWidth={false}
            variant={BUTTON_VARIANT_LIGHT}
            onClick={handleOpenCreateEntityModal}
            disabled={isLoading}
          >
            <FaPlus />
          </ZorbaButton>
        </div>
        <EntitiesTable postgresId={postgresId} />
      </Card>
      <CreateEntityModal postgresId={postgresId} />
    </div>
  );
};

export default BuyerReportPage;
