import { useDispatch, useSelector } from 'react-redux';
import { Space, Spin } from 'antd';
import {
  PhoneOutlined,
  RedEnvelopeOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';

import './index.scss';
import ZorbaConfirmButton from '../../../../../../components/forms/ZorbaConfirmButton';
import {
  setPhoneVerified,
  setEmailVerified,
} from '../../../../../../reducer/Buyer/actions';

export default (props) => {
  const { isLoading } = useSelector(({ BuyerReducer }) => BuyerReducer);

  const { selectedBuyer } = props;

  const dispatch = useDispatch();

  const setStatus = (status, type) => {
    if (type === 'phoneNumber') {
      dispatch(setPhoneVerified({ status, userId: selectedBuyer._id }));
    } else if (type === 'email') {
      dispatch(setEmailVerified({ status, userId: selectedBuyer._id }));
    }
  };

  if (!selectedBuyer) return null;

  const isPhoneNumberVerified = selectedBuyer.phoneNumber.verified;
  const { isEmailVerified } = selectedBuyer;

  return (
    <Spin spinning={isLoading}>
      <div className="buyer-verification">
        <div
          className={`buyer-verification--item ${
            isPhoneNumberVerified ? 'verified' : ''
          }`}
        >
          <Space>
            <PhoneOutlined />
            <Space>
              {isPhoneNumberVerified
                ? 'Phone Number is Verified'
                : 'Phone number is not verified'}
              {isPhoneNumberVerified ? (
                <CheckCircleOutlined />
              ) : (
                <ZorbaConfirmButton
                  confirmTitle="Are you sure you want to verify Phone Number?"
                  type="link"
                  onConfirm={() => setStatus(true, 'phoneNumber')}
                >
                  Verify
                </ZorbaConfirmButton>
              )}
            </Space>
          </Space>
        </div>
        <div
          className={`buyer-verification--item ${
            isEmailVerified ? 'verified' : ''
          }`}
        >
          <Space>
            <RedEnvelopeOutlined />
            <Space>
              {isEmailVerified ? 'Email is Verified' : 'Email is not verified'}
              {isEmailVerified ? (
                <CheckCircleOutlined />
              ) : (
                <ZorbaConfirmButton
                  type="link"
                  onConfirm={() => setStatus(true, 'email')}
                  confirmTitle="Are you sure you want to verify Email?"
                >
                  Verify
                </ZorbaConfirmButton>
              )}
            </Space>
          </Space>
        </div>
      </div>
    </Spin>
  );
};
