import { getRequest } from './requestService';

export const getPlacesAutoComplete = async (
  query,
  allowFullAddress = false,
) => {
  if (typeof query !== 'string') return;
  let placesAutoComplete = [];
  try {
    const res = await getRequest(
      `/vendors/get-places-autocomplete?q=${query}${
        allowFullAddress ? '&full_address=true' : ''
      }`,
    );
    if (res?.data) {
      placesAutoComplete = res.data;
    }
  } catch (e) {}

  return placesAutoComplete;
};

export const getPlaceById = async (placeId) => {
  let selectedPlace;
  try {
    const res = await getRequest(`/vendors/get-place-by-id?placeId=${placeId}`);
    if (res?.data) {
      selectedPlace = res.data;
    }
  } catch (e) {}

  return selectedPlace;
};

export const getPlaceFirstName = (selectedPlace) => {
  if (!selectedPlace?.data) return '';
  const { sublocality, locality, county, state } = selectedPlace;

  return sublocality || locality || county || state;
};

export const getPlaceAutoCompleteType = (autoCompleteResult) => {
  if (!autoCompleteResult?.types || autoCompleteResult?.types.length === 0)
    return '';

  const type = autoCompleteResult.types[0];
  let result = '';
  switch (type) {
    case 'route':
      result = 'Street';
      break;
    case 'sublocality_level_1':
    case 'sublocality':
    case 'neighborhood':
      result = 'Neighborhood';
      break;
    case 'locality':
      result = 'City';
      break;
    case 'administrative_area_level_1':
      result = 'State';
      break;
    case 'administrative_area_level_2':
      result = 'County';
      break;
    case 'postal_code':
      result = 'Zipcode';
      break;
    default:
      break;
  }

  return result;
};
