import * as types from './types';

const initialState = {
  owners: [],
  isLoading: false,

  organizationPowerBuyers: [],
  isFetchingOrganizationPowerBuyers: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.SET_IS_LOADING:
    case types.SET_OWNERS:
    case types.SET_IS_FETCHING_ORGANIZATION_POWER_BUYERS:
    case types.SET_ORGANIZATION_POWER_BUYERS:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
