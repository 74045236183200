import * as types from './types';

const initialState = {
  modalParams: {},
  [types.MODAL_CREATE_ORGANIZATION]: false,
  [types.MODAL_CREATE_USER]: false,
  [types.MODAL_IMPORT_DATA]: false,
  [types.MODAL_REJECT_BUYER]: false,
  [types.MODAL_SUBMIT_OFFER]: false,
  [types.MODAL_REMOVE_BUYER_EVENT]: false,
  [types.MODAL_UNSAVED_CHANGES]: false,
  [types.MODAL_UNSUBSCRIBE_FROM_SMS_CAMPAIGNS]: false,
  [types.MODAL_COUNTER_OFFER]: false,
  [types.MODAL_ACCEPT_OFFER]: false,
  [types.MODAL_CREATE_ENTITY]: false,
  [types.MODAL_CREATE_ORGANIZATION_REPORT]: false,
  [types.MODAL_REPLACE_DEAL_SUBMISSION]: false,
  [types.MODAL_UPDATE_ASKING_PRICE]: false,
  [types.MODAL_DUPLICATED_DEAL]: false,
  [types.MODAL_ENTITY_SUCCESS_MAPPING]: false,
  [types.MODAL_REASSIGN_REFERRAL]: false,
  [types.MODAL_PRE_DEAL_FINAL_OFFER]: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.SET_MODAL_STATE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
