import { MapComponent } from './Components/Map';
import ColorLegend from './Components/Map/Components/ColorLegend';

import './index.scss';

export const StateResearch = () => (
  <div className="state-research-page">
    <div className="map-list-container">
      <div className="map-container">
        <MapComponent />
      </div>
      <ColorLegend />
    </div>
  </div>
);
