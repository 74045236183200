import { Modal, Radio } from 'antd';
import { ZorbaButton, ZorbaInput } from '../../../../../components/forms';
import { BUTTON_VARIANT_DARK } from '../../../../../components/forms/ZorbaButton/constants';

import '../index.scss';

export const DownloadCsVModal = ({
  isDownloadCSVModalOpened,
  setIsDownloadCSVModalOpened,
  exportParams,
  onChange,
  isExportValid,
  onSubmit,
}) => (
  <Modal
    title="Download CSV"
    centered
    open={isDownloadCSVModalOpened}
    footer={null}
    closable
    onCancel={() => setIsDownloadCSVModalOpened(false)}
    destroyOnClose
  >
    <div className="download-csv-modal-container">
      <ZorbaInput
        label="Name of the file"
        onChange={(e) => onChange('fileName', e.target.value)}
        value={exportParams.fileName}
      />
      <span>Select communication channel:</span>
      <Radio.Group
        options={[
          { label: 'Text', value: 'text' },
          { label: 'Cold Call', value: 'coldCall' },
        ]}
        onChange={(e) => onChange('communicationChannel', e.target.value)}
        value={exportParams.communicationChannel}
        direction="column"
      />

      <span>Export format</span>
      <Radio.Group
        options={[
          { label: 'Horizontal', value: 'horizontal' },
          { label: 'Vertical', value: 'vertical' },
        ]}
        onChange={(e) => onChange('fileDirection', e.target.value)}
        value={exportParams.fileDirection}
        direction="column"
      />
      <div className="download-button-container">
        <ZorbaButton
          variant={BUTTON_VARIANT_DARK}
          onClick={onSubmit}
          fullWidth={false}
          disabled={!isExportValid}
        >
          Download
        </ZorbaButton>
      </div>
    </div>
  </Modal>
);
