import Cookies from 'js-cookie';

import { QUERY_PARAM_REDIRECT_URL } from './authService';
import { getQueryParam } from './clientService';

const DEFAULT_BACKOFFICE_DOMAIN = 'https://backoffice.getzorba.com/';
const BACKOFFICE_DOMAIN_BY_ENV = {
  development: 'https://backoffice.getzorba.com:3002/',
  staging: 'https://stgbackoffice.getzorba.com/',
  production: DEFAULT_BACKOFFICE_DOMAIN,
};

const getBackofficeUrl = (isAbsolute) => {
  if (!isAbsolute) return '/';
  return (
    BACKOFFICE_DOMAIN_BY_ENV[process.env.NODE_ENV] || DEFAULT_BACKOFFICE_DOMAIN
  );
};
export const buildAuthPageUrl = ({ isAbsolute = false } = {}) =>
  `${getBackofficeUrl(isAbsolute)}auth/login`;

export const buildHomePageUrl = () => '/';

export const buildBuyerPageUrl = ({ userDetails, queryString = '' }) =>
  userDetails
    ? `/buyers/${userDetails._id}${queryString ? `?${queryString}` : ''}`
    : `/buyers${queryString ? `?${queryString}` : ''}`;
export const buildPropertyPageUrl = ({ property, queryString = '' }) =>
  property
    ? `/properties/${property._id}${queryString ? `?${queryString}` : ''}`
    : `/properties${queryString ? `?${queryString}` : ''}`;

export const buildDealSubmissionPageUrl = ({
  dealSubmission,
  queryString = '',
}) =>
  dealSubmission
    ? `/deal-submission/${dealSubmission._id}${
        queryString ? `?${queryString}` : ''
      }`
    : `/deal-submission${queryString ? `?${queryString}` : ''}`;

export const buildPreDealPageUrl = ({ preDeal, queryString = '' }) =>
  preDeal
    ? `/pre-deal/${preDeal._id}${queryString ? `?${queryString}` : ''}`
    : `/pre-deal${queryString ? `?${queryString}` : ''}`;

export const removeQueryParam = (url, parameter) => {
  // prefer to use l.search if you have a location/link object
  const urlparts = url.split('?');
  if (urlparts.length >= 2) {
    const prefix = `${encodeURIComponent(parameter)}=`;
    const pars = urlparts[1].split(/[&;]/g);

    // reverse iteration as may be destructive
    for (let i = pars.length; (i -= 1 > 0); ) {
      // idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    return urlparts[0] + (pars.length > 0 ? `?${pars.join('&')}` : '');
  }
  return url;
};

export const getRedirectUrl = () => {
  let result = getQueryParam(QUERY_PARAM_REDIRECT_URL);
  if (!result) {
    result = Cookies.get(QUERY_PARAM_REDIRECT_URL);
  }
  Cookies.remove(QUERY_PARAM_REDIRECT_URL);
  return result;
};
