import classNames from 'classnames';
import './index.scss';
import { AiFillCheckCircle } from '@react-icons/all-files/ai/AiFillCheckCircle';
import { AiFillCloseCircle } from '@react-icons/all-files/ai/AiFillCloseCircle';

export default (props) => {
  const { stats, className, selectedDealSubmission } = props;
  const floodZoneTitle = () =>
    selectedDealSubmission?.lastCalculationParams?.floodZone ? (
      <div className="flood-zone-info">
        <AiFillCheckCircle
          style={{ color: '#49e387', marginRight: 5, fontSize: 35 }}
        />
        <div>Flood Zone Data</div>
      </div>
    ) : (
      <div className="flood-zone-info">
        <AiFillCloseCircle
          style={{ color: '#fc6d6d', marginRight: 5, fontSize: 35 }}
        />
        <div>Flood Zone Data</div>
      </div>
    );

  return (
    <>
      <div
        className={classNames('stats', {
          [className]: className,
        })}
      >
        {stats &&
          stats.map((stat) => (
            <div className="stats__item" key={stat.label}>
              <div className="stats__item--label">{stat.label}</div>
              <div className="stats__item--value">
                {stat.value != null ? stat.value : 'Unknown'}
              </div>
            </div>
          ))}
      </div>
      {selectedDealSubmission?.zillowData?.hdpUrl && (
        <a
          href={`https://www.zillow.com${selectedDealSubmission.zillowData.hdpUrl}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          View on Zillow
        </a>
      )}
      {floodZoneTitle()}
    </>
  );
};
