import { buildBuyerPageUrl } from '@zorba-shared/core/Services/urlBuilderService';
import { Table, Tag } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  capitalizeFirstLetter,
  getDaysSince,
} from '@zorba-shared/core/Services/textService';
import { Link } from 'react-router-dom';
import './index.scss';
import { getRequest, postRequest } from '../../services/requestService';
import { formatPhoneNumber } from '../../services/textService';
import { ZorbaButton } from '../../components/forms';
import { BUTTON_VARIANT_LINK } from '../../components/forms/ZorbaButton/constants';
import { updateBuyerStatus } from '../../reducer/Buyer/actions';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import {
  showErrorToast,
  showSuccessToast,
} from '../../services/notificationsService';
/**
 * This page shows the information of each property/listing in our database with actions we can take like editing its attributes or other admin stuff
 */
export default () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useDocumentTitle(`Partner approval`);
  const dispatch = useDispatch();

  const loadPartners = async () => {
    setIsLoading(true);
    const res = await getRequest('/backoffice/user/pending-approval/list');
    setIsLoading(false);
    setUsers(res?.data?.users);
  };

  useEffect(() => {
    loadPartners();
  }, []);

  const onApprove = async (user) => {
    dispatch(
      updateBuyerStatus(
        {
          status: true,
          userId: user._id,
        },
        () => {
          showSuccessToast('User successfully approved!');
          loadPartners();
        },
      ),
    );
  };

  const onReject = async (user) => {
    try {
      await postRequest('/backoffice/user/reject-approval', {
        userId: user._id,
      });
      showSuccessToast('Aproval successfully rejected');
      loadPartners();
    } catch (error) {
      showErrorToast('Rejecting approval failed');
    }
  };
  return (
    <div className="partner-approval-page">
      <Table
        rowKey="_id"
        dataSource={users}
        loading={isLoading}
        columns={[
          {
            title: 'Member since',
            key: 'createdAt',
            render: (user) => (
              <Tag>{`${getDaysSince(new Date(user.createdAt))} days ago`}</Tag>
            ),
          },
          {
            title: 'Name',
            key: 'name',
            render: (userDetails) => (
              <Link to={buildBuyerPageUrl(false, { userDetails })}>
                {userDetails.name}
              </Link>
            ),
          },
          {
            title: 'Email',
            key: 'email',
            dataIndex: 'email',
          },
          {
            title: 'User Type',
            key: 'kind',
            render: (user) => (
              <Tag color="orange">
                {capitalizeFirstLetter(
                  user.kind?.length > 0 ? user.kind[0] : '',
                )}
              </Tag>
            ),
          },
          {
            title: 'Phone',
            key: 'phoneNumber',
            render: (user) => formatPhoneNumber(user.phoneNumber?.number),
          },
          {
            title: 'Organization',
            key: 'organization',
            render: (user) => user.defaultOrganization?.name,
          },
          {
            title: 'Referred by',
            key: 'referredBy',
            render: (user) => user.referredBy?.name,
          },
          {
            title: 'Status',
            key: 'status',
            render: (user) => (
              <Tag>
                {user.defaultOrganization?.verification?.verified
                  ? 'Verified'
                  : 'Pending'}
              </Tag>
            ),
          },
          {
            title: 'Actions',
            key: 'actions',
            render: (user) => (
              <div style={{ display: 'flex' }}>
                <ZorbaButton
                  variant={BUTTON_VARIANT_LINK}
                  onClick={() => onApprove(user)}
                  fullWidth={false}
                >
                  Approve
                </ZorbaButton>
                <ZorbaButton
                  variant={BUTTON_VARIANT_LINK}
                  onClick={() => onReject(user)}
                  fullWidth={false}
                >
                  Reject
                </ZorbaButton>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};
