import {
  FIELD_MAPPING_STATUS_FALSE,
  FIELD_MAPPING_STATUS_IGNORE,
  FIELD_MAPPING_STATUS_INITIAL,
  FIELD_MAPPING_STATUS_MAPPED,
} from '../../constants';
import MapFieldConfirmAction from '../MapFieldConfirmAction';
import MapFieldEditAction from '../MapFieldEditAction';

export const MAPPED_FIELD_COMPONENTS = {
  [FIELD_MAPPING_STATUS_INITIAL]: (props) => (
    <MapFieldConfirmAction {...props} />
  ),
  [FIELD_MAPPING_STATUS_FALSE]: (props) => <MapFieldConfirmAction {...props} />,
  [FIELD_MAPPING_STATUS_IGNORE]: (props) => (
    <MapFieldEditAction {...props} variant={FIELD_MAPPING_STATUS_IGNORE} />
  ),
  [FIELD_MAPPING_STATUS_MAPPED]: (props) => (
    <MapFieldEditAction {...props} variant={FIELD_MAPPING_STATUS_MAPPED} />
  ),
};
