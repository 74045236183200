import { Table } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ZorbaSelect } from '../../components/forms';
import { getReportData } from '../../reducer/BuyersReport/actions';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { daysOptions, getTableColumns } from './constants';

import './index.scss';

const BuyersReportPage = () => {
  useDocumentTitle(`Buyers report`);
  const dispatch = useDispatch();
  const { data, isLoading } = useSelector(
    ({ BuyersReportReducer }) => BuyersReportReducer,
  );
  const [selectedDays, setSelectedDays] = useState(30);

  useEffect(() => {
    dispatch(getReportData({ daysSince: selectedDays }));
  }, [selectedDays]);

  const pagination = {
    position: ['bottomRight'],
    pageSize: 50,
  };

  return (
    <div className="buyers-report-page">
      <div className="buyers-report-filter">
        <ZorbaSelect
          options={daysOptions}
          value={selectedDays}
          onChange={(e) => setSelectedDays(e)}
          className="buyers-report-select"
          disabled={isLoading}
        />
      </div>
      <Table
        rowKey="_id"
        className="buyer-report-table"
        dataSource={data}
        columns={getTableColumns()}
        loading={isLoading}
        pagination={pagination}
        rowClassName={(record) => (!record.isActive ? 'not-active' : '')}
        title={() => `Buyers (${data?.length || 0})`}
        scroll={{
          y: 720,
        }}
      />
    </div>
  );
};

export default BuyersReportPage;
