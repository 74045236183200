import { useState, useEffect } from 'react';

export function useUnmountElement(isMounted, delayTime) {
  const [isElement, setIsElement] = useState(false);

  useEffect(() => {
    let timeoutId;

    if (isMounted && !isElement) {
      setIsElement(true);
    } else if (!isMounted && isElement) {
      timeoutId = setTimeout(() => setIsElement(false), delayTime); // delay our unmount
    }

    return () => clearTimeout(timeoutId); // cleanup mechanism for effects , the use of setTimeout generate a sideEffect
  }, [isMounted, delayTime, isElement]);

  return isElement;
}
