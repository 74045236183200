const getParamName = (flow) => `is${flow}ModalOpen`;
export const SET_MODAL_STATE = 'SET_MODAL_STATE';
export const MODAL_CREATE_ORGANIZATION = getParamName('CreateOrganization');
export const MODAL_CREATE_USER = getParamName('CreateUser');
export const MODAL_PARAM_FLOATING = getParamName('Floating');
export const MODAL_IMPORT_DATA = getParamName('ImportData');

export const MODAL_REJECT_BUYER = getParamName('RejectBuyer');
export const MODAL_SUBMIT_OFFER = getParamName('SubmitOffer');

export const MODAL_COUNTER_OFFER = getParamName('CounterOffer');

export const MODAL_ACCEPT_OFFER = getParamName('AcceptOffer');

export const MODAL_REMOVE_BUYER_EVENT = getParamName('RemoveBuyerEvent');

export const MODAL_UNSAVED_CHANGES = getParamName('UnsavedChanges');
export const MODAL_UNSUBSCRIBE_FROM_SMS_CAMPAIGNS = getParamName(
  'UnsubscribeFromSMSCampaigns',
);

export const MODAL_CREATE_ENTITY = getParamName('CreateEntity');

export const MODAL_CREATE_ORGANIZATION_REPORT = getParamName(
  'CreateOrganizationReport',
);

export const MODAL_ASSOCIATE_ENTITY_TO_ORGANIZATION = getParamName(
  'AssociateEntityToOrganization',
);

export const MODAL_REPLACE_DEAL_SUBMISSION = getParamName(
  'ReplaceDealSubmission',
);

export const MODAL_UPDATE_ASKING_PRICE = getParamName('UpdateAskingPrice');

export const MODAL_DUPLICATED_DEAL = getParamName('DuplicatedDeal');

export const MODAL_FINAL_OFFER = getParamName('FinalOffer');

export const MODAL_ENTITY_SUCCESS_MAPPING = getParamName(
  'EntitySuccessMapping',
);

export const MODAL_REASSIGN_REFERRAL = getParamName('ReassignReferral');

export const MODAL_PRE_DEAL_FINAL_OFFER = getParamName('PreDealFinalOffer');
