import { useState, useEffect } from 'react';
import { Table } from 'antd';
import { useDispatch } from 'react-redux';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { getRequest, postRequest } from '../../services/requestService';
import {
  showErrorToast,
  showSuccessToast,
} from '../../services/notificationsService';
import { getTableColumns } from './constants';
import { isMobile } from '../../services/clientService';
import { ZorbaSelect } from '../../components/forms';
import { EntitySuccessMappingModal } from './Components/EntitySuccessMappingModal';
import { setModalState } from '../../reducer/Modal/actions';
import { MODAL_ENTITY_SUCCESS_MAPPING } from '../../reducer/Modal/types';

import './index.scss';

const DataRequestPage = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEntityName, setSelectedEntityName] = useState('');
  const [selectedRequestId, setSelectedRequestId] = useState('');
  const [dataRequests, setDataRequests] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('pending');
  const [selectedKind, setSelectedKind] = useState('');
  useDocumentTitle(`Data requests`);

  const loadDataRequests = async () => {
    setIsLoading(true);
    try {
      const res = await postRequest('/backoffice/data/get-data-requests', {
        status: selectedStatus,
        kind: selectedKind,
      });
      setDataRequests(res?.data?.data);
    } catch (error) {
      showErrorToast('Fetching data requests failed');
    }
    setIsLoading(false);
  };

  const fetchOrganizations = async () => {
    setIsLoading(true);
    try {
      const res = await getRequest('/backoffice/data/get-all-organizations');
      setOrganizations(res?.data?.data);
    } catch (error) {
      showErrorToast('Fetching organizations failed');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadDataRequests();
  }, [selectedStatus, selectedKind]);

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const handleEntityMappingSuccess = async (entityName, requestId) => {
    setSelectedEntityName(entityName);
    setSelectedRequestId(requestId);
    dispatch(setModalState(MODAL_ENTITY_SUCCESS_MAPPING, true));
  };
  const handleEntityMappingFailure = async (requestId) => {
    setIsLoading(true);
    try {
      await postRequest('/backoffice/data/entity-mapping-failure', {
        requestId,
      });
      showSuccessToast('Success!');
    } catch (error) {
      showErrorToast('Failure!');
    }
    loadDataRequests();
  };

  const handleContactMappingFailure = async (requestId) => {
    setIsLoading(true);
    try {
      await postRequest('/backoffice/data/contact-mapping-failure', {
        requestId,
      });
      showSuccessToast('Success!');
    } catch (error) {
      showErrorToast('Failure!');
    }
    loadDataRequests();
  };

  const handleContactMappingSuccess = async (requestId) => {
    setIsLoading(true);
    try {
      await postRequest('/backoffice/data/contact-mapping-success', {
        requestId,
      });
      showSuccessToast('Success!');
    } catch (error) {
      showErrorToast('Failure!');
    }
    loadDataRequests();
  };

  const tableScroll = {
    scrollToFirstRowOnChange: true,
    x: isMobile() ? true : 'max-content',
  };

  return (
    <div className="data-requests-page">
      <div className="filters-container">
        <ZorbaSelect
          label="Status"
          options={[
            { label: 'All', value: '' },
            { label: 'Pending', value: 'pending' },
            { label: 'Success', value: 'success' },
            { label: 'Failure', value: 'failure' },
          ]}
          value={selectedStatus}
          onChange={(e) => setSelectedStatus(e)}
          fullWidth={false}
        />
        <ZorbaSelect
          label="Kind"
          options={[
            { label: 'All', value: '' },
            { label: 'Entity', value: 'entity' },
            { label: 'Contact', value: 'contact' },
          ]}
          value={selectedKind}
          onChange={(e) => setSelectedKind(e)}
          fullWidth={false}
        />
      </div>
      <Table
        rowKey="_id"
        dataSource={dataRequests}
        loading={isLoading}
        columns={getTableColumns({
          handleEntityMappingSuccess,
          handleEntityMappingFailure,
          handleContactMappingFailure,
          handleContactMappingSuccess,
        })}
        scroll={tableScroll}
      />
      <EntitySuccessMappingModal
        entityName={selectedEntityName}
        organizations={organizations}
        requestId={selectedRequestId}
        loadDataRequests={loadDataRequests}
      />
    </div>
  );
};

export default DataRequestPage;
