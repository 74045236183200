import React, { useState } from 'react';
import { Popconfirm, Steps } from 'antd';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { showErrorToast } from '../../../../../../services/notificationsService';
import { setJob } from '../../../../../../reducer/PropertyData/actions';
import { JOB_WIZARD_STEPS } from '../../constants';
import JobWizardFooter from '../JobWizardFooter';
import { REQUIRED_COLUMN_NAMES } from '../MappingFieldsStep/constants';
import { getTitle } from './service';

import './index.scss';

let deleteJob;
const StepsView = (props) => {
  const { Step } = Steps;
  const {
    stepIndex,
    setStepIndex,
    job,
    currentStepData,
    loading,
    setLoading,
    setIsImportModalOpen,
  } = props;
  const dispatch = useDispatch();
  const [checkoutParams, setCheckoutParams] = useState();

  const onNextClick = async (params) => {
    switch (stepIndex) {
      case 2:
        if (
          REQUIRED_COLUMN_NAMES.find(
            (column) =>
              params.mappedFieldsTemp[column] === null ||
              params.mappedFieldsTemp[column] === undefined,
          )
        ) {
          showErrorToast('Missing fields!');
          return;
        }
        break;
      default:
        break;
    }
    if (stepIndex < JOB_WIZARD_STEPS.length - 1) setStepIndex(stepIndex + 1);
  };
  const onBackClick = () => {
    if (stepIndex > 0) {
      setStepIndex(stepIndex - 1);
    }
  };

  const onCancelApprove = () => {
    // A file was already uploaded --> we need to remove the Job from the db
    if (stepIndex > 1 && job._id) {
      dispatch(deleteJob(job));
    } else {
      dispatch(setJob());
    }

    // Reset and close the modal
    if (props.onCloseModal) {
      props.onCloseModal();
    }
  };

  if (!currentStepData) return null;
  return (
    <div className="modal-view steps-modal-view">
      <div className="steps-header">
        <div className="left">
          <h1 className="title">{getTitle(stepIndex, currentStepData)}</h1>
          <div>
            {stepIndex > 0 && (
              <Steps size="small" current={stepIndex}>
                {JOB_WIZARD_STEPS.map((step) => (
                  <Step key={uuid()} title={step.progressBarTitle} />
                ))}
              </Steps>
            )}
          </div>
        </div>
        {stepIndex > 0 && (
          <div className="right close-btn">
            <Popconfirm
              title="Are you sure you want to cancel this order?"
              okText="Yes, Cancel"
              cancelText="No, Continue"
              onConfirm={onCancelApprove}
            >
              <i className="fas fa-times" />
            </Popconfirm>
          </div>
        )}
      </div>
      {/* RENDER THE STEP CONTENT */}
      {currentStepData.getComponent({
        ...props,
        onNextClick,
        onBackClick,
        currentStepData,
        checkoutParams,
        setCheckoutParams,
        setIsImportModalOpen,

        footer: currentStepData.overrideFooter ? JobWizardFooter : null,
      })}
      {!currentStepData.overrideFooter ? (
        <JobWizardFooter
          onBackClick={onBackClick}
          loading={loading}
          setLoading={setLoading}
          job={job}
          checkoutParams={checkoutParams}
          setCheckoutParams={setCheckoutParams}
          onNextClick={onNextClick}
          currentStepData={currentStepData}
          disabled={currentStepData.disabled}
        />
      ) : null}
    </div>
  );
};

export default StepsView;
