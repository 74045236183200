import * as types from './types';

export function setModalState(modalName, modalStatus, modalParams = {}) {
  return {
    type: types.SET_MODAL_STATE,
    payload: {
      [modalName]: modalStatus,
      modalParams,
    },
  };
}
