export const SET_LOADING = 'SET_LOADING';
export const SET_IS_RENEWING_OFFER = 'SET_IS_RENEWING_OFFER';

export const SET_DEAL_SUBMISSIONS = 'SET_DEAL_SUBMISSIONS';

export const SET_CURRENT_PAGE = 'SET_PAGE';
export const SET_PAGE_SIZE = 'SET_PAGE_SIZE';

export const SET_SELECTED_DEAL_SUBMISSION = 'SET_SELECTED_DEAL_SUBMISSION';

export const SET_IS_FETCHING_DEAL_SUBMISSION =
  'SET_IS_FETCHING_DEAL_SUBMISSION';
export const SET_SELECTED_MATCH = 'SET_SELECTED_MATCH';

export const SET_REDIRECT_URL = 'SET_REDIRECT_URL';
