import { useState, useRef, useEffect } from 'react';
import Map, { Popup, Source, Layer, Marker } from 'react-map-gl';
import { FaMapMarkerAlt } from '@react-icons/all-files/fa/FaMapMarkerAlt';
import bbox from '@turf/bbox';
import { Tag, Divider } from 'antd';
import { addCommas } from '@zorba-shared/core/Services/textService';
import { FILE_NAMES } from './files/fileNames';
import {
  stateNameToCode,
  STYLES,
  LAYOUT,
  stateCodeToName,
  ZOOM_LEVELS,
  EVENT_SOURCES,
} from './constants';
import {
  useMSATransactions,
  useStatesWithTransactions,
  useZIPTransactions,
} from './hooks';

import './index.scss';

const MAPBOX_TOKEN =
  'pk.eyJ1IjoibWF0a3dhIiwiYSI6ImNtMzYwNzM5eDAwOHEyaHE0a3hrMXo4dm8ifQ.2hVV8fM4AFpc9S8d4e2kOg';
export const MapComponent = () => {
  const mapRef = useRef();
  const previousZoomRef = useRef(null);
  const [isProgrammaticZoom, setIsProgrammaticZoom] = useState(false);
  const [selectedFeatureId, setSelectedFeatureId] = useState(null);
  const [selectedMsaFeatureId, setSelectedMsaFeatureId] = useState(null);
  const [selectedState, setSelectedState] = useState('');
  const [selectedMSA, setSelectedMSA] = useState('');
  const [hoverInfo, setHoverInfo] = useState(null);
  const statesWithTransactions = useStatesWithTransactions();
  const msaTransactions = useMSATransactions(selectedState);
  const zipTransactions = useZIPTransactions(selectedMSA);

  const onClick = (event) => {
    const feature = event.features[0];
    if (feature && feature.layer.id === 'states-fill') {
      setSelectedState(stateNameToCode[feature.properties.name]);
      setSelectedMSA('');
      if (msaTransactions?.features?.length) {
        msaTransactions.features.forEach((msa) => {
          mapRef.current.setFeatureState(
            { source: EVENT_SOURCES.MSA, id: msa.id },
            { msaBlurred: false },
          );
        });
        mapRef.current.setFeatureState(
          { source: EVENT_SOURCES.MSA, id: selectedMsaFeatureId },
          { msaSelected: false },
        );
      }
      const newFeatureId = feature.id;

      if (selectedFeatureId !== null) {
        mapRef.current.setFeatureState(
          { source: EVENT_SOURCES.STATES, id: selectedFeatureId },
          { selected: false },
        );
      }

      mapRef.current.setFeatureState(
        { source: EVENT_SOURCES.STATES, id: newFeatureId },
        { selected: true },
      );

      statesWithTransactions.features.forEach((state) => {
        const isCurrentState = state.id === newFeatureId;
        mapRef.current.setFeatureState(
          { source: EVENT_SOURCES.STATES, id: state.id },
          { blurred: !isCurrentState },
        );
      });
      setSelectedFeatureId(newFeatureId);

      const [minLng, minLat, maxLng, maxLat] = bbox(feature);
      setIsProgrammaticZoom(true);
      mapRef.current.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        { padding: 40, duration: 3000 },
      );
    }

    if (feature && feature.layer.id === 'msa-transactions-fill') {
      setSelectedMSA(feature.properties.msaName);
      const newFeatureId = feature.id;
      if (selectedMsaFeatureId !== null) {
        mapRef.current.setFeatureState(
          { source: EVENT_SOURCES.MSA, id: selectedMsaFeatureId },
          { msaSelected: false },
        );
      }

      mapRef.current.setFeatureState(
        { source: EVENT_SOURCES.MSA, id: newFeatureId },
        { msaSelected: true },
      );

      msaTransactions.features.forEach((msa) => {
        const isCurrentMSA = msa.id === newFeatureId;
        mapRef.current.setFeatureState(
          { source: EVENT_SOURCES.MSA, id: msa.id },
          { msaBlurred: !isCurrentMSA },
        );
      });

      setSelectedMsaFeatureId(newFeatureId);

      const [minLng, minLat, maxLng, maxLat] = bbox(feature);
      setIsProgrammaticZoom(true);
      mapRef.current.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        { padding: 40, duration: 3000 },
      );
    }
  };

  const onHover = (event) => {
    const feature = event.features && event.features[0];
    if (feature) {
      const stateCode = stateNameToCode[feature.properties.name];
      if (feature.layer.id === 'states-fill' && stateCode === selectedState) {
        setHoverInfo(null);
      } else {
        const getTitle = () => {
          if (feature.layer.id === 'states-fill') {
            return feature.properties.name;
          }
          if (feature.layer.id === 'msa-transactions-fill') {
            return `${stateCodeToName[selectedState]}, MSA`;
          }
          if (feature.layer.id === 'msa-zip-transactions-fill') {
            return `${feature.properties.ZIP}`;
          }

          return '';
        };
        setHoverInfo({
          lng: event.lngLat.lng,
          lat: event.lngLat.lat,
          title: getTitle(),
          subTitle:
            feature.layer.id === 'msa-transactions-fill'
              ? `${feature.properties.msaName.split(',')[0]}`
              : '',
          zipCodesNumber:
            feature.layer.id === 'msa-transactions-fill'
              ? `${JSON.parse(feature.properties.zipCodes)?.length}`
              : '',
          msaNumber:
            feature.layer.id === 'states-fill'
              ? `${
                  FILE_NAMES.filter(
                    (fileName) =>
                      fileName.includes(stateCode) &&
                      !fileName.includes('NONMETROPOLITAN'),
                  ).length
                }`
              : '',
          transactionCount: feature.properties.transactionCount,
          msaForZip:
            feature.layer.id === 'msa-zip-transactions-fill'
              ? `${selectedMSA.split(',')[0]}`
              : '',
          msaPropertyId: feature.properties.msaNumber,
        });
      }
    } else {
      setHoverInfo(null);
    }
  };

  const handleButtonClick = () => {
    if (hoverInfo.msaPropertyId) {
      const msaFeature = msaTransactions.features.find(
        (feature) => hoverInfo.msaPropertyId === feature.properties.msaNumber,
      );
      if (msaFeature) {
        onClick({
          features: [{ ...msaFeature, layer: { id: 'msa-transactions-fill' } }],
        });
      }
    } else {
      const stateFeature = statesWithTransactions.features.find(
        (feature) => hoverInfo.title === feature.properties.name,
      );
      if (stateFeature) {
        onClick({
          features: [{ ...stateFeature, layer: { id: 'states-fill' } }],
        });
      }
    }
  };

  const handleScrollOut = () => {
    if (isProgrammaticZoom) {
      setIsProgrammaticZoom(false);

      return;
    }
    if (selectedMSA) {
      mapRef.current.setFeatureState(
        { source: EVENT_SOURCES.MSA, id: selectedMsaFeatureId },
        { msaSelected: false },
      );
      msaTransactions.features.forEach((msa) => {
        mapRef.current.setFeatureState(
          { source: EVENT_SOURCES.MSA, id: msa.id },
          { msaBlurred: false },
        );
      });
      setIsProgrammaticZoom(true);
      mapRef.current.easeTo({
        zoom: ZOOM_LEVELS.MSA,
        duration: 3000,
      });
      setSelectedMSA(null);
      setSelectedMsaFeatureId(null);
    }

    if (selectedState && !selectedMSA) {
      mapRef.current.setFeatureState(
        { source: EVENT_SOURCES.STATES, id: selectedFeatureId },
        { selected: false },
      );
      statesWithTransactions.features.forEach((state) => {
        mapRef.current.setFeatureState(
          { source: EVENT_SOURCES.STATES, id: state.id },
          { blurred: false },
        );
      });
      mapRef.current.easeTo({
        zoom: ZOOM_LEVELS.STATE,
        duration: 3000,
      });
      setSelectedState(null);
      setSelectedFeatureId(null);
    }
  };

  useEffect(() => {
    if (mapRef.current) {
      const onZoomEnd = () => {
        const currentZoom = mapRef.current.getZoom();

        if (
          previousZoomRef.current !== null &&
          currentZoom < previousZoomRef.current
        ) {
          handleScrollOut();
        }

        previousZoomRef.current = currentZoom;
      };
      mapRef.current.on('zoomend', onZoomEnd);

      return () => {
        mapRef.current.off('zoomend', onZoomEnd);
      };
    }
  }, [selectedMSA, selectedState, isProgrammaticZoom]);

  return (
    <Map
      ref={mapRef}
      initialViewState={{
        latitude: 37.0902,
        longitude: -95.7129,
        zoom: 4,
      }}
      mapStyle="mapbox://styles/mapbox/streets-v11"
      interactiveLayerIds={[
        'msa-transactions-fill',
        'states-fill',
        'msa-zip-transactions-fill',
      ]}
      onClick={onClick}
      mapboxAccessToken={MAPBOX_TOKEN}
      onMouseMove={onHover}
    >
      <Source id="states" type="geojson" data={statesWithTransactions}>
        <Layer id="states-fill" type="fill" paint={STYLES.STATES_FILL} />
        <Layer id="states-outline" type="line" paint={STYLES.STATES_OUTLINE} />
        <Layer
          id="states-symbol"
          type="symbol"
          layout={LAYOUT.STATES_SYMBOL}
          paint={STYLES.STATES_SYMBOL}
        />
      </Source>
      {msaTransactions && (
        <Source id="msa-transactions" type="geojson" data={msaTransactions}>
          <Layer
            id="msa-transactions-fill"
            type="fill"
            paint={STYLES.MSA_TRANSACTIONS_FILL}
          />
          <Layer
            id="msa-transactions-outline"
            type="line"
            paint={STYLES.MSA_TRANSACTIONS_OUTLINE}
          />
          {msaTransactions.features.map(
            (feature) =>
              feature.properties.msaName !== selectedMSA && (
                <Marker
                  key={feature.id}
                  longitude={feature.properties.center[0]}
                  latitude={feature.properties.center[1]}
                  anchor="center"
                >
                  <FaMapMarkerAlt
                    style={{ fontSize: '18px', color: '#DC143C' }}
                  />
                </Marker>
              ),
          )}
        </Source>
      )}
      {zipTransactions && (
        <Source id="msa-zip-transactions" type="geojson" data={zipTransactions}>
          <Layer
            id="msa-zip-transactions-fill"
            type="fill"
            paint={STYLES.MSA_ZIP_TRANSACTIONS_FILL}
          />
          <Layer
            id="msa-zip-transactions-outline"
            type="line"
            paint={STYLES.MSA_ZIP_TRANSACTIONS_OUTLINE}
          />
        </Source>
      )}
      {hoverInfo && (
        <Popup
          longitude={hoverInfo.lng}
          latitude={hoverInfo.lat}
          closeButton={false}
          closeOnClick={false}
          anchor={false}
        >
          <div className="tooltip-container">
            <div className="title">{hoverInfo.title}</div>
            {hoverInfo.subTitle && (
              <div className="sub-title-msa">{hoverInfo.subTitle}</div>
            )}
            {hoverInfo.msaNumber && (
              <div className="sub-title">
                <Divider type="vertical" color="#D9D9D9" className="divider" />
                <span className="number">{hoverInfo.msaNumber}</span>
                <span className="additional-info">MSAs</span>
              </div>
            )}
            {hoverInfo.zipCodesNumber && (
              <div className="sub-title">
                <Divider type="vertical" color="#D9D9D9" className="divider" />
                <span className="number">{hoverInfo.zipCodesNumber}</span>
                <span className="additional-info">Zipcodes</span>
              </div>
            )}
            {hoverInfo.msaForZip && (
              <div className="sub-title">
                <Divider type="vertical" color="#D9D9D9" className="divider" />
                <span className="additional-info-zip">
                  {hoverInfo.msaForZip}
                </span>
              </div>
            )}
            <div className="description">
              SFR Transactions in the past 90 days
            </div>
            <div>
              <Tag color="#D9D9D9" className="tag">{`${addCommas(
                hoverInfo.transactionCount,
              )} ${
                Number(hoverInfo.transactionCount) === 1 ? 'Home' : 'Homes'
              }`}</Tag>
            </div>
            {(hoverInfo.msaNumber || hoverInfo.zipCodesNumber) && (
              <div
                className="button"
                onClick={() => {
                  handleButtonClick();
                  setHoverInfo(null);
                }}
              >
                {hoverInfo.msaNumber ? 'VIEW STATE' : 'VIEW MSA'}
              </div>
            )}
          </div>
        </Popup>
      )}
    </Map>
  );
};
