import React, { useEffect } from 'react';
import { Button, Form, Input, Space, Switch } from 'antd';

import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { createOrganization } from '../../reducer/Organization/actions';

export default (props) => {
  const dispatch = useDispatch();
  const { isOrganizationSaving } = useSelector(
    ({ OrganizationReducer }) => OrganizationReducer,
  );

  const [form] = Form.useForm();

  /**
   * set default organization name
   */
  useEffect(() => {
    if (props.defaultName) {
      form.setFieldValue('name', props.defaultName);
    }
  }, ['']);
  const onFinish = (values) => {
    dispatch(createOrganization(values));
  };

  const cancel = () => {
    props?.onCancel();
  };
  return (
    <div className="organization-create-form">
      <Form
        name="basic"
        layout="vertical"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
      >
        <Form.Item
          name="name"
          label="Organization Name"
          rules={[
            {
              required: true,
              message: 'Please organization name!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Is Power Buyer?"
          name="isPowerBuyer"
          valuePropName="checked"
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          layout="horizontal"
        >
          <Switch />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            span: 24,
          }}
        >
          <Space align="end" className="btn-group">
            <Button onClick={cancel}>Cancel</Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={isOrganizationSaving}
            >
              Save
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};
