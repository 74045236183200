import { Table } from 'antd';
import PropTypes from 'prop-types';
import { addCommas } from '@zorba-shared/core/Services/textService';
import { isMobile } from '../../../../services/clientService';
import { getTableColumns } from './columns';
import './index.scss';

const tableScroll = {
  scrollToFirstRowOnChange: true,
  x: isMobile() ? true : 'max-content',
};

const BuyerTable = (props) => {
  const { buyers, isLoading, total, currentPage, onUnsubscribeBuyer } = props;

  const updatedBuyers = buyers.map((buyer) => ({
    ...buyer,
    key: buyer._id,
  }));

  const locale = {
    emptyText: isLoading ? null : <p>No Record Found.</p>,
  };

  const onDelete = (item) => {
    props?.onDelete(item);
  };

  const pagination = {
    position: ['bottomRight'],
    onChange: props?.onShowSizeChange,
    total,
    pageSize: 50,
    current: currentPage + 1,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
  };

  return (
    <>
      <div className="buyer-table-info">{`Total: ${addCommas(
        total,
      )} buyers`}</div>
      <Table
        loading={isLoading}
        className="buyer-table"
        columns={getTableColumns(onDelete, onUnsubscribeBuyer)}
        locale={locale}
        dataSource={updatedBuyers}
        pagination={pagination}
        scroll={tableScroll}
      />
    </>
  );
};

BuyerTable.defaultProps = {
  buyers: [],
  isLoading: false,
};

BuyerTable.propTypes = {
  buyers: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool,
};

export default BuyerTable;
