import './index.scss';

import classNames from 'classnames';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { FiChevronRight } from '@react-icons/all-files/fi/FiChevronRight';
import BuyBoxMatchedPropertyCount from '../BuyBoxMatchedPropertyCount';
import { setMatchingCountShown } from '../../../../../../reducer/Buyer/actions';
import WithAnimation from '../../../../../../components/WithAnimation';

const getGooglePlaceFriendlyName = (place) => {
  if (!place?.kind) return '';
  switch (place.kind) {
    case 'locality':
      return place.locality;
    case 'state':
      return place.state;
    case 'county':
      return `${place.county} County`;
    case 'zipcode':
      return place.zipcode;
    default:
      return '';
  }
};
const getAreaSnippet = (areas) => {
  // const { areas } = selectedBuyBox;
  if (!areas || areas.length === 0) return '';
  const titles = areas.map((area) => getGooglePlaceFriendlyName(area));

  let res = titles[0];
  if (titles.length > 1) {
    res += `, ${titles[1]}`;
  }
  if (titles.length > 2) {
    res += `, and ${titles.length - 2} more`;
  }

  return res;
};

export default (props) => {
  const {
    buyBoxList = [],
    selectedBuyBox,
    setSelectedBuyBox,
    buyBoxPropertyMatch,
    isFetchingBuyboxMatchedProperties,
    isMatchingCountShown,
  } = props;
  const dispatch = useDispatch();
  const clickHandler = (buyBox) => {
    setSelectedBuyBox(buyBox);

    // Hide matching count if BuyBox is changed
    if (buyBox._id !== selectedBuyBox._id) {
      dispatch(setMatchingCountShown(false));
    }
  };
  return (
    <div className="buybox-list">
      <div className="actions">
        <Button
          type="link"
          icon={<PlusOutlined />}
          onClick={() => props.onCreateCriteria()}
        >
          {buyBoxList.length > 0 ? 'Add another' : 'Add Criteria'}
        </Button>
      </div>
      {buyBoxList.map((buyBoxItem) => (
        <div
          className={classNames('buybox-list-item', {
            selected: buyBoxItem._id === selectedBuyBox?._id,
          })}
          key={buyBoxItem._id}
          onClick={() => clickHandler(buyBoxItem)}
        >
          <div className="left">
            <div className="title">{buyBoxItem.title || 'Unnamed'}</div>
            <div className="subtitle">
              {buyBoxItem._id
                ? getAreaSnippet(buyBoxItem.areas)
                : 'Unsaved changes'}
            </div>
            <WithAnimation
              isRender={
                buyBoxItem._id === selectedBuyBox?._id && isMatchingCountShown
              }
            >
              <BuyBoxMatchedPropertyCount
                matchingCount={buyBoxPropertyMatch?.[buyBoxItem._id]}
                isLoading={isFetchingBuyboxMatchedProperties}
              />
            </WithAnimation>
          </div>
          <div className="right">
            <FiChevronRight />
          </div>
        </div>
      ))}
    </div>
  );
};
