/* eslint-disable default-param-last */
import * as types from './types';

const initialState = {
  drawerParams: {},
  [types.ADD_TASK_DRAWER]: false,
  [types.DRAWER_DEAL_SUBMISSION_MATCH]: false,
  [types.ACTIVITY_DEAL_SUBMISSION_DRAWER]: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.SET_DRAWER_STATE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
