import React from 'react';
import { Card } from 'antd';
import './index.scss';

const ColorLegend = () => (
  <div className="legend-container">
    <Card className="color-legend">
      <h3 className="legend-title">SFR volume - last 90 days</h3>
      <div className="legend-gradient" />
      <div className="legend-items">
        <div className="legend-item">
          <div className="color-box" style={{ backgroundColor: '#DDE4F7' }} />
          <span>Very Low</span>
        </div>
        <div className="legend-item">
          <div className="color-box" style={{ backgroundColor: '#B4C2F0' }} />
          <span>Low</span>
        </div>
        <div className="legend-item">
          <div className="color-box" style={{ backgroundColor: '#8B9FE8' }} />
          <span>Moderate</span>
        </div>
        <div className="legend-item">
          <div className="color-box" style={{ backgroundColor: '#7388D9' }} />
          <span>High</span>
        </div>
        <div className="legend-item">
          <div className="color-box" style={{ backgroundColor: '#5F74CB' }} />
          <span>Very High</span>
        </div>
      </div>
    </Card>
  </div>
);

export default ColorLegend;
