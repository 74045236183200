import * as types from './types';
import { getRequest } from '../../services/requestService';
import { showErrorToast } from '../../services/notificationsService';

export const getAllOwners = () => async (dispatch) => {
  dispatch({
    type: types.SET_IS_LOADING,
    payload: { isLoading: true },
  });
  try {
    const res = await getRequest('/backoffice/user/get-all-superusers');
    const { data } = res.data;
    dispatch({
      type: types.SET_OWNERS,
      payload: {
        owners: data.map((el) => ({ label: el.name, value: el._id })),
        isLoading: false,
      },
    });
  } catch (error) {
    dispatch({
      type: types.SET_IS_LOADING,
      payload: {
        isLoading: false,
      },
    });
    showErrorToast('Fetching all users failed');
  }
};

export const getAllOrganizationPowerBuyers = () => async (dispatch) => {
  dispatch({
    type: types.SET_IS_FETCHING_ORGANIZATION_POWER_BUYERS,
    payload: { isFetchingOrganizationPowerBuyers: true },
  });
  try {
    const res = await getRequest(
      '/backoffice/user/get-all-organization-power-buyers',
    );
    const { data } = res.data;
    dispatch({
      type: types.SET_ORGANIZATION_POWER_BUYERS,
      payload: {
        organizationPowerBuyers: data.map((el) => ({
          label: el.name,
          value: el._id,
        })),
        isFetchingOrganizationPowerBuyers: false,
      },
    });
  } catch (error) {
    dispatch({
      type: types.SET_IS_FETCHING_ORGANIZATION_POWER_BUYERS,
      payload: { isFetchingOrganizationPowerBuyers: false },
    });
    showErrorToast('Fetching organization power buyers failed');
  }
};
