export const FIELD_MAPPING_STATUS_FALSE = 'false';
export const FIELD_MAPPING_STATUS_INITIAL = 'initial';
export const FIELD_MAPPING_STATUS_IGNORE = 'ignored';
export const FIELD_MAPPING_STATUS_MAPPED = 'mapped';

export const COLUMN_NAME_FIRST_NAME = 'firstName';
export const COLUMN_NAME_LAST_NAME = 'lastName';

export const COLUMN_SECOND_OWNER_FIRST_NAME = 'owner2FirstName';
export const COLUMN_SECOND_OWNER_LAST_NAME = 'owner2LastName';
export const COLUMN_NAME_PROPERTY_ADDRESS = 'address';
export const COLUMN_NAME_PROPERTY_CITY = 'city';
export const COLUMN_NAME_PROPERTY_STATE = 'state';
export const COLUMN_NAME_PROPERTY_ZIP_CODE = 'zip';

export const COLUMN_NAME_MAILING_ADDRESS = 'mailingAddress';
export const COLUMN_NAME_MAILING_CITY = 'mailingCity';
export const COLUMN_NAME_MAILING_STATE = 'mailingState';
export const COLUMN_NAME_MAILING_ZIP_CODE = 'mailingZip';

export const REQUIRED_COLUMN_NAMES = [
  COLUMN_NAME_PROPERTY_ADDRESS,
  COLUMN_NAME_PROPERTY_CITY,
  COLUMN_NAME_PROPERTY_STATE,
  COLUMN_NAME_PROPERTY_ZIP_CODE,
];

const COLUMN_NAMES = [
  COLUMN_NAME_FIRST_NAME,
  COLUMN_NAME_LAST_NAME,
  COLUMN_SECOND_OWNER_FIRST_NAME,
  COLUMN_SECOND_OWNER_LAST_NAME,
  COLUMN_NAME_PROPERTY_ADDRESS,
  COLUMN_NAME_PROPERTY_CITY,
  COLUMN_NAME_PROPERTY_STATE,
  COLUMN_NAME_PROPERTY_ZIP_CODE,
  COLUMN_NAME_MAILING_ADDRESS,
  COLUMN_NAME_MAILING_CITY,
  COLUMN_NAME_MAILING_STATE,
  COLUMN_NAME_MAILING_ZIP_CODE,
];

export const COLUMN_NAME_TITLES = {
  [COLUMN_NAME_FIRST_NAME]: 'First Name',
  [COLUMN_NAME_LAST_NAME]: 'Last Name',
  [COLUMN_SECOND_OWNER_FIRST_NAME]: 'Owner 2 First Name',
  [COLUMN_SECOND_OWNER_LAST_NAME]: 'Owner 2 Last Name',
  [COLUMN_NAME_PROPERTY_ADDRESS]: 'Property Address',
  [COLUMN_NAME_PROPERTY_CITY]: 'Property City',
  [COLUMN_NAME_PROPERTY_STATE]: 'Property State',
  [COLUMN_NAME_PROPERTY_ZIP_CODE]: 'Property Zip Code',
  [COLUMN_NAME_MAILING_ADDRESS]: 'Mailing Address',
  [COLUMN_NAME_MAILING_CITY]: 'Mailing City',
  [COLUMN_NAME_MAILING_STATE]: 'Mailing State',
  [COLUMN_NAME_MAILING_ZIP_CODE]: 'Mailing Zip Code',
};

export const FIELD_MAPPING_SELECT_OPTIONS = COLUMN_NAMES.map((columnName) => ({
  text: COLUMN_NAME_TITLES[columnName],
  value: columnName,
}));
