import { capitalizeFirstLetter } from '@zorba-shared/core/Services/textService';
import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import {
  AUTH_STATES,
  AUTH_STATE_INITIAL,
  AUTH_STATE_SET_PASSWORD,
  AUTH_STATE_SET_TEXT_CODE,
  AUTH_STATE_UPDATE_PHONE_NUMBER,
} from '@zorba-shared/core/Constants/auth';
import { AUTH_STATE_DATA } from './constants';
import './index.scss';
import { buildAuthPageUrl } from '@zorba-shared/core/Services/urlBuilderService';
import { push } from 'connected-react-router';

export default (props) => {
  const [authState, setAuthState] = useState(AUTH_STATE_INITIAL);
  const dispatch = useDispatch();
  const [params, setParams] = useState({
    email: '',
    phoneNumber: { countryCode: 1, number: undefined },
  });
  const authStateData = AUTH_STATE_DATA[authState];

  useEffect(() => {
    const urlAuthState = props.match?.params?.authState;

    if (
      !urlAuthState ||
      ![
        AUTH_STATE_INITIAL,
        AUTH_STATE_SET_PASSWORD,
        AUTH_STATE_UPDATE_PHONE_NUMBER,
        AUTH_STATE_SET_TEXT_CODE,
      ].includes(urlAuthState)
    ) {
      dispatch(push(buildAuthPageUrl(false, AUTH_STATE_INITIAL)));
    } else if (AUTH_STATES.includes(urlAuthState)) {
      setAuthState(urlAuthState);
    }
  }, []);

  // useEffect(() => {
  //   const urlAuthState = props.match?.params?.authState

  //   if (!urlAuthState || !AUTH_STATES.includes(urlAuthState)) {
  //     dispatch(push(buildAuthPageUrl(false, AUTH_STATE_INITIAL)))
  //   } else if (AUTH_STATES.includes(urlAuthState)) {
  //     setAuthState(urlAuthState)
  //   }
  // }, [props.match])

  const onChange = (key, value) => {
    let submittedValue = value;

    if (['firstName', 'lastName'].includes(key)) {
      submittedValue = capitalizeFirstLetter(value);
    }

    setParams({ ...params, [key]: submittedValue });
  };

  return (
    <div className="login-wrapper">
      <div className="login-card">
        <div className="header">
          <div className="icon-lock">
            <img className="icon-lock__icon" alt="lock" src="/img/lock.svg" />
          </div>
          <div className="center title">{authStateData.title}</div>
        </div>
        {authStateData.getComponent({
          ...props,
          authStateData,
          params,
          setParams,
          onChange,
          setAuthState,
        })}
      </div>
    </div>
  );
};
