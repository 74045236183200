export default ({ deals }) => (
  <div className="zorba-list">
    <div className="zorba-list--header">Last {deals.length} deals</div>
    {deals.map((deal, i) => (
      <div className="zorba-list--item" key={i}>
        <div className="zorba-list--item__title">{deal.companyName}</div>
        <div className="zorba-list--item__description">
          {deal.address.formattedAddress}
        </div>
      </div>
    ))}
  </div>
);
