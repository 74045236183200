import { Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import { MODAL_DUPLICATED_DEAL } from '../../../../reducer/Modal/types';
import { setModalState } from '../../../../reducer/Modal/actions';
import { ZorbaButton } from '../../../../components/forms';
import { BUTTON_VARIANT_LINK } from '../../../../components/forms/ZorbaButton/constants';
import { SET_REDIRECT_URL } from '../../../../reducer/DealSubmission/types';

export const DuplicatedDealModal = ({ selectedDealSubmission }) => {
  const { isDuplicatedDealModalOpen } = useSelector(
    ({ ModalReducer }) => ModalReducer,
  );
  const { redirectUrl } = useSelector(
    ({ DealSubmissionReducer }) => DealSubmissionReducer,
  );
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(setModalState(MODAL_DUPLICATED_DEAL, false));
    dispatch({ type: SET_REDIRECT_URL, payload: { redirectUrl: '' } });
  };

  const handleRedirect = () => {
    window.open(redirectUrl, '_blank');
  };

  return (
    <Modal
      open={isDuplicatedDealModalOpen}
      footer={null}
      onCancel={handleCloseModal}
      title={`${selectedDealSubmission.address.formattedAddress}`}
      className="duplicated-deal-modal"
      destroyOnClose
    >
      <span>Deal was already submitted to this buyer</span>
      <ZorbaButton
        variant={BUTTON_VARIANT_LINK}
        style={{ marginTop: '15px' }}
        onClick={handleRedirect}
      >
        Show previous submission
      </ZorbaButton>
    </Modal>
  );
};
