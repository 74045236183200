import {
  PERMISSION_SET_BUYER_AGENT,
  PERMISSION_SET_SELLER_AGENT,
} from '@zorba-shared/core/Constants/auth';

const checkPermissions = (allowedPermissionsSets, dbPermissions) =>
  allowedPermissionsSets.some((allowedPermissionSet) =>
    dbPermissions.some((dbPermission) => dbPermission === allowedPermissionSet),
  );

export const getUserPermissions = (dbPermissions) => ({
  canViewListingsTab: checkPermissions(
    [PERMISSION_SET_SELLER_AGENT],
    dbPermissions,
  ),
  canSubmitOfferToTheSeller: checkPermissions(
    [PERMISSION_SET_BUYER_AGENT],
    dbPermissions,
  ),
});
