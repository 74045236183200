import { getRequest } from '../../services/requestService';
import * as types from './types';

export const loadTranslationCategories =
  (categories) => async (dispatch, getState) => {
    if (!categories || categories.length === 0) return;

    const { translationCategories } = getState().TranslationReducer;
    const categoriesWeAlreadyLoaded = Object.keys(translationCategories);
    const categoriesWeNeverLoaded = categories.filter(
      (category) => !categoriesWeAlreadyLoaded.includes(category),
    );
    if (!categoriesWeNeverLoaded || categoriesWeNeverLoaded?.length === 0)
      return;

    const res = await getRequest('/translation/get-by-category', {
      categories: categoriesWeNeverLoaded,
    });
    const newTranslationObject = {
      ...translationCategories,
      ...res.data,
    };

    dispatch({
      type: types.SET_TRANSLATION_CATEGORY,
      payload: {
        translationCategories: newTranslationObject,
      },
    });
  };
