import React, { useState } from 'react';
import { Form, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ZorbaInput } from '../../../../../../components/forms';
import PreviewRecordsTable from './Components/PreviewRecordsTable';
import { updatePropertyData } from '../../../../../../reducer/PropertyData/actions';

import './index.scss';

const PreviewStep = (props) => {
  const { job, records, loading, setLoading, setIsImportModalOpen } = props;
  const [listName, setListName] = useState('');
  const [listNameError] = useState({
    message: '',
    feedback: false,
  });
  const [isValidating] = useState(false);
  const { tags } = useSelector(
    ({ PropertyDataReducer }) => PropertyDataReducer,
  );
  const [selectedTags, setSelectedTags] = useState(job.tags);
  const tagsInSelectFormat = tags.map((tag) => ({
    label: tag.name,
    value: tag.name,
  }));
  const dispatch = useDispatch();

  const onNextClick = async () => {
    setLoading(true);
    await dispatch(
      updatePropertyData(job, listName, selectedTags, setIsImportModalOpen),
    );
    setLoading(false);
  };

  const onTagChange = (newVal) => {
    setSelectedTags(newVal);
    setListName(newVal.join('-'));
  };

  const FooterComponent = props.footer || null;

  return (
    <div className="job-wizard-modal-step job-wizard-modal-step-preview">
      <Form className="list-name-section" layout="vertical">
        <ZorbaInput
          help={listNameError.message}
          validateStatus={
            isValidating
              ? 'validating'
              : listNameError.message.length > 0
              ? 'error'
              : 'success'
          }
          hasFeedback={listNameError.feedback}
          label="Name your list"
          name="listName"
          placeholder="Tampa, Florida 33708 - vacant"
          value={listName}
          onChange={(e) => setListName(e.target.value)}
        />
        <Select
          mode="tags"
          style={{ width: '100%' }}
          placeholder="Tags Mode"
          onChange={onTagChange}
          options={tagsInSelectFormat}
        />
      </Form>
      <div className="preview-table-section">
        <PreviewRecordsTable job={job} records={records} />
      </div>
      <FooterComponent
        currentStepData={props.currentStepData}
        onBackClick={props.onBackClick}
        onNextClick={onNextClick}
        loading={loading}
        disabled={!tags.length || !listName}
      />
    </div>
  );
};

export default PreviewStep;
