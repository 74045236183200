import './index.scss';
import { FiTrash } from '@react-icons/all-files/fi/FiTrash';
import { FiEdit3 } from '@react-icons/all-files/fi/FiEdit3';
import { v4 as uuid } from 'uuid';
import { Button } from 'antd';
import { OPERATOR_TITLE_MAPPING } from '@zorba-shared/core/Constants/propertyData';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isArray } from 'lodash';
import { ZorbaButton } from '../../../../../../components/forms';
import { BUTTON_VARIANT_DARK } from '../../../../../../components/forms/ZorbaButton/constants';

const AppliedFilters = ({
  filters,
  onAddMoreFilter,
  onRemoveFilter,
  onApplyFilter,
  isAppliedFilter,
}) => {
  const { tags } = useSelector(
    ({ PropertyDataReducer }) => PropertyDataReducer,
  );
  const parsePropertyValueCallback = useCallback(
    (property, value) => {
      switch (property) {
        case 'tags':
          return isArray(value)
            ? value
                ?.map((tagId) => tags.find((tag) => tag._id === tagId)?.name)
                .join(', ')
            : value;
        default:
          return value;
      }
    },
    [filters],
  );
  return (
    <div className="property-data-applied-filter">
      <div className="property-data-applied-filter">
        <div className="title">Applied Filters</div>
        {filters?.map((filter) => (
          <div className="filter-item" key={uuid()}>
            <div className="filter-item-name">
              <strong>{filter.title}</strong>{' '}
              <span className="operator-title">
                {OPERATOR_TITLE_MAPPING[filter.operator]}
              </span>{' '}
              {parsePropertyValueCallback('tags', filter.value)}
            </div>

            <div className="btn-filter-action">
              <Button
                onClick={() => onRemoveFilter(filter)}
                icon={<FiTrash />}
                type="link"
              />
              <Button onClick={() => {}} icon={<FiEdit3 />} type="link" />
            </div>
          </div>
        ))}
      </div>
      <div className="footer-buttons">
        <ZorbaButton
          fullWidth
          variant={BUTTON_VARIANT_DARK}
          onClick={onApplyFilter}
        >
          Search
        </ZorbaButton>

        {isAppliedFilter && (
          <ZorbaButton
            fullWidth
            variant={BUTTON_VARIANT_DARK}
            onClick={onAddMoreFilter}
          >
            add more
          </ZorbaButton>
        )}
      </div>
    </div>
  );
};

export default AppliedFilters;
