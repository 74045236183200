import React, { useState, useEffect, useCallback } from 'react';
import { Drawer } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { PROPERTY_FILTER_CONFIG } from '@zorba-shared/core/Constants/propertyData';
import FilterPropertyList from './Components/FilterPropertyList';
import AppliedFilters from './Components/AppliedFilters';
import AddFilter from './Components/AddFilter';

import {
  setPropertyDataFilter,
  removePropertyDataFilter,
  searchPropertyData,
} from '../../../../reducer/PropertyData/actions';

const FilterDrawer = ({ isFilterDrawerOpen, setIsFilterDrawerOpen }) => {
  const { appliedFilters } = useSelector(
    ({ PropertyDataReducer }) => PropertyDataReducer,
  );

  const [selectedFilter, setSelectedFilter] = useState(null);
  const [isAddFilter, setIsAddFilter] = useState(false);
  const [isAppliedFilter, setIsAppliedFilter] = useState(false);
  const [isShowAllFilter, setIsShowAllFilter] = useState(true);

  const dispatch = useDispatch();

  const onChange = useCallback((filter) => {
    dispatch(setPropertyDataFilter(filter));
    setSelectedFilter(null);
    setIsAddFilter(false);
    setIsAppliedFilter(true);
  }, []);

  const onAddMoreFilter = () => {
    setIsShowAllFilter(true);
    setIsAppliedFilter(false);
  };

  const onRemoveFilter = (filter) => {
    dispatch(removePropertyDataFilter(filter));
  };

  const onApplyFilterHandler = () => {
    dispatch(
      searchPropertyData({
        filters: appliedFilters,
        page: 0,
      }),
    );
    setIsFilterDrawerOpen(false);
  };

  return (
    <Drawer
      className="search-form-drawer"
      width={420}
      title="Filters"
      placement="right"
      onClose={() => setIsFilterDrawerOpen(false)}
      open={isFilterDrawerOpen}
    >
      <AppliedFilters
        isAppliedFilter={isAppliedFilter}
        filters={appliedFilters}
        onAddMoreFilter={onAddMoreFilter}
        onRemoveFilter={onRemoveFilter}
        onApplyFilter={onApplyFilterHandler}
      />

      {isShowAllFilter && (
        <FilterPropertyList
          filterConfig={PROPERTY_FILTER_CONFIG}
          onSelectFilter={(filter) => {
            const newfilter = { ...filter, id: uuid() };
            setSelectedFilter(newfilter);
            setIsAddFilter(true);
            setIsShowAllFilter(false);
          }}
          isBackButtonEnabled={appliedFilters.length > 0}
          goBack={() => {
            setIsAppliedFilter(true);
            setIsShowAllFilter(false);
          }}
        />
      )}

      {isAddFilter && (
        <AddFilter
          selectedFilter={selectedFilter}
          goBack={() => {
            setSelectedFilter(null);
            setIsAddFilter(false);
            setIsShowAllFilter(true);
          }}
          onFilterUpdated={onChange}
        />
      )}
    </Drawer>
  );
};

export default FilterDrawer;
