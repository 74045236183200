import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ConfigProvider } from 'antd';
import AppRoutes from './routes';
import createStore, { history } from './configureStore';
import { Theme } from './constants/theme';

const createdStore = createStore(history);

const App = (props) => (
  <ConfigProvider theme={{ token: Theme() }}>
    <Provider store={createdStore}>
      <ConnectedRouter history={history}>
        <AppRoutes {...props} />
      </ConnectedRouter>
    </Provider>
  </ConfigProvider>
);

export default App;
