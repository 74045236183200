import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import _ from 'lodash';
import { setModalState } from '../../../../../../reducer/Modal/actions';
import { MODAL_FINAL_OFFER } from '../../../../../../reducer/Modal/types';
import {
  ZorbaButton,
  ZorbaInput,
  ZorbaSelect,
} from '../../../../../../components/forms';

import {
  showErrorToast,
  showSuccessToast,
} from '../../../../../../services/notificationsService';
import { postRequest } from '../../../../../../services/requestService';
import { setSelectedDealSubmission } from '../../../../../../reducer/DealSubmission/actions';

import '../RejectBuyerModal/index.scss';

export const FinalOfferModal = ({ selectedDealSubmission }) => {
  const [loading, setLoading] = useState(false);
  const { isFinalOfferModalOpen } = useSelector(
    ({ ModalReducer }) => ModalReducer,
  );
  const dispatch = useDispatch();

  const [params, setParams] = useState({});

  const buyersOptions = selectedDealSubmission.matches.map((match) => ({
    label: match.organization.name,
    value: match._id,
  }));

  const selectedDealSubmissionMatch = selectedDealSubmission.matches.find(
    (match) => match._id === params.associatedMatch,
  );
  const lastOfferEventByBuyer = _.last(
    selectedDealSubmissionMatch?.events.filter(
      (event) => event.kind === 'offer',
    ),
  );

  useEffect(() => {
    if (lastOfferEventByBuyer) {
      setParams((prevState) => ({
        ...prevState,
        finalizedOffer:
          lastOfferEventByBuyer.rawAmount -
          (selectedDealSubmissionMatch?.feeAmount || 0),
        feeAmount: selectedDealSubmissionMatch.feeAmount,
      }));
    }
  }, [lastOfferEventByBuyer]);

  const handleCloseModal = () => {
    dispatch(setModalState(MODAL_FINAL_OFFER, false));
    setParams({});
  };

  const onChange = (key, value) => {
    const updatedParams = { ...params, [key]: value };
    setParams(updatedParams);
  };

  const isFormDisabled =
    !params.associatedMatch || !params.finalizedOffer || !params.feeAmount;

  const onSubmit = async () => {
    setLoading(true);
    try {
      const res = await postRequest(
        `/backoffice/deal-submission/${selectedDealSubmission._id}`,
        params,
      );
      dispatch(setSelectedDealSubmission(res.data.dealSubmission));
      handleCloseModal();
      showSuccessToast('Final offer submitted successfully');
    } catch (e) {
      showErrorToast('Woops. huddle Kobi');
    }
    setLoading(false);
  };

  return (
    <Modal
      open={isFinalOfferModalOpen}
      footer={null}
      onCancel={handleCloseModal}
      title="Submit final offer to the Seller"
      className="reject-buyer-modal"
      destroyOnClose
    >
      <span>
        Are you sure that you want to submit final offer on behalf of the buyer?
      </span>
      <div className="select-container">
        <ZorbaSelect
          label="Select buyer*"
          options={buyersOptions}
          onChange={(e) => onChange('associatedMatch', e)}
        />
        {params.associatedMatch && (
          <>
            <ZorbaInput
              type="number"
              addonBefore="$"
              symbol=""
              label="Final Offer including our fee*"
              value={params.finalizedOffer}
              disabled
            />
            <ZorbaInput
              type="number"
              addonBefore="$"
              symbol=""
              label="Our fee*"
              value={params.feeAmount}
              onChange={(e) => {
                const newAmount = lastOfferEventByBuyer.rawAmount - e;
                const updatedParams = {
                  ...params,
                  feeAmount: e,
                  finalizedOffer: newAmount,
                };
                setParams(updatedParams);
              }}
              className="fee-amount-input"
            />
          </>
        )}
      </div>
      <div className="button-container">
        <ZorbaButton
          fullWidth={false}
          onClick={onSubmit}
          disabled={isFormDisabled || loading}
          loading={loading}
        >
          Send final offer
        </ZorbaButton>
      </div>
    </Modal>
  );
};
