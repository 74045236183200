import { Route, Switch } from 'react-router-dom';
import PrivateRoute from './utils/PrivateRoute';
import LoginPage from './pages/LoginPage';
import BuyerSearchPage from './pages/BuyerSearchPage';
import BuyerPage from './pages/BuyerPage';
import BuyerCheckerPage from './pages/BuyerCheckerPage';
import PropertyDataSearch from './pages/PropertyDataSearch';
import DealSubmissionSearchPage from './pages/DealSubmissionSearchPage';
import PartnerApprovalPage from './pages/PartnerApprovalPage';
import DealSubmissionPage from './pages/DealSubmissionPage';
import BuyersReportPage from './pages/BuyersReportPage';
import BuyerReportPage from './pages/BuyerReportPage';
import ContactRefundPage from './pages/ContactRefundPage';
import DataRequestsPage from './pages/DataRequestsPage';
import InstitutionalActivityResearchPage from './pages/InstitutionalActivityResearchPage';
import StateActivityResearchPage from './pages/StateActivityResearchPage';
import UserReferralsPage from './pages/UserReferralsPage';
import PreDealsPage from './pages/PreDealsPage';
import PreDealPage from './pages/PreDealPage';

export default () => (
  <Switch>
    <Route path="/auth/:authState" component={LoginPage} />
    <Route path="/auth/login" component={LoginPage} />
    <Route path="/login" exact component={LoginPage} />

    <PrivateRoute exact path="/buyers/:userId" component={BuyerPage} />
    <PrivateRoute exact path="/" component={BuyerSearchPage} />
    <PrivateRoute exact path="/buyer-checker" component={BuyerCheckerPage} />
    <PrivateRoute
      exact
      path="/deal-submission"
      component={DealSubmissionSearchPage}
    />
    <PrivateRoute
      exact
      path="/deal-submission/:dealSubmissionId"
      component={DealSubmissionPage}
    />
    <PrivateRoute
      exact
      path="/partner-approval"
      component={PartnerApprovalPage}
    />
    <PrivateRoute
      exact
      path="/property-data-search"
      component={PropertyDataSearch}
    />
    <PrivateRoute
      exact
      path="/buyer-activity-report"
      component={BuyersReportPage}
    />
    <PrivateRoute
      exact
      path="/buyers-report/:postgresId/:buyerName"
      component={BuyerReportPage}
    />
    <PrivateRoute exact path="/contact-refund" component={ContactRefundPage} />
    <PrivateRoute exact path="/data-requests" component={DataRequestsPage} />
    <PrivateRoute
      exact
      path="/institutional-activity-research"
      component={InstitutionalActivityResearchPage}
    />
    <PrivateRoute
      exact
      path="/state-activity-research"
      component={StateActivityResearchPage}
    />
    <PrivateRoute exact path="/user-referrals" component={UserReferralsPage} />
    <PrivateRoute exact path="/pre-deal" component={PreDealsPage} />
    <PrivateRoute exact path="/pre-deal/:preDealId" component={PreDealPage} />
  </Switch>
);
