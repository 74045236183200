import { Spin, Space } from 'antd';
import { get } from 'lodash';
import { FiCalendar } from '@react-icons/all-files/fi/FiCalendar';
import { getFormattedTime } from '@zorba-shared/core/Services/textService';
import ZorbaConfirmButton from '../../../../components/forms/ZorbaConfirmButton';

export default (props) => {
  const { submittedAt } = get(
    props,
    'selectedBuyer.defaultOrganization.verification',
    {},
  );

  return (
    <Spin spinning={props.isLoading}>
      <div className="action-required">
        <div className="action-required__body">
          <div className="zorba-list">
            <div className="zorba-list--item">
              <div className="zorba-list--item__title labled">
                ACTION REQUIRED
                {submittedAt && (
                  <Space>
                    <FiCalendar />
                    {getFormattedTime(submittedAt, 'MMM D')}
                  </Space>
                )}
              </div>
              <div className="zorba-list--item__description">
                {submittedAt
                  ? 'This buyer submitted verification details, make sure to accept or reject it.'
                  : 'This buyer has not submitted verification details, still you can verify or reject without waiting for them.'}
              </div>
            </div>
          </div>
        </div>
        <div className="action-required__footer">
          <div className="btn-group">
            <ZorbaConfirmButton
              size="large"
              confirmTitle="Do you want to reject this buyer?"
              onConfirm={() => props.onConfirm(false)}
            >
              Reject
            </ZorbaConfirmButton>

            <ZorbaConfirmButton
              type="primary"
              size="large"
              confirmTitle="Do you want to verify this buyer?"
              onConfirm={() => props.onConfirm(true)}
            >
              Verify
            </ZorbaConfirmButton>
          </div>
        </div>
      </div>
    </Spin>
  );
};
