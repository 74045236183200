/* eslint-disable react/no-unescaped-entities */
/* eslint-disable radix */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/destructuring-assignment */
import {
  BEDROOM_BATHROOM_OPTIONS,
  MIN_RADIUS_THRESHOLD,
  MAX_RADIUS_THRESHOLD,
  POOL_OPTIONS,
  PARKING_TYPES_OPTIONS,
} from '@zorba-shared/core/Constants/buybox';

import { Tag, Typography, Slider, Tabs, Popconfirm, Switch } from 'antd';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import {
  PROPERTY_TYPE_OPTIONS,
  WASTEWATER_TYPE_OPTIONS,
} from '@zorba-shared/core/Constants/deal';
import { FEMA_FLOOD_ZONE_OPTIONS } from '@zorba-shared/core/Constants/fema';
import {
  PlacesAutoComplete,
  ZorbaButton,
  ZorbaInput,
  ZorbaSelect,
} from '../../../../../../components/forms';
import { BUTTON_VARIANT_SECONDARY } from '../../../../../../components/forms/ZorbaButton/constants';
import ZorbaRadioGroup from '../../../../../../components/forms/ZorbaRadioGroup';
import {
  showSuccessToast,
  showErrorToast,
} from '../../../../../../services/notificationsService';
import {
  BUYBOX_VARIANT_ONBOARDING,
  BUYBOX_VARIANT_PAGE,
  DEFAULT_BUYBOX_PARAMS,
} from '../../constants';
import { getChangedFields } from '../../service';
import ZorbaConfirmButton from '../../../../../../components/forms/ZorbaConfirmButton';
import './index.scss';

const { Text } = Typography;

export default (props) => {
  const {
    selectedBuyBox,
    variant,
    organizationMembers,
    changedParams,
    setChangedParams,
  } = props;
  const [params, setParams] = useState();
  const notificationsRecipientsOptions = organizationMembers.map((member) => ({
    text: member.email,
    value: member.email,
  }));

  useEffect(() => {
    if (variant === BUYBOX_VARIANT_ONBOARDING) {
      setParams({ ...DEFAULT_BUYBOX_PARAMS });
    }
  }, []);

  useEffect(() => {
    setParams(
      selectedBuyBox
        ? {
            ...selectedBuyBox,
            priceRange:
              selectedBuyBox.priceRange.length === 0
                ? [undefined, undefined]
                : selectedBuyBox.priceRange,
            yearBuiltRange:
              selectedBuyBox.yearBuiltRange.length === 0
                ? [undefined, undefined]
                : selectedBuyBox.yearBuiltRange,
            radiusRange:
              selectedBuyBox.radiusRange || DEFAULT_BUYBOX_PARAMS.radiusRange,
            notificationsRecipients:
              selectedBuyBox.notificationsRecipients ||
              DEFAULT_BUYBOX_PARAMS.notificationsRecipients,
            isBuyBoxActive:
              selectedBuyBox.isBuyBoxActive != null
                ? selectedBuyBox.isBuyBoxActive
                : DEFAULT_BUYBOX_PARAMS.isBuyBoxActive,
          }
        : undefined,
    );
    setChangedParams({});
  }, [selectedBuyBox]);

  const onChange = (key, value) => {
    const updatedParams = { ...params, [key]: value };

    setParams(updatedParams);
    const changedFields = getChangedFields(selectedBuyBox, updatedParams);
    setChangedParams(changedFields);
    if (variant === BUYBOX_VARIANT_ONBOARDING && props.onChange) {
      props.onChange({ ...updatedParams });
    }
  };

  const onRadioChange = (key, val) => {
    const maxValue = params[key][1];
    onChange(key, [parseInt(val), maxValue]); // The max value stays the same, the min value is the one we are changing
  };
  const onRangeChange = (key, newValue, modifiedValueIndex) => {
    const updatedValue = [...params[key]];
    updatedValue[modifiedValueIndex] = newValue;

    if (modifiedValueIndex === 0) {
      updatedValue[1] = undefined;
    }
    onChange(key, updatedValue);
  };
  const onPlacesChange = (address) => {
    if (!address?.formattedAddress) return;
    if (
      params.areas.filter(
        (x) => x.formattedAddress === address.formattedAddress,
      ).length > 0
    )
      return;

    onChange(
      'areas',
      [...params.areas, address].filter((x) => !!x),
    );
  };
  const onPlaceDelete = (singleArea) => {
    const updatedAreas = params.areas.filter(
      (x) => x.formattedAddress !== singleArea.formattedAddress,
    );
    onChange('areas', [...updatedAreas]);
  };
  const onSaveClick = () => {
    if (!params.propertyTypes?.length) {
      showErrorToast('Property Type is required.');
      return;
    }
    // UPDATE THE DB
    props.onUpdateCriteria &&
      props.onUpdateCriteria({ ...params, ...changedParams });
    variant === BUYBOX_VARIANT_PAGE &&
      showSuccessToast('Your buying criteria was saved successfully.');
  };
  const onRemoveClick = () => {
    props.onDeleteCriteria && props.onDeleteCriteria(params);
  };

  const onPreviewClick = () => {
    if (typeof props.onPreviewBuyBoxMatchProperties === 'function') {
      props.onPreviewBuyBoxMatchProperties(params);
    }
  };

  if (!params) return null;

  return (
    <div className={classNames('add-buybox-form', [variant])}>
      {/* ACTION BUTTONS */}
      <div className="actions">
        <ZorbaButton
          fullWidth={false}
          variant={BUTTON_VARIANT_SECONDARY}
          disabled={Object.keys(changedParams).length === 0}
          onClick={onSaveClick}
        >
          Save Criteria
        </ZorbaButton>

        <ZorbaButton
          fullWidth={false}
          variant={BUTTON_VARIANT_SECONDARY}
          onClick={onPreviewClick}
          style={{ marginLeft: 15 }}
        >
          Preview
        </ZorbaButton>

        <Popconfirm>
          <ZorbaConfirmButton
            size="large"
            confirmTitle="Are you sure you want to remove this buy box?"
            onConfirm={onRemoveClick}
            style={{ marginLeft: 15 }}
          >
            Delete buybox
          </ZorbaConfirmButton>
        </Popconfirm>
      </div>
      <div className="tabs-container">
        <Tabs type="card" className="secondary-tabs">
          <Tabs.TabPane tab="Info" key="info">
            <div className="form-container">
              <div className="switch-container">
                <label>Is buy box active?</label>
                <Switch
                  className="switch"
                  checked={params.isBuyBoxActive}
                  onChange={(checked) => onChange('isBuyBoxActive', checked)}
                />
              </div>
              <div className="form-column">
                {variant !== BUYBOX_VARIANT_ONBOARDING && (
                  <div className="form-item">
                    <div className="form-title">Name your criteria</div>
                    <div className="form-field">
                      <ZorbaInput
                        placeholder="Fix & Flip in Central Florida"
                        onChange={(e) => onChange('title', e.target.value)}
                        value={params.title}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="form-column">
                {/* Radius */}
                <div className="form-item form-item__radius">
                  <div className="form-title">Radius</div>
                  <div className="form-field">
                    <Slider
                      min={MIN_RADIUS_THRESHOLD}
                      max={MAX_RADIUS_THRESHOLD}
                      value={params.radius}
                      tooltip={{
                        formatter: (value) => `${value} Miles`,
                        getPopupContainer: (triggerNode) =>
                          triggerNode.parentNode,
                      }}
                      style={{ width: '100%' }}
                      marks={{
                        [MIN_RADIUS_THRESHOLD]: `${MIN_RADIUS_THRESHOLD} Miles`,
                        [MAX_RADIUS_THRESHOLD]: `${MAX_RADIUS_THRESHOLD} Miles`,
                      }}
                      onChange={(value) => onChange('radius', value)}
                    />
                  </div>
                  <Text type="secondary">
                    Describe how far from the areas you mentioned you like to
                    reach. The wider the radius the more deals you will get.
                  </Text>
                </div>

                {/* PRICE RANGE */}
                <div className="form-item">
                  <div className="form-title">Asking price range</div>
                  <div className="form-field">
                    <ZorbaInput
                      placeholder="No min"
                      value={params.priceRange[0]}
                      type="number"
                      onChange={(nevVal) =>
                        onRangeChange('priceRange', nevVal, 0)
                      }
                      minValue={0}
                    />
                    <span className="separator">-</span>
                    <ZorbaInput
                      placeholder="No max"
                      value={params.priceRange[1]}
                      type="number"
                      onChange={(nevVal) =>
                        onRangeChange('priceRange', nevVal, 1)
                      }
                      minValue={0}
                    />
                  </div>
                  <Text type="secondary">
                    We will disqualify deals where the asking price is higher
                    than the max asking price here
                  </Text>
                </div>
                {/* REHAB */}
                <div className="form-item">
                  <div className="form-title">Rehab estimate range</div>
                  <div className="form-field">
                    <ZorbaInput
                      placeholder="No min"
                      value={params.rehabRange[0]}
                      type="number"
                      onChange={(nevVal) =>
                        onRangeChange('rehabRange', nevVal, 0)
                      }
                      minValue={0}
                    />
                    <span className="separator">-</span>
                    <ZorbaInput
                      placeholder="No max"
                      value={params.rehabRange[1]}
                      type="number"
                      onChange={(nevVal) =>
                        onRangeChange('rehabRange', nevVal, 1)
                      }
                      minValue={0}
                    />
                  </div>
                </div>
                {/* Bedrooms */}
                <div className="form-item">
                  <div className="form-field">
                    <ZorbaRadioGroup
                      label="Bedrooms"
                      onChange={(e) =>
                        onRadioChange('bedroomRange', e.target.value)
                      }
                      value={(params.bedroomRange[0] || '0').toString()}
                      options={BEDROOM_BATHROOM_OPTIONS}
                    />
                  </div>
                </div>

                {/* Bathrooms */}
                <div className="form-item">
                  <div className="form-field">
                    <ZorbaRadioGroup
                      label="Bathrooms"
                      onChange={(e) =>
                        onRadioChange('bathroomRange', e.target.value)
                      }
                      value={(params.bathroomRange[0] || '0').toString()}
                      options={BEDROOM_BATHROOM_OPTIONS}
                    />
                  </div>
                </div>

                {/* Building size range */}
                <div className="form-item">
                  <div className="form-title">Building size range</div>
                  <div className="form-field">
                    <ZorbaInput
                      placeholder="No min"
                      value={params.buildingSizeRange[0]}
                      type="number"
                      onChange={(nevVal) =>
                        onRangeChange('buildingSizeRange', nevVal, 0)
                      }
                      symbol=""
                      minValue={0}
                      disableFormatter
                      addonAfter="sqft"
                    />
                    <span className="separator">-</span>
                    <ZorbaInput
                      placeholder="No max"
                      value={params.buildingSizeRange[1]}
                      type="number"
                      onChange={(nevVal) =>
                        onRangeChange('buildingSizeRange', nevVal, 1)
                      }
                      symbol=""
                      minValue={0}
                      disableFormatter
                      addonAfter="sqft"
                    />
                  </div>
                </div>

                {/* Lot size range */}
                <div className="form-item">
                  <div className="form-title">Lot size range</div>
                  <div className="form-field">
                    <ZorbaInput
                      placeholder="No min"
                      value={params.lotSizeRange[0]}
                      type="number"
                      onChange={(nevVal) =>
                        onRangeChange('lotSizeRange', nevVal, 0)
                      }
                      symbol=""
                      minValue={0}
                      disableFormatter
                      addonAfter="sqft"
                    />
                    <span className="separator">-</span>
                    <ZorbaInput
                      placeholder="No max"
                      value={params.lotSizeRange[1]}
                      type="number"
                      onChange={(nevVal) =>
                        onRangeChange('lotSizeRange', nevVal, 1)
                      }
                      symbol=""
                      minValue={0}
                      disableFormatter
                      addonAfter="sqft"
                    />
                  </div>
                </div>

                {/* Year Built */}
                <div className="form-item">
                  <div className="form-title">Year Built</div>
                  <div className="form-field">
                    <ZorbaInput
                      placeholder="No min"
                      value={params.yearBuiltRange[0]}
                      type="number"
                      onChange={(nevVal) =>
                        onRangeChange('yearBuiltRange', nevVal, 0)
                      }
                      symbol=""
                      minValue={0}
                      maxValue={new Date().getFullYear()}
                      disableFormatter
                    />
                    <span className="separator">-</span>
                    <ZorbaInput
                      placeholder="No max"
                      value={params.yearBuiltRange[1]}
                      type="number"
                      onChange={(nevVal) =>
                        onRangeChange('yearBuiltRange', nevVal, 1)
                      }
                      symbol=""
                      minValue={0}
                      maxValue={new Date().getFullYear()}
                      disableFormatter
                    />
                  </div>
                </div>

                {/* PROPERTY TYPES */}
                <div className="form-item">
                  <div className="form-title">Property types</div>
                  <div className="form-field">
                    <ZorbaSelect
                      mode="multiple"
                      options={PROPERTY_TYPE_OPTIONS}
                      value={params.propertyTypes}
                      onChange={(newVal) => onChange('propertyTypes', newVal)}
                    />
                  </div>
                </div>

                {/* ALLOWED Flood Zones */}
                <div className="form-item">
                  <div className="form-title">Allowed Flood Zones</div>
                  <div className="form-field">
                    <ZorbaSelect
                      mode="multiple"
                      options={FEMA_FLOOD_ZONE_OPTIONS}
                      value={params.allowedFloodZones}
                      onChange={(newVal) =>
                        onChange('allowedFloodZones', newVal)
                      }
                    />
                  </div>
                </div>

                {/* Wastewater type */}
                <div className="form-item">
                  <div className="form-title">Wastewater type</div>
                  <div className="form-field">
                    <ZorbaSelect
                      mode="multiple"
                      options={WASTEWATER_TYPE_OPTIONS}
                      value={params.wasteWaters}
                      onChange={(newVal) => onChange('wasteWaters', newVal)}
                    />
                  </div>
                </div>

                {/* POOL */}
                <div className="form-item">
                  <div className="form-title">Pool</div>
                  <div className="form-field">
                    <ZorbaSelect
                      onChange={(e) => onChange('pool', e)}
                      value={params.pool}
                      options={POOL_OPTIONS}
                    />
                  </div>
                </div>

                {/* PARKING TYPES */}
                <div className="form-item">
                  <div className="form-title">Parking types</div>
                  <div className="form-field">
                    <ZorbaSelect
                      mode="multiple"
                      options={PARKING_TYPES_OPTIONS}
                      onChange={(e) => onChange('parkingTypes', e)}
                      value={params.parkingTypes}
                    />
                  </div>
                </div>

                {/* PARKING/GARAGE */}
                <div className="form-item">
                  <div className="form-title">Min parking spots/Garage</div>
                  <div className="form-field">
                    <ZorbaSelect
                      options={BEDROOM_BATHROOM_OPTIONS}
                      placeholder="Any"
                      onChange={(newVal) =>
                        onChange('minParkingSpace', newVal, 0)
                      }
                      value={params.minParkingSpace}
                    />
                  </div>
                </div>
                {/* MIN SCHOOL RATING */}
                <div className="form-item">
                  <div className="form-title">Min School Rating</div>
                  <div className="form-field">
                    <ZorbaInput
                      placeholder="Min school rating"
                      value={params.minSchoolRating}
                      type="number"
                      onChange={(nevVal) => onChange('minSchoolRating', nevVal)}
                      symbol=""
                      minValue={0}
                      maxValue={10}
                      disableFormatter
                    />
                  </div>
                </div>
              </div>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Areas" key="areas">
            <div className="form-item">
              <div className="form-field">
                <ZorbaSelect
                  label="Excluded zip codes from matching algorithm"
                  mode="tags"
                  onChange={(e) => onChange('excludedZipCodes', e)}
                  options={[]}
                  value={params.excludedZipCodes}
                />
              </div>
            </div>
            <div className="form-container">
              <div className="form-column">
                {/* Areas */}
                <div className="form-item address">
                  <div className="form-title">I'm looking to buy in</div>
                  <div className="form-field">
                    <PlacesAutoComplete
                      itemClassName="area-list-auto-complete"
                      title={` `}
                      clearInputOnSelect
                      help={
                        props.areasError ||
                        'US only. For example: 33605, Hillsborough County.'
                      }
                      onChange={(address) => onPlacesChange(address)}
                    />
                  </div>
                </div>
                <div className="form-item">
                  <div className="form-title" />
                  <div className="form-field">
                    <div className="area-list-wrapper">
                      {params.areas.map((singleArea) => (
                        <Tag
                          key={singleArea.formattedAddress}
                          closable
                          onClose={() => onPlaceDelete(singleArea)}
                        >
                          {singleArea.formattedAddress}
                        </Tag>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Contact Info" key="contact">
            <div className="form-column">
              {variant !== BUYBOX_VARIANT_ONBOARDING && (
                <div className="form-item">
                  <div className="form-title">Recipient emails</div>
                  <div className="form-field">
                    <ZorbaSelect
                      mode="tags"
                      onChange={(e) => onChange('notificationsRecipients', e)}
                      options={notificationsRecipientsOptions}
                      value={params.notificationsRecipients}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="form-column">
              {variant !== BUYBOX_VARIANT_ONBOARDING && (
                <div className="form-item">
                  <div className="form-title">Agent name</div>
                  <div className="form-field">
                    <ZorbaInput
                      onChange={(e) => onChange('agentName', e.target.value)}
                      value={params.agentName}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="form-column">
              {variant !== BUYBOX_VARIANT_ONBOARDING && (
                <div className="form-item">
                  <div className="form-title">Recipient phones</div>
                  <div className="form-field">
                    <ZorbaSelect
                      mode="tags"
                      onChange={(e) => onChange('notificationsPhones', e)}
                      options={[]}
                      value={params.notificationsPhones}
                    />
                  </div>
                </div>
              )}
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
};
