/* eslint-disable default-param-last */

import * as types from './types';

const initialState = {
  isLoading: false,
  error: null,
};
export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_LISTING_LOADING:
      return {
        ...state,
        ...payload,
      };
    case types.SET_LISTING_ERROR:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
