import { MappingFieldsStep, PreviewStep, UploadStep } from './Components';
import InstructionsStep from './Components/InstructionsStep';

export const JOB_WIZARD_STEPS = [
  {
    key: 'instructions',
    title: 'Instructions',
    progressBarTitle: 'Prepare',
    displaySteps: false,
    getComponent: (props) => <InstructionsStep {...props} />,
    nextButtonText: "Let's Start!",
  },
  {
    key: 'upload',
    title: 'Upload',
    progressBarTitle: 'Upload',
    displaySteps: false,
    getComponent: (props) => <UploadStep {...props} />,
    overrideFooter: true,
  },
  {
    key: 'mapping',
    title: 'Match',
    progressBarTitle: 'Match',
    displaySteps: true,
    nextButtonText: 'Next',
    getComponent: (props) => <MappingFieldsStep {...props} />,
    overrideFooter: true,
  },
  {
    key: 'preview',
    title: 'Review',
    progressBarTitle: 'Review',
    displaySteps: true,
    nextButtonText: 'Import Records',
    backButtonText: 'Edit Mapping',
    getComponent: (props) => <PreviewStep {...props} />,
    overrideFooter: true,
  },
];
