import { Tooltip } from 'antd';
import { AiFillCloseCircle } from '@react-icons/all-files/ai/AiFillCloseCircle';
import { AiFillCheckCircle } from '@react-icons/all-files/ai/AiFillCheckCircle';
import { BsPerson } from '@react-icons/all-files/bs/BsPerson';
import { Link } from 'react-router-dom';
import { buildBuyerPageUrl } from '../../../../services/urlBuilderService';

import { setDrawerState } from '../../../../reducer/Drawer/actions';
import { DRAWER_DEAL_SUBMISSION_MATCH } from '../../../../reducer/Drawer/types';

import './index.scss';

export const getTableColumns = (dispatch) => [
  ...(dispatch
    ? [
        {
          title: '',
          align: 'center',
          render: (data) =>
            !data.match && (
              <Tooltip title="Associate this buyer to the deal">
                <div
                  onClick={() => {
                    dispatch(
                      setDrawerState(DRAWER_DEAL_SUBMISSION_MATCH, true, {
                        ...data,
                        organization: { _id: data.organization },
                      }),
                    );
                  }}
                >
                  <BsPerson className="associate-icon" />
                </div>
              </Tooltip>
            ),
        },
      ]
    : []),
  {
    title: 'Buyer',
    key: 'name',
    render: ({ name, buyer }) => (
      <Link
        className="buyer-name"
        to={buildBuyerPageUrl({ userDetails: { _id: buyer } })}
      >
        {name}
      </Link>
    ),
  },
  {
    title: 'Match',
    key: 'status',
    dataIndex: 'status',
    render: (_, { status, reasons }) =>
      status ? (
        <AiFillCheckCircle
          style={{ color: '#49e387', marginRight: 5, fontSize: 20 }}
        />
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <AiFillCloseCircle
            style={{ color: '#fc6d6d', marginRight: 5, fontSize: 20 }}
          />
          {reasons?.join(',')}
        </div>
      ),
  },
];
