export const getTitle = (stepIndex) => {
  let resultTitle = '';

  switch (stepIndex) {
    case 0:
      resultTitle = 'Skip Trace instructions';
      break;
    default:
      break;
  }

  return resultTitle;
};
