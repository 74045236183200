import { useState, useEffect } from 'react';
import { Table } from 'antd';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { getRequest, postRequest } from '../../services/requestService';
import {
  showErrorToast,
  showSuccessToast,
} from '../../services/notificationsService';
import { getTableColumns } from './constants';
import { isMobile } from '../../services/clientService';

const ContactRefundPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [contactsToRefund, setContactsToRefund] = useState([]);
  useDocumentTitle(`Contact refund`);

  const loadContactsToRefund = async () => {
    setIsLoading(true);
    try {
      const res = await getRequest('/backoffice/data/get-contacts-to-refund');
      setContactsToRefund(res?.data?.data);
    } catch (error) {
      showErrorToast('Fetching contacts to refund failed');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadContactsToRefund();
  }, []);

  const handleRefundCredit = async (params) => {
    setIsLoading(true);
    try {
      await postRequest('/backoffice/data/refund-credit', params);
      showSuccessToast('Contact credit successfully refunded');
      loadContactsToRefund();
    } catch (error) {
      showErrorToast('Refunding credit failed');
    }
    setIsLoading(false);
  };

  const tableScroll = {
    scrollToFirstRowOnChange: true,
    x: isMobile() ? true : 'max-content',
  };

  return (
    <div className="contact-refund-page">
      <Table
        rowKey="transactionId"
        dataSource={contactsToRefund}
        loading={isLoading}
        columns={getTableColumns(handleRefundCredit)}
        scroll={tableScroll}
      />
    </div>
  );
};

export default ContactRefundPage;
