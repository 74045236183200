import {
  getFormattedTime,
  getPriceText,
} from '@zorba-shared/core/Services/textService';
import { Card, Tag } from 'antd';
import { Spinner } from '../../../../../../components/Spinner';

export const TransactionsList = ({
  isLoading,
  transactions,
  mapRef,
  setPopupInfo,
}) => {
  if (isLoading) return <Spinner />;

  return transactions.map((transaction, idx) => (
    <Card
      id={transaction.property_id}
      className="transaction-card"
      onMouseEnter={() => {
        mapRef.flyTo({
          center: [transaction.longitude, transaction.latitude],
          zoom: 15,
          duration: 2000,
          essential: true,
        });
        setPopupInfo({ ...transaction });
      }}
      onMouseLeave={() => {
        setPopupInfo(null);
      }}
    >
      <span className="transaction-title">{`${idx + 1}. ${
        transaction.address
      }`}</span>
      <div>
        <span style={{ fontSize: '14px' }}>Buyer: </span>
        <Tag style={{ fontSize: '14px' }}>
          {` ${transaction.buyer} ${
            transaction.organization_name
              ? `(${transaction.organization_name})`
              : ''
          }`}
        </Tag>
      </div>
      <span>{`Mailing address: ${transaction.mailing_address}`}</span>
      <span>
        Purchase price:{' '}
        {transaction.transaction_amount &&
        transaction.transaction_amount !== '0'
          ? getPriceText(transaction.transaction_amount)
          : 'N/D'}
      </span>
      <span>{`Beds: ${transaction.beds ? transaction.beds : 'N/D'} | Baths: ${
        transaction.baths ? transaction.baths : 'N/D'
      } | Year built: ${
        transaction.year_built ? transaction.year_built : 'N/D'
      }`}</span>
      <span>{`Lender: ${
        transaction.lender_name ? transaction.lender_name : 'N/D'
      }`}</span>
      <span>
        Sold at: {getFormattedTime(transaction.transaction_date, 'MM/DD/YYYY')}
      </span>
    </Card>
  ));
};
