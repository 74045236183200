import { SiZillow } from '@react-icons/all-files/si/SiZillow';
import { SiHubspot } from '@react-icons/all-files/si/SiHubspot';

export const MAPPED_PARAMS = [
  { title: 'Property Type', prop: 'homeType' },
  { title: 'Zestimate', prop: 'zestimate', type: 'price' },
  { title: 'Rent Zestimate', prop: 'rentZestimate', type: 'price' },
  { title: 'Year built', prop: 'yearBuilt' },
  { title: 'Beds', prop: 'bedrooms' },
  { title: 'Baths', prop: 'bathrooms' },
  { title: 'Building Size', prop: 'livingAreaValue' },
  { title: 'Lot size', prop: 'lotSize' },
  { title: 'School Rating', prop: 'schoolRating' },
  { title: 'Has Pool', prop: 'hasPool' },
  { title: 'Parking Spaces', prop: 'parkingSpaces' },
  { title: 'Hoa Fee', prop: 'hoaFee', type: 'price' },
];

export const MAPPED_TAX_ASSESSOR_PARAMS = [
  { title: 'Year built', prop: 'yearBuilt' },
  { title: 'Beds', prop: 'bedroom' },
  { title: 'Baths', prop: 'bathroom' },
  { title: 'Building Size', prop: 'buildingSize' },
  { title: 'Lot Size', prop: 'lotSize' },
  { title: 'Has Pool', prop: 'hasPool', type: 'boolean' },
  { title: 'Parking Spaces', prop: 'parkingSpaces' },
];

export const combineObjectsByTitle = (arr) => {
  const result = arr.reduce((acc, obj) => {
    if (obj.title in acc) {
      acc[obj.title].push(obj.reason);
    } else {
      acc[obj.title] = [obj.reason];
    }
    return acc;
  }, {});
  return Object.entries(result).map(([title, reasons]) => ({
    title,
    reason: reasons.join(', '),
  }));
};

/**
 * An Helper function that return build a property links for hubspot and zillow
 *
 * @param {*} zillowUrl
 * @param {*} hubspotUrl
 */
export const createProviderButtons = (zillowUrl, hubspotUrl) => {
  const buttons = [];
  if (zillowUrl) {
    buttons.push({
      label: 'View on Zillow',
      target: '_blank',
      href: `https://www.zillow.com${zillowUrl}`,
      rel: 'noreferrer',
      icon: <SiZillow color="#0074E4" />,
    });
  }

  if (hubspotUrl) {
    buttons.push({
      label: 'View on Hubspot',
      target: '_blank',
      href: `https://app.hubspot.com/contacts/9142635/deal/${hubspotUrl}`,
      rel: 'noreferrer',
      icon: <SiHubspot color="#FF7A59" />,
    });
  }
  return buttons;
};
