import { Tag, Spin } from 'antd';

import './index.scss';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 14 }} spin />;

export default ({ matchingCount, isLoading = false }) => (
  <Tag color="blue" className="matched-property-count">
    {isLoading && <Spin indicator={antIcon} />}
    {!isLoading && <span>{matchingCount || 0} Matches</span>}
  </Tag>
);
