import './index.scss';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { YES_NO_OPTIONS } from '@zorba-shared/core/Constants/buybox';
import {
  OPERATOR_VALUE_BOOLEAN,
  OPERATOR_EQUAL,
  OPERATOR_VALUE_VALUE_ARRAY,
  OPERATOR_ANY_OF,
} from '@zorba-shared/core/Constants/propertyData';
import {
  ZorbaRadioGroup,
  ZorbaSelect,
} from '../../../../../../components/forms';
import { Select } from 'antd';

const FilterOperatorValue = (props) => {
  const { initialValue, valueType, selectedOperator, operator, onChange } =
    props;

  const [propertyValue, setPropertyValue] = useState();
  const { tags } = useSelector(
    ({ PropertyDataReducer }) => PropertyDataReducer,
  );

  useEffect(() => {
    setPropertyValue(initialValue);
  }, [initialValue]);

  const isBooleanIsEqual =
    valueType === OPERATOR_VALUE_BOOLEAN &&
    selectedOperator === OPERATOR_EQUAL &&
    operator === OPERATOR_EQUAL;

  const isArrayIsEqual =
    valueType === OPERATOR_VALUE_VALUE_ARRAY &&
    selectedOperator === OPERATOR_EQUAL &&
    operator === OPERATOR_EQUAL;

  const isArrayAnyOf =
    valueType === OPERATOR_VALUE_VALUE_ARRAY &&
    selectedOperator === OPERATOR_ANY_OF &&
    operator === OPERATOR_ANY_OF;

  const onChangeHandler = (val) => {
    setPropertyValue(val);
    onChange(val);
  };

  return (
    <div className="filter-value">
      {isBooleanIsEqual && (
        <ZorbaRadioGroup
          options={YES_NO_OPTIONS}
          onChange={(event) => onChangeHandler(event.target.value)}
          value={propertyValue}
        />
      )}

      {isArrayIsEqual && (
        <ZorbaRadioGroup
          options={YES_NO_OPTIONS}
          onChange={(event) => onChangeHandler(event.target.value)}
          value={propertyValue}
        />
      )}

      {isArrayAnyOf && (
        <div className="tags-container">
          <Select
            mode="tags"
            className="tags-select"
            placeholder="Add Tags"
            value={propertyValue}
            onChange={(newVal) => onChangeHandler(newVal)}
            options={tags.map((tag) => ({
              label: tag.name,
              value: tag._id,
            }))}
          />
        </div>
      )}
    </div>
  );
};

export default FilterOperatorValue;
