import { push, replace } from 'connected-react-router';

import {
  getAuthToken,
  logoutUser,
  QUERY_PARAM_MAGIC_TOKEN,
  QUERY_PARAM_ORG_INVITE,
  QUERY_PARAM_USER_ID,
  setAuthToken,
} from '../../services/authService';
import {
  getAndDeleteCookie,
  getQueryParam,
} from '../../services/clientService';
import { showErrorToast } from '../../services/notificationsService';
import { getRequest, postRequest } from '../../services/requestService';
import {
  buildAuthPageUrl,
  buildHomePageUrl,
  removeQueryParam,
  getRedirectUrl,
} from '../../services/urlBuilderService';
import { buildQueryString } from '../../services/urlService';

import * as types from './types';
import { getUserPermissions } from './utils';

export function setAuthLoadingState(isAuthLoading) {
  return {
    type: types.SET_AUTH_LOADING_STATE,
    payload: { isAuthLoading },
  };
}

export const providerLogin = (formData) => async (dispatch) => {
  dispatch({ type: types.PROVIDER_LOGIN, payload: { isAuthLoading: true } });
  formData.emailInviteId = getQueryParam('email_invite');
  const orgInviteId = getAndDeleteCookie(QUERY_PARAM_ORG_INVITE);
  formData.redirectUri = buildAuthPageUrl({ isAbsolute: true });

  if (orgInviteId) {
    formData[QUERY_PARAM_ORG_INVITE] = orgInviteId;
  }

  try {
    const res = await postRequest('/auth/provider-login', formData);

    const { token, userDetails } = res.data;
    if (!token) throw new Error('Missing token');
    if (!userDetails.permissions || userDetails.permissions.length === 0) {
      window.location.reload();
    }
    setAuthToken(token);

    dispatch({
      type: types.SET_USER_DATA,
      payload: { isAuthLoading: false, isAppLoading: false, ...res.data },
    });
    dispatch({
      type: types.SET_PERMISSIONS,
      payload: {
        permissions: getUserPermissions(userDetails.permissions),
      },
    });

    redirectAfterLoginOrSignup(dispatch, userDetails);
  } catch (e) {
    showErrorToast('Oops. Something happened');
  }
  dispatch({ type: types.PROVIDER_LOGIN, payload: { isAuthLoading: false } });
};

const redirectAfterLoginOrSignup = (dispatch, userDetails) => {
  let redirectUrl = getRedirectUrl();
  if (!redirectUrl) {
    redirectUrl = buildHomePageUrl();
  }

  dispatch(replace(redirectUrl));
};

export function setGuest() {
  return {
    type: types.SET_GUEST,
    payload: { isInit: true },
  };
}

export const getUserData =
  (uid = '', placeFriendlyUrl = '') =>
  async (dispatch) => {
    let shouldRefreshUrl = false;
    let newUrl;
    const magicToken = getQueryParam(QUERY_PARAM_MAGIC_TOKEN);
    if (uid) {
      newUrl = removeQueryParam(
        window.location.pathname + window.location.search,
        'uid',
      );
      shouldRefreshUrl = true;
    }

    if (shouldRefreshUrl) {
      dispatch(replace(newUrl));
    }
    try {
      dispatch({
        type: types.SET_USER_DATA,
        payload: { isAppLoading: true, isAuthLoading: true, isInit: true },
      });

      const requestUrl = buildQueryString('/auth/get-user-data', {
        [QUERY_PARAM_USER_ID]: uid,
        placeFriendlyUrl,
      });

      const res = await getRequest(
        requestUrl,
        {},
        {
          headers: {
            'x-magic-token': magicToken,
            'x-access-token': getAuthToken(),
          },
        },
      );
      dispatch({
        type: types.SET_USER_DATA,
        payload: {
          ...res.data,
          isAuthLoading: false,
          isAppLoading: false,
        },
      });
      dispatch({
        type: types.SET_PERMISSIONS,
        payload: {
          permissions: getUserPermissions(res?.data?.userDetails.permissions),
        },
      });
    } catch (e) {
      if (e?.response?.status === 401) {
        const redirectUrl = removeQueryParam(
          window.location.pathname + window.location.search,
          QUERY_PARAM_USER_ID,
        );
        logoutUser();

        const loginUrl = buildAuthPageUrl({ isAbsolute: false, redirectUrl });
        loginUrl && dispatch(push(loginUrl));
        dispatch({
          type: types.SET_USER_DATA,
          payload: { isAuthLoading: false, isAppLoading: false },
        });
      } else {
        showErrorToast('Oops. Network Error');
      }
    }
  };

export const updateUserDetails =
  (key, value, callback) => async (dispatch, getState) => {
    const { userDetails } = getState().AuthReducer;
    userDetails[key] = value;
    dispatch({ type: types.SET_USER_DATA, payload: { userDetails } });
  };

export const updateUserDataParams = (params) => (dispatch, getState) => {
  const updatedUserDetails = {
    ...getState().AuthReducer.userDetails,
    ...params,
  };
  dispatch({
    type: types.SET_USER_DATA,
    payload: { userDetails: updatedUserDetails },
  });
};

export const setEmailVerified = () => async (dispatch, getState) => {
  const { userDetails } = getState().AuthReducer;
  const updatedUserDetails = {
    ...userDetails,
    isEmailVerified: true,
  };
  dispatch({
    type: types.SET_USER_DATA,
    payload: { userDetails: updatedUserDetails },
  });
};

export const setPhoneVerified =
  (updatedNumebr) => async (dispatch, getState) => {
    const { userDetails } = getState().AuthReducer;
    const updatedUserDetails = {
      ...userDetails,
      phoneNumber: {
        ...userDetails.phoneNumber,
        verified: true,
        number: updatedNumebr,
      },
    };
    dispatch({
      type: types.SET_USER_DATA,
      payload: { userDetails: updatedUserDetails },
    });
  };
