/* eslint-disable default-param-last */
import { message } from 'antd';
import { DEFAULT_ERROR_MESSAGE } from './constants';

export const showSuccessToast = (msg) => {
  message.success(msg);
};

export const showErrorToast = (msg = DEFAULT_ERROR_MESSAGE, timeout) => {
  message.error(msg, timeout);
};
