import React from 'react';
import { Layout } from 'antd';
import { AiOutlineMenu } from '@react-icons/all-files/ai/AiOutlineMenu';

const { Header } = Layout;

export default (props) => (
  <Header className="site-layout-background">
    {React.createElement(props.collapsed ? AiOutlineMenu : AiOutlineMenu, {
      className: 'trigger',
      onClick: () => props.setCollapsed(!props.collapsed),
    })}
  </Header>
);
