import {
  MAX_RADIUS_THRESHOLD,
  MIN_RADIUS_THRESHOLD,
} from '@zorba-shared/core/Constants/buybox';
import { PROPERTY_TYPE_SINGLE_FAMILY } from '@zorba-shared/core/Constants/deal';

export const DEFAULT_BUYBOX_PARAMS = {
  title: '',
  areas: [],
  bedroomRange: [0, 10],
  bathroomRange: [0, 10],
  yearBuiltRange: [undefined, undefined],
  // buildingSizeRange: [1000, 10000],
  priceRange: [undefined, undefined],
  rehabRange: [undefined, undefined],
  buildingSizeRange: [undefined, undefined],
  lotSizeRange: [undefined, undefined],
  radiusRange: [MIN_RADIUS_THRESHOLD, MAX_RADIUS_THRESHOLD], // In Miles
  propertyTypes: [PROPERTY_TYPE_SINGLE_FAMILY],
  pool: 'any',
  minParkingSpace: 0,
  minSchoolRating: 0,
  taxRatePercent: 0,
  annualPropertyManagementPercentage: 0,
  maxRehabPrice: 0,
  minCapRate: 0,
  wasteWaters: [],
  allowedFloodZones: [],
  notificationsRecipients: [],
  parkingTypes: [],
  isBuyBoxActive: true,
  notificationsPhones: [],
  agentName: '',
};

export const BUYBOX_VARIANT_PAGE = 'page';
export const BUYBOX_VARIANT_ONBOARDING = 'onboarding';
