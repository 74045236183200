import * as types from './types';

export function setDrawerState(drawerName, drawerStatus, drawerParams = {}) {
  return {
    type: types.SET_DRAWER_STATE,
    payload: {
      [drawerName]: drawerStatus,
      drawerParams,
    },
  };
}
