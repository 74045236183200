import { Table } from 'antd';
import { useSelector } from 'react-redux';
import { getTableColumns } from './constants';

export const AcquiredPropertiesTable = () => {
  const { selectedOrganizationData, isLoading } = useSelector(
    ({ BuyersReportReducer }) => BuyersReportReducer,
  );

  return (
    <Table
      rowKey="name"
      loading={isLoading}
      columns={getTableColumns()}
      dataSource={selectedOrganizationData?.acquiredProperties || []}
    />
  );
};
