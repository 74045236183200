import { Tag } from 'antd';
import classNames from 'classnames';

import './index.scss';

export const TagItem = ({ type, children }) => (
  <Tag
    className={classNames('zorba-tag', {
      success: type === 'success',
      error: type === 'error',
    })}
  >
    {children}
  </Tag>
);
