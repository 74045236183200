import { Link } from 'react-router-dom';
import { MdArrowBack } from '@react-icons/all-files/md/MdArrowBack';
import { Tag } from 'antd';
import { PROPERTY_TYPE_LOT } from '@zorba-shared/core/Constants/deal';
import { PRE_DEALS_STATUS_OPTIONS } from '../../../PreDealsPage/Components/PreDealsTable/constants';
import PreDealStats from '../PreDealStats';
import { generateStats } from './constants';

import './index.scss';

const PreDealHeader = ({ selectedPreDeal }) => {
  const title =
    selectedPreDeal?.lastCalculationParams?.propertyType ===
    PROPERTY_TYPE_LOT ? (
      <>
        ${selectedPreDeal.address.formattedAddress}{' '}
        <Tag color="orange">VACANT LOT</Tag>
      </>
    ) : (
      selectedPreDeal.address.formattedAddress
    );

  return (
    <div className="pre-deal-header">
      <div className="left">
        <div className="names">
          <div className="full-name">
            <Link className="back-btn" to="/pre-deal">
              <MdArrowBack />
            </Link>
            {title}
            <Tag style={{ marginLeft: '10px' }}>
              {
                PRE_DEALS_STATUS_OPTIONS.find(
                  (option) => option.value === selectedPreDeal?.step,
                )?.label
              }
            </Tag>
          </div>
        </div>
        <div className="stats-container">
          <PreDealStats
            selectedPreDeal={selectedPreDeal}
            stats={generateStats({ selectedPreDeal })}
          />
        </div>
      </div>
    </div>
  );
};

export default PreDealHeader;
