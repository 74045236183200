import * as types from './types';

const initialState = {
  title: 'Properties',
  properties: [],
  isLoading: false,
  hasMoreResults: false,
  total: 0,
  sellerLead: undefined,
};
export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_PROPERTIES:
    case types.SET_PROPERTY:
    case types.SET_LOADING:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
