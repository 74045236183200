import { Checkbox, Modal } from 'antd';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SELLER_REJECTION_REASON_OPTIONS } from '@zorba-shared/core/Constants/offer';
import { setModalState } from '../../../../../../reducer/Modal/actions';
import { MODAL_REJECT_BUYER } from '../../../../../../reducer/Modal/types';
import { ZorbaButton, ZorbaSelect } from '../../../../../../components/forms';

import {
  showErrorToast,
  showSuccessToast,
} from '../../../../../../services/notificationsService';
import { postRequest } from '../../../../../../services/requestService';

import './index.scss';

export const RejectBuyerModal = ({ selectedDealSubmission }) => {
  const { isRejectBuyerModalOpen } = useSelector(
    ({ ModalReducer }) => ModalReducer,
  );
  const dispatch = useDispatch();

  const [params, setParams] = useState({ skipBuyerNotification: false });
  const matchesToReject = selectedDealSubmission.matches.filter(
    (match) => match.status !== 'rejected_by_seller',
  );
  const buyersOptions = matchesToReject.map((match) => ({
    label: match.organization.name,
    value: match._id,
  }));

  const allOption = {
    label: 'All',
    value: matchesToReject.map((match) => match._id).join(','),
  };
  const handleCloseModal = () => {
    dispatch(setModalState(MODAL_REJECT_BUYER, false));
  };

  const onChange = (key, value) => {
    const updatedParams = { ...params, [key]: value };
    setParams(updatedParams);
  };

  const handleRejectBuyer = async () => {
    try {
      await postRequest('/backoffice/deal-submission-match/reject-buyer', {
        ...params,
        dealSubmissionMatchIds: params.dealSubmissionMatchId.split(','),
        dealSubmissionId: selectedDealSubmission._id,
      });
      handleCloseModal();
      showSuccessToast('Buyer rejected successfully');
    } catch (error) {
      showErrorToast('Rejecting a buyer failed');
    }
  };

  const selectBuyerOptions =
    buyersOptions.length > 1 ? [allOption, ...buyersOptions] : buyersOptions;

  return (
    <Modal
      open={isRejectBuyerModalOpen}
      footer={null}
      onCancel={handleCloseModal}
      title="Reject by Seller"
      className="reject-buyer-modal"
      destroyOnClose
    >
      <span>Are you sure that you want to reject a buyer?</span>
      <div className="select-container">
        <ZorbaSelect
          label="Select buyer*"
          options={selectBuyerOptions}
          onChange={(e) => onChange('dealSubmissionMatchId', e)}
        />
        <ZorbaSelect
          label="Rejection reason*"
          options={SELLER_REJECTION_REASON_OPTIONS}
          onChange={(e) => onChange('rejectionReason', e)}
        />
        <Checkbox
          onChange={(e) => onChange('skipBuyerNotification', e.target.checked)}
          checked={params.skipBuyerNotification}
        >
          Check this if you like to skip the email notification to the buyer
        </Checkbox>
      </div>
      <div className="button-container">
        <ZorbaButton
          fullWidth={false}
          onClick={handleRejectBuyer}
          disabled={!params.dealSubmissionMatchId || !params.rejectionReason}
        >
          Reject buyer
        </ZorbaButton>
      </div>
    </Modal>
  );
};
