import { Table } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { getTableColumns } from './constants';
import { deleteEntity } from '../../../../reducer/BuyersReport/actions';

export const EntitiesTable = () => {
  const { selectedOrganizationData, isLoading } = useSelector(
    ({ BuyersReportReducer }) => BuyersReportReducer,
  );
  const dispatch = useDispatch();
  const handleOnDelete = (payload) => {
    dispatch(deleteEntity(payload));
  };

  return (
    <Table
      rowKey="name"
      loading={isLoading}
      columns={getTableColumns({ onDelete: handleOnDelete })}
      dataSource={selectedOrganizationData?.entities || []}
    />
  );
};
