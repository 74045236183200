import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import ContentHeader from '../../components/ContentHeader';
import { UserReferrals } from './Components/UserReferrals';

const UserReferralsPage = () => {
  useDocumentTitle('User Referrals Page');

  return (
    <>
      <ContentHeader title="User referrals" />
      <UserReferrals />
    </>
  );
};

export default UserReferralsPage;
