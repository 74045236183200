import _ from 'lodash';
import { Tag, Tooltip } from 'antd';
import { AiFillCheckCircle } from '@react-icons/all-files/ai/AiFillCheckCircle';
import { AiFillCloseCircle } from '@react-icons/all-files/ai/AiFillCloseCircle';
import { getPriceText } from '@zorba-shared/core/Services/textService';
import { v4 as uuid } from 'uuid';

const VacncyStatus = ({ vacant }) => {
  if (typeof vacant === 'undefined') return '';
  return vacant ? (
    <Tag color="lightblue" style={{ color: '#333' }}>
      Vacant
    </Tag>
  ) : (
    <Tag color="orange" style={{ color: '#333' }}>
      Occupied
    </Tag>
  );
};

export const getColumns = () => [
  {
    title: 'Match',
    key: 'status',
    render: ({ isPowerBuyerMatch }) =>
      isPowerBuyerMatch ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <AiFillCheckCircle
            style={{ color: '#49e387', marginRight: 5, fontSize: 20 }}
          />
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <AiFillCloseCircle
            style={{ color: '#fc6d6d', marginRight: 5, fontSize: 20 }}
          />
        </div>
      ),
    align: 'center',
    fixed: 'left',
  },
  {
    title: 'Property address',
    key: 'address',
    dataIndex: 'address',
    fixed: 'left',
  },
  {
    title: 'Property city',
    key: 'city',
    dataIndex: 'city',
  },
  {
    title: 'Property state',
    key: 'state',
    dataIndex: 'state',
  },
  {
    title: 'Property zip',
    key: 'zip',
    dataIndex: 'zip',
  },
  {
    title: 'Tags',
    key: 'tags',
    dataIndex: 'tags',
    width: 300,
    textWrap: 'word-break',
    render: (tags) =>
      tags.map((t) => (
        <Tag style={{ padding: 0 }} key={uuid()}>
          {t.name}
        </Tag>
      )),
  },
  {
    title: 'Owner 1',
    key: 'owner1',
    dataIndex: 'owners',
    render: (owners) =>
      owners?.length > 0
        ? `${owners[0].firstName} ${owners[0].lastName}`.trim()
        : '',
  },
  {
    title: 'Owner 2',
    key: 'owner1',
    dataIndex: 'owners',
    render: (owners) =>
      owners?.length > 1
        ? `${owners[1].firstName} ${owners[1].lastName}`.trim()
        : '',
  },
  {
    title: 'Primary number',
    key: 'numbers',
    dataIndex: 'owners',
    render: (owners) => {
      const numbers =
        owners?.length > 1
          ? _.flatten(owners.map((owner) => owner.numbers))
          : [];

      return (
        numbers.find((number) => number.numberType === 'Wireless')?.number ||
        numbers[0]?.number
      );
    },
  },
  {
    title: 'Vacant',
    key: 'vacant',
    render: ({ usps }) => {
      if (!usps || Object.keys(usps).length === 0) return null;

      return <VacncyStatus vacant={usps.vacant} />;
    },
  },

  {
    title: 'Min - Max Offers',
    key: 'offers',
    render: ({ offers }) => {
      if (!offers) return null;

      const detailedOffers = () => {
        const keysWithoutMinAndMaxOffer = Object.keys(offers).filter(
          (object) => object !== 'min' && object !== 'max',
        );

        return keysWithoutMinAndMaxOffer.map((key) => {
          const offer = offers[key];
          return offer.min && offer.max ? (
            <div>{`${key}: ${getPriceText(offer.min)} - ${getPriceText(
              offer.max,
            )}`}</div>
          ) : (
            <div>{`${key}: ${offer}`}</div>
          );
        });
      };

      return (
        <Tooltip title={detailedOffers()}>
          {`${getPriceText(offers.min)} - ${getPriceText(offers.max)}`}
        </Tooltip>
      );
    },
  },
];
