import { useState } from 'react';
import { Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';

import { setModalState } from '../../../../reducer/Modal/actions';
import { MODAL_ENTITY_SUCCESS_MAPPING } from '../../../../reducer/Modal/types';
import { ZorbaButton, ZorbaSelect } from '../../../../components/forms';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../services/notificationsService';
import { postRequest } from '../../../../services/requestService';

import './index.scss';

export const EntitySuccessMappingModal = ({
  entityName,
  organizations,
  requestId,
  loadDataRequests,
}) => {
  const { isEntitySuccessMappingModalOpen } = useSelector(
    ({ ModalReducer }) => ModalReducer,
  );

  const dispatch = useDispatch();
  const [isPending, setIsPending] = useState();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      organizationId: '',
    },
  });
  const handleCloseModal = () => {
    dispatch(setModalState(MODAL_ENTITY_SUCCESS_MAPPING, false));
    reset();
  };

  const handleSuccessMapping = async (formValues) => {
    setIsPending(true);
    try {
      await postRequest('/backoffice/data/entity-mapping-success', {
        organizationId: formValues.organizationId,
        requestId,
      });
      loadDataRequests();
      handleCloseModal();
      showSuccessToast('Success');
    } catch (error) {
      showErrorToast('Marking request as success failed!');
    }
    setIsPending(false);
  };

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Modal
      open={isEntitySuccessMappingModalOpen}
      footer={null}
      onCancel={handleCloseModal}
      title={`Successful mapping for ${entityName}`}
      destroyOnClose
      className="create-entity-modal"
    >
      <form onSubmit={handleSubmit(handleSuccessMapping)}>
        <div className="form-item">
          <Controller
            name="organizationId"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <ZorbaSelect
                label="Mapped Organization*"
                onChange={field.onChange}
                value={field.value}
                help={errors.organizationId ? 'Required' : ''}
                options={organizations.map((organization) => ({
                  label: organization.name,
                  value: organization.id,
                }))}
                showSearch
                filterOption={filterOption}
              />
            )}
          />
        </div>
        <div className="button-container">
          <ZorbaButton
            className="counter-accept-button"
            htmlType="submit"
            fullWidth={false}
            loading={isPending}
            disabled={isPending}
          >
            Submit
          </ZorbaButton>
        </div>
      </form>
    </Modal>
  );
};
