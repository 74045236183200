import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import Map, { Marker, Popup } from 'react-map-gl';
import {
  getFormattedTime,
  getPriceText,
} from '@zorba-shared/core/Services/textService';
import Pin from './Components/Pin';

const MAPBOX_TOKEN =
  'pk.eyJ1IjoibWF0a3dhIiwiYSI6ImNtMzYwNzM5eDAwOHEyaHE0a3hrMXo4dm8ifQ.2hVV8fM4AFpc9S8d4e2kOg';

export const MapComponent = ({
  transactions,
  location,
  mapRef,
  setMapRef,
  popupInfo,
  setPopupInfo,
}) => {
  const pins = useMemo(
    () =>
      transactions.map((transaction, index) => (
        <div
          onMouseEnter={() => {
            setPopupInfo({ ...transaction });
          }}
          onMouseLeave={() => {
            setPopupInfo(null);
          }}
        >
          <Marker
            key={uuid()}
            longitude={transaction.longitude}
            latitude={transaction.latitude}
            anchor="bottom"
          >
            <Pin number={index + 1} />
          </Marker>
        </div>
      )),
    [transactions],
  );

  useEffect(() => {
    if (location && location.length === 2 && mapRef) {
      mapRef.flyTo({
        center: [location[1], location[0]],
        zoom: 13,
        duration: 3000,
        essential: true,
      });
    }
  }, [location, mapRef]);

  return (
    <Map
      ref={(instance) => setMapRef(instance)}
      initialViewState={{
        latitude: location[0] || 37.0902,
        longitude: location[1] || -95.7129,
        zoom: 4,
      }}
      mapStyle="mapbox://styles/mapbox/streets-v12"
      mapboxAccessToken={MAPBOX_TOKEN}
    >
      {location && location.length === 2 && mapRef && (
        <Marker
          key={uuid()}
          latitude={location[0]}
          longitude={location[1]}
          anchor="bottom"
        >
          <Pin color="#d00" size={40} />
        </Marker>
      )}
      {pins}

      {popupInfo && (
        <Popup
          anchor="top"
          longitude={Number(popupInfo.longitude)}
          latitude={Number(popupInfo.latitude)}
          onClose={() => setPopupInfo(null)}
        >
          <div className="transaction-popup">
            <span className="transaction-title">{`${popupInfo.address}`}</span>
            <span>
              {`Buyer: ${popupInfo.buyer} ${
                popupInfo.organization_name
                  ? `(${popupInfo.organization_name})`
                  : ''
              }`}
            </span>
            <span>{`Mailing address: ${popupInfo.mailing_address}`}</span>
            <div>
              <span>Purchase price: </span>
              <span className="transaction-title">
                {popupInfo.transaction_amount &&
                popupInfo.transaction_amount !== '0'
                  ? getPriceText(popupInfo.transaction_amount)
                  : 'N/D'}
              </span>
            </div>
            <span>{`Beds: ${popupInfo.beds ? popupInfo.beds : 'N/D'} | Baths: ${
              popupInfo.baths ? popupInfo.baths : 'N/D'
            } | Year built: ${
              popupInfo.year_built ? popupInfo.year_built : 'N/D'
            }`}</span>
            <span>{`Lender: ${
              popupInfo.lender_name ? popupInfo.lender_name : 'N/D'
            }`}</span>{' '}
            <span>
              Sold at:{' '}
              {getFormattedTime(popupInfo.transaction_date, 'MM/DD/YYYY')}
            </span>
          </div>
        </Popup>
      )}
    </Map>
  );
};
