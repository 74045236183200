import { BsThreeDotsVertical } from '@react-icons/all-files/bs/BsThreeDotsVertical';
import { FaShare } from '@react-icons/all-files/fa/FaShare';
import { getFormattedTime } from '@zorba-shared/core/Services/textService';
import { Button } from 'antd';
import { ZorbaDropdown } from '../../../../components/forms';

export const getTableColumns = ({ handleOpenModal }) => [
  {
    title: 'Created At',
    key: 'createdAt',
    render: ({ createdAt }) => getFormattedTime(createdAt),
  },
  {
    title: 'Referring user',
    key: 'referringUser',
    render: ({ referringUser }) =>
      `${referringUser?.name} (${referringUser?.email})`,
  },
  {
    title: 'Referred user',
    key: 'referredUser',
    render: ({ referredUser }) =>
      `${referredUser?.name} (${referredUser?.email})`,
  },
  {
    title: 'Actions',
    render: (referral) => (
      <ZorbaDropdown
        actions={[
          {
            title: 'Reassign referral',
            icon: <FaShare />,
            onClick: () => {
              handleOpenModal(referral);
            },
          },
        ]}
        btnText={
          <Button type="text" size="large" icon={<BsThreeDotsVertical />} />
        }
        trigger={['click']}
        className="stage-actions-dropdown-button"
      />
    ),
  },
];
