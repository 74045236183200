import { useState } from 'react';
import { Modal, Form, Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import _ from 'lodash';

import { setModalState } from '../../../../../../reducer/Modal/actions';
import { MODAL_ACCEPT_OFFER } from '../../../../../../reducer/Modal/types';
import {
  ZorbaButton,
  ZorbaSelect,
  ZorbaDatePicker,
} from '../../../../../../components/forms';

import './index.scss';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../../../services/notificationsService';
import { postRequest } from '../../../../../../services/requestService';
import { fetchDealSubmission } from '../../../../../../reducer/DealSubmission/actions';

dayjs.extend(utc);
dayjs.extend(timezone);

export const AcceptOfferModal = ({ selectedDealSubmission }) => {
  const { isAcceptOfferModalOpen } = useSelector(
    ({ ModalReducer }) => ModalReducer,
  );
  const dispatch = useDispatch();
  const [textAreaValue, setTextAreaValue] = useState();
  const [isAcceptingOffer, setIsAcceptingOffer] = useState();
  const finalOffer = _.last(
    selectedDealSubmission.events.filter((e) => e.kind === 'buyer_final_offer'),
  );

  const lastOfferEvent =
    finalOffer ||
    _.last(
      selectedDealSubmission.events.filter((event) => event.kind === 'offer'),
    );

  const isPreInspection = lastOfferEvent?.inspectionMode === 'pre_inspection';

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      occupancy: selectedDealSubmission?.userData?.occupancy,
      inspectionAvailability1: selectedDealSubmission?.userData
        ?.inspectionAvailability?.length
        ? dayjs(
            selectedDealSubmission?.userData?.inspectionAvailability[0],
            'YYYY-MM-DD h:mm a',
          ).tz('America/New_York')
        : '',
      inspectionAvailability2:
        selectedDealSubmission?.userData?.inspectionAvailability?.length > 1
          ? dayjs(
              selectedDealSubmission?.userData?.inspectionAvailability[1],
              'YYYY-MM-DD h:mm a',
            ).tz('America/New_York')
          : '',
    },
  });
  const handleCloseModal = () => {
    dispatch(setModalState(MODAL_ACCEPT_OFFER, false));
  };

  const handleAcceptOffer = async (formValues) => {
    const payload = {
      ...formValues,
      step: isPreInspection ? 'pending_inspection' : 'pending_agreement',
      additionalRequests: textAreaValue,
      dealSubmissionId: selectedDealSubmission._id,
    };

    try {
      setIsAcceptingOffer(true);
      await postRequest('/backoffice/deal-submission/accept-offer', payload);
      setIsAcceptingOffer(false);
      handleCloseModal();
      showSuccessToast('Offer accepted');
      dispatch(fetchDealSubmission(selectedDealSubmission._id));
    } catch (error) {
      setIsAcceptingOffer(false);
      showErrorToast('Accepting an offer failed');
    }
  };

  const watchInspectionAvailability1 = watch('inspectionAvailability1');

  return (
    <Modal
      open={isAcceptOfferModalOpen}
      footer={null}
      onCancel={handleCloseModal}
      title="Accept an offer on behalf of the seller"
      className="accept-offer-modal"
      destroyOnClose
    >
      <form onSubmit={handleSubmit(handleAcceptOffer)}>
        {isPreInspection && (
          <div className="pre-inspection-form">
            <div className="form-item">
              <Controller
                name="occupancy"
                control={control}
                rules={{ required: !!isPreInspection }}
                render={({ field }) => (
                  <ZorbaSelect
                    label="Is the property occupied or vacant?*"
                    onChange={field.onChange}
                    value={field.value}
                    options={[
                      { text: 'Owner occupied', value: 'owner_occupied' },
                      { text: 'Tenant occupied', value: 'tenant_occupied' },
                      { text: 'Vacant', value: 'vacant' },
                    ]}
                    help={errors.occupancy ? 'Required' : ''}
                  />
                )}
              />
            </div>
            <div className="items">
              <div className="form-item">
                <Controller
                  name="inspectionAvailability1"
                  control={control}
                  render={({ field }) => (
                    <ZorbaDatePicker
                      field={field}
                      formClassName="full-width"
                      label="Do you have a preferred time for us to walk the property?"
                      showTime
                    />
                  )}
                />
                {watchInspectionAvailability1 && (
                  <Controller
                    name="inspectionAvailability2"
                    control={control}
                    render={({ field }) => (
                      <ZorbaDatePicker
                        field={field}
                        formClassName="full-width"
                        label=""
                        showTime
                      />
                    )}
                  />
                )}
              </div>
            </div>
            <div className="form-item access-details">
              <Controller
                name="accessDetails"
                control={control}
                render={({ field }) => (
                  <Form.Item label="Access Details">
                    <Input.TextArea
                      placeholder="We will need the access details to schedule the inspection. Is there a lockbox code, and if so what is the code?"
                      className="text-area"
                      value={field.value}
                      onChange={field.onChange}
                    />
                  </Form.Item>
                )}
              />
            </div>
          </div>
        )}
        <div className="accept-offer-container">
          <div className="zorba-input-item">
            <div className="ant-form-item ant-form-item-label">
              <label className="additional-requests-label">
                Additional requests
              </label>
            </div>
            <Input.TextArea
              placeholder="Type any questions or requests you have to our team"
              onChange={(e) => setTextAreaValue(e.target.value)}
              className="text-area"
            />
          </div>
        </div>
        <div className="button-container">
          <ZorbaButton
            className="counter-accept-button"
            htmlType="submit"
            fullWidth={false}
            loading={isAcceptingOffer}
            disabled={isAcceptingOffer}
          >
            {isPreInspection ? 'Schedule pre-inspection' : 'Request contract'}
          </ZorbaButton>
        </div>
      </form>
    </Modal>
  );
};
