import { AppstoreOutlined } from '@ant-design/icons';
import { CgCheckR } from '@react-icons/all-files/cg/CgCheckR';
import { FaCommentDollar } from '@react-icons/all-files/fa/FaCommentDollar';
import { FaTools } from '@react-icons/all-files/fa/FaTools';
import { HiDocumentReport } from '@react-icons/all-files/hi/HiDocumentReport';
import { RiRefundFill } from '@react-icons/all-files/ri/RiRefundFill';
import { RiDatabase2Fill } from '@react-icons/all-files/ri/RiDatabase2Fill';
import { FiActivity } from '@react-icons/all-files/fi/FiActivity';
import { FaUserGraduate } from '@react-icons/all-files/fa/FaUserGraduate';
import { AiOutlineTranslation } from '@react-icons/all-files/ai/AiOutlineTranslation';
import { Link } from 'react-router-dom';

export const getNavItems = ({ userDetails }) => {
  let items = [
    {
      key: '1',
      label: <Link to="/">User Search</Link>,
      icon: <AppstoreOutlined />,
    },
    {
      key: '2',
      label: <Link to="/buyer-checker">Buybox Checker</Link>,
      icon: <FaTools />,
    },
    {
      key: '3',
      label: <Link to="/deal-submission">Deals search</Link>,
      icon: <FaCommentDollar />,
    },
    {
      key: '4',
      label: <Link to="/partner-approval">Partner approval</Link>,
      icon: <CgCheckR />,
    },
    {
      key: '5',
      label: <Link to="/buyer-activity-report">Buyer activity report</Link>,
      icon: <HiDocumentReport />,
      protected: () =>
        [
          'kobi@getzorba.com',
          'mateusz.kwasny@getzorba.com',
          'or@getzorba.com',
          'stefany.quiroz@getzorba.com',
          'milan.petojevic@getzorba.com',
          'anj@getzorba.com',
        ].includes(userDetails?.email),
    },
    {
      key: '6',
      label: <Link to="/contact-refund">Contact refund</Link>,
      icon: <RiRefundFill />,
      protected: () =>
        [
          'kobi@getzorba.com',
          'mateusz.kwasny@getzorba.com',
          'or@getzorba.com',
          'stefany.quiroz@getzorba.com',
          'milan.petojevic@getzorba.com',
        ].includes(userDetails?.email),
    },
    {
      key: '7',
      label: <Link to="/data-requests">Data requests</Link>,
      icon: <RiDatabase2Fill />,
      protected: () =>
        [
          'kobi@getzorba.com',
          'mateusz.kwasny@getzorba.com',
          'or@getzorba.com',
          'stefany.quiroz@getzorba.com',
          'milan.petojevic@getzorba.com',
        ].includes(userDetails?.email),
    },
    {
      key: '8',
      label: (
        <Link to="/institutional-activity-research">Activity research</Link>
      ),
      icon: <FiActivity />,
    },
    {
      key: '9',
      label: <Link to="/user-referrals">User referrals</Link>,
      icon: <FaUserGraduate />,
    },
    {
      key: '10',
      label: <Link to="/state-activity-research">State research</Link>,
      icon: <FiActivity />,
    },
    {
      key: '11',
      label: <Link to="/pre-deal">Pre-Deals search</Link>,
      icon: <AiOutlineTranslation />,
    },
  ];

  items = items.filter((item) => !item.protected || item.protected());

  return items;
};
