import { Tabs } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PreDealsTable } from '../PreDealsTable';
import { searchPreDeals } from '../../../../reducer/PreDeals/actions';

import './index.scss';

export const PreDealsList = () => {
  const dispatch = useDispatch();
  const { total, currentPage, pageSize } = useSelector(
    ({ PreDealsReducer }) => PreDealsReducer,
  );

  useEffect(() => {
    dispatch(
      searchPreDeals({
        page: currentPage,
        pageSize,
      }),
    );
  }, [currentPage, pageSize]);

  const getLabel = (title, totalItems) => (
    <div className="label-container">
      {title}
      <div className="indicator-container">{totalItems}</div>
    </div>
  );

  const tabs = [
    {
      key: 'tab1',
      label: getLabel('All', total),
      children: <PreDealsTable />,
    },
  ];

  return (
    <div className="tabs-container deal-submission-list">
      <Tabs type="card" items={tabs} />
    </div>
  );
};
