import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaFlagCheckered } from '@react-icons/all-files/fa/FaFlagCheckered';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { fetchPreDeal } from '../../reducer/PreDeals/actions';
import PageSkeleton from '../../components/PageSkeleton';
import PreDealHeader from './Components/PreDealHeader';
import { PreDealFinalOfferModal } from './Components/PreDealFinalOfferModal';
import { ZorbaButton } from '../../components/forms';
import { BUTTON_VARIANT_DARK } from '../../components/forms/ZorbaButton/constants';
import { setModalState } from '../../reducer/Modal/actions';
import { MODAL_PRE_DEAL_FINAL_OFFER } from '../../reducer/Modal/types';

const PreDealPage = ({ match }) => {
  const dispatch = useDispatch();
  const { preDealId } = match.params;
  const { selectedPreDeal, isFetchingPreDeal } = useSelector(
    ({ PreDealsReducer }) => PreDealsReducer,
  );
  useDocumentTitle(
    `PreDeal -  ${
      selectedPreDeal ? selectedPreDeal?.address?.formattedAddress : ''
    }`,
  );

  useEffect(() => {
    if (preDealId) {
      dispatch(fetchPreDeal(preDealId));
    }
  }, [preDealId]);

  const handleOpenModal = (modalName) => {
    dispatch(setModalState(modalName, true));
  };

  if (!selectedPreDeal || isFetchingPreDeal) return <PageSkeleton />;

  return (
    <>
      <PreDealHeader selectedPreDeal={selectedPreDeal} />
      <div
        style={{
          width: '100%',
          display: 'flex',
          marginTop: '20px',
          justifyContent: 'flex-end',
        }}
      >
        <ZorbaButton
          variant={BUTTON_VARIANT_DARK}
          fullWidth
          onClick={() => handleOpenModal(MODAL_PRE_DEAL_FINAL_OFFER)}
        >
          Submit final offer
          <FaFlagCheckered size={25} />
        </ZorbaButton>
      </div>
      <PreDealFinalOfferModal selectedPreDeal={selectedPreDeal} />
    </>
  );
};

export default PreDealPage;
