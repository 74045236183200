import { useEffect, useState } from 'react';
import { centroid } from '@turf/centroid';
import { getDynamicColor } from './constants';
import { FILE_NAMES } from './files/fileNames';

export const useMSATransactions = (selectedState) => {
  const [combinedFeatureCollection, setCombinedFeatureCollection] =
    useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const s3BucketUrl =
        'https://zorba.s3.amazonaws.com/mapData/Outputs/MSA_GROUPED_TRANSACTIONS/';

      try {
        const filteredFileNames = FILE_NAMES.filter(
          (fileName) =>
            fileName.includes(selectedState) &&
            !fileName.includes('NONMETROPOLITAN'),
        );

        const fetchPromises = filteredFileNames.map((fileName) =>
          fetch(`${s3BucketUrl}${fileName}`).then((res) => res.json()),
        );

        const jsonDataArray = await Promise.all(fetchPromises);

        let combinedFeatures = [];
        jsonDataArray.forEach((jsonData) => {
          if (jsonData.type === 'FeatureCollection') {
            combinedFeatures = combinedFeatures.concat(jsonData.features);
          }
        });

        const min = Math.min(
          ...combinedFeatures.map(
            (feature) => feature.properties.transactionCount || 0,
          ),
        );
        const max = Math.max(
          ...combinedFeatures.map(
            (feature) => feature.properties.transactionCount || 0,
          ),
        );

        const enrichedMSAData =
          combinedFeatures.length > 0
            ? {
                type: 'FeatureCollection',
                features: combinedFeatures.map((feature, index) => {
                  const backgroundColor = getDynamicColor(
                    feature.properties.transactionCount,
                    min,
                    max,
                  );
                  const center = centroid(feature).geometry.coordinates;

                  return {
                    ...feature,
                    id: index + 10000,
                    properties: {
                      ...feature.properties,
                      background_color: backgroundColor,
                      center,
                    },
                  };
                }),
              }
            : null;

        setCombinedFeatureCollection(enrichedMSAData);
      } catch (error) {
        console.error('Error fetching MSA transactions:', error);
        setCombinedFeatureCollection(null);
      }
    };

    if (selectedState) {
      fetchData();
    } else {
      setCombinedFeatureCollection(null);
    }
  }, [selectedState]);

  return combinedFeatureCollection;
};

export const useZIPTransactions = (selectedMSA) => {
  const [combinedFeatureCollection, setCombinedFeatureCollection] =
    useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const s3BucketUrl =
        'https://zorba.s3.amazonaws.com/mapData/Outputs/MSA_ZIPS_TRANSACTIONS/';

      try {
        const filteredFileNames = FILE_NAMES.filter(
          (path) =>
            path.split('/').pop().includes(selectedMSA) &&
            !path.includes('NONMETROPOLITAN'),
        );

        const fetchPromises = filteredFileNames.map((path) =>
          fetch(`${s3BucketUrl}${path.split('/').pop()}`).then((res) =>
            res.json(),
          ),
        );

        const jsonDataArray = await Promise.all(fetchPromises);

        let combinedFeatures = [];
        jsonDataArray.forEach((jsonData) => {
          if (jsonData.type === 'FeatureCollection') {
            combinedFeatures = combinedFeatures.concat(jsonData.features);
          }
        });

        const min = Math.min(
          ...combinedFeatures.map(
            (feature) => feature.properties.transactionCount || 0,
          ),
        );
        const max = Math.max(
          ...combinedFeatures.map(
            (feature) => feature.properties.transactionCount || 0,
          ),
        );

        const enrichedMSAData =
          combinedFeatures.length > 0
            ? {
                type: 'FeatureCollection',
                features: combinedFeatures.map((feature) => {
                  const backgroundColor = getDynamicColor(
                    feature.properties.transactionCount,
                    min,
                    max,
                  );
                  const center = centroid(feature).geometry.coordinates;

                  return {
                    ...feature,
                    properties: {
                      ...feature.properties,
                      background_color: backgroundColor,
                      center,
                    },
                  };
                }),
              }
            : null;

        setCombinedFeatureCollection(enrichedMSAData);
      } catch (error) {
        console.error('Error fetching ZIP transactions:', error);
        setCombinedFeatureCollection(null);
      }
    };

    if (selectedMSA) {
      fetchData();
    } else {
      setCombinedFeatureCollection(null);
    }
  }, [selectedMSA]);

  return combinedFeatureCollection;
};

export const useStatesWithTransactions = () => {
  const [statesWithTransactions, setStatesWithTransactions] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const s3BucketUrl = 'https://zorba.s3.amazonaws.com/mapData/Outputs/';
      const statesFile = 'states.json';
      const transactionsFile = 'transactions.json';

      try {
        // Fetch states.json and transactions.json concurrently
        const [statesData, transactionsData] = await Promise.all([
          fetch(`${s3BucketUrl}${statesFile}`).then((res) => res.json()),
          fetch(`${s3BucketUrl}${transactionsFile}`).then((res) => res.json()),
        ]);

        if (statesData && transactionsData) {
          const features = statesData.features.map((feature) => {
            const stateData = transactionsData.find(
              (data) => data.state_name === feature.properties.name,
            );

            // Calculate dynamic color based on transaction count
            const transactionCounts = transactionsData.map(
              (d) => d.transaction_count || 0,
            );
            const min = Math.min(...transactionCounts);
            const max = Math.max(...transactionCounts);

            const backgroundColor = stateData
              ? getDynamicColor(stateData.transaction_count, min, max)
              : '#FFFFFF'; // Default color for states with no data

            return {
              ...feature,
              properties: {
                ...feature.properties,
                background_color: backgroundColor,
                transactionCount: stateData?.transaction_count || 0,
              },
            };
          });

          setStatesWithTransactions({
            ...statesData,
            features,
          });
        }
      } catch (error) {
        console.error('Error fetching state or transaction data:', error);
        setStatesWithTransactions(null);
      }
    };

    fetchData();
  }, []);

  return statesWithTransactions;
};
