import './index.scss';
import { Avatar, Card } from 'antd';

const { Meta } = Card;

const UserAvatar = (props) => (
  <Card bordered={false}>
    <Meta
      avatar={
        props.avatar && (
          <Avatar
            src={props.avatar}
            style={{ backgroundColor: '#f0f0f0', verticalAlign: 'middle' }}
          />
        )
      }
      title={props.title}
      description={props.description || ''}
    />
  </Card>
);

export default UserAvatar;
