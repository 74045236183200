import { useEffect, useState, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { Tag, Card, Drawer, Space } from 'antd';
import { AiFillCheckCircle } from '@react-icons/all-files/ai/AiFillCheckCircle';
import { AiFillCloseCircle } from '@react-icons/all-files/ai/AiFillCloseCircle';
import {
  getPriceText,
  formatDecimal,
} from '@zorba-shared/core/Services/textService';
import { useDispatch, useSelector } from 'react-redux';
import { FiFilter } from '@react-icons/all-files/fi/FiFilter';
import { useSearchParams } from '../../utils';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import ContentHeader from '../../components/ContentHeader';
import { PlacesAutoComplete, ZorbaButton } from '../../components/forms';
import {
  SET_TAX_ASSESSOR_DATA,
  SET_INPUT_OBJECT,
} from '../../reducer/Buyer/types';
import { getMatchedBuyers } from '../../reducer/Buyer/actions';
import MatchingBuyersTable from './Components/MatchingBuyersTable';
import {
  MAPPED_PARAMS,
  createProviderButtons,
  MAPPED_TAX_ASSESSOR_PARAMS,
} from './constants';
import GenerateOffer from './Components/GenerateOffer';
import {
  BUTTON_VARIANT_LIGHT,
  BUTTON_VARIANT_DARK,
} from '../../components/forms/ZorbaButton/constants';
import MatchingBuyerFilter from './Components/MatchingBuyerFilter';

import './index.scss';

const BuyerCheckerPage = () => {
  useDocumentTitle('Buyer Checker');
  const [searchParams] = useSearchParams();
  const queryAddress = searchParams.get('query');
  const [address, setAddress] = useState();
  const [params, setParams] = useState({
    maxAllowableOffer: undefined,
    grossRent: undefined,
    rehabCost: undefined,
  });
  const [buyerFilteredList, setBuyerFilteredList] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const {
    isCheckingBuyers,
    inputObj,
    buyers,
    maxOffer,
    taxAssessorData,
    isGeneratingOffer,
  } = useSelector(({ BuyerReducer }) => BuyerReducer);
  const { zillowData } = inputObj || {};
  const dispatch = useDispatch();
  const onPlacesChange = (newAddress) => {
    if (!newAddress) return;
    setAddress(newAddress);

    dispatch(getMatchedBuyers(newAddress));
  };
  const buyersWithMatchingBuybox = buyers.filter((item) => item.status);

  const getPropValue = (obj) => {
    if (obj.type === 'price')
      return zillowData && zillowData[obj.prop]
        ? getPriceText(zillowData[obj.prop].toFixed(2))
        : 'Unknown';
    if (obj.prop === 'schoolRating')
      return zillowData && zillowData[obj.prop]
        ? formatDecimal(zillowData[obj.prop], 1)
        : 'Unknown';

    return zillowData && zillowData[obj.prop] != null
      ? zillowData[obj.prop]
      : 'Unknown';
  };

  const getTaxAssessorPropValue = (obj) => {
    if (obj.type === 'boolean') {
      return taxAssessorData && taxAssessorData[obj.prop] != null
        ? taxAssessorData[obj.prop].toString()
        : 'Unknown';
    }

    return taxAssessorData && taxAssessorData[obj.prop] != null
      ? taxAssessorData[obj.prop]
      : 'Unknown';
  };

  const actionButtons = useMemo(
    () => createProviderButtons(zillowData?.hdpUrl, inputObj?.hubspotDealId),
    [zillowData?.hdpUrl, inputObj?.hubspotDealId],
  );

  const onFilterHandler = (selectedBuyer = []) => {
    const updatedFilters = { ...selectedFilters, buyers: selectedBuyer };
    setSelectedFilters(updatedFilters);

    if (!selectedBuyer.length) setBuyerFilteredList(buyers);
    else {
      const filteredBuyer = buyers.filter((item) =>
        selectedBuyer.includes(item.buyer),
      );
      setBuyerFilteredList(filteredBuyer);
    }
  };

  useEffect(() => {
    setParams({
      maxAllowableOffer: inputObj?.askingPrice,
      grossRent: inputObj?.zillowData?.rentZestimate,
      bedroom: inputObj?.bedroom,
      bathroom: inputObj?.bathroom,
      yearBuilt: inputObj?.yearBuilt,
      squareFootage: inputObj?.squareFootage,
      lotSize: inputObj?.lotSize,
      schoolRating: inputObj?.zillowData?.schoolRating
        ? inputObj?.zillowData?.schoolRating.toFixed(2)
        : '',
      hasPool: inputObj?.hasPool,
      parkingSpaces: inputObj?.parkingSpaces,
      floodZone: inputObj?.floodZone,
      parkingTypes: inputObj?.parkingTypes || [],
      propertyType: inputObj?.propertyType,
    });
  }, [inputObj]);

  useEffect(() => {
    setBuyerFilteredList(buyers);
  }, [buyers]);

  useEffect(
    () => () => {
      dispatch({
        type: SET_TAX_ASSESSOR_DATA,
        payload: { taxAssessorData: null },
      });
      dispatch({
        type: SET_INPUT_OBJECT,
        payload: { inputObj: null },
      });
    },
    [],
  );

  const getTitle = (title, data) =>
    data ? (
      <div style={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}>
        <AiFillCheckCircle
          style={{ color: '#49e387', marginRight: 5, fontSize: 35 }}
        />
        <div>{title}</div>
      </div>
    ) : (
      <div style={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}>
        <AiFillCloseCircle
          style={{ color: '#fc6d6d', marginRight: 5, fontSize: 35 }}
        />
        <div>{title}</div>
      </div>
    );

  return (
    <>
      <ContentHeader
        title="Buyer Checker"
        variant={BUTTON_VARIANT_LIGHT}
        actionButtons={actionButtons}
      />
      <div className="matching-buyer-container">
        <div className="search-container">
          <PlacesAutoComplete
            title={null}
            disabled={isCheckingBuyers}
            allowFullAddress
            placeholder="Start by typing the property address"
            onChange={(value) => onPlacesChange(value)}
            initialInputText={queryAddress}
            autoSelectMatchingItem
          />
          {buyersWithMatchingBuybox?.length > 0 && !isCheckingBuyers && (
            <Space>
              <ZorbaButton
                variant={BUTTON_VARIANT_DARK}
                icon={<FiFilter />}
                onClick={() => setIsFilterDrawerOpen(true)}
              >
                Filter
                {selectedFilters?.buyers?.length > 0
                  ? ` (${selectedFilters?.buyers?.length})`
                  : ''}
              </ZorbaButton>
            </Space>
          )}
        </div>
        {address && !isCheckingBuyers && (
          <Card
            title={getTitle('Zillow Data', zillowData)}
            className="buybox-checker-section-container tax-assessor-container"
          >
            {MAPPED_PARAMS.map((obj) => (
              <Tag className="buybox-checker-tag" key={obj.title}>
                <span className="tag-label">{obj.title}:</span>
                {getPropValue(obj)}
              </Tag>
            ))}
          </Card>
        )}

        {address && !isCheckingBuyers && (
          <Card
            title={getTitle('Tax Assessor Data', taxAssessorData)}
            className="buybox-checker-section-container tax-assessor-container"
          >
            {(taxAssessorData?.owners || []).map((owner) => (
              <Tag className="buybox-checker-tag" key={uuid()}>
                <span className="tag-label">Owner:</span>
                {owner.grantee_name}
              </Tag>
            ))}
            {taxAssessorData?.latestAvm?.estimated_value_amount && (
              <Tag className="buybox-checker-tag">
                <span className="tag-label">AVM:</span>
                {getPriceText(taxAssessorData.latestAvm.estimated_value_amount)}
              </Tag>
            )}
            {MAPPED_TAX_ASSESSOR_PARAMS.map((obj) => (
              <Tag className="buybox-checker-tag" key={obj.title}>
                <span className="tag-label">{obj.title}:</span>
                {getTaxAssessorPropValue(obj)}
              </Tag>
            ))}
            {inputObj?.floodZone && (
              <Tag className="buybox-checker-tag">
                <span className="tag-label">Flood Zone:</span>
                {inputObj?.floodZone}
              </Tag>
            )}
          </Card>
        )}
        {address && !isCheckingBuyers && (
          <Card title="Generate Offer">
            <GenerateOffer
              params={params}
              zillowData={zillowData}
              setParams={setParams}
              buyers={buyers}
              address={address}
              onSubmitButtonText="Generate offer"
            />
          </Card>
        )}
        <div className="matching-buyer-table-container">
          <MatchingBuyersTable
            address={address}
            data={buyerFilteredList}
            buyersWithMatchingBuybox={buyersWithMatchingBuybox}
            isLoading={isCheckingBuyers || isGeneratingOffer}
            maxOffer={maxOffer}
            isShowMatchingCount={selectedFilters?.buyers?.length}
          />
        </div>
      </div>
      <Drawer
        width={420}
        title="Filters"
        placement="right"
        onClose={() => setIsFilterDrawerOpen(false)}
        open={isFilterDrawerOpen}
      >
        <MatchingBuyerFilter
          buyers={buyers}
          setIsFilterDrawerOpen={setIsFilterDrawerOpen}
          onFilter={onFilterHandler}
        />
      </Drawer>
    </>
  );
};

export default BuyerCheckerPage;
