import { Row, Col, Skeleton } from 'antd';

export default () => (
  <>
    <Row gutter={32}>
      <Col xs={24}>
        <Skeleton active paragraph={{ rows: 0 }} />
      </Col>
    </Row>

    <Row gutter={32}>
      <Col xs={16}>
        <Skeleton
          avatar={{ size: 'large', active: true }}
          paragraph={{ rows: 2 }}
        />
      </Col>
      <Col xs={8}>
        <Skeleton paragraph={{ rows: 2 }} />
      </Col>
    </Row>

    <Row gutter={32} style={{ marginTop: '32px' }}>
      <Col xs={16}>
        <Skeleton paragraph={{ rows: 4 }} />
        <Skeleton paragraph={{ rows: 4 }} />
        <Skeleton paragraph={{ rows: 4 }} />
      </Col>
      <Col xs={8}>
        <Skeleton paragraph={{ rows: 2 }} />
      </Col>
    </Row>
  </>
);
