import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import _ from 'lodash';
import { setModalState } from '../../../../reducer/Modal/actions';
import { MODAL_PRE_DEAL_FINAL_OFFER } from '../../../../reducer/Modal/types';
import {
  ZorbaButton,
  ZorbaInput,
  ZorbaSelect,
} from '../../../../components/forms';

import {
  showErrorToast,
  showSuccessToast,
} from '../../../../services/notificationsService';
import { postRequest } from '../../../../services/requestService';
import { fetchPreDeal } from '../../../../reducer/PreDeals/actions';

import './index.scss';

export const PreDealFinalOfferModal = ({ selectedPreDeal }) => {
  const [loading, setLoading] = useState(false);
  const { isPreDealFinalOfferModalOpen } = useSelector(
    ({ ModalReducer }) => ModalReducer,
  );
  const dispatch = useDispatch();

  const [params, setParams] = useState({});

  const buyersOptions = selectedPreDeal.matches.map((match) => ({
    label: match.organization.name,
    value: match._id,
  }));

  const selectedPreDealMatch = selectedPreDeal.matches.find(
    (match) => match._id === params.associatedMatch,
  );
  const lastOfferEventByBuyer = _.last(
    selectedPreDealMatch?.events.filter((event) => event.kind === 'offer'),
  );

  useEffect(() => {
    if (lastOfferEventByBuyer) {
      setParams((prevState) => ({
        ...prevState,
        finalizedOffer:
          lastOfferEventByBuyer.rawAmount -
          (selectedPreDealMatch?.feeAmount || 0),
        feeAmount: selectedPreDealMatch.feeAmount,
      }));
    }
  }, [lastOfferEventByBuyer]);

  const handleCloseModal = () => {
    dispatch(setModalState(MODAL_PRE_DEAL_FINAL_OFFER, false));
    setParams({});
  };

  const onChange = (key, value) => {
    const updatedParams = { ...params, [key]: value };
    setParams(updatedParams);
  };

  const isFormDisabled =
    !params.associatedMatch || !params.finalizedOffer || !params.feeAmount;

  const onSubmit = async () => {
    setLoading(true);
    try {
      await postRequest(`/backoffice/pre-deal/submit-final-offer`, {
        preDealId: selectedPreDeal._id,
        ...params,
      });
      dispatch(fetchPreDeal(selectedPreDeal._id));
      handleCloseModal();
      showSuccessToast('Final offer submitted successfully');
    } catch (e) {
      showErrorToast('Woops. huddle Kobi');
    }
    setLoading(false);
  };

  return (
    <Modal
      open={isPreDealFinalOfferModalOpen}
      footer={null}
      onCancel={handleCloseModal}
      title="Submit final offer to the Seller"
      className="pre-deal-final-offer-modal"
      destroyOnClose
    >
      <span>
        Are you sure that you want to submit final offer on behalf of the buyer?
      </span>
      <div className="select-container">
        <ZorbaSelect
          label="Select buyer*"
          options={buyersOptions}
          onChange={(e) => onChange('associatedMatch', e)}
        />
        {params.associatedMatch && (
          <>
            <ZorbaInput
              type="number"
              addonBefore="$"
              symbol=""
              label="Final Offer including our fee*"
              value={params.finalizedOffer}
              disabled
            />
            <ZorbaInput
              type="number"
              addonBefore="$"
              symbol=""
              label="Our fee*"
              value={params.feeAmount}
              onChange={(e) => {
                const newAmount = lastOfferEventByBuyer.rawAmount - e;
                const updatedParams = {
                  ...params,
                  feeAmount: e,
                  finalizedOffer: newAmount,
                };
                setParams(updatedParams);
              }}
              className="fee-amount-input"
            />
          </>
        )}
      </div>
      <div className="button-container">
        <ZorbaButton
          fullWidth={false}
          onClick={onSubmit}
          disabled={isFormDisabled || loading}
          loading={loading}
        >
          Send final offer
        </ZorbaButton>
      </div>
    </Modal>
  );
};
