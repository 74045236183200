import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  FIELD_MAPPING_SELECT_OPTIONS,
  FIELD_MAPPING_STATUS_IGNORE,
  FIELD_MAPPING_STATUS_MAPPED,
} from './constants';
import { FieldCard, MapFieldActions, RequiredFieldsModal } from './Components';
import { findObjectKeyByValue } from '../../../../../../services/objectService';

import { convertNumberToLetters, validateFieldMapping } from './service';
import { updateMappedColumns } from '../../../../../../reducer/PropertyData/actions';

import './index.scss';

const MappingFieldsStep = (props) => {
  const { job, records, setStepIndex, setLoading, loading } = props;
  const [mappedFieldsTemp, setMappedFieldsTemp] = useState({
    ...job?.mappedColumns,
  });
  const [fileFieldsStatus, setFileFieldsStatus] = useState({}); // Hold the status of every column in the spreadsheet the user uploaded (initial,match,confirmed,ignored)
  const [isValidationModalVisible, setIsValidationModalVisible] =
    useState(false);
  const [invalidFields, setInvalidFields] = useState([]);

  const dispatch = useDispatch();
  if (!job) return null;

  const onNextClick = async () => {
    const { invalidFields: nextClickInvalidFields } = validateFieldMapping(
      mappedFieldsTemp,
      fileFieldsStatus,
    );

    if (nextClickInvalidFields.length > 0) {
      setIsValidationModalVisible(true);
      setInvalidFields(nextClickInvalidFields);
      return;
    }

    setLoading(true);
    await dispatch(updateMappedColumns(job, mappedFieldsTemp, setStepIndex));
    setLoading(false);
  };

  const onFieldStatusUpdate = async (field, newStatus) => {
    const updatedFileFieldsStatus = { ...fileFieldsStatus, [field]: newStatus };
    setFileFieldsStatus(updatedFileFieldsStatus);
    const { invalidFields: fieldStatusUpdateInvalidFields } =
      validateFieldMapping(mappedFieldsTemp, updatedFileFieldsStatus);
    setInvalidFields(fieldStatusUpdateInvalidFields);
  };
  const onFieldIgnore = (field) => {
    const mappedFieldIndex = Object.values(mappedFieldsTemp).indexOf(field);
    const mappedFieldValue =
      mappedFieldIndex === -1
        ? null
        : Object.keys(mappedFieldsTemp)[mappedFieldIndex];
    setMappedFieldsTemp((fields) => ({ ...fields, [mappedFieldValue]: null }));
  };

  const FooterComponent = props.footer || null;

  return (
    <>
      <div className="job-wizard-modal-step job-wizard-modal-step-mapping-fields">
        {Object.keys(job).length && (
          <div className="fields-container">
            {job.columnNames.map((field, index) => {
              const mappedFieldIndex =
                Object.values(mappedFieldsTemp).indexOf(field);
              const mappedFieldValue =
                mappedFieldIndex === -1
                  ? null
                  : Object.keys(mappedFieldsTemp)[mappedFieldIndex];
              const mappedFieldLabel = FIELD_MAPPING_SELECT_OPTIONS.find(
                (option) => option.value === mappedFieldValue,
              )?.text;

              return (
                <FieldCard
                  field={field}
                  mappedFieldsTemp={mappedFieldsTemp}
                  setMappedFieldsTemp={setMappedFieldsTemp}
                  disableSelect={[
                    FIELD_MAPPING_STATUS_IGNORE,
                    FIELD_MAPPING_STATUS_MAPPED,
                  ].includes(fileFieldsStatus[field])}
                  fieldChar={convertNumberToLetters(index + 1)}
                  fileFieldStatus={fileFieldsStatus[field]}
                  records={records}
                  // Update the field status
                  onSelect={() =>
                    onFieldStatusUpdate(field, FIELD_MAPPING_STATUS_MAPPED)
                  }
                  key={field}
                >
                  <MapFieldActions
                    fileFieldStatus={fileFieldsStatus[field]}
                    onFieldStatusUpdate={(newStatus) =>
                      onFieldStatusUpdate(field, newStatus)
                    }
                    disableConfirm={
                      !findObjectKeyByValue(mappedFieldsTemp, field)
                    }
                    field={mappedFieldLabel}
                    onIgnore={() => onFieldIgnore(field)}
                  />
                </FieldCard>
              );
            })}
          </div>
        )}
        <RequiredFieldsModal
          isValidationModalVisible={isValidationModalVisible}
          setIsValidationModalVisible={setIsValidationModalVisible}
          invalidFields={invalidFields}
        />
      </div>
      <FooterComponent
        currentStepData={props.currentStepData}
        onBackClick={props.onBackClick}
        onNextClick={onNextClick}
        loading={loading}
      />
    </>
  );
};

export default MappingFieldsStep;
