import { Button, Space, Row, Col, Form, Tag } from 'antd';
import React from 'react';
import {
  DEFAULT_FILTERS,
  DEAL_SUBMISSION_STATUS_OPTIONS,
} from '../../constants';
import {
  ZorbaSelect,
  ZorbaInput,
  PlacesAutoComplete,
  ZorbaRangePicker,
} from '../../../../../../components/forms';
import UserAutoComplete from '../../../../../../components/forms/UserAutoComplete';

export default ({
  isLoading,
  form,
  onAdvanceFilterChanged,
  onAdvanceSearchClear,
  powerBuyers,
  onUserAutoCompleteHandler,
  selectedAreas,
  setSelectedAreas,
}) => {
  const applySearch = (appliedSearchCriteria = {}) => {
    if (onAdvanceFilterChanged) {
      onAdvanceFilterChanged({
        ...appliedSearchCriteria,
        areas: selectedAreas,
      });
    }
    setSelectedAreas([]);
  };

  const clearSearch = () => {
    form.resetFields();
    if (onAdvanceSearchClear) {
      onAdvanceSearchClear(DEFAULT_FILTERS);
    }
  };

  const onPlacesChange = (address) => {
    if (!address?.formattedAddress) return;

    if (
      selectedAreas.filter(
        (x) => x.formattedAddress === address.formattedAddress,
      ).length > 0
    )
      return;

    setSelectedAreas([...selectedAreas, address].filter((x) => !!x));
  };

  const onPlaceDelete = (singleArea) => {
    const updatedAreas = selectedAreas.filter(
      (selectedArea) =>
        selectedArea.formattedAddress !== singleArea.formattedAddress,
    );
    setSelectedAreas([...updatedAreas]);
  };

  return (
    <div className="buyer-advance-filter">
      <Form
        layout="vertical"
        name="advanceFilter"
        initialValues={DEFAULT_FILTERS}
        onFinish={applySearch}
        autoComplete="off"
        form={form}
      >
        <Row gutter={16}>
          <Col xs={24} xl={24} lg={24} md={24} sm={24}>
            <div className="form-field form-field__group">
              <div className="form-field__item">
                <Form.Item label="Status" name="status">
                  <ZorbaSelect
                    options={DEAL_SUBMISSION_STATUS_OPTIONS}
                    mode="multiple"
                    allowClear
                    placeholder="Select status"
                  />
                </Form.Item>
              </div>
              <div className="form-field__item">
                <Form.Item label="Power Buyer" name="powerBuyers">
                  <ZorbaSelect
                    options={powerBuyers}
                    mode="multiple"
                    allowClear
                    placeholder="Select power buyer"
                  />
                </Form.Item>
              </div>
              <div className="form-field__item">
                <Form.Item label="Seller name" name="sellerName">
                  <UserAutoComplete
                    onAutoCompleteSelect={onUserAutoCompleteHandler}
                  />
                </Form.Item>
              </div>

              <div className="form-field__item">
                <Form.Item
                  label="Min actions"
                  name="minActions"
                  rules={[{ type: 'number' }]}
                >
                  <ZorbaInput minValue="0" type="number" symbol="" />
                </Form.Item>
              </div>
            </div>
          </Col>
          <Col xs={24} xl={12} lg={12} md={24} sm={24}>
            <div className="form-field form-field__group">
              <div className="form-field__item">
                <PlacesAutoComplete
                  title="Located in"
                  clearInputOnSelect
                  onChange={(address) => onPlacesChange(address)}
                />
                <div className="area-list-wrapper">
                  {selectedAreas.map((singleArea) => (
                    <Tag
                      className="area-list-tag"
                      key={singleArea.formattedAddress}
                      closable
                      onClose={() => onPlaceDelete(singleArea)}
                    >
                      {singleArea.formattedAddress}
                    </Tag>
                  ))}
                </div>
              </div>
              <div className="form-field__item">
                <ZorbaRangePicker
                  label="When was the deal created"
                  name="createdAtRange"
                  format="YYYY-MM-DD"
                  allowEmpty={[false, true]}
                  defaultValue={[]}
                />
              </div>
            </div>
          </Col>
          <Col xs={24} xl={24} lg={8} md={24} sm={24}>
            <Space
              align="end"
              style={{ height: '100%', marginTop: '12px' }}
              className="btn-group-filter"
            >
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                disabled={isLoading}
              >
                Search
              </Button>
              <Button
                type="default"
                size="large"
                onClick={clearSearch}
                disabled={isLoading}
              >
                Clear all filters
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
