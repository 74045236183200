import React from 'react';
import { MAPPED_FIELD_COMPONENTS } from './constants';

import './index.scss';

/**
 * Render the right action based on the map field status
 * @returns
 */
const MapFieldActions = (props) => {
  const { fileFieldStatus } = props;
  const CurrentComponent = MAPPED_FIELD_COMPONENTS[fileFieldStatus];

  if (!CurrentComponent) return null;
  return (
    <div
      className={`mapping-field-stage-card-controls mapping-status-${fileFieldStatus}`}
    >
      <CurrentComponent {...props} />
    </div>
  );
};

export default MapFieldActions;
