import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { MdExitToApp } from '@react-icons/all-files/md/MdExitToApp';
import { Tag, Space } from 'antd';

import { logoutUser } from '../../../../../../services/authService';

import './index.scss';
import { PERMISSION_TITLES } from '../../../../../../constants/permission';

export const UserPanel = () => {
  const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);
  const IMAGE_PLACEHOLDER =
    'https://res.cloudinary.com/dyqquolic/image/upload/v1662419998/assets/icon-2_y22xtm.png';

  const handleLogout = useCallback(async () => {
    await logoutUser();
  }, []);

  return (
    <div className="user-panel-container">
      <div className="user-details-container">
        <img
          alt={userDetails.name}
          src={userDetails.picture || IMAGE_PLACEHOLDER}
          className="avatar"
        />
        <h3 className="user-name">{userDetails.name}</h3>
        <span className="user-email">{userDetails.email}</span>
        {userDetails.permissions && (
          <Space size={[8, 8]} wrap className="user-permissions" align="center">
            {userDetails.permissions.map((permission) => (
              <Tag color="default" key={permission}>
                {PERMISSION_TITLES[permission]}
              </Tag>
            ))}
          </Space>
        )}
      </div>

      <div className="buttons-container">
        <button className="logout-button" onClick={handleLogout} type="button">
          <MdExitToApp size={16} color="#3f434a" />
        </button>
      </div>
    </div>
  );
};
