import { Modal } from 'antd';
import React from 'react';
import { COLUMN_NAME_TITLES } from '../../constants';

import './index.scss';

const RequiredFieldsModal = (props) => {
  const {
    isValidationModalVisible,
    setIsValidationModalVisible,
    invalidFields,
  } = props;

  const closeModal = () => setIsValidationModalVisible(false);
  const matchingFieldNames = invalidFields.map(
    (invalidField) => COLUMN_NAME_TITLES[invalidField],
  );
  return (
    <Modal
      open={isValidationModalVisible}
      okButtonProps={{ className: 'ant-btn skiptracing-button continue-btn' }}
      onOk={closeModal}
      cancelButtonProps={{ style: { display: 'none' } }}
      closable={false}
      maskClosable
      title="The following fields needs to be selected and confirmed:"
      className="required-fields-modal"
    >
      <ol>
        {matchingFieldNames.map((name) => (
          <li>{name}</li>
        ))}
      </ol>
    </Modal>
  );
};

export default RequiredFieldsModal;
