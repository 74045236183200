import React from 'react';
import { ConfigProvider, Table } from 'antd';

import { COLUMN_NAME_TITLES } from '../../../MappingFieldsStep/constants';
import { findObjectKeyByValue } from '../../../../../../../../services/objectService';

import './index.scss';

const PreviewRecordsTable = (props) => {
  const { records, job } = props;
  const getRecordWithMappedFields = (record = {}) => {
    const mappedFieldsRecord = {};
    Object.keys(record).forEach((field) => {
      const mappedField = findObjectKeyByValue(job.mappedColumns, field);
      if (mappedField) mappedFieldsRecord[mappedField] = record[field];
    });
    return mappedFieldsRecord;
  };

  const columns = [
    {
      title: ' ',
      dataIndex: 'key',
      key: 'key',
      width: '0',
      className: 'index-column',
    },
    ...Object.keys(COLUMN_NAME_TITLES).map((column) => ({
      title: COLUMN_NAME_TITLES[column],
      dataIndex: column,
      key: column,
      render: (text) => {
        if (text?.length || typeof text === 'number')
          return {
            children: <span>{text}</span>,
          };
        return (
          <div className="preview-records-cell-empty-wrapper">
            <div className="preview-records-cell-empty" />
          </div>
        );
      },
    })),
  ];
  const dataSource = records.map((record, index) => ({
    key: `${index + 1}`,
    ...getRecordWithMappedFields(record),
  }));

  return (
    <div className="preview-records-table">
      <ConfigProvider renderEmpty={() => null}>
        <Table
          bordered
          columns={columns}
          dataSource={dataSource}
          pagination={false}
        />
      </ConfigProvider>
    </div>
  );
};

export default PreviewRecordsTable;
