import { useState } from 'react';
import { Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { getPriceText } from '@zorba-shared/core/Services/textService';
import { getLatestAskingPriceBySeller } from '@zorba-shared/core/Constants/offer';
import { ZorbaButton, ZorbaInput } from '../../../../../../components/forms';
import { setModalState } from '../../../../../../reducer/Modal/actions';
import { postRequest } from '../../../../../../services/requestService';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../../../services/notificationsService';
import { fetchDealSubmission } from '../../../../../../reducer/DealSubmission/actions';
import { MODAL_UPDATE_ASKING_PRICE } from '../../../../../../reducer/Modal/types';

import './index.scss';

export const UpdateAskingPriceModal = ({ selectedDealSubmission }) => {
  const { isUpdateAskingPriceModalOpen } = useSelector(
    ({ ModalReducer }) => ModalReducer,
  );
  const dispatch = useDispatch();
  const [updatedAskingPrice, setUpdatedAskingPrice] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const handleCloseModal = () => {
    dispatch(setModalState(MODAL_UPDATE_ASKING_PRICE, false));
  };
  const handleCounterOffer = async () => {
    try {
      setIsLoading(true);
      await postRequest('/backoffice/deal-submission/update-asking-price', {
        dealSubmissionId: selectedDealSubmission._id,
        updatedAskingPrice,
      });
      setIsLoading(false);
      handleCloseModal();
      showSuccessToast('Asking price updated successfully');
      dispatch(fetchDealSubmission(selectedDealSubmission._id));
    } catch (error) {
      setIsLoading(false);
      showErrorToast(
        error?.response?.data?.reason || 'Updating asking price failed',
      );
    }
  };

  return (
    <Modal
      open={isUpdateAskingPriceModalOpen}
      footer={null}
      onCancel={handleCloseModal}
      title={`${selectedDealSubmission.address.formattedAddress}`}
      className="update-asking-price-modal"
      destroyOnClose
    >
      <span>
        Current asking price:{' '}
        {getPriceText(getLatestAskingPriceBySeller(selectedDealSubmission))}
      </span>
      <div className="form-item-container">
        <ZorbaInput
          label="New asking price*"
          onChange={(value) => setUpdatedAskingPrice(value)}
          type="number"
          value={updatedAskingPrice}
          symbol=""
          addonBefore="$"
          fullWidth
        />
      </div>
      <div className="button-container">
        <ZorbaButton
          disabled={!updatedAskingPrice || isLoading}
          onClick={handleCounterOffer}
          fullWidth={false}
          loading={isLoading}
        >
          Submit
        </ZorbaButton>
      </div>
    </Modal>
  );
};
