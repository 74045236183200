import { AiFillCloseCircle } from '@react-icons/all-files/ai/AiFillCloseCircle';
import { useState } from 'react';
import { Table } from 'antd';
import { v4 as uuid } from 'uuid';
import { AiFillCheckCircle } from '@react-icons/all-files/ai/AiFillCheckCircle';
import { getTableColumns } from './columns';
import { combineObjectsByTitle } from '../../constants';

import { ZorbaButton } from '../../../../components/forms';
import { BUTTON_VARIANT_DARK } from '../../../../components/forms/ZorbaButton/constants';

import './index.scss';

/**
 * This component render all the matching buyers that are matched with property.
 */

const MatchingBuyersTable = (params) => {
  const {
    data,
    isLoading,
    address,
    buyersWithMatchingBuybox,
    isShowMatchingCount,
    hideTitle = false,
    dispatch,
    hideOfferParams = false,
  } = params;
  if (!address) return null;
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const getTitle = () => {
    if (hideTitle) return null;

    const title =
      buyersWithMatchingBuybox.length > 0 ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <AiFillCheckCircle
            style={{ color: '#49e387', marginRight: 5, fontSize: 35 }}
          />
          <div>
            {address?.formattedAddress}
            {isShowMatchingCount &&
              ` has ${buyersWithMatchingBuybox.length} matches!`}
          </div>
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <AiFillCloseCircle
            style={{ color: '#fc6d6d', marginRight: 5, fontSize: 35 }}
          />
          <div>{`${address?.formattedAddress}  has no results :(`}</div>
        </div>
      );

    return isLoading ? 'Loading...' : title;
  };

  const onExpand = (expanded, record) => {
    const currentKey = record.buyer;
    const newExpandedRowKeys = expanded
      ? [...expandedRowKeys, currentKey]
      : expandedRowKeys.filter((key) => key !== currentKey);

    setExpandedRowKeys(newExpandedRowKeys);
  };

  const onExpandAllClick = () => {
    if (expandedRowKeys.length < data.length) {
      setExpandedRowKeys(data.map((row) => row.buyer));
    } else {
      setExpandedRowKeys([]);
    }
  };

  return (
    <>
      <div className="expand-container">
        <ZorbaButton
          fullWidth={false}
          variant={BUTTON_VARIANT_DARK}
          onClick={onExpandAllClick}
        >
          {expandedRowKeys.length < data.length
            ? 'Expand all rows'
            : 'Contract all rows'}
        </ZorbaButton>
      </div>
      <Table
        rowKey="buyer"
        className="matching-buyer-table"
        loading={isLoading}
        columns={getTableColumns(dispatch, hideOfferParams)}
        dataSource={data}
        pagination={false}
        title={getTitle}
        scroll={{ x: true }}
        expandedRowKeys={expandedRowKeys}
        onExpand={onExpand}
        expandable={{
          expandedRowRender: (record) =>
            combineObjectsByTitle(record.detailedReasons).map(
              (detailedReason) => (
                <div key={uuid()}>
                  <span className="detailed-reasons-title">{`${detailedReason.title}:`}</span>
                  <span>{detailedReason.reason}</span>
                </div>
              ),
            ),
          rowExpandable: (record) => record.detailedReasons?.length,
        }}
      />
    </>
  );
};
export default MatchingBuyersTable;
