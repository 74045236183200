import { Button, Popconfirm } from 'antd';

export default (props) => {
  const {
    onConfirm,
    onCancel,
    children,
    confirmTitle = 'Are you sure you want to perform this acton?',
    onClick = () => {},
    ...inheritedProps
  } = props;

  return (
    <Popconfirm
      title={confirmTitle}
      onConfirm={onConfirm}
      onCancel={onCancel}
      okText="Yes"
      cancelText="No"
    >
      <Button {...inheritedProps}>{children}</Button>
    </Popconfirm>
  );
};
