import * as types from './types';
import { getRequest, postRequest } from '../../services/requestService';
import {
  showErrorToast,
  showSuccessToast,
} from '../../services/notificationsService';

export function setSearchQuery(searchQuery) {
  return {
    type: types.SET_SEARCH_QUERY,
    payload: { searchQuery },
  };
}

export const searchPropertyData = (searchParams) => async (dispatch) => {
  dispatch({
    type: types.SET_IS_FETCHING_DATA,
    payload: { isFetchingData: true },
  });
  dispatch({
    type: types.SET_SEARCH_QUERY,
    payload: { searchQuery: searchParams.searchQuery },
  });

  try {
    const res = await postRequest(
      '/backoffice/property-data/search',
      searchParams,
    );
    dispatch({
      type: types.SET_PROPERTY_DATA_RESULTS,
      payload: { total: res.data.total, data: res.data.data },
    });
    dispatch({
      type: types.SET_IS_FETCHING_DATA,
      payload: { isFetchingData: false },
    });
  } catch (error) {
    showErrorToast('Fetching property data failed');
    dispatch({
      type: types.SET_IS_FETCHING_DATA,
      payload: { isFetchingData: false },
    });
  }
};

export function setJob(job) {
  return {
    type: types.SET_JOB,
    payload: { job },
  };
}

export function setRecords(records) {
  return {
    type: types.SET_RECORDS,
    payload: { records },
  };
}

export const getPropertyDataTags = () => async (dispatch) => {
  const res = await getRequest('/backoffice/property-data/tag');
  dispatch({ type: types.SET_TAGS, payload: { ...res.data } });
};

export const updateMappedColumns =
  (job, mappedFields, setStepIndex) => async (dispatch) => {
    try {
      const requestUrl = '/backoffice/property-data/job/update-mapped-columns';

      const res = await postRequest(requestUrl, {
        id: job._id,
        mappedColumns: mappedFields,
      });
      if (res.status === 200 && res.data.job) {
        dispatch(setJob(res.data.job));
        setStepIndex((stepIndex) => stepIndex + 1);
      }
    } catch (error) {
      showErrorToast('Updating mapped columns failed');
    }
  };

export const updatePropertyData =
  (job, listName, selectedTags, setIsImportModalOpen) => async (dispatch) => {
    try {
      const requestUrl = '/backoffice/property-data/job/update';
      const res = await postRequest(requestUrl, {
        id: job._id,
        name: listName,
        tags: selectedTags,
      });
      if (res.status === 200 && res.data.job) {
        dispatch(setJob(res.data.job));
        setIsImportModalOpen(false);
        showSuccessToast('List imported successfully!');
        dispatch(getPropertyDataTags());
      }
    } catch (error) {
      showErrorToast('Importing list failed');
    }
  };

export const triggerPropertyDataJob = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: types.SET_IS_FETCHING_DATA,
      payload: { isFetchingData: true },
    });

    const res = await postRequest(
      '/backoffice/property-data/job/create',
      formData,
    );
    if (res.status === 200) {
      showSuccessToast(`${formData.jobKind} job started started succesfully`);
    }
    dispatch({
      type: types.SET_IS_FETCHING_DATA,
      payload: { isFetchingData: false },
    });
  } catch (error) {
    showErrorToast(`Requesting ${formData.jobKind} failed`);
    dispatch({
      type: types.SET_IS_FETCHING_DATA,
      payload: { isFetchingData: false },
    });
  }
};

export const createTags =
  (data, setIsAddTagModalOpened) => async (dispatch) => {
    try {
      dispatch({
        type: types.SET_IS_FETCHING_DATA,
        payload: { isFetchingData: true },
      });

      await postRequest('/backoffice/property-data/tag', data);
      showSuccessToast('Tags created successfully!');
      dispatch({
        type: types.SET_IS_FETCHING_DATA,
        payload: { isFetchingData: false },
      });
      setIsAddTagModalOpened(false);
      dispatch(getPropertyDataTags());

      dispatch(
        searchPropertyData({
          filters: data.filters,
          page: data.currentPage,
          pageSize: data.pageSize,
        }),
      );
    } catch (error) {
      showErrorToast(`Creating tags failed!`);
      dispatch({
        type: types.SET_IS_FETCHING_DATA,
        payload: { isFetchingData: false },
      });
    }
  };

export const setPropertyDataFilter = (filter) => async (dispatch, getState) => {
  let filters = getState().PropertyDataReducer.appliedFilters;
  filters = [...filters, filter];
  dispatch({
    type: types.SET_PROPERTY_DATA_FILTER,
    payload: { appliedFilters: filters },
  });
};

export const removePropertyDataFilter =
  (filter) => async (dispatch, getState) => {
    let filters = getState().PropertyDataReducer.appliedFilters;
    filters = filters.filter((item) => item.id !== filter.id);
    dispatch({
      type: types.SET_PROPERTY_DATA_FILTER,
      payload: { appliedFilters: filters },
    });
  };

export const clearPropertyDataFilter = () => async (dispatch) => {
  dispatch({
    type: types.SET_PROPERTY_DATA_FILTER,
    payload: { appliedFilters: [] },
  });
};
