import { getFormattedTime } from '@zorba-shared/core/Services/textService';
import { BsThreeDotsVertical } from '@react-icons/all-files/bs/BsThreeDotsVertical';
import { BsFillTrashFill } from '@react-icons/all-files/bs/BsFillTrashFill';
import { Button } from 'antd';
import { ZorbaDropdown } from '../../../../components/forms';

export const getTableColumns = ({ onDelete }) => [
  {
    title: 'Entity Name',
    key: 'entityName',
    render: ({ name }) => name,
  },
  {
    title: 'Created At',
    key: 'createdAt',
    render: ({ createdAt }) => getFormattedTime(createdAt),
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    fixed: 'right',
    render: (_, record) => (
      <ZorbaDropdown
        actions={[
          {
            title: 'Delete',
            icon: <BsFillTrashFill />,
            onClick: () => {
              if (onDelete) {
                onDelete({
                  name: record.name,
                  organizationId: record.organizationId,
                });
              }
            },
          },
        ]}
        btnText={
          <Button type="text" size="large" icon={<BsThreeDotsVertical />} />
        }
        trigger={['click']}
        className="stage-actions-dropdown-button"
      />
    ),
  },
];
