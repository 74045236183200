import { Drawer } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { getLatestAskingPriceBySeller } from '@zorba-shared/core/Constants/offer';
import _ from 'lodash';
import { loadTranslationCategories } from '../../../../reducer/Translation/actions';
import { createDealSubmissionMatch } from '../../../../reducer/DealSubmission/actions';
import TranslationService from '../../../../services/translationService';
import { ZorbaButton, ZorbaSelect } from '../../../../components/forms';
import { BUTTON_VARIANT_DARK } from '../../../../components/forms/ZorbaButton/constants';
import { setDrawerState } from '../../../../reducer/Drawer/actions';
import { DRAWER_DEAL_SUBMISSION_MATCH } from '../../../../reducer/Drawer/types';
import OfferTerms from './Components/OfferTerms';
import {
  DEAL_SUBMISSION_MATCH_FORM,
  DEAL_SUBMISSION_MATCH_OFFER_PARAMS,
  getDealSubmissionMatchDefaultParams,
  HOMEWARD_ORGANIZATION_ID,
} from './constants';
import { buildCreateRequest } from './service';
import OfferCalculator from './Components/OfferCalculator';
import { getMonthlyHoaFee } from '../BuyBoxCheckerTab/utils';

import './index.scss';

export default () => {
  const dispatch = useDispatch();
  const { selectedDealSubmission } = useSelector(
    ({ DealSubmissionReducer }) => DealSubmissionReducer,
  );
  const { isDealSubmissionMatchDrawerOpen, drawerParams } = useSelector(
    ({ DrawerReducer }) => DrawerReducer,
  );
  const { translationCategories } = useSelector(
    ({ TranslationReducer }) => TranslationReducer,
  );
  const { permissions, userDetails } = useSelector(
    ({ AuthReducer }) => AuthReducer,
  );
  const [offerParams, setOfferParams] = useState();

  useEffect(() => {
    dispatch(loadTranslationCategories(['errors']));
  }, []);

  const defaultUnderwritingValues = getDealSubmissionMatchDefaultParams(
    selectedDealSubmission?.underwriting,
  );
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      ...defaultUnderwritingValues,
    },
  });
  const convertMatchToForm = (params) => {
    if (!params || Object.keys(params).length === 0) return {};
    let rawParams = _.pick(params, DEAL_SUBMISSION_MATCH_FORM);
    const lastEvent = _.last(params.events);

    const rawOfferParams = _.pick(
      params.offerParams || {},
      DEAL_SUBMISSION_MATCH_OFFER_PARAMS,
    );
    const isStringStatus = typeof rawParams.status === 'string';

    const calculateFee = () => {
      let FEE_PERCENTAGE = 0.06;
      const minimumFee = 4000;

      // SETTING UP 0 FOR HOMEWARD
      if (params.organization._id === HOMEWARD_ORGANIZATION_ID) {
        FEE_PERCENTAGE = 0.03;
      }
      const latestAskingPrice = Number(
        getLatestAskingPriceBySeller(selectedDealSubmission),
      );
      const percentageFee = latestAskingPrice * FEE_PERCENTAGE;


      // Return the higher value between percentageFee and minimumFee
      return Math.round(Math.max(percentageFee, minimumFee));
    };

    rawParams = {
      ...rawParams,
      // OFFER PARAMS FROM CALCULATION AS A SECONDARY SOURCE OF TRUTH
      ...rawOfferParams,
      // UNDERWRITING PARAMS AS A MAIN SOURCE OF TRUTH IF THERE IS NO STATUS
      ...(isStringStatus ? {} : defaultUnderwritingValues),
      organization: params.organization._id,
      status: rawParams.status || params.status,
      monthlyHoaFee: getMonthlyHoaFee(selectedDealSubmission) || 0,
      inspectionMode: lastEvent?.inspectionMode || 'pre_inspection',
      daysToClose: lastEvent?.daysToClose || 21,
      feeAmount: rawParams.feeAmount || calculateFee(),
      emd: lastEvent?.emd || 5000,
    };

    if (typeof rawParams.status !== 'string') {
      rawParams.status = 'offer_request';
    }
    return rawParams;
  };

  useEffect(() => {
    if (drawerParams) {
      reset(convertMatchToForm(drawerParams));
      setOfferParams({ ...drawerParams.offerParams });
    }
  }, [drawerParams]);

  const onClose = () => {
    dispatch(setDrawerState(DRAWER_DEAL_SUBMISSION_MATCH, false));
    reset(defaultUnderwritingValues);
    setOfferParams();
  };

  const onSubmit = async (newParams) => {
    const requestParams = buildCreateRequest({
      drawerParams,
      selectedDealSubmission,
      offerParams,
      newParams,
      userDetails,
    });
    dispatch(createDealSubmissionMatch(requestParams));
    onClose();
  };

  if (!selectedDealSubmission) return null;

  const matchingBuyerOptions = selectedDealSubmission?.powerBuyerMatches?.length
    ? selectedDealSubmission.powerBuyerMatches.map((match) => ({
        text: match.name,
        value: match.organization,
      }))
    : [];

  const translationService = new TranslationService(translationCategories);

  return (
    <Drawer
      className="deal-submission-match-drawer"
      title="Associate Buyer to this deal"
      placement="right"
      onClose={onClose}
      open={isDealSubmissionMatchDrawerOpen}
      width={500}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="organization"
          control={control}
          render={({ field }) => (
            <ZorbaSelect
              label="Selected Buyer"
              options={matchingBuyerOptions}
              field={field}
              disabled
            />
          )}
        />
        <OfferTerms
          errors={errors}
          control={control}
          translationService={translationService}
          getValues={getValues}
          watch={watch}
        />

        <OfferCalculator
          offerParams={offerParams}
          selectedDealSubmission={selectedDealSubmission}
          control={control}
          watch={watch}
          setValue={setValue}
          permissions={permissions}
          getValues={getValues}
        />
        <div className="footer-buttons">
          <ZorbaButton
            fullWidth
            htmlType="submit"
            variant={BUTTON_VARIANT_DARK}
          >
            {drawerParams._id ? 'Update Offer' : 'Send to buyer'}
          </ZorbaButton>
        </div>
      </form>
    </Drawer>
  );
};
