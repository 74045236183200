/* eslint-disable import/prefer-default-export */
import './index.scss';
import { Tag, Tooltip, Button, Space } from 'antd';
import { flatten, uniq } from 'lodash';
import { Link } from 'react-router-dom';
import { BsThreeDotsVertical } from '@react-icons/all-files/bs/BsThreeDotsVertical';
import { BsFillTrashFill } from '@react-icons/all-files/bs/BsFillTrashFill';
import { MdTabUnselected } from '@react-icons/all-files/md/MdTabUnselected';

import { getFormattedTime } from '@zorba-shared/core/Services/textService';
import { buildBuyerPageUrl } from '../../../../services/urlBuilderService';
import { copy } from '../../../../services/clipboardService';

import { ZorbaDropdown } from '../../../../components/forms';
import UserAvatar from '../../../../components/UserAvatar';
import { getName } from '../../../../utils';
import { showSuccessToast } from '../../../../services/notificationsService';
import { USER_KIND_TITLES } from '../../../../constants/permission';

const copyHandler = (text) => {
  copy(text);
  showSuccessToast('Copied to clipboard');
};

const TagItem = (type, text) => {
  const className = {
    'zorba-tag': true,
    success: type === 'success',
    error: type === 'error',
  };
  return <Tag className={className}>{text}</Tag>;
};

export const getTableColumns = (onDelete, onUnsubscribeBuyer) => [
  {
    title: 'Name',
    key: 'name',
    onCell: (record) => ({
      onClick: (event) => {
        if (
          record.email &&
          event.target.className &&
          event.target.className.indexOf('buyer-email') >= 0
        ) {
          copyHandler(record.email);
        }
      },
    }),
    render: (buyer) => (
      <UserAvatar
        title={
          <Link
            className="buyer-name"
            to={buildBuyerPageUrl({ userDetails: buyer })}
          >
            {getName(buyer)}{' '}
            {buyer.kind?.length > 0 && (
              <Space
                size={[8, 8]}
                wrap
                className="user-permissions"
                align="center"
              >
                {(buyer.kind || []).map((kind) => (
                  <Tag key={kind} className="user-kind">
                    {USER_KIND_TITLES[kind]}
                  </Tag>
                ))}
              </Space>
            )}
          </Link>
        }
        description={
          <Tooltip title="Click to copy email" className="buyer-email">
            {buyer.email}
          </Tooltip>
        }
        avatar={buyer.picture || '/img/person.svg'}
      />
    ),
    sorter: (a, b) => (a.firstName > b.firstName ? 1 : -1),
  },
  {
    title: 'Company Name',
    key: 'companyName',
    render: (buyer) => buyer.defaultOrganization?.name || '',
    sorter: (a, b) =>
      a.defaultOrganization?.name > b.defaultOrganization?.name ? 1 : -1,
  },
  {
    title: 'Active States',
    dataIndex: 'markets',
    width: 200,
    render: (_, buyer) => {
      const allAreas = flatten(
        buyer.defaultOrganization?.buyBoxList?.map((buyBox) =>
          buyBox.areas.map((area) => area?.stateCode),
        ),
      );
      const uniqueAreas = uniq(allAreas);

      return <div className="text-wrap">{uniqueAreas.join(', ')}</div>;
    },
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    onCell: ({ phoneNumber }) => ({
      onClick: () => {
        if (phoneNumber.number) {
          copyHandler(phoneNumber.number);
        }
      },
    }),
    render: (_, { phoneNumber }) =>
      phoneNumber?.number ? (
        <Tooltip title="Click to copy">
          <span>
            (+{phoneNumber.countryCode}) {phoneNumber.number}
          </span>
        </Tooltip>
      ) : (
        ''
      ),
    sorter: (a, b) => (a.phoneNumber?.number > b.phoneNumber?.number ? 1 : -1),
  },
  {
    title: 'Is Verified?',
    dataIndex: 'isVerified',
    render: (_, { defaultOrganization }) => {
      const isVerified = !!defaultOrganization?.verification?.verified;

      return isVerified ? TagItem('success', 'Yes') : '';
    },
  },
  {
    title: 'Has Buybox?',
    key: 'buybox',
    render: (_, { defaultOrganization }) => {
      const hasBuyBox = !!defaultOrganization?.buyBoxList?.length > 0;

      return hasBuyBox ? TagItem('success', 'Yes') : '';
    },
  },
  {
    title: 'Is banned?',
    dataIndex: 'status',
    render: (_, { isBanned }) => (isBanned ? TagItem('error', 'Banned') : null),
  },
  {
    title: 'Is unsubscribed in CIO?',
    render: ({ isUnsubscribedFromSMSInCIO }) =>
      isUnsubscribedFromSMSInCIO ? TagItem('error', 'Yes') : null,
  },
  {
    title: 'Registered On',
    dataIndex: 'createdAt',
    render: (_, { createdAt }) => getFormattedTime(createdAt, 'MMM D'),
    sorter: (a, b) => (a.createdAt > b.createdAt ? 1 : -1),
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    render: (_, buyer) => (
      <ZorbaDropdown
        actions={[
          {
            title: 'Delete',
            icon: <BsFillTrashFill />,
            onClick: () => {
              if (onDelete) {
                onDelete(buyer);
              }
            },
          },
          ...(buyer.isUnsubscribedFromSMSInCIO
            ? []
            : [
                {
                  title: 'Unsubscribe from SMS campaigns',
                  icon: <MdTabUnselected />,
                  onClick: () => {
                    onUnsubscribeBuyer(buyer);
                  },
                },
              ]),
        ]}
        btnText={
          <Button type="text" size="large" icon={<BsThreeDotsVertical />} />
        }
        trigger={['click']}
        className="stage-actions-dropdown-button"
      />
    ),
  },
];
