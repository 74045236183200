import * as types from './types';

const initialState = {
  organizations: [],
  hasMoreResults: false,
  total: 0,
  isOrganizationSaving: false,
  newOrganization: null,
};
export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_ORGANIZATIONS:
    case types.SET_ORGANIZATION_LOADING:
    case types.SET_ORGANIZATION_SAVING:
    case types.ADD_ORGANIZATION:
    case types.SET_NEW_ORGANIZATION:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
