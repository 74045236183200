import { Modal } from 'antd';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { postRequest } from '../../../../../../services/requestService';
import { MODAL_REASSIGN_REFERRAL } from '../../../../../../reducer/Modal/types';
import { setModalState } from '../../../../../../reducer/Modal/actions';
import { ZorbaButton, ZorbaSelect } from '../../../../../../components/forms';
import { BUTTON_VARIANT_DARK } from '../../../../../../components/forms/ZorbaButton/constants';
import {
  showSuccessToast,
  showErrorToast,
} from '../../../../../../services/notificationsService';

import './index.scss';

export const ReassignReferralModal = ({ selectedReferral, loadReferrals }) => {
  const [selectedEmail, setSelectedEmail] = useState('');
  const [isPending, setIsPending] = useState(false);
  const { isReassignReferralModalOpen } = useSelector(
    ({ ModalReducer }) => ModalReducer,
  );
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    setSelectedEmail('');
    dispatch(setModalState(MODAL_REASSIGN_REFERRAL, false));
  };

  const handleReassign = async () => {
    setIsPending(true);
    try {
      await postRequest('/backoffice/user/referrals/reassign-referral', {
        referralId: selectedReferral._id,
        newReferringUserEmail: selectedEmail,
      });
      handleCloseModal();
      showSuccessToast('Referral successfully reassigned');
      loadReferrals();
    } catch (error) {
      showErrorToast('Reassigning referral failed');
    }
    setIsPending(false);
  };

  const selectOptions = [
    { label: 'Masha Cikota', value: 'masha.cikota@getzorba.com' },
    { label: 'Elijah Perez', value: 'elijah.perez@getzorba.com' },
    { label: 'Sally Marvin', value: 'sally.marvin@getzorba.com' },
    { label: 'Trevor Yard', value: 'trevor.yard@getzorba.com' },
    { label: 'Anj Murphy', value: 'anj@getzorba.com' },
    { label: 'Aaron Godwin', value: 'aaron.godwin@getzorba.com' },
    { label: 'Brett Woodruff', value: 'brett.woodruff@getzorba.com' },
    { label: 'David Ordonez', value: 'david.ordonez@getzorba.com' },
  ].filter((option) => option.value !== selectedReferral?.referringUser?.email);

  return (
    <Modal
      open={isReassignReferralModalOpen}
      footer={null}
      onCancel={handleCloseModal}
      title={`Reassign referral - ${selectedReferral?.referredUser?.name}`}
      destroyOnClose
      className="reassign-modal"
    >
      <div className="select-container">
        <ZorbaSelect
          label="Select new referral owner"
          options={selectOptions}
          value={selectedEmail}
          onChange={(newVal) => setSelectedEmail(newVal)}
        />
      </div>
      <div className="button-container">
        <ZorbaButton
          variant={BUTTON_VARIANT_DARK}
          style={{ marginTop: '15px' }}
          disabled={!selectedEmail || isPending}
          onClick={handleReassign}
          isLoading={isPending}
        >
          Reassign
        </ZorbaButton>
      </div>
    </Modal>
  );
};
