import { ZorbaButton } from '../../../../../../../../components/forms';
import {
  FIELD_MAPPING_STATUS_FALSE,
  FIELD_MAPPING_STATUS_IGNORE,
  FIELD_MAPPING_STATUS_MAPPED,
} from '../../constants';

const EDIT_STATE = {
  [FIELD_MAPPING_STATUS_IGNORE]: {
    title: 'Ignored',
    icon: <i className="far fa-eye-slash" />,
    className: 'mapping-status-ignore',
  },
  [FIELD_MAPPING_STATUS_MAPPED]: {
    title: 'Confirmed mapping',
    icon: <i className="fas fa-check" />,
    className: 'mapping-status-mapped',
  },
};

const MapFieldEditAction = (props) => {
  const { variant = FIELD_MAPPING_STATUS_MAPPED, onFieldStatusUpdate } = props;

  const currentStateData = EDIT_STATE[variant];
  return (
    <>
      <div className={`field-status-info ${currentStateData.className}`}>
        {currentStateData.icon}
        <span>{currentStateData.title}</span>
      </div>
      <div className="controls-wrapper">
        <ZorbaButton
          fullWidth={false}
          onClick={() => onFieldStatusUpdate(FIELD_MAPPING_STATUS_FALSE)}
        >
          Edit
        </ZorbaButton>
      </div>
    </>
  );
};

export default MapFieldEditAction;
