import { Modal, Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  deleteBuyer,
  searchBuyers,
  unsubscribeUserFromSMSCampaigns,
} from '../../../../reducer/Buyer/actions';
import BuyerFilter from '../BuyerFilter';
import BuyerTable from '../BuyerTable';

import './index.scss';

export default () => {
  const [searchCriteria, setSearchCriteria] = useState(null);
  const { buyers, isLoading, total } = useSelector(
    ({ BuyerReducer }) => BuyerReducer,
  );
  const dispatch = useDispatch();
  const { confirm } = Modal;

  /**
   * call search API on every change in search criteria.
   */
  useEffect(() => {
    if (searchCriteria) {
      dispatch(searchBuyers(searchCriteria));
    }
  }, [searchCriteria]);

  const onSearchChange = (searchCriteria) => {
    setSearchCriteria((oldValue) => ({
      ...oldValue,
      ...searchCriteria,
      page: 0,
    }));
  };

  const onShowSizeChange = (current, pageSize) => {
    setSearchCriteria((oldValue) => ({
      ...oldValue,
      page: current - 1,
      itemsPerPage: pageSize,
    }));
  };

  const getLabel = (title) => <div className="tab-label">{title}</div>;

  const onDeleteBuyer = (buyer) => {
    confirm({
      content: `Do you want to delete this Buyer? An organization will be removed if associated with this buyer Only.`,
      okText: 'Delete',
      confirmLoading: isLoading,
      onOk() {
        dispatch(deleteBuyer(buyer._id));
      },
    });
  };

  const onUnsubscribeBuyer = (buyer) => {
    confirm({
      content: `Do you want to unsubscribe this user from SMS campaigns in CIO?`,
      okText: 'Unsubscribe',
      confirmLoading: isLoading,
      onOk() {
        dispatch(unsubscribeUserFromSMSCampaigns(buyer._id, searchCriteria));
      },
    });
  };

  const tabs = [
    {
      key: 'tab1',
      label: getLabel('All', total),
      children: (
        <>
          <BuyerFilter onSearchChange={onSearchChange} />
          <BuyerTable
            isLoading={isLoading}
            total={total}
            buyers={buyers}
            onShowSizeChange={onShowSizeChange}
            currentPage={searchCriteria?.page || 0}
            onDelete={onDeleteBuyer}
            onUnsubscribeBuyer={onUnsubscribeBuyer}
          />
        </>
      ),
    },
  ];

  return (
    <div className="tabs-container">
      <Tabs type="card" items={tabs} />
    </div>
  );
};
