import { Controller } from 'react-hook-form';
import { getPriceText } from '@zorba-shared/core/Services/textService';
import {
  INSPECTION_DAYS_OPTIONS,
  DAYS_TO_CLOSE_OPTIONS,
} from '@zorba-shared/core/Constants/offer';

import { ZorbaInput, ZorbaSelect } from '../../../../../../components/forms';
import { HOMEWARD_ORGANIZATION_ID } from '../../constants';

/**
 * This section includes the offer amount and terms (closing days, EMD, inspection period)
 */
export default (props) => {
  const { control, errors, getValues, watch } = props;
  const watchedInspectionMode = watch('inspectionMode');
  const organization = watch('organization');

  return (
    <>
      <h3 className="buyer-type-title">Offer amount and terms</h3>
      <div className="row two-items">
        {/* REHAB EST */}
        <Controller
          name="rehabCost"
          control={control}
          // rules={{
          //   validate: {
          //     required: (newVal) => {
          //       if (!newVal && !getValues('rehabCost'))
          //         return 'Rehab cost is required';

          //       return true;
          //     },
          //   },
          // }}
          render={({ field }) => (
            <ZorbaInput
              type="number"
              label="Rehab"
              help={errors?.rehabCost?.message}
              placeholder="$"
              field={field}
            />
          )}
        />
        {/* RENT */}
        <Controller
          name="rent"
          control={control}
          // rules={{
          //   validate: {
          //     required: (newVal) => {
          //       if (!newVal && !getValues('rent')) return 'Rent is required';

          //       return true;
          //     },
          //   },
          // }}
          render={({ field }) => (
            <ZorbaInput
              type="number"
              label="Rent"
              help={errors?.rent?.message}
              placeholder="$"
              field={field}
            />
          )}
        />
      </div>
      <div className="row two-items">
        {/* EMD */}
        <Controller
          name="emd"
          control={control}
          rules={{
            validate: {
              required: (newVal) => {
                if (!newVal && !getValues('emd')) return 'EMD is required';
                if (newVal < 2000) return 'Minimum 2000 $';

                return true;
              },
            },
          }}
          render={({ field }) => (
            <ZorbaInput
              label="EMD"
              type="number"
              placeholder={getPriceText(1000)}
              field={field}
              help={errors?.emd?.message}
            />
          )}
        />
        {/* ARV */}
        {/* <Controller */}
        {/*  name="arv" */}
        {/*  control={control} */}
        {/*  rules={{ */}
        {/*    validate: { */}
        {/*      required: (newVal) => { */}
        {/*        if (!newVal && !getValues('arv')) return 'ARV is required'; */}

        {/*        return true; */}
        {/*      }, */}
        {/*    }, */}
        {/*  }} */}
        {/*  render={({ field }) => ( */}
        {/*    <ZorbaInput */}
        {/*      type="number" */}
        {/*      label="ARV" */}
        {/*      help={errors?.arv?.message} */}
        {/*      placeholder="$" */}
        {/*      field={field} */}
        {/*    /> */}
        {/*  )} */}
        {/* /> */}
      </div>
      <div className="row two-items">
        <Controller
          name="monthlyHoaFee"
          control={control}
          render={({ field }) => (
            <ZorbaInput
              type="number"
              label="Monthly HOA Fee"
              help={errors?.monthlyHoaFee?.message}
              placeholder="$"
              field={field}
            />
          )}
        />
        <Controller
          name="feeAmount"
          control={control}
          rules={{
            validate: {
              required: (newVal) => {
                // HOMEWARD ALLOWED 0 AS A FEE AMOUNT
                if (organization === HOMEWARD_ORGANIZATION_ID) return true;

                if (!newVal && !getValues('feeAmount'))
                  return 'feeAmount is required';
                if (newVal < 1000) return 'Minimum 1000 $';

                return true;
              },
            },
          }}
          render={({ field }) => (
            <ZorbaInput
              type="number"
              label="Our Fee"
              help={errors?.feeAmount?.message}
              placeholder="$"
              field={field}
              disabled={organization === HOMEWARD_ORGANIZATION_ID}
            />
          )}
        />
      </div>
      <div className="row two-items">
        <Controller
          name="daysToClose"
          control={control}
          rules={{
            validate: {
              required: (newVal) => {
                if (!newVal && !getValues('daysToClose'))
                  return 'Days to close is required';

                return true;
              },
            },
          }}
          render={({ field }) => (
            <ZorbaSelect
              label="Days to close"
              options={DAYS_TO_CLOSE_OPTIONS}
              field={field}
              help={errors?.daysToClose?.message}
              validateStatus={
                errors?.daysToClose?.message ? 'error' : 'success'
              }
            />
          )}
        />
      </div>
      <div className="row two-items">
        <Controller
          name="inspectionMode"
          control={control}
          rules={{
            validate: {
              required: (newVal) => {
                if (!newVal && !getValues('inspectionMode'))
                  return 'Inspection mode is required';

                return true;
              },
            },
          }}
          render={({ field }) => (
            <ZorbaSelect
              label="Inspection mode"
              options={[
                { text: 'Pre inspection', value: 'pre_inspection' },
                { text: 'Contingency', value: 'contingency' },
              ]}
              field={field}
              help={errors?.inspectionMode?.message}
              validateStatus={
                errors?.inspectionMode?.message ? 'error' : 'success'
              }
            />
          )}
        />
        {watchedInspectionMode === 'contingency' && (
          <Controller
            name="inspectionDays"
            control={control}
            rules={{
              validate: {
                required: (newVal) => {
                  if (
                    watchedInspectionMode === 'contingency' &&
                    !newVal &&
                    !getValues('inspectionDays')
                  )
                    return 'Inspection period is required';

                  return true;
                },
              },
            }}
            render={({ field }) => (
              <ZorbaSelect
                label="Inspection period"
                options={INSPECTION_DAYS_OPTIONS}
                field={field}
                help={errors?.inspectionDays?.message}
                validateStatus={
                  errors?.inspectionDays?.message ? 'error' : 'success'
                }
              />
            )}
          />
        )}
      </div>
    </>
  );
};
