import { useEffect } from 'react';

import './index.scss';
import { useDispatch } from 'react-redux';
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle';
import { getPropertyDataJobs } from '../../../../reducer/PropertyDataJobs/actions';
import JobsTable from './Components/JobsTable';

const PropertyDataJobsTab = (props) => {
  const { selectedTab } = props;
  useDocumentTitle('Property Data Jobs');
  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedTab && selectedTab === 'propertyDataJobs') {
      dispatch(getPropertyDataJobs());
    }
  }, [selectedTab]);

  return <JobsTable />;
};

export default PropertyDataJobsTab;
