import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import ContentHeader from '../../components/ContentHeader';
import DealSubmissionList from './Components/DealSubmissionList';

const DealSubmissionsPage = () => {
  useDocumentTitle('Deal Submission Search');

  return (
    <>
      <ContentHeader title="Deals search" />
      <DealSubmissionList />
    </>
  );
};

export default DealSubmissionsPage;
