import { get } from 'lodash';
import { postRequest } from '../../services/requestService';
import { showErrorToast } from '../../services/notificationsService';
import * as types from './types';

export const getMatchedTransactions = (address) => async (dispatch) => {
  if (!address) return;

  dispatch({
    type: types.SET_IS_LOADING,
    payload: {
      isLoading: true,
      transactions: [],
    },
  });
  try {
    const res = await postRequest(`/backoffice/institutional-research/search`, {
      address,
    });
    const { transactions } = res.data;

    dispatch({
      type: types.SET_TRANSACTIONS,
      payload: {
        transactions,
      },
    });
  } catch (error) {
    const msg = get(error, 'response.data.reason');
    showErrorToast(msg || 'Oops, Please try again later');
  }

  dispatch({
    type: types.SET_IS_LOADING,
    payload: {
      isLoading: false,
    },
  });
};
