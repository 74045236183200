/**
 * Replace moment.js with day.js
 * Solution taken from https://ant.design/docs/react/replace-moment
 */
import { Form, DatePicker as Picker } from 'antd';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(utc);
dayjs.extend(timezone);

const DatePicker = ({ label, formClassName, tooltip, ...props }) => {
  const handleChange = (value) => {
    if (value) {
      const localTime = dayjs(value);
      const formattedLocalTime = localTime.format('YYYY-MM-DDTHH:mm:ss');
      const valueInEST = dayjs.tz(
        formattedLocalTime,
        'YYYY-MM-DDTHH:mm:ss',
        'America/New_York',
      );
      const onChangeHandler = props.field
        ? props.field.onChange
        : props.onChange;
      onChangeHandler(valueInEST);
    } else {
      const onChangeHandler = props.field
        ? props.field.onChange
        : props.onChange;
      onChangeHandler(null);
    }
  };

  return label || props.field ? (
    <Form.Item label={label} className={formClassName} tooltip={tooltip}>
      <Picker
        {...props}
        {...(props.field || {})}
        format="YYYY-MM-DD h:mm a"
        onChange={handleChange}
      />
    </Form.Item>
  ) : (
    <Picker {...props} onChange={handleChange} />
  );
};

export default DatePicker;
