import { Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import ContentHeader from '../../components/ContentHeader';
import { getBuyerDetails } from '../../reducer/Buyer/actions';
import TopSectionInfo from './Components/TopSectionInfo';

import './index.scss';
import { getTabs } from './constants';
import PageSkeleton from '../../components/PageSkeleton';
import { getOrganizations } from '../../reducer/Organization/actions';

import { toQueryString, useSearchParams } from '../../utils';
import { UnsavedChangesModal } from './Components/UnsavedChangesModal';
import { setModalState } from '../../reducer/Modal/actions';
import { MODAL_UNSAVED_CHANGES } from '../../reducer/Modal/types';
/**
 * This page shows the information of each buyer in our database with actions we can take like editing his profile or other admin stuff
 */
export default (props) => {
  const dispatch = useDispatch();
  const { selectedBuyer, selectedBuyerData, isLoading, buyBoxPropertyMatch } =
    useSelector(({ BuyerReducer }) => BuyerReducer);
  const [searchParams, setSearchParams] = useSearchParams();
  const [params, setParams] = useState({});
  const [changedParams, setChangedParams] = useState({});
  const [clickedTab, setClickedTab] = useState();
  const [activeTab, setActiveTab] = useState('info');
  useDocumentTitle(`Buyer - ${selectedBuyer ? selectedBuyer?.name : ''}`);

  const revertChangesHandler = () => {
    setChangedParams({});
    const buyerObj = cloneDeep(selectedBuyer);
    setParams(buyerObj);
  };

  const tabs = getTabs({
    props,
    selectedBuyer,
    isLoading,
    changedParams,
    setChangedParams,
    params,
    setParams,
    revertChangesHandler,
  });

  const handleDiscardChanges = () => {
    dispatch(setModalState(MODAL_UNSAVED_CHANGES, false));
    const queryParams = toQueryString({ clickedTab }) || {};
    setSearchParams(queryParams);
    setActiveTab(clickedTab);
    revertChangesHandler();
  };
  const tabChangeHandler = (tab) => {
    const hasUnsavedChanges = Object.keys(changedParams).length > 0;
    if (hasUnsavedChanges) {
      setClickedTab(tab);
      dispatch(setModalState(MODAL_UNSAVED_CHANGES, true));
    } else {
      const queryParams = toQueryString({ tab }) || {};
      setSearchParams(queryParams);
      setActiveTab(tab);
    }
  };

  const buyBoxMatchedPropertyTotal = Object.keys(buyBoxPropertyMatch || {})
    .map((key) => buyBoxPropertyMatch[key])
    .reduce((acc, val) => acc + val, 0);

  useEffect(() => {
    const { userId } = props.match.params;
    const tab = searchParams.get('tab') || 'info';
    setActiveTab(tab);
    dispatch(getBuyerDetails(userId));
    dispatch(getOrganizations());
  }, []);

  if (!selectedBuyer || isLoading) return <PageSkeleton />;

  return (
    <div className="buyer-page">
      <ContentHeader title={`Buyers > ${selectedBuyer.name}`} />
      <TopSectionInfo
        selectedBuyer={selectedBuyer}
        selectedBuyerData={selectedBuyerData}
        totalBuyBoxMatchedProperties={buyBoxMatchedPropertyTotal}
      />
      <div
        className="tabs-container"
        style={{
          maxWidth: ['properties', 'offers', 'buybox'].includes(activeTab)
            ? '100%'
            : '768px',
        }}
      >
        <Tabs
          type="card"
          activeKey={activeTab}
          items={tabs}
          onChange={tabChangeHandler}
        />
      </div>
      <UnsavedChangesModal handleDiscardChanges={handleDiscardChanges} />
    </div>
  );
};
