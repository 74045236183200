import { Drawer } from 'antd';
import classNames from 'classnames';
import { isMobile } from '../../../services/clientService';
import ZorbaButton from '../ZorbaButton';
import { BUTTON_VARIANT_SECONDARY } from '../ZorbaButton/constants';
import './index.scss';

export default (props) => {
  const { text, visible, saveLoading, disabledSaveButton } = props;
  return (
    <Drawer
      getContainer={false}
      mask={false}
      placement="bottom"
      open={visible}
      width="100%"
      className={classNames('save-changes-drawer', {
        visible: true,
      })}
      height={isMobile() ? 100 : 73}
      closable={false}
      contentWrapperStyle={{
        position: 'fixed',
        margin: '0 auto',
        width: '650px',
      }}
    >
      <div className="buttons-wrapper">
        <ZorbaButton
          rounded={false}
          loading={saveLoading}
          onClick={() => props.onSave && props.onSave()}
          disabled={disabledSaveButton}
        >
          Save Changes
        </ZorbaButton>
        <ZorbaButton
          rounded={false}
          variant={BUTTON_VARIANT_SECONDARY}
          onClick={() => props.onRevert && props.onRevert()}
        >
          Revert
        </ZorbaButton>
      </div>
      <div className="text">{text}</div>
    </Drawer>
  );
};
