import { useLocation, useHistory } from 'react-router-dom';
import { useMemo } from 'react';

export const toQueryString = (payload) => {
  const params = new URLSearchParams();
  if (payload) {
    Object.keys(payload).forEach((key) => {
      const value = payload[key];
      if (value && (!Array.isArray(value) || value.length > 0)) {
        params.append(key, value);
      }
    });
  }
  return params;
};

export const useSearchParams = () => {
  const { search } = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const setSearchParams = (searchParamsToSet) => {
    history.push({ search: searchParamsToSet.toString() });
  };

  return [searchParams, setSearchParams];
};

export const getName = (ob) => {
  if (!ob) return '';
  const names = [];

  if (ob.firstName) {
    names.push(ob.firstName);
  }
  if (ob.lastName) {
    names.push(ob.lastName);
  }
  if (names.length === 0 && ob.name) {
    names.push(ob.name);
  }
  return names.length > 0 ? names.join(' ') : 'UNNAMED';
};
