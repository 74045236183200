import classNames from 'classnames';
import './index.scss';

export default (props) => {
  const { stats, className, selectedPreDeal } = props;

  return (
    <>
      <div
        className={classNames('pre-deal-stats', {
          [className]: className,
        })}
      >
        {stats &&
          stats.map((stat) => (
            <div className="stats__item" key={stat.label}>
              <div className="stats__item--label">{stat.label}</div>
              <div className="stats__item--value">
                {stat.value != null ? stat.value : 'Unknown'}
              </div>
            </div>
          ))}
      </div>
      {selectedPreDeal?.lastCalculationParams?.zillowData?.hdpUrl && (
        <a
          href={`https://www.zillow.com${selectedPreDeal.lastCalculationParams.zillowData.hdpUrl}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          View on Zillow
        </a>
      )}
    </>
  );
};
